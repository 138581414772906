import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';
import { handleStorageUpdate } from '../../functions/AddBasket';
import './Item.css';

export default function Item(props) {
  const { setBusketNumber, setWishList, wishList, data, setActiveWindow, language } = useContext(Context);
  const [colors, setColors] = useState(false);
  const [colorsNumber, setColorsNumber] = useState(3);
  const [onFavorite, setOnFavorite] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (props && data) {
      const filteredProducts = data.filter((item) => {
        return item.article === props.item.article && item.id !== props.item.id;
      });
      setColors(filteredProducts);
    }

    const favorite = localStorage.getItem('gots-favorite');
    if (favorite) {
      const gotsNumbers = JSON.parse(favorite);
      const hasMatch = gotsNumbers.some((item) => item === props.item.id);
      if (hasMatch) {
        setOnFavorite(true);
      }
    }

    if (!isMobile) setColorsNumber(20);
  }, [props, data, isMobile]);

  // добавление и удаление иконки избранных
  useEffect(() => {
    if (wishList.length > 0) {
      const hasMatch = wishList.some((item) => item === props.item.id);
      if (!hasMatch) {
        setOnFavorite(false);
      } else {
        setOnFavorite(true);
      }
    } else {
      setOnFavorite(false);
    }
    // eslint-disable-next-line
  }, [wishList]);

  // нажатие на сердечко
  const handleFavoriteClick = (id) => {
    if (onFavorite) {
      handleStorageUpdate(id, 'gots-favorite', setWishList, false);
    } else {
      handleStorageUpdate(id, 'gots-favorite', setWishList, true);
    }
  };

  // наведение фото
  const [activeFoto, setActiveFoto] = useState(false);

  // наведение на корзину
  const [activeCard, setaCtiveCard] = useState(false);

  const texts = {
    ukr: {
      title: props.item.titleUa,
      price: props.item.priceUa,
      priceSale: props.item.priceUaSale,
      currency: '₴',
      link: '',
    },
    eng: {
      title: props.item.titleEn,
      price: props.item.priceEn,
      priceSale: props.item.priceEnSale,
      currency: '€',
      link: '/en',
    },
    pl: {
      title: props.item.titlePl,
      price: props.item.priceEn,
      priceSale: props.item.priceEnSale,
      currency: '€',
      link: '/pl',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <li
      onMouseEnter={!isMobile ? () => setActiveFoto(true) : undefined}
      onMouseLeave={!isMobile ? () => setActiveFoto(false) : undefined}
      className="product-card"
    >
      <div className="product-card__img-wrap">
        <img
          // onClick={() => navigate(`/product?id=${props.item.id}`)}
          className="product-card__img-one"
          src={props.item.img[0]}
          alt={selectedLanguageData.title}
        />
        <img
          onClick={() =>
            props.recommended
              ? navigate(
                  `${selectedLanguageData.link}/${props.item.category}/${props.item.titleEn.toLowerCase().replace(/\s+/g, '-')}-${
                    props.item.id
                  }`,
                )
              : navigate(
                  `${
                    location.pathname === '/' || location.pathname === '/en/' || location.pathname === '/pl/'
                      ? props.item.category
                      : location.pathname
                  }/${props.item.titleEn.toLowerCase().replace(/\s+/g, '-')}-${props.item.id}`,
                )
          }
          className={`product-card__img-two ${activeFoto ? 'product-card__img-two-active' : ''}`}
          src={props.item.img[1]}
          alt={selectedLanguageData.title}
        />
        <img
          onClick={() => handleFavoriteClick(props.item.id)}
          className={`product-card__icon ${activeFoto || onFavorite || isMobile ? 'product-card__icon-active' : ''}`}
          src={`${onFavorite ? '/img/header/favorite-full.svg' : '/img/header/favorite.svg'}`}
          alt="favorite"
        />
      </div>
      <h3 className="product-card__info-title">{selectedLanguageData.title}</h3>
      <div className="product-card__info">
        <div className="product-card__info-price">
          {selectedLanguageData.currency}{' '}
          {selectedLanguageData.priceSale && selectedLanguageData.priceSale !== '' ? (
            <>
              {selectedLanguageData.priceSale}{' '}
              <span className="product-card__info-price-sale">
                {selectedLanguageData.currency} {selectedLanguageData.price}
              </span>
            </>
          ) : (
            <>{selectedLanguageData.price}</>
          )}
        </div>

        <div className="product-card__info-bottom-icon-wrap">
          <ul className="product-card__info-color-list">
            <li
              // onClick={() => navigate(`${selectedLanguageData.link}/product?id=${props.item.id}`)}
              onClick={() =>
                navigate(
                  `${
                    location.pathname === '/' || location.pathname === '/en/' || location.pathname === '/pl/'
                      ? props.item.category
                      : location.pathname
                  }/${props.item.titleEn.toLowerCase().replace(/\s+/g, '-')}-${props.item.id}`,
                )
              }
              className={`product-card__info-color-list-item ${props.item.color}`}
            ></li>
            {colors &&
              colors.slice(0, colorsNumber).map((item, index) => {
                return (
                  <li
                    // onClick={() => navigate(`${location.pathname}/${props.item.titleEn.toLowerCase().replace(/\s+/g, '-')}-${item.id}`)}
                    onClick={() =>
                      navigate(
                        `${
                          location.pathname === '/' || location.pathname === '/en/' || location.pathname === '/pl/'
                            ? props.item.category
                            : location.pathname
                        }/${props.item.titleEn.toLowerCase().replace(/\s+/g, '-')}-${item.id}`,
                      )
                    }
                    // onClick={() => navigate(`${selectedLanguageData.link}/product?id=${item.id}`)}
                    key={index}
                    className={`product-card__info-color-list-item ${item.color}`}
                  ></li>
                );
              })}
            {colors.length > 3 && colorsNumber === 3 && (
              <li onClick={() => setColorsNumber(15)} className={`product-card__info-color-list-item`}>
                <div className="product-card__info-color-list-item-plus">+</div>
              </li>
            )}
          </ul>
          <button
            onMouseEnter={() => setaCtiveCard(true)}
            onMouseLeave={() => setaCtiveCard(false)}
            className="product-card__info-button"
            onClick={() => {
              handleStorageUpdate(props.item.id, 'gots', setBusketNumber, true);
              setActiveWindow('gots');
            }}
          >
            <img className='img-contain' src={`${activeCard ? '/img/header/cart-full.svg' : '/img/header/cart.svg'}`} alt="cart" />
          </button>
        </div>
      </div>
      {props.item.newSale && (props.item.newSale === 'new' || props.item.newSale === 'sale') && (
        <div className={`product-card__offer-${props.item.newSale}`}>
          {props.item.newSale.toUpperCase()} {props.item.newSale === 'sale' ? '%' : '!'}
        </div>
      )}
    </li>
  );
}
