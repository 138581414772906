import React from 'react';
import { CircularProgress } from '@mui/material';
import './Loader.css';

export default function Loader() {
  return (
    <div className="loader">
      <CircularProgress color="inherit" />
    </div>
  );
}
