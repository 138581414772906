import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const storage = getStorage();

// додаємо 1 фото і повертаємо лінк
export const addPhoto = async (fileName, productImage) => {
  try {
    const storageRef = ref(storage, `products/${fileName}`);
    const snapshot = await uploadBytes(storageRef, productImage);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('File available at', downloadURL);
    return downloadURL; // Возвращаем URL-адрес
  } catch (error) {
    console.error('Error uploading or getting download URL:', error);
    throw error; // Пробрасываем ошибку дальше
  }
};

export const getId = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const seconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};
