import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { useMediaQuery } from 'react-responsive';
import './ThanksPage.css';

export default function ThanksPage() {
  const { language, setBusketNumber, setLanguage } = useContext(Context);
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 576 });

  // записываем корзину
  useEffect(() => {
    const gots = localStorage.getItem('gots-language');
    if (gots) {
      setLanguage(gots);
    }
    // eslint-disable-next-line
  }, []);

  const texts = {
    ukr: {
      title: 'Дякуємо!',
      text: "ваша покупка підтверджена, ми зв'яжемося з вами якнайшвидше",
      button: 'На головну сторінку',
      errorTitle: 'ОЙ...',
      errorText: 'щось пішло не так, спробуйте ще раз',
      link: '/',
    },
    eng: {
      title: 'Thank you!',
      text: 'your purchase is confirmed, we will contact u as soon as we can',
      button: 'To main page',
      errorTitle: 'Oops...',
      errorText: 'Something went wrong, please try again',
      link: '/en/',
    },
    pl: {
      title: 'Dziękuję!',
      text: 'Twój zakup został potwierdzony, skontaktujemy się z Tobą tak szybko, jak to możliwe',
      button: 'Na głównym',
      errorTitle: 'Ups...',
      errorText: 'Coś poszło nie tak, spróbuj ponownie',
      link: '/pl/',
    },
  };

  useEffect(() => {
    if (
      location.pathname === '/successful-order' ||
      location.pathname === '/en/successful-order' ||
      location.pathname === '/pl/successful-order'
    ) {
      setBusketNumber([]);
      localStorage.removeItem('gots');
    }
    // eslint-disable-next-line
  }, [location]);

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="thanks-page">
      <div className="thanks-page__title-wrap">
        {!isMobile && (
          <p className="thanks-page__title">
            {location.pathname === '/successful-order' ||
            location.pathname === '/en/successful-order' ||
            location.pathname === '/pl/successful-order'
              ? selectedLanguageData.title
              : selectedLanguageData.errorTitle}
          </p>
        )}
        <div className="thanks-page__title-img">
          <img
            className="img-contain"
            src={
              location.pathname === '/successful-order' ||
              location.pathname === '/en/successful-order' ||
              location.pathname === '/pl/successful-order'
                ? '/img/about/heart.svg'
                : '/img/main/wishlist.svg'
            }
            alt="heart"
          />
        </div>
      </div>
      <div className="thanks-page__text">
        {location.pathname === '/successful-order' ||
        location.pathname === '/en/successful-order' ||
        location.pathname === '/pl/successful-order'
          ? selectedLanguageData.text
          : selectedLanguageData.errorText}
      </div>
      <Link to={selectedLanguageData.link} className="thanks-page__button">
        {selectedLanguageData.button}
      </Link>
    </div>
  );
}
