import React, { useContext } from 'react';
import { Context } from '../../Context';
import { Helmet } from 'react-helmet-async';
import './DeliveryPayment.css';

export default function DeliveryPayment() {
  const { language } = useContext(Context);
const texts = {
  ukr: {
    pageName: 'Доставка та оплата',
    delivery: 'Доставка',
    deliveryText: 'Замовлення по Україні відправляються службою доставки "Нова Пошта". Доставка по місту Київ можлива за запитом на таксі.',
    deliveryText2: 'Замовлення по Польщі відправляються службами доставки “DPD”, “InPost”, “Poczta Polska”.',
    deliveryText3:
      'Міжнародні замовлення можливі по всьому світу окрім Росії та Білорусі, вони відправляються службою доставки "Нова Пошта", "DHL", “UPS” та інші.',
    payment: 'Оплата',
    paymentText: 'Вартість доставки завжди розраховується за тарифами відповідних служб доставки.',
    paymentText2: 'Вартість доставки не входить в суму виробів та оплачується окремо отримувачем.',
    paymentText3: 'Усі замовлення відправляються виключно по повній передоплаті.',
    seoTitle: 'Доставка і оплата нашої продукції - Got`s Label',
    seoDescription: 'Дізнайся більше про способи оплати та доставки твоєї покупки від замовлення до твого гардеробу',
    seoLang: '/delivery-payment',
    lang: 'uk',
  },
  eng: {
    pageName: 'Delivery and payment',
    delivery: 'Delivery',
    deliveryText:
      'Orders in Ukraine are delivered by "Nova Poshta" delivery service. Delivery within Kyiv is possible upon request by taxi.',
    deliveryText2: 'Orders in Poland are delivered by "DPD", "InPost", "Poczta Polska" delivery services.',
    deliveryText3:
      'International orders are available worldwide except for Russia and Belarus, they are delivered by "Nova Poshta", "DHL", "UPS" and other delivery services.',
    payment: 'Payment',
    paymentText: 'The delivery cost is always calculated according to the tariffs of the respective delivery services.',
    paymentText2: 'The delivery cost is not included in the total amount of products and is paid separately by the recipient.',
    paymentText3: 'All orders are shipped only on full prepayment.',
    seoTitle: 'Delivery and payment of our products - Got`s Label',
    seoDescription: 'Learn more about payment methods and delivery of your purchase from order to your wardrobe',
    seoLang: '/en/delivery-payment',
    lang: 'en',
  },
  pl: {
    pageName: 'Dostawa i płatność',
    delivery: 'Dostawa',
    deliveryText:
      'Zamówienia w Ukrainie są dostarczane przez firmy kurierskie "Nova Poshta". Dostawa w oblasti Kijowa możliwa jest po wcześniejszym zamówieniu przez taxi.',
    deliveryText2: 'Zamówienia w Polsce są dostarczane przez firmy kurierskie "DPD", "InPost", "Poczta Polska".',
    deliveryText3:
      'Międzynarodowe zamówienia są dostępne na całym świecie z wyjątkiem Rosji i Białorusi. Są one dostarczane przez firmy kurierskie "Nova Poshta", "DHL", "UPS" i inne.',
    payment: 'Płatność',
    paymentText: 'Koszt dostawy zawsze jest obliczany zgodnie z taryfami odpowiednich firm kurierskich.',
    paymentText2: 'Koszt dostawy nie jest zawarty w całkowitej kwocie produktów i jest płacony osobno przez odbiorcę.',
    paymentText3: 'Wszystkie zamówienia są wysyłane tylko po pełnej przedpłacie.',
    seoTitle: 'Dostawa i płatność za nasze produkty - Got`s Label',
    seoDescription: 'Dowiedz się więcej o sposobach płatności i dostawie zakupu z zamówienia do Twojej szafy',
    seoLang: '/pl/delivery-payment',
    lang: 'pl',
  },
};

  const selectedLanguageData = texts[language.toLowerCase()];
  return (
    <div className="delivety-payment">
      <Helmet>
        <title>{selectedLanguageData.seoTitle}</title>
        <meta name="description" content={selectedLanguageData.seoDescription} />
        <link rel="canonical" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />
        {/* языки */}
        <html lang={selectedLanguageData.lang} />
        <link rel="alternate" hreflang="uk" href={`https://gotslabel.com/delivery-payment`} />
        <link rel="alternate" hreflang="pl" href={`https://gotslabel.com/pl/delivery-payment`} />
        <link rel="alternate" hreflang="en" href={`https://gotslabel.com/en/delivery-payment`} />
        <link rel="alternate" hreflang="x-default" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />
      </Helmet>

      <h1 className="delivety-payment__title">{selectedLanguageData.pageName}</h1>
      <h2 className="delivety-payment__text--bold">{selectedLanguageData.delivery}</h2>
      <h3 className="delivety-payment__text">
        {selectedLanguageData.deliveryText}
        <br />
        {selectedLanguageData.deliveryText2}
        <br />
        {selectedLanguageData.deliveryText3}
      </h3>
      <h2 className="delivety-payment__text--bold">{selectedLanguageData.payment}</h2>
      <h3 className="delivety-payment__text">
        {selectedLanguageData.paymentText}
        <br />
        {selectedLanguageData.paymentText2}
        <br />
        {selectedLanguageData.paymentText3}
      </h3>
    </div>
  );
}
