import React, { useContext, useEffect, useState } from 'react';
import {
  categoryArray,
  inputFields,
  inputSale,
  selectFields,
  textAreaFields,
  checkboxes,
  collectionArray,
  colors,
  newSale,
} from './Others';
import { Context } from '../../../Context';
import { db } from '../../../firebase';
import { setDoc, doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import Snackbar from '@mui/material/Snackbar';
import { addPhoto, getId } from './helper';
import { isImage } from '../../../functions/LoadImages';
import Loader from '../../../components/Loader/Loader';
import RequireAdminAuth from '../RequireAdminAuth';
import { filter } from '../../../functions/Filter';
import { Link } from 'react-scroll';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from 'react-responsive';
import imageCompression from 'browser-image-compression';
import './Setting.css';

export default function Setting() {
  const { data, setData } = useContext(Context);
  const [dataFilter, setDataFilter] = useState([]); //фільтрована дата
  const [arrayPhoto, setArrayPhoto] = useState([]); //масив фотографій з поля інпут
  const [arrayDeletePhoto, setArrayDeletePhoto] = useState([]); //масив фотографій для видалення з сховища
  const [product, setProduct] = useState({}); // один товар, формується при заповненні інпутів
  const [flagOpenForm, setFlagOpenForm] = useState(false); // флаг, який контролює відкривання форми заповнення товару
  const [editItem, setEditItem] = useState(null); // записуємо товар який передається в поля інпутів
  const [isLoading, setIsLoading] = useState(false); // стан лоадера

  const storage = getStorage();

  const isMobile = useMediaQuery({ maxWidth: 920 });
  // ------------------------------- для фільтрів -------------------------------

  const [selectedCategory, setSelectedCategory] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(false);
  const [searchItem, setSearchItem] = useState(false);
  const [selectedNewSale, setSelectedNewSale] = useState(false);
  const [showBestsellers, setBestsellers] = useState(false);
  const [showHide, setHide] = useState(false);

  // ------------------------------- повідомленя -------------------------------

  const [openAlert, setOpenAlert] = useState('');
  const handleAlertClose = () => {
    setOpenAlert('');
  };

  // ------------------------------- створення фільтрованої дати -------------------------------

  useEffect(() => {
    if (data) {
      setDataFilter(data);
    }
  }, [data]);

  // ------------------------------- оновлення дати з контексту -------------------------------

  const updateData = (product, id) => {
    const updatedValues = { ...product, id };
    const updatedData = [...data, updatedValues];
    setData(updatedData);
  };

  // ------------------------------- додавання товару -------------------------------

  const addProduct = async () => {
    setIsLoading(true);
    try {
      const imageUrls = await Promise.all(
        arrayPhoto.map(async (image) => {
          const uniqueName = `${Math.random().toString(36).substring(2, 10)}-${image.name}`;
          const imageUrl = await addPhoto(uniqueName, image);
          return imageUrl;
        }),
      );
      let newProduct = { ...product, img: imageUrls };
      const newID = getId();
      await setDoc(doc(db, 'data', newID), newProduct);
      updateData(newProduct, newID);
      setOpenAlert('Додано');
    } catch (error) {
      console.error('Error adding product:', error);
    }
    setIsLoading(false);
  };

  // -------------------------------  редагування товару -------------------------------
  const changeProduct = async (product) => {
    setIsLoading(true);
    // видалення фотографій (якщо є)
    if (arrayDeletePhoto.length > 0) {
      const deleteImgPromises = arrayDeletePhoto.map((img) => deletePhoto(img));
      await Promise.all(deleteImgPromises);
    }
    // додавання нових фотографій з поля вводу (якщо є)
    if (arrayPhoto.length > 0) {
      const imageUrls = await Promise.all(
        arrayPhoto.map(async (image) => {
          const uniqueName = `${Math.random().toString(36).substring(2, 10)}-${image.name}`;
          const imageUrl = await addPhoto(uniqueName, image);
          return imageUrl;
        }),
      );
      // робимо новий масив з фотками
      const newImgProduct = { ...product, img: [...product.img, ...imageUrls] };
      // пушим на фаербейс
      await setDoc(doc(db, 'data', newImgProduct.id), newImgProduct);
      setOpenAlert('Оновлено');
      const index = data.findIndex((item) => item.id === product.id);
      if (index !== -1) {
        const updatedData = [...data];
        updatedData[index] = newImgProduct;
        setData(updatedData);
      }
      setOpenAlert('Оновлено');
    } else {
      // якщо немає нових фотографій, пушим на фаербейс
      await setDoc(doc(db, 'data', product.id), product);
      // оновлюємо дату
      const index = data.findIndex((item) => item.id === product.id);
      if (index !== -1) {
        const updatedData = [...data];
        updatedData[index] = product;
        setData(updatedData);
      }
      setOpenAlert('Оновлено');
    }
    setIsLoading(false);
  };

  //-------------------------------- видалення товару---------------------------------------

  const deleteProduct = async (id, imgArr) => {
    setIsLoading(true);
    // оновлюємо дату
    const index = data.findIndex((item) => item.id === id);
    if (index !== -1) {
      const updatedData = [...data];
      updatedData.splice(index, 1);
      setData(updatedData);
    }
    try {
      const deleteImgPromises = imgArr.map((img) => deletePhoto(img));
      await Promise.all(deleteImgPromises);
      await deleteDoc(doc(db, 'data', id));
      setOpenAlert('Видалено');
    } catch (error) {
      console.error('Error deleting product:', error);
    }
    setIsLoading(false);
  };

  // ------------------------------- додавання фото -------------------------------

  // const handleFileDownload = (e) => {
  //   if (e.target.files.length > 0) {
  //     const filesArray = Array.from(e.target.files);
  //     setArrayPhoto(filesArray);
  //   }
  // };

   const handleFileDownload = async (e) => {
     if (e.target.files.length > 0) {
       const filesArray = Array.from(e.target.files);

       // Поддерживаемые форматы
       const supportedFormats = ['image/jpeg', 'image/png'];

       // Проверка на наличие неподдерживаемых форматов
       const hasUnsupportedFormat = filesArray.some((file) => !supportedFormats.includes(file.type));

       if (hasUnsupportedFormat) {
         alert('Один або кілька файлів мають непідтримувані формати. Завантажуйте лише зображення JPEG або PNG.');
         setArrayPhoto([]); // Очищаем массив, если есть неподдерживаемые форматы
         return; // Останавливаем процесс
       }

       // Настройки сжатия
       const options = {
         maxSizeMB: 0.4, // Максимальный размер файла в мегабайтах
         maxWidthOrHeight: 1920, // Максимальная ширина или высота изображения
         useWebWorker: true, // Использовать Web Worker для повышения производительности
         fileType: 'image/webp', // Указываем формат WebP
         initialQuality: 0.7,
       };

       // Функция для создания нового файла с изменённым расширением
       const renameFile = (file, newExtension) => {
         const newName = file.name.replace(/\.[^/.]+$/, `.${newExtension}`);
         return new File([file], newName, { type: `image/${newExtension}` });
       };

       // Сжимаем каждое изображение
       const compressedFiles = await Promise.all(
         filesArray.map(async (file) => {
           try {
             const compressedBlob = await imageCompression(file, options);
             // Создаём новый файл с обновлённым расширением
             const compressedFile = renameFile(file, 'webp');
             return new File([compressedBlob], compressedFile.name, { type: 'image/webp' });
           } catch (error) {
             console.error('Error compressing image:', error);
             return file; // Если сжатие не удалось, возвращаем оригинальный файл
           }
         }),
       );
       // Устанавливаем массив сжатых изображений
       setArrayPhoto(compressedFiles);
     }
   };

  // ------------------------------- видалення 1 фото -------------------------------

  const deletePhoto = async (imgURL) => {
    const fileRef = ref(storage, imgURL);

    try {
      await deleteObject(fileRef);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  //  ---------------------- видалення 1 фото з товару який редагуємо -------------------------

  const deletePhotoLocal = (url) => {
    if (!editItem || !editItem.img) {
      console.error('editItem або editItem.img не існує');
      return;
    }
    const imgArr = [...editItem.img];
    const newArray = imgArr.filter((img) => img !== url);
    setEditItem({
      ...editItem,
      img: newArray,
    });
    setArrayDeletePhoto([...arrayDeletePhoto, url]);
  };

  // -------------------------------  запис інпутів -------------------------------

  const handleInputChange = (event, editItem) => {
    const { name, value, type, checked } = event.target;

    if (editItem) {
      if (type === 'checkbox') {
        setEditItem({ ...editItem, [name]: checked });
      } else {
        // Если новая цена не sale, устанавливаем priceUaSale и priceEnSale в false
        if (name === 'newSale' && value !== 'sale') {
          setEditItem({
            ...editItem,
            [name]: value,
            priceUaSale: '',
            priceEnSale: '',
            interest: '',
          });
        } else {
          setEditItem({ ...editItem, [name]: value });
        }
      }
    } else {
      const newValue = type === 'checkbox' ? checked : value;
      setProduct({
        ...product,
        [name]: newValue,
      });
    }
  };
  // -------------------------------  кнопки для переміщення зображень -------------------------------
  const handleMoveUpElement = (index) => {
    const targetIndex = index + 1;
    const newItems = [...editItem.img]; // Копіюємо масив елементів
    const elementToMove = newItems.splice(index, 1)[0]; // Видаляємо елемент з поточного індексу
    newItems.splice(targetIndex, 0, elementToMove); // Вставляємо елемент на нову позицію
    setEditItem({ ...editItem, img: newItems }); // Оновлюємо стан з новим масивом
  };

  const handleMoveDownElement = (index) => {
    const targetIndex = index - 1;
    const newItems = [...editItem.img]; // Копіюємо масив елементів
    const elementToMove = newItems.splice(index, 1)[0]; // Видаляємо елемент з поточного індексу
    newItems.splice(targetIndex, 0, elementToMove); // Вставляємо елемент на нову позицію
    setEditItem({ ...editItem, img: newItems }); // Оновлюємо стан з новим масивом
  };
  // ------------------------------- фільтр -------------------------------
  // функция изменений для фильра
  const handleChangeFilter = (editFunction, valueType) => (event) => {
    const newValue = valueType === 'checkbox' ? event.target.checked : event.target.value;
    editFunction(newValue);
  };

  // фильтр
  useEffect(() => {
    const filters = {
      category: selectedCategory === 'Всі товари' ? false : selectedCategory,
      collection: selectedCollection === 'Всі колекції' ? false : selectedCollection,
      newSale: selectedNewSale === 'Обрати New або Sale' ? false : selectedNewSale,
      titleUa: searchItem,
      bestsellers: showBestsellers,
      hide: showHide,
    };

    if (data) {
      const newFilteredProducts = filter(data, filters);
      setDataFilter(newFilteredProducts);
    }
    // eslint-disable-next-line
  }, [selectedCategory, selectedCollection, selectedNewSale, showBestsellers, showHide, searchItem]);

  // показываем корректные наименования
  const getTranslation = (value, array) => {
    const item = array.find((item) => item.value === value);
    return item ? item.label : '';
  };

  return (
    <RequireAdminAuth>
      {isLoading && <Loader />}
      <div className="setting" id="top">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          open={openAlert}
          onClose={handleAlertClose}
          message={openAlert}
          key={'top-center'}
        />
        <div className="setting__button">
          <button
            className="setting__button-title"
            onClick={() => {
              setEditItem(null);
              setArrayPhoto([]);
              setArrayDeletePhoto([]);
              setFlagOpenForm(!flagOpenForm);
            }}
          >
            {!flagOpenForm ? 'Додати товар' : 'Відміна'}
          </button>
        </div>
        {flagOpenForm && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (editItem) {
                changeProduct(editItem);
              } else {
                addProduct();
              }
              setFlagOpenForm(false);
              setArrayPhoto([]);
              setArrayDeletePhoto([]);
            }}
          >
            <div className="setting__inputs-window">
              <div className="setting__inputs">
                <input className="setting__inputs-file" onChange={handleFileDownload} type="file" multiple required={!editItem} />

                {inputFields.map((field) => (
                  <TextField
                    key={field.name}
                    name={field.name}
                    onChange={(e) => handleInputChange(e, editItem)}
                    value={editItem ? editItem[field.name] : product.name}
                    type="text"
                    variant="outlined"
                    required={field.req}
                    label={field.placeholder}
                    placeholder={field.req ? '*' : ''}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black', fontSize: '15px', fontFamily: 'KT Kiyosuna Sans' },
                    }}
                    inputProps={{
                      sx: { color: 'black' },
                      style: {
                        borderColor: 'black',
                        fontSize: '16px',
                        fontFamily: 'KT Kiyosuna Sans',
                        background: field.req ? 'inherit' : '#dedcdc',
                      },
                      onKeyPress: (event) => {
                        // Предотвращаем ввод, если это не число или не разрешенный символ
                        if (field.type === 'number') {
                          const allowedCharacters = /^\d+$/; // Разрешены только цифры
                          if (!allowedCharacters.test(event.key)) {
                            event.preventDefault();
                          }
                        }
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'black' },
                      },
                    }}
                  />
                ))}

                {selectFields.map((field) => (
                  <select
                    key={field.name}
                    className="setting__inputs-select"
                    name={field.name}
                    onChange={(e) => handleInputChange(e, editItem)}
                    value={editItem ? editItem[field.name] : product.name}
                    required={field.req}
                  >
                    <option value="" hidden>
                      {field.placeholder}
                    </option>
                    {field.options.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                ))}

                {/* {(product && product.sale) || (editItem && editItem.sale) */}
                {(product && product.newSale === 'sale') || (editItem && editItem.newSale === 'sale')
                  ? inputSale.map((field) => (
                      <TextField
                        key={field.name}
                        name={field.name}
                        onChange={(e) => handleInputChange(e, editItem)}
                        value={editItem ? editItem[field.name] : product.name}
                        type="numder"
                        required
                        variant="outlined"
                        label={field.placeholder}
                        placeholder="++"
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: 'black',
                            fontSize: '15px',
                            fontFamily: 'KT Kiyosuna Sans',
                          },
                        }}
                        inputProps={{
                          sx: { color: 'black' },
                          style: {
                            borderColor: 'black',
                            fontSize: '16px',
                            fontFamily: 'KT Kiyosuna Sans',
                          },
                          onKeyPress: (event) => {
                            // Предотвращаем ввод, если это не число или не разрешенный символ
                            if (field.type === 'number') {
                              const allowedCharacters = /^\d+$/; // Разрешены только цифры
                              if (!allowedCharacters.test(event.key)) {
                                event.preventDefault();
                              }
                            }
                          },
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': { borderColor: 'black' },
                          },
                        }}
                      />
                    ))
                  : null}

                {checkboxes.map((setting) => (
                  <label key={setting.name} className="setting__inputs-select-checkbox">
                    <input
                      name={setting.name}
                      type="checkbox"
                      onChange={(e) => handleInputChange(e, editItem)}
                      value={editItem ? editItem[setting.name] : product.name}
                      checked={editItem ? editItem[setting.name] : product[setting.name]}
                    />
                    {setting.label}
                  </label>
                ))}
              </div>

              {/* фотки */}
              {editItem && (
                <div className="setting__inputs-foto">
                  {editItem.img.map((url, index) => (
                    <div key={index} className="setting__inputs-foto-inner">
                      <div className="setting__inputs-foto-wrapper">
                        {isImage(url) ? (
                          <img src={url} alt="small foto" />
                        ) : (
                          <img className="img-contain" src="/img/main/play.svg" alt="small foto" />
                        )}
                        <div
                          onClick={() => {
                            deletePhotoLocal(url);
                          }}
                          className="setting__inputs-foto-delete"
                        >
                          x
                        </div>
                      </div>
                      <div className="setting__inputs-foto-arrow-wrap">
                        <button
                          type="button"
                          className="setting__inputs-foto-arrow setting__inputs-foto-arrow--up"
                          onClick={() => {
                            handleMoveDownElement(index);
                          }}
                        >
                          <img src="/img/main/arrow.svg" alt="arrow" className="img-contain" />
                        </button>

                        <button
                          type="button"
                          className="setting__inputs-foto-arrow"
                          onClick={() => {
                            handleMoveUpElement(index);
                          }}
                        >
                          <img src="/img/main/arrow.svg" alt="arrow" className="img-contain" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="setting__inputs">
                {textAreaFields.map((field) => (
                  <TextField
                    placeholder={field.req ? '*' : ''}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black', fontSize: '15px', fontFamily: 'KT Kiyosuna Sans' },
                    }}
                    key={field.name}
                    required={field.req}
                    name={field.name}
                    label={field.placeholder}
                    onChange={(e) => handleInputChange(e, editItem)}
                    value={editItem ? editItem[field.name] : product.name}
                    variant="outlined"
                    multiline
                    rows={4}
                    inputProps={{
                      sx: { color: 'black' },
                      style: {
                        borderColor: 'black',
                        fontSize: '16px',
                        fontFamily: 'KT Kiyosuna Sans',
                        background: field.req ? 'inherit' : '#dedcdc',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'black' },
                      },
                    }}
                  />
                ))}
              </div>
            </div>
            <button className="setting__button-title" type="submit">
              Зберегти
            </button>
          </form>
        )}

        <div className="setting__search">
          <select className="setting__search-select" onChange={handleChangeFilter(setSelectedCategory)}>
            <option>Всі товари</option>
            {categoryArray.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>

          <select className="setting__search-select" onChange={handleChangeFilter(setSelectedCollection)}>
            <option>Всі колекції</option>
            {collectionArray.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>

          <select className="setting__search-select" onChange={handleChangeFilter(setSelectedNewSale)}>
            <option>Обрати New або Sale</option>
            {newSale.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>

          <input
            className="setting__search-select"
            onChange={handleChangeFilter(setSearchItem, 'text')}
            type="text"
            name="search"
            placeholder="Пошук товару за ім`ям"
          />

          <label className="setting__search-select-change">
            <input name="bestsellers" type="checkbox" onChange={handleChangeFilter(setBestsellers, 'checkbox')} />
            Bestsellers
          </label>
          <label className="setting__search-select-change">
            <input name="hide" type="checkbox" onChange={handleChangeFilter(setHide, 'checkbox')} />
            Сховані товари
          </label>
        </div>

        <div className="setting__item-wrapper">
          {data &&
            dataFilter
              .slice()
              .reverse()
              .map((item, index) => (
                <div key={index} className="setting__item">
                  <div className="setting__item-info">
                    <div className="setting__item-info-picture">
                      {isImage(item.img[0]) ? (
                        <img src={item.img} alt="small foto" />
                      ) : (
                        <img className="img-contain" src="/img/main/play.svg" alt="small foto" />
                      )}
                    </div>
                    <div className="setting__item-info-wrapper">
                      <div className="setting__item-info-title">{item.titleUa}</div>
                      <div className="setting__item-info-others">Артикль: {item.article}</div>
                      {!isMobile && <div className="setting__item-info-others">Колір: {getTranslation(item.color, colors)}</div>}
                      {!isMobile && (
                        <div className="setting__item-info-others">Категорія: {getTranslation(item.category, categoryArray)}</div>
                      )}
                    </div>
                  </div>
                  <Link
                    to="top"
                    offset={-100}
                    smooth={true}
                    duration={300}
                    className="setting__item-edit"
                    onClick={() => {
                      setEditItem(item);
                      setFlagOpenForm(true);
                    }}
                  >
                    {isMobile ? 'R' : 'Редагувати'}
                  </Link>
                  <button className="setting__item-delete" onClick={() => deleteProduct(item.id, item.img)}>
                    X
                  </button>
                </div>
              ))}
        </div>
      </div>
    </RequireAdminAuth>
  );
}
