import React, { useEffect, useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';
import Item from '../Item/Item';
import ItemList from '../ItemList/ItemList';
import { Link } from 'react-router-dom';
import './OurBestsellers.css';

export default function OurBestsellers() {
  const { data, language } = useContext(Context);
  const [list, setList] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 576 });

  useEffect(() => {
    if (data) {
      const filteredItems = data.filter((item) => item.bestsellers === true).slice(0, 4);

      setList(filteredItems);
    }
  }, [data]);

  const texts = {
    eng: {
      title: 'our bestsellers',
      link: '/bestsellers',
    },
    ukr: {
      title: 'наші бестселери',
      link: '/en/bestsellers',
    },
    pl: {
      title: 'nasze bestsellery',
      link: '/pl/bestsellers',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <>
      {list.length > 3 && (
        <div className="ourBestsellers">
          <Link to={selectedLanguageData.link} className="ourBestsellers__title">
            {selectedLanguageData.title}
          </Link>
          {!isMobile ? (
            <ItemList list={list} />
          ) : (
            <ul className="ourBestsellers__list">
              {list.map((item, index) => (
                <Item key={item.id} item={item} />
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
}
