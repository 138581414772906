import React, { useContext } from 'react';
import { Context } from '../../Context';
import './CookiePolicy.css';

export default function CookiePolicy() {
  const { language } = useContext(Context);

  const texts = {
    ukr: {
      politCookie: {
        title: 'Політика використання файлів cookie',
        text: 'Цей веб-сайт використовує файли cookie для поліпшення вашого досвіду користування. Файли cookie – це невеликі текові файли, які можуть зберігати інформацію про ваш веб-переглядач. Вони використовуються для забезпечення коректної роботи функцій сайту та для збору аналітичних даних про трафік.',
      },
      conteiner2: {
        title: 'Ми використовуємо файли cookie для:',
        text: '- Забезпечення коректної роботи веб-сайту.',
        text1: '- Аналізу та відстеження використання нашого сайту.',
        text2: '- Покращення вашого досвіду користування на сайті.',
        text3:
          'За допомогою файлів cookie ми не зберігаємо особисту інформацію, яка може ідентифікувати вас особисто, якщо ви не надали цю інформацію самостійно.',
        text4:
          'Ви можете відмовитися від прийняття файлів cookie, налаштувавши ваш веб-переглядач відповідним чином. Будь ласка, зверніть увагу, що відмова від файлів cookie може призвести до обмежень у функціональності деяких частин нашого веб-сайту.',
        text5:
          'Ця політика використання файлів cookie підлягає періодичному оновленню, тому ми радимо періодично переглядати цю сторінку для отримання оновленої інформації про файли cookie.',
        text6:
          "Якщо у вас є будь-які питання про нашу політику використання файлів cookie, будь ласка, зв'яжіться з нами за допомогою контактної інформації, наведеної на нашому веб-сайті.",
      },
    },
    eng: {
      politCookie: {
        title: 'Cookie Policy',
        text: "This website uses cookies to enhance your user experience. Cookies are small text files that can store information about your web browser. They are used to ensure the proper functioning of the website's features and to collect analytical data about traffic.",
      },
      conteiner2: {
        title: 'We use cookies to:',
        text: '- Ensure the proper functioning of the website.',
        text1: '- Analyze and track the usage of our site.',
        text2: '- Improve your user experience on the site.',
        text3:
          'We do not store personal information that can identify you personally unless you provide this information voluntarily.',
        text4:
          'You can opt out of accepting cookies by adjusting your web browser settings accordingly. Please note that refusing cookies may limit the functionality of some parts of our website.',
        text5:
          'This cookie policy is subject to periodic updates, so we recommend periodically reviewing this page for updated information about cookies.',
        text6:
          'If you have any questions about our cookie policy, please contact us using the contact information provided on our website.',
      },
    },
    pl: {
      politCookie: {
        title: 'Polityka plików cookie',
        text: 'Ta strona internetowa używa plików cookie w celu poprawy jakości korzystania z niej. Pliki cookie to niewielkie pliki tekstowe, które mogą przechowywać informacje o przeglądarce internetowej. Są one używane do zapewnienia poprawnego działania funkcji strony internetowej oraz do zbierania danych analitycznych dotyczących ruchu.',
      },
      conteiner2: {
        title: 'Wykorzystujemy pliki cookie w celu:',
        text: '- Zapewnienia poprawnego działania strony internetowej.',
        text1: '- Analizy i śledzenia korzystania z naszej witryny.',
        text2: '- Poprawy jakości korzystania z witryny.',
        text3:
          'Nie przechowujemy danych osobowych, które mogłyby identyfikować cię osobiście, chyba że podasz takie informacje dobrowolnie.',
        text4:
          'Możesz zrezygnować z akceptowania plików cookie, dostosowując ustawienia swojej przeglądarki internetowej. Należy jednak pamiętać, że odmowa plików cookie może ograniczyć funkcjonalność niektórych części naszej strony internetowej.',
        text5:
          'Niniejsza polityka plików cookie podlega okresowym aktualizacjom, dlatego zalecamy regularne sprawdzanie tej strony w celu uzyskania aktualnych informacji na temat plików cookie.',
        text6:
          'Jeśli masz jakiekolwiek pytania dotyczące naszej polityki plików cookie, prosimy o kontakt za pomocą informacji kontaktowych podanych na naszej stronie internetowej.',
      },
    },
  };
  const selectedLanguageData = texts[language.toLowerCase()];
  return (
    <div className="cookie-policy">
      <h2>{selectedLanguageData.politCookie.title}</h2>
      <p>{selectedLanguageData.politCookie.text}</p>
      <h2>{selectedLanguageData.conteiner2.title}</h2>
      <p>{selectedLanguageData.conteiner2.text}</p>
      <p>{selectedLanguageData.conteiner2.text1}</p>
      <p>{selectedLanguageData.conteiner2.text2}</p>
      <p>{selectedLanguageData.conteiner2.text3}</p>
      <p>{selectedLanguageData.conteiner2.text4}</p>
      <p>{selectedLanguageData.conteiner2.text5}</p>
      <p>{selectedLanguageData.conteiner2.text6}</p>
    </div>
  );
}
