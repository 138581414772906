import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../Context';
import './PrivacyPolicy.css';

export default function PublicOfferPrivacyPolicy() {
  const { language } = useContext(Context);
  const texts = {
    ukr: {
      generalTitle: 'ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ',
      date: 'Дата набрання чинності: 30 березня 2024 року',
      firstText:
        '«GOT`S LABEL» поважає та піклується про конфіденційність персональнихданих людей, які користуються сайтом. Дана Політика конфіденційностірозроблена з метою надання повної та прозорої інформації щодо обробкиданих Власником сайту. Ми визнаємо, що маємо особливе зобов’язання щодозахисту персональних даних, отриманих від дітей. Ми не збираємо і небудемо свідомо збирати особисті дані будь-якої дитини віком до 18 років без згоди її батьків або опікунів. Якщо ви є батьком або опікуном і вас турбує передача персональних даних вашої дитини, будь ласка, напишітьнам на електронну адресу: gotslabel.cooperation@gmail.com.',
      secondText:
        "Дана Політика конфіденційності є незалежним документом. Ваші права іобов'язки при використанні веб-сайту https://www.gotslabel.com (далі - сайт або Веб-сайт), також визначаються (можуть бути визначені) наступними документами (угодами):",
      fisrtItemList: '- Згода на обробку та використання персональних даних,',
      secondItemList: '- Публічна оферта',
      thirdItemList: '- інші документи.',
      fisrtLink:
        'Використання та обробка персональних даних Власником сайту здійснюєтьсявідповідно до ',
      linkToPage: ' Згоди на використання та обробку персональних даних.',
      thirdText:
        'Політика конфіденційності діє відносно всієї інформації, яку інтернет-магазин «GOT`S LABEL», може отримати про Користувача під час використання cайту інтернет-магазину, програм і продуктів інтернет-магазину.',
      fourText:
        'Політика конфіденційності регулює умови використання Веб-сайту та загальні правила збору, обробки, поширення, використання і зберігання даних Користувача Власником сайту.',
      fifeText:
        'Під час обробки та використання конфіденційної інформації та персональних даних, Власник сайту дотримується юридичних вимог  для захисту інформації, яка отримується  від  Користувачів. ',
      sixText:
        'Політика конфіденційності - це документ, в якому пояснюється, яку інформацію про Користувачів буде збирати Власник сайту  при використанні Користувачами сайту,  та як ця інформація буде використовуватися, передаватись і захищатись.',
      secondTitle: 'ТЕРМІНИ',
      user: 'Кожного разу, коли в Політиці конфіденційності використовується термін з великої літери, він отримує таке значення:',
      personalData:
        'Користувач  -  будь-яка фізична особа, яка має доступ до Сайту, за допомогою мережі Інтернет і використовує Сайт інтернет-магазину та яка переглядає інформацію на Сайті та/або замовляє та/або купує Товари/послуги з використанням технічних інструментів Сайту. Користувач в цій Політиці є також суб’єктом персональних даних, тобто фізичною особою, персональні дані якої обробляються. ',
      confidentialInfo:
        'Персональні дані – персональні дані - відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована.',
      dataPrivacy:
        "Конфіденційна інформація цє інформація про фізичну особу (суб’єкта персональних даних), а також інформація, доступ до якої обмежено фізичною або юридичною особою, крім суб'єктів владних повноважень.",
      onlineStore:
        'Конфіденційність персональних даних – обов’язкова для дотримання Власником сайту або іншими особами, які отримали доступ до персональних даних, вимога не допускати їх поширення без згоди суб’єкта персональних даних або наявності іншої законної підстави.',
      website:
        'Інтернет-магазин «GOT`S LABEL» - засіб для представлення або реалізації товару, роботи чи послуги шляхом вчинення електронного правочину. ',
      secondWebsite:
        'Сайт інтернет-магазину «GOT`S LABEL» (далі по тексту – Сайт/Сайт інтернет-магазину) – сукупність розміщених в мережі Інтернет веб-сторінок, що об’єднані доменом https://www.gotslabel.com.  ',
      owner:
        ' Власник («Власник Cайту») - Фізична особа- підприємець Готочкіна Світлана Геннадіївна, Індивідуальний Податковий Номер 3192020323, адреса реєстрації: 54037, місто Миколаїв, вул. Коцюбинського 20, яка здійснює адміністрування та технічну підтримку Сайту та уповноважені співробітники на управління Сайтом, що діють від імені інтернет-магазину «GOT`S LABEL», які організовують і здійснюють обробку персональних даних, а також визначають цілі обробки персональних даних, склад персональних даних, що підлягають обробці, дії або операції, що здійснюються з персональними даними.',
      dataProcessing:
        'Обробка персональних даних – означає операцію або набір операцій, що виконуються з персональними даними або наборами персональних даних автоматизованим або неавтоматизованим способом, наприклад збір, запис, упорядкування, упорядкування, зберігання, адаптація або зміна, завантаження, перегляд, використання, розголошення шляхом надсилання, розповсюдження чи іншого використання, зіставлення чи об’єднання, обмеження, видалення чи знищення.',
      dataSharing:
        'Поширення персональних даних – діяльність, спрямована на розкриття персональних даних необмеженому колу осіб.',
      dataExchange:
        ' Обмін персональними даними – діяльність, спрямована на розкриття персональних даних певній особі або групі людей.',
      dataSuspension:
        'Призупинення обробки персональних даних – тимчасове припинення обробки персональних даних (за винятком випадків, коли обробка необхідна для оновлення/оновлення персональних даних/ідентифікації персональних даних).',
      dataUsage:
        'Використання персональних даних – операції, які здійснюються Власником з персональними даними з метою прийняття рішення або здійснення інших дій, які мають правові наслідки щодо володільця персональних даних чи інших осіб, або іншим чином стосуються прав і свобод володільця, персональних даних та інших осіб.',
      dataDestruction:
        'Знищення персональних даних – дії, що унеможливлюють відтворення змісту персональних даних у системі персональних даних та (або) в результаті яких знищуються матеріальні носії персональних даних.',
      cookies:
        'Файли cookie («Файли cookie») – дані, які автоматично передаються Власнику під час використання Сайту за допомогою програмного забезпечення, встановленого на пристрої Користувача, включаючи IP-адресу, географічне розташування, інформацію про браузер і тип операційної системи на пристрої Користувача, пристрій характеристики та програмне забезпечення, яке використовує Користувач, дата та час доступу до Сайту. ',
      ipAddress:
        "IP-адреса – номер, призначений мережевому інтерфейсу, який використовується комп'ютером для ідентифікації кожного разу, коли він отримує доступ до Інтернету.",
      gdpr: 'GDPR – Регламент (ЄС) 2016/679 Європейського Парламенту та Ради від 27 квітня 2016 року про захист осіб у зв’язку з обробкою персональних даних і про вільний рух таких даних, а також про скасування Директиви 95/46 / EC (загальний регламент захисту даних).',
      otherTerms:
        'Інші терміни та поняття, що використовуються в цій Політиці, повинні розумітися відповідно до чинного законодавства України.',
      gatheringInformation: {
        title: 'МЕТА ЗБИРАННЯ ІНФОРМАЦІЇ',
        section1:
          '1.1. Користувачі не зобов’язані надавати Власнику свої Персональні дані, проте, без певної інформації про вас ми не зможемо надати вам наші послуги та продати і передати Товар, укласти правочин.',
        section2:
          '1.2. Власник не має можливості здійснювати оцінку дієздатності Користувача. Проте, ми діємо виходячи із того, що Користувач діє добросовісно, надає правдиві та повні персональні дані, а також докладає всіх необхідних зусиль для підтримання таких даних в актуальному стані.',

        section3:
          '1.3. Надання Користувачем Власнику номера телефону та адреси електронної пошти підтверджує його згоду на отримання від Власника push-повідомлень, cмс-повідомлень, повідомлень в чатах месенджерів та на електрону пошту. Користувач може в будь-який момент відмовитися від отримання повідомлень в чаті мобільного додатку. Власник сайту залишає за собою право проводити інформаційні розсилки.',
        section4:
          '1.4. Політика конфіденційності діє відносно всієї інформації, яку Власник, може отримати щодо Користувача під час використання Сайту, купівлі Товару.',

        section5:
          '1.5. Власник збирає, використовує, оброблює та передає інформацію від і про Користувачів з метою надання Користувачам послуг, продажу товарів чи інших дій, які зумовлені господарською діяльністю Власника.',

        section6:
          "1.6. Технічні (програмні) засоби Сайту можуть збирати такі Персональні дані: • ім'я та прізвище,\n• номер телефону,\n• адреса електронної пошти,\n• іншу інформацію, яка необхідна для надання послуг або продажу товарів Користувачам.",

        section7:
          '1.7. Власник також має право отримувати необхідну інформацію від і про Користувача через колл-центр у випадку необхідності підтвердження замовлення чи отримання додаткової інформації. В такому випадку, Користувач має надати свою добровільну і безсумнівну згоду на збір і обробку зазначених персональних даних і їх передачу третім особам для забезпечення функціонування Веб-сайту та / або надання послуг чи продажі товарів.',

        section8:
          '1.8. Користувач також надає свою добровільну згоду на запис розмови із співробітниками колл-центру для покращення якості обслуговування Користувача.',

        section9:
          '1.9. Власник вважатиме всю надану інформацію від Користувача достовірною та такою, яка відповідає дійсності. Власник не несе відповідальності за надання Користувачем з будь-яких причин завідомо не достовірної, неточної чи не правдивої інформації. В таких випадках, Користувач самостійно несе відповідальність за будь-які наслідки, що виникають в зв’язку з наданням Власнику недостовірної, неповної чи не точної інформації.',

        section10:
          "1.10. Власник сайту не збирає дані, обробка яких потребує спеціального захисту та пов’язана з дотриманням конкретних положень законодавства, включаючи дані, що розкривають етнічне та расове походження, політичні, релігійні чи філософські переконання, членство в політичних партіях чи профспілках, інформацію про кримінальні судимості, а також дані про стан здоров'я, сексуальну приналежність чи сексуальну орієнтацію, біометричні та генетичні дані.",

        section11:
          "1.11. Доступ дітей до Сайту, послуг, сервісів, товару, в менеджери (чати) інтернет-магазину здійснюється виключно за рішенням та з дозволу їх батьків, що підтверджується шляхом надання даних дитини Власнику одним з батьків та за умови прийняття (акцептування) батьками Договору публічної оферти. Ми просимо батьків ознайомити їхніх дітей, з цією Політикою конфіденційності. Ми просимо дітей не надсилати на нашу електронну адресу жодної особистої інформації. Якщо батьки або інші законні представники дитини виявлять, що діти зробили яку-небудь інформацію доступною для Власника сайту, і вважатимуть, що надані дитиною дані мають бути знищені, таким батькам необхідно зв'язатися з нами через електронну адресу, наведену у цій Політиці конфіденційності, після чого ми негайно видалимо особисту інформацію про дитину.",

        section12:
          "1.12. Власник також має право збирати інформацію про запити Користувачів в службу підтримки щодо роботи сайту і пов'язаних з ним сервісів, питання, пропозиції чи скарги Користувачів.",

        section13:
          "1.13. Під час відвідування Користувачем сайту та замовлення товарів чи послуг, Власник має право, а Користувач погоджується з таким правом Власника розміщувати невеликі файли даних (cookie), на комп'ютері або іншому пристрої, з якого був здійснений доступ до сайту Користувачем. Власник використовує ці технології, щоб налаштувати сайт і рекламу; вимірювати ефективність реклами і збирати інформацію для зниження ризику, запобігання шахрайства та зміцнення довіри і безпеки. Користувач може самостійно контролювати використання файлів cookie в налаштуваннях інтернет-браузера.",
      },
      useInformation: {
        title: '2. ВИКОРИСТАННЯ ІНФОРМАЦІЇ',
        section1:
          '2.1. Власник збирає та використовує тільки ту інформацію Користувача, яка необхідна Власнику для правильного використання Сайту, надання послуг та товарів відповідно до замовлень Користувачів.',
        section2:
          '2.2. Власник має право використовувати отриману від Користувачів інформацію в такий спосіб:',
        section3: '- адмініструвати веб-сайт і / або надавати послуги, продавати товар',
        section4: '- розробляти нові продукти і послуги;',
        section5: '- надавати замовлені послуги або товари;',
        section6: '- персоналізувати  сайт;',
        section7: '- відправляти технічні повідомлення і підтримку і адміністративні повідомлення;',
        section8:
          '- спілкуватися про продукти, послуги, рекламні акції, події та інші новини і інформації, яка, на може зацікавити Користувача;',
        section9:
          "- відслідковувати і аналізувати тенденції, використання і дії, пов'язані з сайтом і / або послугами;",
        section10:
          '- надавати третім особам статистичну інформацію про користувачів (але ці треті сторони не зможуть ідентифікувати будь-якого окремого користувача з цієї інформації);',
        section11: '- перевіряти дотримання умов, що регулюють використання нашого веб-сайту;',
        section12: "- зв'язати або об'єднати особисті дані, які збираються сайтом",
        section13:
          "2.3. Власник веб-сайту є єдиним контролером і обробником даних, за винятком випадків, коли існує об'єктивна потреба власника веб-сайту контролювати/обробляти або зберігати інформацію у контрагентів або агентів власника веб-сайту.",
        section14:
          '2.4. Власник веб-сайту має право не розголошувати адресу отримувача товарів або послуг у випадку відсутності згоди на таке розголошення зі сторони отримувача.',
      },
      giveInformation: {
        title: '3. ПЕРЕДАЧА ІНФОРМАЦІЇ ТРЕТІМ СТОРОНАМ, ТРАНСКОРДОННА ПЕРЕДАЧА',
        section1:
          '3.1. Власник є адміністратором персональних даних  та має право надавати, розголошувати, розкривати або розповсюджувати конфіденційну інформацію та персональні дані:',
        section2:
          '- постачальникам товарів та послуг відповідно до умов укладених договорів, які допомагають Власнику  з частинами ділових операцій;',
        section3: '- банкам ;',
        section4:
          '- суб’єктам господарської діяльності, які входять до структури власності Власника.',
        section5:
          '- правоохоронним органам, державним посадовим особам або іншим сторонам, у випадку коли розкриття/розголошення конфіденційної інформації або персональних даних передбачено чинним законодавством України.',
        section6:
          '- контрагентам або агентам по обробці персональних даних, які найняті або співпрацюють з Власником та послуги яких потрібні з практичної точки зору, за умов підписання з ними договорів про нерозголошення конфіденційної інформації;',
        section7:
          '- іншим третім сторонам, у випадку, якщо надання, розповсюдження чи розголошення конфіденційної інформації та персональних даних викликано необхідністю надати Користувачу  послугу або товар та не суперечить вимогам нормативно-правових документів.',
        section8:
          "3.2. Використання персональних даних працівниками, уповноваженими на це Власником сайту здійснюється лише відповідно до їхніх професійних чи службових або трудових обов'язків. Ці працівники зобов'язані не допускати розголошення у будь-який спосіб персональних даних, які їм було довірено або які стали відомі у зв'язку з виконанням професійних чи службових або трудових обов'язків, крім випадків, передбачених чинним законодавством.",
        section9:
          "3.3. До початку здійснення транскордонної передачі персональних даних Власник сайту зобов'язаний переконатися у тому, що іноземною державою, на територію якої передбачається здійснити передачу персональних даних, забезпечується надійний захист прав суб'єктів персональних даних.",
        section10:
          "3.4. Транскордонна передача персональних даних на території іноземних держав, які не відповідають зазначеним вище вимогам, може здійснюватися тільки у разі наявності письмової згоди суб'єкта персональних даних на транскордонну передачу його персональних даних та/або виконання договору, стороною якого є суб'єкт персональних даних.",
        section11:
          '3.5. Транскордонна передача даних може здійснюватися, наприклад, у випадку передачі даних для доставки товару Покупцю за межі території України, тощо.',
      },
      secretInformation: {
        title: '4. ДОСТУП ДО КОНФІДЕНЦІЙНОЇ ІНФОРМАЦІЇ',
        section1:
          '4.1. Власник використовує відповідні електронні і процедурні засоби захисту, щоб захистити конфіденційність інформації, яку надає Користувач від втрати, неправильного використання, розкриття, зміни та знищення.',
        section2:
          '4.2. Власник не розголошує персональні дані та конфіденційну інформацію, які були надані користувачами іншим особами крім випадків, коли розголошення такої інформації прямо передбачено нормами чинного законодавства України.',
        section3:
          '4.3. .Користувач визнає, що в разі недбалого ставлення Користувачем до безпеки і захисту його персональних даних та даних авторизації на Сайті, треті особи можуть отримати несанкціонований доступ до облікового запису, персональним і іншими даними Користувача. В такому випадку Власник сайту не несе відповідальності за шкоду, заподіяну Користувачу  таким доступом.',
      },
      refreshInformation: {
        title: '5. ОНОВЛЕННЯ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ',
        section1:
          '5.1. Власник залишає за собою право змінювати або доповнювати цю Політику конфіденційності в односторонньому порядку в будь-який час у випадку, якщо таке оновлення зумовлене необхідністю або у випадку набрання чинності Законів та інших нормативно-правових актів які передбачають зміни в діюче Законодавство України  в сфері використання, збирання та оброблення конфіденційної інформації чи персональних даних.',
      },
      other: {
        title: '6. ІНШЕ',
        section1:
          '6.1. Використовуючи сайт і / або замовляючи послуги чи товари Користувач приєднується до умов цієї Політики конфіденційності, підтверджує та надає свою згоду дотримуватись умов Політики конфіденційності',
        section2:
          '6.2. Якщо Користувач не згоден з умовами Політики конфіденційності в цілому або будь-якою її частиною, Користувач повинні відмовитися від використання Веб-сайту та / або замовлення  послуг або товарів.',
        section3:
          '6.3. Відповідно до Політики конфіденційності, Користувач гарантує і надає Власнику сайту право на обробку персональних даних Користувача , включаючи право на виконання таких дій для обробки персональних даних (повністю або частково в автоматизованій системі): зберігання, реєстрація, зміна, відновлення та видалення. Також Користувач погоджується  з тим, що персональні дані включені в базу персональних даних і ніяких додаткових повідомлень не потрібно.',
        section4:
          '6.4. Здійснюючи замовлення товару або послуг на сайті Користувач підтверджує, що умови Політики конфіденційності, цілі та мета збору та обробки інформації йому чітка та зрозуміла, Користувач надає свою беззаперечну згоду на використання інформації Власником сайту.',
        section5:
          '6.5. У випадку виникнення претензій, рекомендацій чи зауважень, Користувач має право звернутись до служби підтримки сайту ',
        section6: 'Електронна пошта gotslabel.cooperation@gmail.com ',
        section7: 'Контактний номер +380 (99) 039 99 99',
        section8:
          '6.6. Політика конфіденційності встановлює зобов’язання Власника сайту інтернет-магазину «GOT`S LABEL» щодо нерозголошення та забезпечення режиму захисту конфіденційності персональних даних, які Користувач повинен надати на вимогу Власника сайту при реєстрації на сайті інтернет-магазину або при оформленні замовлення для придбання Товару чи замовлення послуги.',
        section9:
          '6.7. Власник сайту відповідально ставиться до питань збереження персональних даних користувачів, зберігає отримані відомості в безпеці та обробляє їх відповідно до чинного законодавства України, Закону України «Про захист прав споживачів», а також з урахуванням положень Генерального регламенту про захист персональних даних (General Data Protection Regulation (GDPR), Постанови (Європейський Союз) 2016/679), в передбачених ним випадках.',
        section10:
          '6.8. Інші умови використання персональних даних та конфіденційної інформації, що не врегульовані даною Політикою та Згодою на збір та обробку персональних даних регулюються відповідно до норм чинного законодавства України.',
      },
    },
    eng: {
      generalTitle: 'Privacy Policy',
      date: 'Effective Date: March 30, 2024',
      firstText:
        "«GOT`S LABEL» respects and cares about the privacy of individuals who use the website. This Privacy Policy has been developed to provide complete and transparent information regarding the processing of data by the Website Owner. We understand that we have a special obligation to protect the personal data obtained from children. We do not collect and will not knowingly collect personal data of any child under the age of 18 without the consent of their parents or guardians. If you are a parent or guardian and are concerned about the transfer of your child's personal data, please contact us at gotslabel.cooperation@gmail.com.",
      secondText:
        'This Privacy Policy is a separate document. Your rights and obligations when using the website https://www.gotslabel.com (hereinafter referred to as the Website or Web) are also determined (may be determined) by the following documents (agreements):',
      fisrtItemList: '- Consent to the processing and use of personal data,',
      secondItemList: '- Public offer',
      thirdItemList: '- Other documents.',
      fisrtLink:
        'The processing and use of personal data by the Website Owner are carried out in accordance with the ',
      linkToPage: ' Consent for the use and processing of personal data.',
      thirdText:
        "The Privacy Policy applies to all information that the internet store 'GOT`S LABEL' may obtain about the User when using the internet store website, applications and internet store products.",
      fourText:
        'The Privacy Policy regulates the conditions of use of the Website and the general rules for the collection, processing, dissemination, use and storage of User data by the Website Owner.',
      fifeText:
        'During the processing and use of confidential information and personal data, the Website Owner complies with the legal requirements to protect the information received from Users.',
      sixText:
        'The Privacy Policy is a document that explains what information about Users will be collected by the Website Owner when Users use the Website and how this information will be used, transmitted and protected.',
      secondTitle: 'TERMS',
      user: 'Each time a term with a capital letter is used in the Privacy Policy, it acquires the following meaning:',
      personalData:
        'User - any natural person who has access to the Website through the Internet and uses the website of an online store and who views information on the Website and/or orders or buys Goods/Services using the technical tools of the Website. The User in this Policy is also a subject of personal data, that is, a natural person whose personal data is processed.',
      confidentialInfo:
        'Personal data - information or a set of information about an individual that is identified or can be specifically identified.',
      dataPrivacy:
        'Confidential information is information about an individual (subject of personal data), as well as information access to which is restricted by a natural or legal person, except for subjects of authority.',
      onlineStore:
        'Confidentiality of personal data is a requirement mandatory for compliance by the Website Owner or other persons who have access to personal data, not to allow their dissemination without the consent of the subject of personal data or the presence of another legal basis.',
      website:
        "Internet shop 'GOT'S LABEL' - a means for presenting or implementing a product, work, or service by making an electronic transaction.",
      secondWebsite:
        "Website of the Internet shop 'GOT'S LABEL' (hereinafter - Website/Website of the Internet shop) - a set of web pages placed on the Internet united by the domain https://www.gotslabel.com.",
      owner:
        "Owner ('Website Owner') - Individual Entrepreneur Svitlana Hennadiivna Gotochkina, Individual Tax Number 3192020323, registration address: 54037, Mykolaiv city, 20 Kotsubyns'koho St., who administers and provides technical support to the Website and authorized employees to manage the Website, acting on behalf of the Internet shop 'GOT'S LABEL', who organize and carry out the processing of personal data, as well as determine the purposes of personal data processing, the composition of personal data subject to processing, actions or operations performed with personal data.",
      dataProcessing:
        'Processing of personal data means an operation or set of operations performed with personal data or sets of personal data by automated or non-automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment or combination, restriction, erasure, or destruction.',
      dataSharing:
        'Dissemination of personal data is an activity aimed at disclosing personal data to an unlimited number of persons.',
      dataExchange:
        'Exchange of personal data is an activity aimed at disclosing personal data to a specific person or group of people.',
      dataSuspension:
        'Suspension of personal data processing is a temporary cessation of personal data processing (except in cases where processing is necessary for updating/refreshing personal data/identifying personal data).',
      dataUsage:
        'Use of personal data - operations carried out by the Owner with personal data for the purpose of making a decision or taking other actions that have legal consequences for the owner of personal data or other persons, or otherwise affect the rights and freedoms of the owner, personal data, and other persons.',
      dataDestruction:
        'Destruction of personal data - actions that prevent the reproduction of the content of personal data in the personal data system and/or as a result of which material carriers of personal data are destroyed.',
      cookies:
        "Cookie Files ('Cookies') - data automatically transmitted to the Owner when using the Website through software installed on the User's device, including IP address, geographic location, information about the browser and type of operating system on the User's device, device characteristics, and software used by the User, date and time of access to the Website.",
      ipAddress:
        'IP address - a number assigned to a network interface used by a computer to identify each time it accesses the Internet.',
      gdpr: 'GDPR - Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).',
      otherTerms:
        'Other terms and concepts used in this Policy must be understood in accordance with the current legislation of Ukraine.',
      gatheringInformation: {
        title: 'INFORMATION COLLECTION PURPOSE',
        section1:
          '1.1. Users are not obliged to provide the Owner with their Personal data, however, without certain information about you, we will not be able to provide you with our services, sell and transfer Goods to you, conclude transactions.',

        section2:
          '1.2. The Owner cannot assess the legal capacity of the User. However, we act on the assumption that the User acts in good faith, provides truthful and complete personal data, and also makes all necessary efforts to maintain such data up-to-date.',

        section3:
          "1.3. Providing the Owner with the User's phone number and email address confirms his consent to receive push notifications, SMS messages, messages in messenger chats and to the email from the Owner. The User can opt out of receiving messages in the mobile application chat at any time. The website owner reserves the right to conduct informational mailings.",

        section4:
          '1.4. The confidentiality policy applies to all information that the Owner may receive regarding the User when using the Site, purchasing Goods.',

        section5:
          "1.5. The Owner collects, uses, processes and transmits information from and about Users for the purpose of providing Users with services, selling goods or other actions related to the Owner's economic activities.",

        section6:
          '1.6. Technical (software) means of the Site may collect the following Personal data: • name and surname,\n• phone number,\n• email address,\n• other information necessary for providing services or selling goods to Users.',
        section7:
          '1.7. The Owner also has the right to receive the necessary information from and about the User through the call center if it is necessary to confirm the order or obtain additional information. In this case, the User must provide his voluntary and unconditional consent to the collection and processing of the specified personal data and their transfer to third parties to ensure the functioning of the Website and / or the provision of services or sale of goods.',

        section8:
          '1.8. The User also voluntarily consents to recording conversations with call center employees to improve the quality of User service.',

        section9:
          '1.9. The Owner will consider all information provided by the User to be truthful and in accordance with reality. The Owner is not responsible for the provision by the User of knowingly untrue, inaccurate or false information for any reason. In such cases, the User independently bears responsibility for any consequences arising from the provision to the Owner of untrue, incomplete or inaccurate information.',

        section10:
          '1.10. The website owner does not collect data whose processing requires special protection and is related to compliance with specific provisions of the law, including data that reveal ethnic and racial origin, political, religious or philosophical beliefs, membership in political parties or trade unions, information on criminal convictions, as well as data on health status, sexual orientation or sexual identity, biometric and genetic data.',

        section11:
          "1.11. Access of children to the Site, services, products, to the managers (chats) of the online store is carried out exclusively at the decision and with the permission of their parents, which is confirmed by providing the child's data to the Owner by one of the parents and provided that the parents accept (accept) the Public Offer Agreement. We ask parents to familiarize their children with this Privacy Policy. We ask children not to send any personal information to our email address. If parents or other legal representatives of the child find that children have made any information available to the Owner of the site, and believe that the data provided by the child should be deleted, such parents need to contact us at the email address provided in this Privacy Policy, after which we will immediately delete personal information about the child.",

        section12:
          '1.12. The Owner also has the right to collect information about User requests to the support service regarding the operation of the website and related services, questions, suggestions or complaints of Users.',

        section13:
          '1.13. When visiting the site by the User and ordering goods or services, the Owner has the right, and the User agrees to such a right of the Owner, to place small data files (cookies) on the computer or other device from which the User accessed the site. The Owner uses these technologies to configure the site and advertising; measure the effectiveness of advertising and collect information to reduce risks, prevent fraud and strengthen trust and security. The User can independently control the use of cookie files in the settings of the Internet browser.',
      },
      useInformation: {
        title: '2. USE OF INFORMATION',
        section1:
          '2.1. The owner collects and uses only the information necessary for the owner to properly use the Site, provide services, and sell goods in accordance with user orders.',
        section2:
          '2.2. The owner has the right to use the information obtained from users in the following ways:',
        section3: '- administer the website and/or provide services, sell goods',
        section4: '- develop new products and services;',
        section5: '- provide ordered services or goods;',
        section6: '- personalize the website;',
        section7: '- send technical messages and support and administrative messages;',
        section8:
          '- communicate about products, services, advertising campaigns, events, and other news and information that may interest the user;',
        section9:
          '- track and analyze trends, usage, and actions related to the website and/or services;',
        section10:
          '- provide third parties with statistical information about users (but these third parties will not be able to identify any specific user from this information);',
        section11: '- verify compliance with the terms governing the use of our website;',
        section12: '- link or combine personal data collected by the website.',
        section13:
          "2.3. The owner of the website is the sole controller and processor of data, except where there is an objective need for the website owner to control/process or store information at the website owner's counterparts or agents.",
        section14:
          '2.4. The website owner has the right not to disclose the address of the recipient of goods or services in the absence of consent to such disclosure from the recipient.',
      },
      giveInformation: {
        title: '3. TRANSMISSION OF INFORMATION TO THIRD PARTIES, CROSS-BORDER TRANSFER',
        section1:
          '3.1. The Owner is the administrator of personal data and has the right to provide, disclose, disseminate or make confidential information and personal data available to:',
        section2:
          '- suppliers of goods and services in accordance with the terms of contracts that assist the Owner with parts of business operations;',
        section3: '- banks;',
        section4:
          "- entities engaged in business activities that are part of the Owner's ownership structure.",
        section5:
          '- law enforcement authorities, state officials or other entities, where the disclosure of confidential information or personal data is required by applicable Ukrainian law.',
        section6:
          '- contractors or data processing agents, who are hired or cooperate with the Owner and whose services are practically necessary, subject to the signing of confidentiality agreements with them;',
        section7:
          '- other third parties, where the provision, dissemination or disclosure of confidential information or personal data is necessary to provide the user with a service or product and does not contradict the requirements of regulatory documents.',
        section8:
          '3.2. The use of personal data by employees authorized by the Owner of the website is carried out only in accordance with their professional or official duties. These employees are obliged not to disclose in any way personal data entrusted to them or which became known to them in connection with the performance of their professional or official duties, except as provided by applicable law.',
        section9:
          '3.3. Before commencing the cross-border transfer of personal data, the Owner of the website is obliged to ensure that the foreign country to which the transfer of personal data is intended provides reliable protection of the rights of data subjects.',
        section10:
          '3.4. Cross-border transfer of personal data to foreign countries that do not meet the above requirements may only be carried out with the written consent of the data subject to the cross-border transfer of his personal data and/or the performance of a contract, where the data subject is a party.',
        section11:
          '3.5. Cross-border data transfer may be carried out, for example, in the case of transferring data for the delivery of goods to the Buyer outside the territory of Ukraine, etc.',
      },
      secretInformation: {
        title: '4. ACCESS TO CONFIDENTIAL INFORMATION',
        section1:
          '4.1. The Owner uses appropriate electronic and procedural protection measures to protect the confidentiality of information provided by the User from loss, misuse, disclosure, alteration, and destruction.',
        section2:
          '4.2. The Owner does not disclose personal data and confidential information provided by users to third parties unless the disclosure of such information is explicitly provided for by the provisions of the current legislation of Ukraine.',
        section3:
          "4.3. The User acknowledges that in case of negligence on the part of the User in ensuring the security and protection of their personal data and authentication data on the Website, third parties may gain unauthorized access to the user's account, personal, and other data. In such a case, the Website Owner shall not be liable for any damage caused to the User by such access.",
      },
      refreshInformation: {
        title: '5. UPDATE OF PRIVACY POLICY',
        section1:
          '5.1. The Owner reserves the right to change or supplement this Privacy Policy unilaterally at any time if such update is necessary or in case of the entry into force of laws and other regulatory acts that provide for changes in the current legislation of Ukraine regarding the use, collection, and processing of confidential information or personal data.',
      },
      other: {
        title: '6. OTHER',
        section1:
          '6.1. By using the website and/or ordering services or products, the User agrees to the terms of this Privacy Policy, confirms, and consents to comply with the terms of the Privacy Policy.',
        section2:
          '6.2. If the User does not agree with the terms of the Privacy Policy in whole or in part, the User should refrain from using the Website and/or ordering services or products.',
        section3:
          '6.3. According to the Privacy Policy, the User guarantees and grants the Website Owner the right to process their personal data, including the right to take actions to process personal data (in whole or in part in an automated system): storage, registration, modification, restoration, and deletion. The User also agrees that personal data will be included in the personal data database and no additional notifications are required.',
        section4:
          '6.4. By placing an order for a product or service on the website, the User confirms that the terms of the Privacy Policy, the purposes and methods of collecting and processing information are clear and understandable to them, and the User gives their unconditional consent to the use of information by the Website Owner.',
        section5:
          '6.5. In case of claims, recommendations, or comments, the User has the right to contact the website support service',
        section6: 'Email address: gotslabel.cooperation@gmail.com ',
        section7: 'Contact number: +380 (99) 039 99 99',
        section8:
          '6.6. The privacy policy establishes the obligations of the Website Owner of the online store “GOT`S LABEL” regarding the non-disclosure and provision of protection of personal data that the User must provide at the request of the Website Owner when registering on the online store website or placing an order to purchase a product or service.',
        section9:
          '6.7. The Website Owner takes seriously the issues of preserving the personal data of users, keeps the information received secure, and processes it in accordance with the current legislation of Ukraine, the Law of Ukraine “On Consumer Rights Protection”, as well as taking into account the provisions of the General Data Protection Regulation (GDPR, Directive (European Union) 2016/679), in the cases provided for therein.',
        section10:
          '6.8. Other terms regarding the use of personal data and confidential information, which are not regulated by this Policy and Consent to the collection and processing of personal data, are regulated in accordance with the current legislation of Ukraine.',
      },
    },

    pl: {
      generalTitle: 'Polityka Prywatności',
      date: 'Data wejścia w życie: 30 marca 2024 roku',
      firstText:
        '"GOT`S LABEL" szanuje i dba o prywatność danych osobowych osób korzystających z witryny. Niniejsza Polityka Prywatności została opracowana w celu zapewnienia pełnej i przejrzystej informacji dotyczącej przetwarzania danych przez Właściciela witryny. Rozumiemy, że mamy szczególne zobowiązanie do ochrony danych osobowych dzieci. Nie zbieramy i nie będziemy świadomie zbierać danych osobowych żadnego dziecka poniżej 18 roku życia bez zgody jego rodziców lub opiekunów. Jeśli jesteś rodzicem lub opiekunem i martwisz się o przekazanie danych osobowych swojego dziecka, prosimy o kontakt pod adresem e-mail: gotslabel.cooperation@gmail.com."',
      secondText:
        'Niniejsza Polityka Prywatności jest odrębnym dokumentem. Twoje prawa i obowiązki związane z korzystaniem ze strony internetowej https://www.gotslabel.com (dalej - witryna lub Strona internetowa), są również określane (mogą być określane) przez następujące dokumenty (umowy):',
      fisrtItemList: 'Zgoda na przetwarzanie i wykorzystywanie danych osobowych,',
      secondItemList: 'Oferta publiczna',
      thirdItemList: 'inne dokumenty.',
      fisrtLink:
        'Przetwarzanie i wykorzystywanie danych osobowych przez Właściciela witryny odbywa się zgodnie z ',
      linkToPage: ' Zgoda na przetwarzanie i wykorzystywanie danych osobowych.',
      thirdText:
        'Polityka Prywatności dotyczy wszystkich informacji, które sklep internetowy „GOT`S LABEL” może uzyskać o Użytkowniku podczas korzystania ze strony internetowej sklepu internetowego, aplikacji i produktów sklepu internetowego.',
      fourText:
        'Polityka Prywatności reguluje warunki korzystania z Witryny internetowej oraz ogólne zasady zbierania, przetwarzania, udostępniania, wykorzystywania i przechowywania danych Użytkownika przez Właściciela Witryny internetowej.',
      fifeText:
        'Podczas przetwarzania i wykorzystywania poufnych informacji i danych osobowych Właściciel witryny stosuje odpowiednie prawne wymogi do ochrony informacji, które otrzymuje od Użytkowników.',
      sixText:
        'Polityka Prywatności to dokument, który wyjaśnia, jakie informacje o Użytkownikach zostaną zebrane przez Właściciela witryny podczas korzystania przez Użytkowników z Witryny internetowej i w jaki sposób te informacje będą wykorzystywane, przekazywane i chronione.',
      secondTitle: 'TERMINY',
      user: 'Za każdym razem, gdy w Polityce prywatności używa się terminu z dużej litery, przyjmuje on następujące znaczenie:',
      personalData:
        'Użytkownik - każda osoba fizyczna, która ma dostęp do Strony za pośrednictwem Internetu i korzysta z witryny sklepu internetowego oraz przegląda informacje na Stronie i/lub zamawia lub kupuje Towary/Usługi za pomocą narzędzi technicznych Strony. Użytkownik w tej Polityce jest także podmiotem danych osobowych, to znaczy osobą fizyczną, których dane osobowe są przetwarzane.',
      confidentialInfo:
        'Dane osobowe - informacje lub zbiór informacji o osobie fizycznej, która jest zidentyfikowana lub może być specyficznie zidentyfikowana.',
      dataPrivacy:
        'Informacja poufna to informacja o osobie (podmiocie danych osobowych), a także informacja, dostęp do której jest ograniczony przez osobę fizyczną lub prawną, z wyjątkiem podmiotów władzy.',
      onlineStore:
        'Poufność danych osobowych to wymóg obowiązkowy do przestrzegania przez Właściciela Strony lub inne osoby, które mają dostęp do danych osobowych, aby nie dopuszczać do ich rozpowszechniania bez zgody podmiotu danych osobowych lub istnienia innej podstawy prawnej.',
      website:
        "Sklep internetowy 'GOT'S LABEL' - środek prezentacji lub wdrożenia produktu, pracy lub usługi poprzez dokonanie transakcji elektronicznej.",
      secondWebsite:
        "Strona internetowa sklepu internetowego 'GOT'S LABEL' (dalej: Strona/Strona sklepu internetowego) - zbiór stron internetowych umieszczonych w Internecie połączonych domeną https://www.gotslabel.com.",
      owner:
        "Właściciel ('Właściciel Strony') - Przedsiębiorca Indywidualny Svitlana Hennadiivna Gotochkina, Numer Identyfikacji Podatkowej 3192020323, adres rejestracji: 54037, miasto Mykolajów, ul. Kotsubyns'koho 20, który administruje i zapewnia wsparcie techniczne dla Strony oraz upoważnieni pracownicy do zarządzania Stroną, działający w imieniu sklepu internetowego 'GOT'S LABEL', którzy organizują i przeprowadzają przetwarzanie danych osobowych, a także określają cele przetwarzania danych osobowych, skład danych osobowych podlegających przetwarzaniu, działania lub operacje wykonywane z danymi osobowymi.",
      dataProcessing:
        'Przetwarzanie danych osobowych oznacza operację lub zestaw operacji wykonywanych z danymi osobowymi lub zestawami danych osobowych za pomocą zautomatyzowanych lub niezautomatyzowanych środków, takich jak zbieranie, rejestracja, organizacja, strukturyzacja, przechowywanie, adaptacja lub zmiana, pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przekazywanie, rozpowszechnianie lub w inny sposób udostępnianie, porównywanie lub łączenie, ograniczenie, usunięcie lub zniszczenie.',
      dataSharing:
        'Rozpowszechnianie danych osobowych to działalność mająca na celu ujawnienie danych osobowych nieograniczonemu kręgowi osób.',
      dataExchange:
        'Wymiana danych osobowych to działalność mająca na celu ujawnienie danych osobowych określonej osobie lub grupie osób.',
      dataSuspension:
        'Zawieszenie przetwarzania danych osobowych to tymczasowe wstrzymanie przetwarzania danych osobowych (za wyjątkiem przypadków, gdy przetwarzanie jest niezbędne do aktualizacji/odświeżenia danych osobowych/identyfikacji danych osobowych).',
      dataUsage:
        'Wykorzystanie danych osobowych - operacje przeprowadzane przez Właściciela danych osobowych w celu podjęcia decyzji lub podjęcia innych działań mających skutki prawne dla właściciela danych osobowych lub innych osób, lub w inny sposób dotyczących praw i wolności właściciela, danych osobowych i innych osób.',
      dataDestruction:
        'Niszczenie danych osobowych - działania, które uniemożliwiają odtworzenie treści danych osobowych w systemie danych osobowych i/lub w wyniku których niszczone są nośniki materialne danych osobowych.',
      cookies:
        "Pliki cookie ('Cookies') - dane automatycznie przekazywane Właścicielowi podczas korzystania ze Strony za pomocą oprogramowania zainstalowanego na urządzeniu Użytkownika, w tym adres IP, lokalizacja geograficzna, informacje o przeglądarce i typie systemu operacyjnego na urządzeniu Użytkownika, charakterystyka urządzenia i oprogramowania używane przez Użytkownika, data i czas dostępu do Strony.",
      ipAddress:
        'Adres IP - numer przypisany do interfejsu sieciowego używanego przez komputer do identyfikacji za każdym razem, gdy uzyskuje dostęp do Internetu.',
      gdpr: 'RODO - Rozporządzenie (UE) 2016/679 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).',
      otherTerms:
        'Inne terminy i pojęcia używane w niniejszej Polityce należy rozumieć zgodnie z obowiązującymi przepisami prawa Ukrainy.',
      gatheringInformation: {
        title: 'CEL ZBIERANIA INFORMACJI',
        section1:
          '1.1. Użytkownicy nie są zobowiązani do podawania Właścicielowi swoich Danych osobowych, jednakże bez pewnych informacji na Twój temat nie będziemy w stanie świadczyć Ci usług, sprzedawać i przekazywać Tobie Towarów, zawierać transakcji.',

        section2:
          '1.2. Właściciel nie może ocenić zdolności prawnej Użytkownika. Jednakże działamy na założeniu, że Użytkownik działa w dobrej wierze, podaje prawdziwe i kompletne dane osobowe, a także podejmuje wszelkie niezbędne działania, aby utrzymać takie dane w aktualnym stanie.',

        section3:
          '1.3. Podanie przez Użytkownika Właścicielowi numeru telefonu i adresu e-mail potwierdza jego zgodę na otrzymywanie powiadomień push, wiadomości SMS, wiadomości w czatach messengera oraz na e-maila od Właściciela. Użytkownik może w każdej chwili zrezygnować z otrzymywania wiadomości w czacie aplikacji mobilnej. Właściciel witryny zastrzega sobie prawo do prowadzenia wysyłek informacyjnych.',

        section4:
          '1.4. Polityka prywatności dotyczy wszystkich informacji, które Właściciel może otrzymać dotyczących Użytkownika podczas korzystania z witryny, zakupu Towarów.',

        section5:
          '1.5. Właściciel zbiera, używa, przetwarza i przekazuje informacje od i o Użytkownikach w celu świadczenia Użytkownikom usług, sprzedaży towarów lub innych działań związanych z działalnością gospodarczą Właściciela.',

        section6:
          '1.6. Techniczne (programowe) środki witryny mogą gromadzić następujące Dane osobowe: • imię i nazwisko,\n• numer telefonu,\n• adres e-mail,\n• inne informacje niezbędne do świadczenia usług lub sprzedaży towarów Użytkownikom.',

        section7:
          '1.7. Właściciel ma również prawo otrzymywać niezbędne informacje od i o Użytkowniku za pośrednictwem centrum obsługi, jeśli jest to konieczne do potwierdzenia zamówienia lub uzyskania dodatkowych informacji. W takim przypadku Użytkownik musi udzielić swojej dobrowolnej i bezwarunkowej zgody na gromadzenie i przetwarzanie podanych danych osobowych oraz ich przekazywanie osobom trzecim w celu zapewnienia funkcjonowania Witryny i / lub świadczenia usług lub sprzedaży towarów.',

        section8:
          '1.8. Użytkownik dobrowolnie wyraża zgodę na nagrywanie rozmów z pracownikami centrum obsługi w celu poprawy jakości obsługi Użytkownika.',

        section9:
          '1.9. Właściciel uzna wszystkie informacje podane przez Użytkownika za prawdziwe i zgodne z rzeczywistością. Właściciel nie ponosi odpowiedzialności za podanie przez Użytkownika celowo nieprawdziwych, nieścisłych lub fałszywych informacji z jakiegokolwiek powodu. W takich przypadkach Użytkownik samodzielnie ponosi odpowiedzialność za wszelkie konsekwencje wynikające z podania Właścicielowi nieprawdziwych, niekompletnych lub nieścisłych informacji.',

        section10:
          '1.10. Właściciel witryny nie zbiera danych, których przetwarzanie wymaga szczególnej ochrony i wiąże się z przestrzeganiem określonych przepisów prawa, w tym danych ujawniających pochodzenie etniczne i rasowe, poglądy polityczne, religijne lub filozoficzne, przynależność do partii politycznych lub związków zawodowych, informacje o skazaniach, a także dane dotyczące stanu zdrowia, orientacji seksualnej lub tożsamości seksualnej, dane biometryczne i genetyczne.',

        section11:
          '1.11. Dostęp dzieci do witryny, usług, produktów, do menedżerów (czatów) sklepu internetowego odbywa się wyłącznie na decyzję i za zgodą ich rodziców, co potwierdza podanie danych dziecka Właścicielowi przez jednego z rodziców i pod warunkiem, że rodzice akceptują (akceptują) Umowę Oferty Publicznej. Prosimy rodziców o zapoznanie swoich dzieci z niniejszą Polityką Prywatności. Prosimy dzieci o nieprzesyłanie żadnych danych osobowych na nasz adres e-mail. Jeśli rodzice lub inni prawni przedstawiciele dziecka stwierdzą, że dzieci udostępniły Właścicielowi witryny jakiekolwiek informacje, i uznają, że dane podane przez dziecko powinny zostać usunięte, tacy rodzice powinni skontaktować się z nami pod adresem e-mail podanym w niniejszej Polityce Prywatności, po czym natychmiast usuniemy dane osobowe dziecka.',

        section12:
          '1.12. Właściciel ma również prawo zbierać informacje dotyczące zgłoszeń Użytkowników do obsługi dotyczącej działania witryny oraz związanych z nią usług, pytania, sugestie lub skargi Użytkowników.',

        section13:
          '1.13. Podczas odwiedzania witryny przez Użytkownika i zamawiania towarów lub usług Właściciel ma prawo, a Użytkownik zgadza się na takie prawo Właściciela, umieszczać na komputerze lub innym urządzeniu, z którego Użytkownik uzyskał dostęp do witryny, małe pliki danych (ciasteczka). Właściciel wykorzystuje te technologie do konfigurowania witryny i reklam; mierzenie skuteczności reklam i zbieranie informacji w celu zmniejszenia ryzyka, zapobiegania oszustwom oraz wzmacniania zaufania i bezpieczeństwa. Użytkownik może samodzielnie kontrolować używanie plików cookie w ustawieniach przeglądarki internetowej.',
      },
      useInformation: {
        title: '2. UŻYCIE INFORMACJI',
        section1:
          '2.1. Właściciel zbiera i wykorzystuje tylko te informacje użytkownika, które są niezbędne dla właściciela do prawidłowego korzystania z witryny, świadczenia usług oraz sprzedaży towarów zgodnie z zamówieniami użytkowników.',
        section2:
          '2.2. Właściciel ma prawo wykorzystywać informacje uzyskane od użytkowników w następujący sposób:',
        section3: '- administrować stroną internetową i/lub świadczyć usługi, sprzedawać towary',
        section4: '- opracowywać nowe produkty i usługi;',
        section5: '- świadczyć zamówione usługi lub towary;',
        section6: '- personalizować stronę;',
        section7: '- wysyłać komunikaty techniczne i wsparcie oraz komunikaty administracyjne;',
        section8:
          '- komunikować się na temat produktów, usług, akcji reklamowych, wydarzeń i innych wiadomości i informacji, które mogą zainteresować użytkownika;',
        section9:
          '- śledzić i analizować trendy, korzystanie i działania związane z witryną i/lub usługami;',
        section10:
          '- dostarczać osobom trzecim informacje statystyczne o użytkownikach (ale te osoby trzecie nie będą mogły zidentyfikować żadnego konkretnego użytkownika z tych informacji);',
        section11: '- sprawdzać przestrzeganie warunków korzystania z naszej witryny;',
        section12: '- łączyć lub łączyć dane osobowe zbierane przez witrynę.',

        section13:
          '2.3. Właściciel strony internetowej jest jedynym kontrolerem i procesorem danych, z wyjątkiem przypadków, gdy istnieje obiektywna potrzeba właściciela strony internetowej do kontrolowania/przetwarzania lub przechowywania informacji u kontrahentów lub agentów właściciela strony internetowej.',
        section14:
          '2.4. Właściciel strony internetowej ma prawo nie ujawniać adresu odbiorcy towarów lub usług w przypadku braku zgody na takie ujawnienie ze strony odbiorcy.',
      },
      giveInformation: {
        title: '3. PRZEKAZYWANIE INFORMACJI STRONOM TRZECIM, PRZEKAZ MIĘDZYNARODOWY',
        section1:
          '3.1. Właściciel jest administratorem danych osobowych i ma prawo przekazywać, ujawniać, rozpowszechniać lub udostępniać poufne informacje i dane osobowe:',
        section2:
          '- dostawcom towarów i usług zgodnie z warunkami umów, które pomagają właścicielowi w częściach operacji handlowych;',
        section3: '- bankom;',
        section4:
          '- podmiotom działalności gospodarczej, które są częścią struktury własności właściciela.',
        section5:
          '- organom ścigania, urzędnikom państwowym lub innym podmiotom, jeśli ujawnienie/dyskrecja poufnych informacji lub danych osobowych jest wymagane przez obowiązujące prawo Ukrainy.',
        section6:
          '- kontrahentom lub agentom przetwarzającym dane osobowe, którzy są wynajęci lub współpracują z właścicielem i których usługi są praktycznie potrzebne, pod warunkiem podpisania z nimi umów o poufności informacji;',
        section7:
          '- innym stronom trzecim, jeśli udostępnienie, rozpowszechnienie lub ujawnienie poufnych informacji lub danych osobowych jest konieczne do świadczenia użytkownikowi usługi lub produktu i nie stoi w sprzeczności z wymaganiami dokumentów normatywno-prawnych.',
        section8:
          '3.2. Wykorzystanie danych osobowych przez pracowników upoważnionych do tego przez właściciela strony internetowej odbywa się wyłącznie zgodnie z ich zawodowymi lub służbowymi obowiązkami. Ci pracownicy są zobowiązani do nieujawniania w żaden sposób danych osobowych, które im powierzono lub które stały się im znane w związku z wykonywaniem ich zawodowych lub służbowych obowiązków, z wyjątkiem przypadków przewidzianych obowiązującym prawem.',
        section9:
          '3.3. Przed rozpoczęciem przekazywania międzynarodowego danych osobowych właściciel strony internetowej zobowiązany jest upewnić się, że obca państwo, do którego ma nastąpić przekazanie danych osobowych, zapewnia solidną ochronę praw podmiotów danych osobowych.',
        section10:
          '3.4. Międzynarodowe przekazywanie danych osobowych na terytorium obcych państw, które nie spełniają powyższych wymagań, może odbywać się tylko w przypadku pisemnej zgody podmiotu danych osobowych na międzynarodowe przekazanie jego danych osobowych lub/ i wykonania umowy, której stroną jest podmiot danych osobowych.',
        section11:
          '3.5. Przekazywanie danych międzynarodowych może być realizowane, na przykład, w przypadku przekazywania danych w celu dostarczenia towaru Kupującemu poza terytorium Ukrainy, itp.',
      },
      secretInformation: {
        title: '4. DOSTĘP DO INFORMACJI POUFNEJ',
        section1:
          '4.1. Właściciel używa odpowiednich środków elektronicznych i proceduralnych ochrony, aby chronić poufność informacji przekazywanych przez Użytkownika przed utratą, niewłaściwym wykorzystaniem, ujawnieniem, zmianą i zniszczeniem.',
        section2:
          '4.2. Właściciel nie ujawnia danych osobowych i informacji poufnych przekazanych przez użytkowników innym osobom, chyba że ujawnienie takich informacji jest wyraźnie przewidziane przez obowiązujące przepisy prawa Ukrainy.',
        section3:
          '4.3. Użytkownik uzna, że w przypadku niedbalstwa użytkownika w zakresie bezpieczeństwa i ochrony jego danych osobowych oraz danych uwierzytelniających na stronie internetowej, osoby trzecie mogą uzyskać nieautoryzowany dostęp do konta użytkownika, jego danych osobowych i innych danych. W takim przypadku właściciel strony internetowej nie ponosi odpowiedzialności za szkody wyrządzone użytkownikowi przez taki dostęp.',
      },
      refreshInformation: {
        title: '5. AKTUALIZACJA POLITYKI PRYWATNOŚCI',
        section1:
          '5.1. Właściciel zastrzega sobie prawo do zmiany lub uzupełnienia niniejszej Polityki prywatności w trybie jednostronnym w dowolnym momencie, jeśli taka aktualizacja jest konieczna lub w przypadku wejścia w życie przepisów prawa i innych aktów normatywnych, które przewidują zmiany w obowiązującym prawie Ukrainy dotyczącym wykorzystywania, zbierania i przetwarzania poufnych informacji lub danych osobowych.',
      },
      other: {
        title: '6. INNE',
        section1:
          '6.1. Korzystając z witryny i / lub zamawiając usługi lub produkty, Użytkownik akceptuje warunki niniejszej Polityki prywatności, potwierdza i wyraża zgodę na przestrzeganie warunków Polityki prywatności.',
        section2:
          '6.2. Jeśli Użytkownik nie zgadza się z warunkami Polityki prywatności w całości lub w części, Użytkownik powinien zrezygnować z korzystania z Witryny i / lub zamawiania usług lub produktów.',
        section3:
          '6.3. Zgodnie z Polityką prywatności Użytkownik gwarantuje i udziela Właścicielowi witryny prawo do przetwarzania jego danych osobowych, w tym prawo do podejmowania działań w celu przetwarzania danych osobowych (całkowicie lub częściowo w systemie zautomatyzowanym): przechowywania, rejestrowania, modyfikowania, przywracania i usuwania. Użytkownik zgadza się również, że dane osobowe zostaną uwzględnione w bazie danych osobowych i nie są wymagane żadne dodatkowe powiadomienia.',
        section4:
          '6.4. Składając zamówienie na produkt lub usługę na stronie internetowej, Użytkownik potwierdza, że warunki Polityki prywatności, cele i sposób zbierania i przetwarzania informacji są dla niego jasne i zrozumiałe, a Użytkownik wyraża bezwarunkową zgodę na wykorzystanie informacji przez Właściciela witryny.',
        section5:
          '6.5. W przypadku zgłoszenia reklamacji, zaleceń lub uwag, Użytkownik ma prawo skontaktować się z obsługą klienta witryny',
        section6: 'Adres e-mailowy: gotslabel.cooperation@gmail.com ',
        section7: 'Numer kontaktowy: +380 (99) 039 99 99',
        section8:
          '6.6. Polityka prywatności określa zobowiązania Właściciela witryny sklepu internetowego „GOT`S LABEL” w zakresie nieujawniania i zapewnienia ochrony danych osobowych, które Użytkownik musi podać na żądanie Właściciela witryny podczas rejestracji na stronie internetowej sklepu internetowego lub składania zamówienia na zakup produktu lub usługi.',
        section9:
          '6.7. Właściciel witryny traktuje poważnie kwestie ochrony danych osobowych użytkowników, przechowuje otrzymane informacje w bezpieczeństwie i przetwarza je zgodnie z obowiązującym prawem Ukrainy, Ustawą o ochronie praw konsumentów Ukrainy, a także z uwzględnieniem postanowień Rozporządzenia Ogólnego o Ochronie Danych Osobowych (RODO, Dyrektywa (Unia Europejska) 2016/679), w przypadkach przewidzianych w tych dokumentach.',
        section10:
          '6.8. Inne warunki dotyczące wykorzystywania danych osobowych i poufnych informacji, które nie są uregulowane niniejszą Polityką i Zgodą na zbieranie i przetwarzanie danych osobowych, regulowane są zgodnie z obowiązującymi przepisami prawa Ukrainy.',
      },
    },
  };
  const selectedLanguageData = texts[language.toLowerCase()];
  return (
    <div className="privacy-policy">
      <h2>{selectedLanguageData.generalTitle}</h2>
      <p>{selectedLanguageData.date}</p>
      <p>{selectedLanguageData.firstText}</p>
      <p>{selectedLanguageData.secondText}</p>
      <p>{selectedLanguageData.fisrtItemList}</p>
      <p>{selectedLanguageData.secondItemList}</p>
      <p>{selectedLanguageData.thirdItemList}</p>
      <p>{selectedLanguageData.fisrtLink}</p>
      <Link to="/public-offer">{selectedLanguageData.linkToPage}</Link>
      <p>{selectedLanguageData.thirdText}</p>
      <p>{selectedLanguageData.fourText}</p>
      <p>{selectedLanguageData.fifeText}</p>
      <p>{selectedLanguageData.sixText}</p>
      <h2>{selectedLanguageData.secondTitle}</h2>
      <p>{selectedLanguageData.user}</p>
      <p>{selectedLanguageData.personalData}</p>
      <p>{selectedLanguageData.confidentialInfo}</p>
      <p>{selectedLanguageData.dataPrivacy}</p>
      <p>{selectedLanguageData.onlineStore}</p>
      <p>{selectedLanguageData.secondWebsite}</p>
      <p>{selectedLanguageData.owner}</p>
      <p>{selectedLanguageData.dataProcessing}</p>
      <p>{selectedLanguageData.dataSharing}</p>
      <p>{selectedLanguageData.dataExchange}</p>
      <p>{selectedLanguageData.dataSuspension}</p>
      <p>{selectedLanguageData.dataUsage}</p>
      <p>{selectedLanguageData.dataDestruction}</p>
      <p>{selectedLanguageData.cookies}</p>
      <p>{selectedLanguageData.ipAddress}</p>
      <p>{selectedLanguageData.gdpr}</p>
      <p>{selectedLanguageData.otherTerms}</p>
      <h2>{selectedLanguageData.gatheringInformation.title}</h2>
      <p>{selectedLanguageData.gatheringInformation.section1}</p>
      <p>{selectedLanguageData.gatheringInformation.section2}</p>
      <p>{selectedLanguageData.gatheringInformation.section3}</p>
      <p>{selectedLanguageData.gatheringInformation.section4}</p>
      <p>{selectedLanguageData.gatheringInformation.section5}</p>
      <p>{selectedLanguageData.gatheringInformation.section6}</p>
      <p>{selectedLanguageData.gatheringInformation.section7}</p>
      <p>{selectedLanguageData.gatheringInformation.section8}</p>
      <p>{selectedLanguageData.gatheringInformation.section9}</p>
      <p>{selectedLanguageData.gatheringInformation.section10}</p>
      <p>{selectedLanguageData.gatheringInformation.section11}</p>
      <p>{selectedLanguageData.gatheringInformation.section12}</p>
      <p>{selectedLanguageData.gatheringInformation.section13}</p>
      <h2>{selectedLanguageData.useInformation.title}</h2>
      <p>{selectedLanguageData.useInformation.section1}</p>
      <p>{selectedLanguageData.useInformation.section2}</p>
      <p>{selectedLanguageData.useInformation.section3}</p>
      <p>{selectedLanguageData.useInformation.section4}</p>
      <p>{selectedLanguageData.useInformation.section5}</p>
      <p>{selectedLanguageData.useInformation.section6}</p>
      <p>{selectedLanguageData.useInformation.section7}</p>
      <p>{selectedLanguageData.useInformation.section8}</p>
      <p>{selectedLanguageData.useInformation.section9}</p>
      <p>{selectedLanguageData.useInformation.section10}</p>
      <p>{selectedLanguageData.useInformation.section11}</p>
      <p>{selectedLanguageData.useInformation.section12}</p>
      <p>{selectedLanguageData.useInformation.section13}</p>
      <p>{selectedLanguageData.useInformation.section14}</p>
      <h2>{selectedLanguageData.giveInformation.title}</h2>
      <p>{selectedLanguageData.giveInformation.section1}</p>
      <p>{selectedLanguageData.giveInformation.section2}</p>
      <p>{selectedLanguageData.giveInformation.section3}</p>
      <p>{selectedLanguageData.giveInformation.section4}</p>
      <p>{selectedLanguageData.giveInformation.section5}</p>
      <p>{selectedLanguageData.giveInformation.section6}</p>
      <p>{selectedLanguageData.giveInformation.section7}</p>
      <p>{selectedLanguageData.giveInformation.section8}</p>
      <p>{selectedLanguageData.giveInformation.section9}</p>
      <p>{selectedLanguageData.giveInformation.section10}</p>
      <p>{selectedLanguageData.giveInformation.section11}</p>
      <h2>{selectedLanguageData.secretInformation.title}</h2>
      <p>{selectedLanguageData.secretInformation.section1}</p>
      <p>{selectedLanguageData.secretInformation.section2}</p>
      <p>{selectedLanguageData.secretInformation.section3}</p>
      <h2>{selectedLanguageData.refreshInformation.title}</h2>
      <p>{selectedLanguageData.refreshInformation.section1}</p>
      <h2>{selectedLanguageData.other.title}</h2>
      <p>{selectedLanguageData.other.section1}</p>
      <p>{selectedLanguageData.other.section2}</p>
      <p>{selectedLanguageData.other.section3}</p>
      <p>{selectedLanguageData.other.section4}</p>
      <p>{selectedLanguageData.other.section5}</p>
      <p>{selectedLanguageData.other.section6}</p>
      <p>{selectedLanguageData.other.section7}</p>
      <p>{selectedLanguageData.other.section8}</p>
      <p>{selectedLanguageData.other.section9}</p>
      <p>{selectedLanguageData.other.section10}</p>
    </div>
  );
}
