import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';
import { menuItems, categories, meinLink } from './HeaderInfo';
import Window from '../Window/Window';
import './Header.css';

export default function Header() {
  const {
    activeHeader,
    setActiveHeader,
    language,
    setLanguage,
    activeWindow,
    setActiveWindow,
    busketNumber,
    wishList,
    activeBurger,
    setActiveBurger,
    cookiesActive,
  } = useContext(Context);

  const isMobile = useMediaQuery({ maxWidth: 1200 });
  // анимация логотипа
  const [scrolle, setScrolle] = useState(useMediaQuery({ maxWidth: 1200 }));

  const navigate = useNavigate();

  // получение пути
  const location = useLocation();

  // устанавливаем язык
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'en') {
      setLanguage('eng');
    } else if (pathParts[1] === 'pl') {
      setLanguage('pl');
    } else if (pathParts[0] === '') {
      setLanguage('ukr');
    }
    // eslint-disable-next-line
  }, [location]);

  // скролл анимации
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 10) setScrolle(true);
    }

    if (!isMobile) {
      if (
        location.pathname !== '/' &&
        location.pathname !== '/en/' &&
        location.pathname !== '/pl/'
      ) {
        setScrolle(true);
      } else {
        const handleWheel = (event) => {
          const { deltaY } = event;
          if (deltaY < 0 && window.scrollY < 10 && !activeHeader) setScrolle(false);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('wheel', handleWheel);

        return () => {
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('wheel', handleWheel);
        };
      }
    } else {
      setScrolle(true);
    }
  }, [activeHeader, location, isMobile]);

  const [links, setLinks] = useState(false);

  // кнопка меню
  const handleLinks = (props) => {
    if (props === false) {
      setActiveHeader(false);
      setLinks(false);
    } else if (props === true) {
      setActiveHeader(!activeHeader);

      if (!scrolle) {
        setScrolle(true);
        setTimeout(() => {
          setLinks(!links);
        }, 400);
      } else {
        setTimeout(() => {
          setLinks(!links);
        }, 10);
      }
    }
  };

  // переключение языка
  const handleLanguage = (props) => {
    const languageCode = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    localStorage.setItem('gots-language', props);

    const languageObject = {
      ukr: '/',
      eng: '/en',
      pl: '/pl',
    };

    let newUrl;

    if (language === 'ukr' && props !== 'ukr') {
      newUrl = `${languageObject[props]}${languageCode}?${searchParams.toString()}`;
    } else if (props === 'ukr' && language !== 'ukr') {
      newUrl = languageCode.replace(/^\/\w+\//, '/');
      if (searchParams.toString()) {
        newUrl += `?${searchParams.toString()}`;
      }
    } else {
      newUrl = languageCode.replace(
        new RegExp(`${languageObject[language]}(?=/|$)`),
        `${languageObject[props]}`,
      );
      if (searchParams.toString()) {
        newUrl += `?${searchParams.toString()}`;
      }
    }
    navigate(newUrl);
  };

  useEffect(() => {
    if (activeHeader || activeWindow || cookiesActive) {
      document.body.style.overflow = 'hidden'; // Блокируем прокрутку тела страницы, если меню открыто
    } else {
      document.body.style.overflow = 'auto'; // Восстанавливаем прокрутку тела страницы, если меню закрыто
      setLinks(false);
    }
  }, [activeHeader, activeWindow, cookiesActive]);

  const [mobileSubcategories, setMobileSubcategories] = useState(false);

  // толефон
  const handleBurger = () => {
    setScrolle(true);
    setActiveBurger(!activeBurger);
    setActiveHeader(!activeHeader);
    setMobileSubcategories(false);
  };

  return (
    <>
      <header className={`header ${activeHeader ? 'header-active' : ''}`}>
        <Window />

        <div className={`header__logo ${scrolle ? 'header__logo-active' : ''}`}>
          <img className="img-contain" src="/img/header/big-logo.svg" alt="GOT's logo" />
        </div>

        <div className="header__menu-wrap">
          <a
            href={meinLink[language]}
            className={`header__menu-logo ${scrolle ? 'header__menu-logo-active' : ''} ${
              isMobile ? 'header__menu-logo-off' : ''
            }`}
          >
            <img className="img-contain" src="/img/header/small-logo.svg" alt="GOT's logo" />
          </a>

          <ul className="header__menu-nav">
            {menuItems[language].map((item, index) => (
              <li key={index} className="header__menu-nav-item">
                {!item.to ? (
                  <button
                    type="button"
                    onClick={() => handleLinks(true)}
                    className="header__menu-nav-item-button"
                  >
                    {item.label}
                  </button>
                ) : (
                  <Link
                    onClick={() => handleLinks(false)}
                    to={item.to}
                    className="header__menu-nav-item-button"
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>

          <div className="header__menu-right">
            <ul className="header__menu-right-language">
              {['ukr', 'eng', 'pl'].map((lang) => (
                <li key={lang} className="header__menu-right-language-item">
                  <button
                    type="button"
                    onClick={() => handleLanguage(lang)}
                    className={`header__menu-right-language-item-button ${
                      language === lang ? 'header__menu-right-language-item-button-active' : ''
                    }`}
                  >
                    {lang.toUpperCase()}
                  </button>
                </li>
              ))}
            </ul>

            <div className="header__menu-right-icon">
              <button
                type="button"
                onClick={() => {
                  setActiveWindow('gots-favorite');
                  setActiveBurger(false);
                  setActiveHeader(false);
                  handleLinks(false);
                }}
                className="header__menu-right-icon-button"
              >
                <img
                  className="img-contain"
                  src={
                    !wishList.length > 0
                      ? '/img/header/favorite.svg'
                      : '/img/header/favorite-full.svg'
                  }
                  alt="favorite"
                />
                <div className="header__menu-right-icon-button-favorite">{wishList.length}</div>
              </button>
              <button
                type="button"
                onClick={() => {
                  setActiveWindow('gots');
                  setActiveBurger(false);
                  setActiveHeader(false);
                  handleLinks(false);
                }}
                className="header__menu-right-icon-button"
              >
                <img
                  className="img-contain"
                  src={
                    !busketNumber.length > 0 ? '/img/header/cart.svg' : '/img/header/cart-full.svg'
                  }
                  alt="cart"
                />
                <div className="header__menu-right-icon-button-number">{busketNumber.length}</div>
              </button>
            </div>
          </div>

          {/* бургер */}
          <div onClick={() => handleBurger()} className="header__menu-burger">
            <div
              className={`header__menu-burger-icon-one ${
                activeBurger ? 'header__menu-burger-icon-one-active' : ''
              }`}
            ></div>
            <div className="header__menu-burger-icon-two"></div>
            <div
              className={`header__menu-burger-icon-three ${
                activeBurger ? 'header__menu-burger-icon-three-active' : ''
              }`}
            ></div>
          </div>
        </div>

        {links && (
          <div className="header__menu-nav-open">
            <div className="header__menu-nav-open__item">
              <ul className="header__menu-nav-open__item-nav">
                {links &&
                  categories[language].categoryOne.buttons.map((item, index) => (
                    <li key={index} className="header__menu-nav-open__item-nav-item">
                      <Link
                        onClick={() => handleLinks(false)}
                        to={item.link}
                        className="header__menu-nav-open__item-nav-item-link"
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header__menu-nav-open__item">
              <Link
                to={categories[language].categoryOne.picture.link}
                onClick={() => handleLinks(false)}
                className="header__menu-nav-open__item-img"
              >
                <img src={categories[language].categoryOne.picture.src} alt="GOT's logo" />
                <div className="header__menu-nav-open__item-text">
                  {categories[language].categoryOne.picture.text}
                </div>
              </Link>
            </div>

            <div className="header__menu-nav-open__item">
              <ul className="header__menu-nav-open__item-nav">
                {links &&
                  categories[language].categoryTwo.buttons.map((item, index) => (
                    <li key={index} className="header__menu-nav-open__item-nav-item">
                      <Link
                        to={item.link}
                        onClick={() => handleLinks(false)}
                        className="header__menu-nav-open__item-nav-item-link"
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="header__menu-nav-open__item">
              <Link
                to={categories[language].categoryTwo.picture.link}
                className="header__menu-nav-open__item-img"
                onClick={() => handleLinks(false)}
              >
                <img src={categories[language].categoryTwo.picture.src} alt="GOT's logo" />
                <div className="header__menu-nav-open__item-text">
                  {categories[language].categoryTwo.picture.text}
                </div>
              </Link>
            </div>
          </div>
        )}
      </header>

      {/* окно бургера */}
      <div
        className={`header__menu-burger-window ${
          activeBurger ? 'header__menu-burger-window-active' : ''
        }`}
      >
        {activeBurger && (
          <ul className="header__menu-burger-window-language">
            {['ukr', 'eng', 'pl'].map((lang) => (
              <li key={lang} className="header__menu-right-language-item">
                <button
                  type="button"
                  onClick={() => handleLanguage(lang)}
                  className={`header__menu-right-language-item-button ${
                    language === lang ? 'header__menu-right-language-item-button-active' : ''
                  }`}
                >
                  {lang.toUpperCase()}
                </button>
              </li>
            ))}
          </ul>
        )}

        {activeBurger && (
          <ul className="header__menu-burger-window-nav">
            {menuItems[language].map((item, index) => (
              <li key={index}>
                {!item.to ? (
                  <button
                    type="button"
                    onClick={() => setMobileSubcategories(!mobileSubcategories)}
                    className="header__menu-burger-window-nav-item"
                  >
                    {item.label}

                    <div
                      className={`header__menu-burger-window-nav-open ${
                        mobileSubcategories ? 'header__menu-burger-window-nav-open-active' : ''
                      }`}
                    >
                      {mobileSubcategories && (
                        <div className="header__menu-burger-window-nav-open-wrapper">
                          <div className="header__menu-burger-window-nav-open-collections">
                            <Link
                              to={categories[language].categoryOne.picture.link}
                              onClick={() => handleBurger()}
                              className="header__menu-burger-window-nav-open-collections-item"
                            >
                              <img
                                src={categories[language].categoryOne.picture.src}
                                alt="GOT's logo"
                              />
                              <div className="header__menu-burger-window-nav-open-collections-item-text">
                                {categories[language].categoryOne.picture.text}
                              </div>
                            </Link>

                            <Link
                              to={categories[language].categoryTwo.picture.link}
                              className="header__menu-burger-window-nav-open-collections-item"
                              onClick={() => handleBurger()}
                            >
                              <img
                                src={categories[language].categoryTwo.picture.src}
                                alt="GOT's logo"
                              />
                              <div className="header__menu-burger-window-nav-open-collections-item-text">
                                {categories[language].categoryTwo.picture.text}
                              </div>
                            </Link>
                          </div>

                          <div className="header__menu-burger-window-nav-open-collections">
                            <ul className="header__menu-burger-window-nav-open-collections-category">
                              {categories[language].categoryOne.buttons.map((item, index) => (
                                <li key={index}>
                                  <Link
                                    onClick={() => handleBurger()}
                                    to={item.link}
                                    className="header__menu-burger-window-nav-open-collections-category-item"
                                  >
                                    {item.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>

                            <ul className="header__menu-burger-window-nav-open-collections-category">
                              {categories[language].categoryTwo.buttons.map((item, index) => (
                                <li key={index}>
                                  <Link
                                    to={item.link}
                                    onClick={() => handleBurger()}
                                    className="header__menu-burger-window-nav-open-collections-category-item"
                                  >
                                    {item.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </button>
                ) : (
                  <Link
                    onClick={() => handleBurger()}
                    to={item.to}
                    className="header__menu-burger-window-nav-item"
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}

        {activeBurger && (
          <div className="header__menu-burger-social-icon">
            <a
              href="https://www.instagram.com/gots.label?igsh=MXlxa3JsZXB2YWV0"
              target="blank"
              className="header__menu-burger-social-icon-button"
            >
              <img className="img-contain" src="/img/footer/instagram.svg" alt="instagram" />
            </a>
            <a
              href="https://t.me/+380660292069"
              target="blank"
              className="header__menu-burger-social-icon-button"
            >
              <img src="/img/footer/telegram.svg" alt="telegram" />
            </a>
          </div>
        )}
      </div>
    </>
  );
}
