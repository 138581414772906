import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../Context';
import './Faqs.css';

export default function Faqs() {
  const { language } = useContext(Context);
  const texts = {
    ukr: {
      title:
        "Якщо у вас є будь-які питання, ви можете знайти відповіді тут перед тим як зв'язатися з нами",
      id1: {
        q: 'Чи здійснюються міжнародні доставки?',
        a: 'Так, ми відправляємо по всьому світу окрім Росії та Білорусі.',
      },
      id2: {
        q: 'Чи всі матеріали натуральні?',
        a: 'Так, бренд використовує тільки натуральні матеріали преміум якості.',
      },
      id3: {
        q: 'Чи можу я повернути або обміняти товар?',
        a: 'Обмін та повернення товарів можливий протягом 14 днів, при умовах за посиланням:',
        a2: 'публічна оферта.',
        link: '/public-offer',
        a3: 'При індивідуальному замовленні обміну та повернення немає.',
      },
      id4: {
        q: 'Як правильно підібрати розмір та чи можливе індивідуальне замовлення? ',
        a: 'Розмір усіх наших виробів єдиний оверсайз. Ми пишемо всі параметри та зріст моделей, щоб ви мали уявлення як виріб буде виглядати на вас.',
        a2: 'Якщо вам потрібне індивідуальне замовлення, ви можете приїхати до нас на примірку, написати нам у інстаграм або за телефоном ',
        a2l: '+380990399999',
        a2_1: ' і ми вас проконсультуємо.',
        a3: 'Нагадуємо, що при індивідуальному замовленні обміну та повернення немає.',
      },
      id5: {
        q: 'Як доглядати за шкірою та хутром?',
        a: 'Ми рекомендуємо при наявності пошкоджень або плям звернутися у хімчистку, що спеціалізується на натуральних матеріалах.',
        a2: 'Також попереджаємо, що якщо шкіра забруднилася, її можна витерти мокрою серветкою, але обовʼязково без вмісту спирту. Також якщо забруднилася саме овчина, рекомендуємо придбати щіточку для вичосу овчини та замші.',
        a3: 'кщо ваші шкіряні вироби помʼялися, ви можете прогладити їх через тканину праскою. Також відпарювачем користуватися для речей з натуральних матеріалів заборонено!',
        a4: 'При наявності будь яких інших питань, ви можете звʼязатися з нами і ми підскажемо що саме потрібно зробити.',
      },
      id6: {
        q: 'За якою адресою я можу приміряти вироби у фізичному магазині?',
        a: 'Ви можете приміряти наші вироби у шоурумах у Києві та Варшаві.',
        a2: 'Київ, вул. Лютеранська 11А',
        a3: 'Час роботи: 11:00-19:30 кожного дня',
        a4: 'Телефон: ',
        a4_1: '+380990399999',
        a5: 'Warsaw, Wilcza 44',
        a6: 'Час роботи: 11:00-19:00 з понеділка по суботу',
        a7: 'Телефон: ',
        a7_1: '+48 517 212 756',
      },
      id8: {
        q: 'В мене ще залишилися питання, як я можу звʼязатися з менеджером?',
        a: 'При наявності будь яких питань ви можете звʼязатися з нами за контактами:',
        a2: 'Клієнтський сервіс: ',
        a2_1: '+38 099 039 9999',
        a3: 'Для співпраць: +380660292069 ',
        a3_1: 'WhatsApp',
        a3_2: 'Telegram',
        a4: 'Email: ',
        a4_1: 'gotslabel.cooperation@gmail.com',
      },
    },
    eng: {
      title: 'iF U HAVE ANY QUESTIONS YOU CAN TRY TO FIND ANSWERS HERE BEFORE CONTACTING US',
      id1: {
        q: 'Are international deliveries available?',
        a: 'Yes, we ship worldwide except for Russia and Belarus.',
      },
      id2: {
        q: 'Are all materials natural?',
        a: 'Yes, the brand uses only premium quality natural materials.',
      },
      id3: {
        q: 'Can I return or exchange the goods?',
        a: 'Exchange and return of goods are possible within 14 days, according to the terms at the link:',
        a2: 'public offer.',
        link: '/eng/public-offer',
        a3: 'For individual orders, there is no possibility of exchange and return.',
      },
      id4: {
        q: 'How to choose the right size and is custom ordering possible?',
        a: 'The size of all our products is universal - oversized. We provide all parameters and the height of the models so that you have an idea of how the product will look on you.',
        a2: 'If you need a custom order, you can come to us for fitting, write to us on Instagram, or call us at ',
        a2l: '+380990399999',
        a2_1: ' , and we will provide you with consultation.',
        a3: 'Please note that for custom orders, there is no possibility of exchange and return.',
      },
      id5: {
        q: 'How to care for leather and fur?',
        a: 'We recommend contacting a professional dry cleaner specializing in natural materials in case of damage or stains.',
        a2: 'We also remind you that if the leather is dirty, it can be wiped with a damp cloth, but be sure to avoid alcohol content. If specifically the fur is dirty, we recommend purchasing a brush for combing fur and suede.',
        a3: 'If your leather products are wrinkled, you can iron them through fabric. Remember that using a clothes steamer is prohibited for items made of natural materials!',
        a4: 'If you have any other questions, you can contact us, and we will advise you on what needs to be done.',
      },
      id6: {
        q: 'Where can I try on products at a physical store?',
        a: 'You can try on our products at showrooms in Kiev and Warsaw.',
        a2: 'Kiev, 11A Luteranska Street',
        a3: 'Opening hours: 11:00-19:30 every day',
        a4: 'Phone: ',
        a4_1: '+380990399999',
        a5: 'Warsaw, 44 Wilcza Street',
        a6: 'Opening hours: 11:00-19:00 from Monday to Saturday',
        a7: 'Phone: ',
        a7_1: '+48 517 212 756',
      },
      id8: {
        q: 'I still have questions, how can I contact a manager?',
        a: 'If you have any additional questions, you can contact us using the following contact details:',
        a2: 'Customer service: ',
        a2_1: '+38 099 039 9999',
        a3: 'For cooperation: +380660292069 ',
        a3_1: 'WhatsApp',
        a3_2: 'Telegram',
        a4: 'Email: ',
        a4_1: 'gotslabel.cooperation@gmail.com',
      },
    },
    pl: {
      title:
        'JEŚLI MASZ JAKIEKOLWIEK PYTANIA, MOŻESZ SPRÓBOWAĆ ZNALEŹĆ ODPOWIEDZI TUTAJ, ZANIM SIĘ Z NAMI SKONTAKTUJESZ',
      id1: {
        q: 'Czy są realizowane międzynarodowe dostawy?',
        a: 'Tak, wysyłamy na całym świecie z wyjątkiem Rosji i Białorusi.',
      },
      id2: {
        q: 'Czy wszystkie materiały są naturalne?',
        a: 'Tak, marka używa tylko naturalnych materiałów premium jakości.',
      },
      id3: {
        q: 'Czy mogę zwrócić lub wymienić towar?',
        a: 'Wymiana i zwrot towarów jest możliwy w ciągu 14 dni, zgodnie z warunkami pod linkiem:',
        a2: 'oferta publiczna.',
        link: '/pl/publiczna-oferta',
        a3: 'Przy zamówieniach indywidualnych nie ma możliwości wymiany i zwrotu.',
      },
      id4: {
        q: 'Jak dobrać odpowiedni rozmiar i czy możliwe jest zamówienie indywidualne?',
        a: 'Rozmiar wszystkich naszych produktów jest uniwersalny - oversize. Podajemy wszystkie parametry i wzrost modeli, abyś miał/a pojęcie, jak dany produkt będzie wyglądał na Tobie.',
        a2: 'Jeśli potrzebujesz zamówienia indywidualnego, możesz przyjść do nas na przymiarkę, napisać do nas na Instagramie lub zadzwonić pod numer ',
        a2l: '+380990399999',
        a2_1: ', a my udzielimy Ci konsultacji.',
        a3: 'Przypominamy, że przy zamówieniach indywidualnych nie ma możliwości wymiany i zwrotu.',
      },
      id5: {
        q: 'Jak dbać o skórę i futro?',
        a: 'Zalecamy skontaktowanie się z profesjonalną pralnią specjalizującą się w naturalnych materiałach w przypadku uszkodzeń lub plam.',
        a2: 'Przypominamy również, że jeśli skóra jest zabrudzona, można ją wytrzeć wilgotną szmatką, ale koniecznie bez zawartości alkoholu. Jeśli chodzi o zanieczyszczenia własnie futra, zalecamy zakup szczotki do czesania futra i zamszu.',
        a3: 'Jeśli twoje skórzane produkty są pogniecione, możesz je wyprasować przez tkaninę żelazkiem. Pamiętaj, że stosowanie parownika do odzieży jest zabronione dla produktów z naturalnych materiałów!',
        a4: 'W przypadku innych pytań, możesz się z nami skontaktować, a my doradzimy, co należy zrobić.',
      },
      id6: {
        q: 'Pod jakim adresem mogę przymierzyć produkty w sklepie stacjonarnym?',
        a: 'Możesz przymierzyć nasze produkty w showroomach w Kijowie i Warszawie.',
        a2: 'Kijów, ul. Luteranska 11A',
        a3: 'Godziny otwarcia: 11:00-19:30 codziennie',
        a4: 'Telefon: ',
        a4_1: '+380990399999',
        a5: 'Warszawa, ul. Wilcza 44',
        a6: 'Godziny otwarcia: 11:00-19:00 od poniedziałku do soboty',
        a7: 'Telefon: ',
        a7_1: '+48 517 212 756',
      },
      id8: {
        q: 'Mam jeszcze pytania, jak mogę skontaktować się z menedżerem?',
        a: 'W przypadku dodatkowych pytań możesz skontaktować się z nami pod poniższymi danymi kontaktowymi:',
        a2: 'Obsługa klienta: ',
        a2_1: '+38 099 039 9999',
        a3: 'Dla współpracy: +380660292069 ',
        a3_1: 'WhatsApp',
        a3_2: 'Telegram',
        a4: 'Email: ',
        a4_1: 'gotslabel.cooperation@gmail.com',
      },
    },
  };
  const selectedLanguageData = texts[language.toLowerCase()];
  const [questions, setQuestions] = useState([]);

  const toggleAnswer = (id) => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, isOpen: !q.isOpen } : q)));
  };

  useEffect(() => {
    setQuestions([
      {
        id: 1,
        question: selectedLanguageData.id1.q,
        answer: selectedLanguageData.id1.a,
        isOpen: true,
      },
      {
        id: 2,
        question: selectedLanguageData.id2.q,
        answer: selectedLanguageData.id2.a,
        isOpen: false,
      },
      {
        id: 3,
        question: selectedLanguageData.id3.q,
        answer: (
          <>
            {selectedLanguageData.id3.a}
            {' '}
            <Link to={selectedLanguageData.id3.link} className="faqs__list-item-answer-link">
              {selectedLanguageData.id3.a2}
            </Link>
            <br />
            <br />
            {selectedLanguageData.id3.a3}
          </>
        ),
        isOpen: false,
      },
      {
        id: 4,
        question: selectedLanguageData.id4.q,
        answer: (
          <>
            {selectedLanguageData.id4.a}
            <br />
            <br />
            {selectedLanguageData.id4.a2}
            {' '}
            <a href={`tel:${selectedLanguageData.id4.a2l}`}>{selectedLanguageData.id4.a2l}</a>
            {' '}
            {selectedLanguageData.id4.a2_1}
            <br />
            <br />
            {selectedLanguageData.id4.a3}
          </>
        ),
        isOpen: false,
      },
      {
        id: 5,
        question: selectedLanguageData.id5.q,
        answer: (
          <>
            {selectedLanguageData.id5.a}
            <br />
            <br />
            {selectedLanguageData.id5.a2}
            <br />
            <br />
            {selectedLanguageData.id5.a3}
            <br />
            <br />
            {selectedLanguageData.id5.a4}
          </>
        ),
        isOpen: false,
      },
      {
        id: 6,
        question: selectedLanguageData.id6.q,
        answer: (
          <>
            {selectedLanguageData.id6.a}
            <br />
            <br />
            {selectedLanguageData.id6.a2}
            <br />
            {selectedLanguageData.id6.a3}
            <br />
            {selectedLanguageData.id6.a4}
            <a href={`tel:${selectedLanguageData.id6.a4_1}`}>{selectedLanguageData.id6.a4_1}</a>
            <br />
            <br />
            {selectedLanguageData.id6.a5}
            <br />
            {selectedLanguageData.id6.a6}
            <br />
            {selectedLanguageData.id6.a7}
            {' '}
            <a href={`tel:${selectedLanguageData.id6.a7_1}`}>{selectedLanguageData.id6.a7_1}</a>
          </>
        ),
        isOpen: false,
      },
      {
        id: 8,
        question: selectedLanguageData.id8.q,
        answer: (
          <>
            {selectedLanguageData.id8.a}
            <br />
            <br />
            {selectedLanguageData.id8.a2}
            <a
              href={`tel:${selectedLanguageData.id8.a2_1}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedLanguageData.id8.a2_1}
            </a>
            <br />
            {selectedLanguageData.id8.a3}
            {'( '}
            <a href="https://wa.me/+380660292069" target="_blank" rel="noopener noreferrer">
              {selectedLanguageData.id8.a3_1}
            </a>
            {' , '}
            <a href="https://t.me/+380660292069" target="_blank" rel="noopener noreferrer">
              {selectedLanguageData.id8.a3_2}
            </a>
            {' )'}
            <br />
            {selectedLanguageData.id8.a4}
            <a
              href="mailto:gotslabel.cooperation@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedLanguageData.id8.a4_1}
            </a>
          </>
        ),
        isOpen: false,
      },
    ]);
    // eslint-disable-next-line
  }, [language]);

  return (
    <div className="faqs">
      <h1 className="faqs__title">FAQ</h1>
      <p className="faqs__text">{selectedLanguageData.title}</p>
      <ul className="faqs__list">
        {questions.map((q) => (
          <HelpFaqs
            key={q.id}
            question={q.question}
            answer={q.answer}
            isOpen={q.isOpen}
            toggleAnswer={() => toggleAnswer(q.id)}
          />
        ))}
      </ul>
    </div>
  );
}

function HelpFaqs({ question, answer, isOpen, toggleAnswer }) {
  return (
    <li className="faqs__list-item">
      <div onClick={toggleAnswer} className="faqs__list-item-question">
        {question}
        <div className={`faqs__list-item-img ${isOpen ? 'flip' : ''}`}>
          <img className="img-contain" src="/img/main/faq-arrow.svg" alt="arrow" />
        </div>
      </div>
      {isOpen && (
        <p className="faqs__list-item-answer" onClick={toggleAnswer}>
          {answer}
          <div className="faqs__list-item-img--open">
            <img className="img-contain" src="/img/main/faq-arrow.svg" alt="arrow" onClick={toggleAnswer} />
          </div>
        </p>
      )}
    </li>
  );
}
