export const texts =  {
  ukr: {
    navigateAfterSubmit: '/successful-order',
    privacyNavigate: '/privacy-policy',
    navigate: '/',
    color: 'колір:',
    size: 'розмір:',
    article: 'артикль.',
    total: 'підсумок: ₴',
    qua: 'кількість:',
    payInfo: '*вартість доставки не входить в вартість виробу',
    linkBack: 'https://gotslabel.com/successful-order',
    form1: {
      title: '01. Особиста інформація',
      checkForm: '*Будь ласка, заповніть всю необхідну інформацію',
      text1: "Ім'я",
      text2: 'Прізвище',
      text3: 'Ваш e-mail',
      text4: 'Ваш телефон',
      button: 'Далі',
    },
    form2: {
      title: '02. Доставка',
      text1: 'Забрати з нашого магазину',
      text2: 'Замовити доставку',
      text3: {
        n1: 'ШОУРУМ КИЇВ',
        n2: 'вул. Лютеранська, 11А',
        n3: 'Пн - Нд 11:00 - 19:30',
      },
      text4: {
        n1: 'ШОУРУМ ВАРШАВА',
        n2: 'вул. Вільча, 44',
        n3: 'Пн - Сб 11:00 - 19:00',
      },
      text5: {
        n1: 'ДОСТАВКА ПО УКРАЇНІ',
        n2: 'НОВА ПОШТА',
      },
      text6: {
        n1: 'ДОСТАВКА В ІНШІ КРАЇНИ',
      },
      text7: 'Доставка за адресою',
      text8: 'Доставка на поштове відділення',
      input1: 'Місто',
      input2: 'Вулиця',
      input3: 'Будинок',
      input4: 'Квартира',
      input5: 'Місто',
      input6: 'Номер відділення',
      input7: 'Індекс',
      input8: 'Країна',
    },
    form3: {
      title: '03. Оплата',
      text1: 'Оплата в магазині',
      text2: 'Оплата онлайн',
      text3: 'Оплата карткою на місці',
      text4: '*Я прочитав і зрозумів ',
      link: 'Політику конфіденційності',
      text5: ' і погоджуюся з нею.',
      button: 'підтвердити',
    },
  },
  eng: {
    navigateAfterSubmit: '/en/successful-order',
    privacyNavigate: '/en/privacy-policy',
    navigate: '/en',
    color: 'color:',
    size: 'size:',
    article: 'article.',
    total: 'total: €',
    qua: 'qua:',
    payInfo: '*shipping cost is not included in the product price',
    linkBack: 'https://gotslabel.com/en/successful-order',
    form1: {
      title: '01. Personal information',
      checkForm: '*Please provide all the information',
      text1: 'First name',
      text2: 'Second name',
      text3: 'Your e-mail',
      text4: 'Your tel.',
      button: 'next step',
    },
    form2: {
      title: '02. Delivery',
      text1: 'PICK UP FROM OUR STORE',
      text2: 'ORDER A DELIVERY',
      text3: {
        n1: 'SHOWROOM KYIV',
        n2: 'Luteranska st. 11A',
        n3: 'Mon - Sun 11:00 - 19:30',
      },
      text4: {
        n1: 'SHOWROOM WARSAW',
        n2: 'Wilcza 44',
        n3: 'Mon - Sat 11:00 - 19:00',
      },
      text5: {
        n1: 'DELIVERY IN UKRAIN',
        n2: 'NOVA POSHTA',
      },
      text6: {
        n1: 'DELIVERY IN OTHER CONTRIES',
      },
      text7: 'DELIVERY TO ADRESS',
      text8: 'DELIVERY TO POST DEPARTMENT',
      input1: 'City',
      input2: 'Street',
      input3: 'Building',
      input4: 'Appartment',
      input5: 'City',
      input6: 'Department number',
      input7: 'Index',
      input8: 'Country',
    },
    form3: {
      title: '03. Payment',
      text1: 'Payment in the store',
      text2: 'Payment online',
      text3: 'PAY WITH CARD ON SITE',
      text4: '*I have read and understood the ',
      link: 'Privacy Statement',
      text5: ' and I agree to them.',
      button: 'confirm',
    },
  },
  pl: {
    navigateAfterSubmit: '/pl/successful-order',
    privacyNavigate: '/pl/privacy-policy',
    navigate: '/pl',
    color: 'kolor:',
    size: 'rozmiar:',
    article: 'artykuł.',
    total: 'łącznie: €',
    qua: 'ilość:',
    payInfo: '*koszt dostawy nie jest uwzględniony w cenie produktu',
    linkBack: 'https://gotslabel.com/pl/successful-order',
    form1: {
      title: '01. Informacje osobiste',
      checkForm: '*Proszę podać wszystkie informacje',
      text1: 'Imię',
      text2: 'Nazwisko',
      text3: 'Twój adres e-mail',
      text4: 'Twój numer telefonu',
      button: 'następny krok',
    },
    form2: {
      title: '02. Dostawa',
      text1: 'Odbiór ze sklepu',
      text2: 'Zamówienie dostawy',
      text3: {
        n1: 'SALON KYIV',
        n2: 'ul. Luterańska 11A',
        n3: 'Pn - Nd 11:00 - 19:30',
      },
      text4: {
        n1: 'SALON WARSZAWA',
        n2: 'ul. Wilcza 44',
        n3: 'Pn - Sob 11:00 - 19:00',
      },
      text5: {
        n1: 'DOSTAWA W UKRAINIE',
        n2: 'NOVA POSHTA',
      },
      text6: {
        n1: 'DOSTAWA DO INNYCH KRAJÓW',
        // n2: "UKRPOSHTA",
      },
      text7: 'Dostawa pod adres',
      text8: 'Dostawa na pocztę',
      input1: 'Miasto',
      input2: 'Ulica',
      input3: 'Budynek',
      input4: 'Mieszkanie',
      input5: 'Miasto',
      input6: 'Numer oddziału',
      input7: 'Indeks',
      input8: 'Kraj',
    },
    form3: {
      title: '03. Płatność',
      text1: 'Płatność w sklepie',
      text2: 'Płatność online',
      text3: 'PŁACENIE KARTĄ NA MIEJSCU',
      text4: '*Przeczytałem i zrozumiałem ',
      link: 'Oświadczenie o ochronie prywatności',
      text5: ' i zgadzam się z nimi.',
      button: 'potwierdź',
    },
  },
};
