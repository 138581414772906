import React from 'react';
import { Link } from 'react-router-dom';

export const menuItems = {
  ukr: {
    Information: [
      { label: 'Про нас', path: '/about-us' },
      { label: 'Контакти', path: '/contacts' },
      { label: 'Доставка та оплата', path: '/delivery-payment' },
    ],
    Other: [
      { label: 'Часті питання', path: '/faq' },
      { label: 'Знижки %', path: '/sale' },
      { label: 'Новинки', path: '/new' },
    ],
    Terms: [
      { label: 'публічна оферта', path: '/public-offer' },
      { label: 'Політика конфіденційності', path: '/privacy-policy' },
      { label: 'Політика використання cookies', path: '/cookie-policy' },
    ],
  },
  eng: {
    Information: [
      { label: 'About us', path: '/en/about-us' },
      { label: 'Contacts', path: '/en/contacts' },
      { label: 'Delivery & payment', path: '/en/delivery-payment' },
    ],
    Other: [
      { label: 'FAQs', path: '/en/faq' },
      { label: 'Sale%', path: '/en/sale' },
      { label: 'New in', path: '/en/new' },
    ],
    Terms: [
      { label: 'Public offer', path: '/en/public-offer' },
      { label: 'Privacy policy', path: '/en/privacy-policy' },
      { label: 'Cookie policy', path: '/en/cookie-policy' },
    ],
  },
  pl: {
    Information: [
      { label: 'O nas', path: '/pl/about-us' },
      { label: 'Kontakt', path: '/pl/contacts' },
      { label: 'Dostawa i płatność', path: '/pl/delivery-payment' },
    ],
    Other: [
      { label: 'Przecena %', path: '/pl/sale' },
      { label: 'Nowości', path: '/pl/new' },
      { label: 'FAQ', path: '/pl/faq' },
    ],
    Terms: [
      { label: 'Oferta publiczna', path: '/pl/public-offer' },
      { label: 'Polityka prywatności', path: '/pl/privacy-policy' },
      { label: 'Polityka plików cookie', path: '/pl/cookie-policy' },
    ],
  },
};

export const menuItemsTablet = {
  ukr: {
    Other: [
      { label: 'Про нас', path: '/about-us' },
      { label: 'Контакти', path: '/contacts' },
      { label: 'Знижки %', path: '/sale' },
      { label: 'Новинки', path: '/new' },
      { label: 'Часті питання', path: '/faq' },
    ],
    Terms: [
      { label: 'публічна оферта', path: '/public-offer' },
      { label: 'Політика конфіденційності', path: '/privacy-policy' },
      { label: 'Політика використання cookies', path: '/cookie-policy' },
    ],
  },
  eng: {
    Other: [
      { label: 'About us', path: '/en/about-us' },
      { label: 'Contacts', path: '/en/contacts' },
      { label: 'Sale%', path: '/en/sale' },
      { label: 'New in', path: '/en/new' },
      { label: 'FAQs', path: '/en/faq' },
    ],
    Terms: [
      { label: 'Public offer', path: '/en/public-offer' },
      { label: 'Privacy policy', path: '/en/privacy-policy' },
      { label: 'Cookie policy', path: '/en/cookie-policy' },
    ],
  },
  pl: {
    Other: [
      { label: 'O nas', path: '/pl/about-us' },
      { label: 'Kontakt', path: '/pl/contacts' },
      { label: 'Przecena %', path: '/pl/sale' },
      { label: 'Nowości', path: '/pl/new' },
      { label: 'FAQ', path: '/pl/faq' },
    ],
    Terms: [
      { label: 'Oferta publiczna', path: '/pl/public-offer' },
      { label: 'Polityka prywatności', path: '/pl/privacy-policy' },
      { label: 'Polityka plików cookie', path: '/pl/cookie-policy' },
    ],
  },
};

export const menuItemsMobile = {
  ukr: [
    { label: 'Про нас', path: '/about-us' },
    { label: 'Контакти', path: '/contacts' },
    { label: 'Доставка та оплата', path: '/delivery-payment' },
    { label: 'Часті питання', path: '/faq' },
    { label: 'публічна оферта', path: '/public-offer' },
    { label: 'Політика конфіденційності', path: '/privacy-policy' },
    { label: 'Політика використання cookies', path: '/cookie-policy' },
  ],
  eng: [
    { label: 'About us', path: '/en/about-us' },
    { label: 'Contacts', path: '/en/contacts' },
    { label: 'Delivery & payment', path: '/en/delivery-payment' },
    { label: 'FAQs', path: '/en/faq' },
    { label: 'Public offer', path: '/en/public-offer' },
    { label: 'Privacy policy', path: '/en/privacy-policy' },
    { label: 'Cookie policy', path: '/en/cookie-policy' },
  ],
  pl: [
    { label: 'O nas', path: '/pl/about-us' },
    { label: 'Kontakt', path: '/pl/contacts' },
    { label: 'Dostawa i płatność', path: '/pl/delivery-payment' },
    { label: 'FAQ', path: '/pl/faq' },
    { label: 'Oferta publiczna', path: '/pl/public-offer' },
    { label: 'Polityka prywatności', path: '/pl/privacy-policy' },
    { label: 'Polityka plików cookie', path: '/pl/cookie-policy' },
  ],
};

export const texts = {
  ukr: {
    link: '/',
    window: 'дякуємо за підписку',
    offerOne: 'отримайте наші',
    offerTwo: 'спеціальні пропозиції!',
    placeholder: 'Введіть ваш e-mail *',
    find: 'знайдіть нас у соцмережах',
    questionsOne: 'Будемо раді відповісти на будь-які ваші запитання',
    questionsTwo: '',
    understoodOne: () => (
      <>
        *Я прочитав і зрозумів
        {' '}
        <Link to="/privacy-policy" className="footer__copiright-text-link">
          Політику конфіденційності
        </Link>
        {' '}
        та погоджуюся на отримання новин та інших маркетингових комунікацій, як це передбачено в
        ній.
      </>
    ),
    understoodTwo: '',
    understoodThree: '',
  },
  eng: {
    link: '/en/',
    window: 'thanks for subscribing',
    offerOne: 'receive our',
    offerTwo: 'special offers!',
    placeholder: 'E-mail address *',
    find: 'find us on socials',
    questionsOne: 'if you have any questions please contact us',
    questionsTwo: '',
    understoodOne: () => (
      <>
        *I have read and understood the
        {' '}
        <Link to="/en/privacy-policy" className="footer__copiright-text-link">
          Privacy Statement
        </Link>
        {' '}
        and I agree to receive the newsletter and other marketing communication as set out therein.
      </>
    ),
    understoodTwo: '',
    understoodThree: '',
  },

  pl: {
    link: '/pl/',
    window: 'Dzięki za subskrypcję',
    offerOne: 'otrzymaj nasze',
    offerTwo: 'specjalne oferty!',
    placeholder: 'Adres e-mail *',
    find: 'znajdź nas w mediach społecznościowych',
    questionsOne: 'jeśli masz jakiekolwiek pytania, proszę skontaktuj się z nami',
    questionsTwo: '',
    understoodOne: () => (
      <>
        *Przeczytałem/am i zrozumiałem/am
        {' '}
        <Link to="/pl/privacy-policy" className="footer__copiright-text-link">
          Politykę Prywatności
        </Link>
        {' '}
        i wyrażam zgodę na otrzymywanie informacji i innych komunikatów określonych w tym
        dokumencie.
      </>
    ),
    understoodTwo: '',
    understoodThree: '',
  },
};
