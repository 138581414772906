import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';
import './Contacts.css';
import { Helmet } from 'react-helmet-async';

export default function Contacts() {
  const { language } = useContext(Context);

  const isMobile = useMediaQuery({ maxWidth: 576 });

  const texts = {
    ukr: {
      title: 'нас можна <br /> знайти тут',
      contactOne: "ЗВ'ЯЖІТЬСЯ З НАШОЮ КЛІЄНТСЬКОЮ СЛУЖБОЮ: ",
      contactTwo: 'ДЛЯ СПІВПРАЦІ ТА КОЛАБОРАЦІЇ:',
      contactThree: 'АБО ПО ЕЛЕКТРОННІЙ ПОШТІ:',
      instagram: 'НАШ INSTAGRAM:',
      ukraine: 'Україна',
      poland: 'Польща',
      show: 'подивитись на карті',
      tel: 'телефон:',
      kyiv: 'Київ',
      kyivAdress: 'адреса: <br /> вул. Лютеранська 11а',
      kyivOpen: 'години роботи: <br /> пн-нд: 11:00 - 19:30',
      warsaw: 'Варшава',
      warsawAdress: 'адреса: <br /> Wilcza 44',
      warsawOpen: 'години роботи: <br /> пн-сб: 11:00 - 19:00',
      seoTitle: 'Контактна інформація - Got`s Label',
      seoDescription:
        'Звертайтеся з питаннями та побажаннями за вказаними контактами на цій сторінці. Будемо раді спілкуванню з нашими клієнтами!',
      seoLang: '/contacts',
      lang: 'uk',
    },
    eng: {
      title: 'u can find <br /> us here',
      contactOne: 'CONTACT OUR CLIENT SERVICE:',
      contactTwo: 'FOR COOPERATION AND COLLABROATION:',
      contactThree: 'OR VIA EMAIL:',
      instagram: 'OUR INSTAGRAM:',
      ukraine: 'UKRAINE',
      poland: 'POLAND',
      show: 'show on map',
      tel: 'phone:',
      kyiv: 'kyiv',
      kyivAdress: 'adress: <br /> St. Lutheranska 11а',
      kyivOpen: 'open hours: <br /> mon-sun: 11:00 a.m. - 7:30 p.m.',
      warsaw: 'warsaw',
      warsawAdress: 'adress: <br /> St. wilcza 44',
      warsawOpen: 'open hours: <br /> mon-sat: 11:00 a.m. - 7:00 p.m.',
      seoTitle: 'Contact information - Got`s Label',
      seoDescription:
        'Please contact the specified contacts on this page with questions and wishes. We will be happy to communicate with our customers!',
      seoLang: '/en/contacts',
      lang: 'en',
    },
    pl: {
      title: 'znajdziesz nas <br />  tutaj',
      contactOne: 'SKONTAKTUJ SIĘ Z NASZYM DZIAŁEM OBSŁUGI KLIENTA:',
      contactTwo: 'NA POTRZEBY WSPÓŁPRACY I KOLABORACJI:',
      contactThree: 'LUB POPRZEZ E-MAIL:',
      instagram: 'NASZ INSTAGRAM:',
      ukraine: 'UKRAINA',
      poland: 'POLSKA',
      show: 'pokaż na mapie',
      tel: 'telefon:',
      kyiv: 'Kijów',
      kyivAdress: 'adres: <br /> ul. Lutheranska 11а',
      kyivOpen: 'godziny otwarcia: <br /> pn-nd: 11:00 - 19:30',
      warsaw: 'Warszawa',
      warsawAdress: 'adres: <br /> ul. Wilcza 44',
      warsawOpen: 'godziny otwarcia: <br /> pn-sob: 11:00 - 19:00',
      seoTitle: 'Dane kontaktowe - Got`s Label',
      seoDescription:
        'W przypadku pytań i życzeń prosimy o kontakt z określonymi osobami kontaktowymi na tej stronie. Chętnie nawiążemy kontakt z naszymi klientami!',
      seoLang: '/pl/contacts',
      lang: 'pl',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="contacts">
      <Helmet>
        <title>{selectedLanguageData.seoTitle}</title>
        <meta name="description" content={selectedLanguageData.seoDescription} />
        <link rel="canonical" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />
        {/* языки */}
        <html lang={selectedLanguageData.lang} />
        <link rel="alternate" hreflang="uk" href={`https://gotslabel.com/contacts`} />
        <link rel="alternate" hreflang="pl" href={`https://gotslabel.com/pl/contacts`} />
        <link rel="alternate" hreflang="en" href={`https://gotslabel.com/en/contacts`} />
        <link rel="alternate" hreflang="x-default" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />
      </Helmet>

      <div className="contacts__headline">
        <h1 className="contacts__headline-title" dangerouslySetInnerHTML={{ __html: selectedLanguageData.title }} />
        <div className="contacts__headline-text-wrap">
          <p className="contacts__headline-text contacts__headline-text-mobile">
            {selectedLanguageData.contactOne} {isMobile && <br />}
            <a className="contacts__headline-text-small" href="tel:+380990399999" target="_blank" rel="noreferrer">
              +38 099 039 9999
            </a>
          </p>
          <p className="contacts__headline-text contacts__headline-text-mobile">
            {selectedLanguageData.contactTwo} {isMobile && <br />}
            <a className="contacts__headline-text-small" href="tel:+380660292069" target="_blank" rel="noreferrer">
              +38 066 029 2069
            </a>
          </p>
          <p className="contacts__headline-text">
            {selectedLanguageData.contactThree}{' '}
            <a className="contacts__headline-text-small" href="mailto:gotslabel.cooperation@gmail.com">
              gotslabel.cooperation@gmail.com
            </a>
          </p>
        </div>
        <div className="contacts__headline-text-wrap">
          <p className="contacts__headline-text">{selectedLanguageData.instagram}</p>
          <p className="contacts__headline-text contacts__headline-text-small">
            {selectedLanguageData.ukraine} -{' '}
            <a href="https://www.instagram.com/gots.label/" target="_blank" rel="noreferrer">
              @gots.labeL
            </a>
          </p>
          <p className="contacts__headline-text contacts__headline-text-small">
            {selectedLanguageData.poland} -{' '}
            <a href="https://www.instagram.com/gotslabel.warsaw/" target="_blank" rel="noreferrer">
              @gotslabel.warsaw
            </a>
          </p>
        </div>
      </div>
      <div className="contacts__shops">
        <div className="contacts__shops-kyiv">
          <div className="contacts__shops-info">
            <p className="contacts__shops-info-city">{selectedLanguageData.kyiv}</p>
            <p className="contacts__shops-info-adress" dangerouslySetInnerHTML={{ __html: selectedLanguageData.kyivAdress }} />
            <p className="contacts__shops-info-hours" dangerouslySetInnerHTML={{ __html: selectedLanguageData.kyivOpen }} />
            <p className="contacts__shops-info-hours">
              {selectedLanguageData.tel}
              <br />
              <a className="contacts__shops-info-hours" href="tel:+380990399999" target="_blank" rel="noreferrer">
                +38 099 039 99 99
              </a>
            </p>
            <a
              className="contacts__shops-info-map"
              href="https://maps.app.goo.gl/aekjSPrz9ymkxFVp7?g_st=ic"
              target="_blank"
              rel="noreferrer"
            >
              {selectedLanguageData.show}
            </a>
          </div>
          <img className="contacts__shops-img" alt="shop-kyiv" src="/img/about/shop-kyiv.webp" />
        </div>
        <div className="contacts__shops-warsaw">
          <img className="contacts__shops-img" alt="shop-kyiv" src="/img/about/shop-warsaw.webp" />
          <div className="contacts__shops-info">
            <p className="contacts__shops-info-city">{selectedLanguageData.warsaw}</p>
            <p className="contacts__shops-info-adress" dangerouslySetInnerHTML={{ __html: selectedLanguageData.warsawAdress }} />
            <p className="contacts__shops-info-hours" dangerouslySetInnerHTML={{ __html: selectedLanguageData.warsawOpen }} />
            <p className="contacts__shops-info-hours">
              {selectedLanguageData.tel}
              <br />
              <a className="contacts__shops-info-hours" href="tel:+48517212756" target="_blank" rel="noreferrer">
                +48 517 212 756
              </a>
            </p>
            <a
              className="contacts__shops-info-map"
              href="https://www.google.com/maps/place/Wilcza+44,+00-679+Warszawa,+%D0%9F%D0%BE%D0%BB%D1%8C%D1%88%D0%B0/@52.2244504,21.0105301,17z/data=!3m1!4b1!4m6!3m5!1s0x471eccee7216fa57:0x467694cf5df183b6!8m2!3d52.2244471!4d21.013105!16s%2Fg%2F11c5nlt8x_?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              {selectedLanguageData.show}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
