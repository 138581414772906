export const handleStorageUpdate = async (id, storageKey, setStateFunction, isAdd) => {
  const storedItems = await localStorage.getItem(storageKey);

  if (storedItems) {
    const parsedItems = JSON.parse(storedItems);

    const updatedItems = isAdd
      ? [...parsedItems, id]
      : parsedItems.filter((itemId) => itemId !== id);

    if (updatedItems.length === 0) {
      localStorage.removeItem(storageKey);
      setStateFunction([]);
    } else {
      localStorage.setItem(storageKey, JSON.stringify(updatedItems));
      setStateFunction(updatedItems);
    }
  } else {
    if (isAdd) {
      const updatedItems = [id];
      localStorage.setItem(storageKey, JSON.stringify(updatedItems));
      setStateFunction(updatedItems);
    }
  }
};
