import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../../Context';
import './ProductInfo.css';

export default function ProductInfo({ productText }) {
  const { activeWindow, language, setActiveWindow } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const texts = {
    ukr: {
      title: 'склад',
      details: 'деталі продукту',
      close: 'закрити',
    },
    eng: {
      title: 'Composition',
      details: 'product details',
      close: 'close',
    },
    pl: {
      title: 'skład',
      details: 'szczegóły produktu',
      close: 'zamknąć',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="basket">
      {activeWindow[0] === 'characteristic' ? (
        <div className="basket__title">{selectedLanguageData.title}</div>
      ) : (
        <div className="basket__title">{selectedLanguageData.details}</div>
      )}
      <div className="product-info__text">{productText}</div>
      {isMobile && (
        <button
          type="button"
          onClick={() => setActiveWindow(false)}
          className="basket__cost-button"
        >
          {selectedLanguageData.close}
        </button>
      )}
    </div>
  );
}
