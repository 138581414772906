import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';
import './About.css';
import { Helmet } from 'react-helmet-async';

export default function About() {
  const isMobile = useMediaQuery({ maxWidth: 1040 });
  const { language } = useContext(Context);

  const texts = {
    ukr: {
      descriptionOne: "Got's Label – український бренд шкiряних виробiв, заснований у 2021 роцi Свiтланою Готочкiною.",
      descriptionTwo:
        'У асортименті бренду представлені різноманітні варіації та кольори натуральної шкіри, від глибокого бордового до яскраво - рожевого, від класичного чорного до сміливого металевого – кожен кусочок шкіри має свою власну історію.',
      text: `Ми прагнемо задовольнити найвибагливіші смаки наших клієнтів, надаючи увагу кожній деталі та дбаючи про довговічність та якість.
                <br />
            Наші вироби створені у єдиному розмірі оверсайз, але ми завжди готові пошити індивідуальний розмір по параметрам клієнта.
                <br />
                <br />
       Ми віримо у поєднання легкості, сміливості та задоволення у кожній деталі.
        <br /> Ваша унікальність - це те, що надихає нас кожен день на нові витвори, і ми завжди готові зробити все можливе, щоб ваш стиль вражав і надихав оточуючих.`,
      our: "ваші Got's Label.",
      seoTitle: 'Got`s Label – український бренд шкіряних виробів',
      seoDescription:
        'У асортименті бренду представлені різноманітні варіації та кольори натуральної шкіри, від глибокого бордового до яскраво - рожевого, від класичного чорного до сміливого металевого – кожен кусочок шкіри має свою власну історію.',
      seoLang: '/about-us',
      lang: 'uk',
    },
    eng: {
      descriptionOne: "Got's Label – a Ukrainian brand of leather goods, established in 2021 by Svitlana Gotochkina.",
      descriptionTwo:
        "The brand's assortment includes a variety of variations and colors of natural leather, from deep burgundy to bright pink, from classic black to bold metallic – every piece of leather has its own unique story.",
      text: `We aim to satisfy the most discerning tastes of our clients, paying attention to every detail and ensuring durability and quality.
        <br />
        Our products are created in a single oversized size, but we are always ready to tailor a custom size based on the client's measurements.
        <br />
        <br />
       We believe in the fusion of lightness, boldness, and satisfaction in every detail.
       <br /> Your uniqueness is what inspires us daily to create new works, and we are always ready to do everything possible to make your style impress and inspire those around you.`,
      our: "Your Got's Label.",
      seoTitle: 'Got`s Label is a Ukrainian brand of leather goods',
      seoDescription:
        'The brand`s assortment includes various variations and colors of genuine leather, from deep burgundy to bright pink, from classic black to bold metallic - each piece of leather has its own story.',
      seoLang: '/en/about-us',
      lang: 'en',
    },
    pl: {
      descriptionOne: "Got's Label – ukraińska marka wyrobów skórzanych, założona w 2021 roku przez Switłanę Gotochkinę.",
      descriptionTwo:
        'Asortyment marki obejmuje różnorodne wersje i kolory naturalnej skóry, od głębokiego bordowego po jaskrawy róż, od klasycznej czerni po odważny metaliczny – każdy kawałek skóry ma swoją unikalną historię.',
      text: `Dążymy do zaspokojenia najbardziej wyrafinowanych gustów naszych klientów, zwracając uwagę na każdy szczegół i dbając o trwałość oraz jakość.
        <br />
        Nasze produkty są tworzone w jednym, uniwersalnym rozmiarze oversize, ale zawsze jesteśmy gotowi dostosować indywidualny rozmiar według wymiarów klienta.
       <br />
          <br />
       Wierzymy w połączenie lekkości, śmiałości i zadowolenia w każdym szczególe.
       <br /> Twoja unikalność to, co inspiruje nas każdego dnia do tworzenia nowych dzieł, i zawsze jesteśmy gotowi zrobić wszystko, by Twój styl robił wrażenie i inspirował otoczenie.`,
      our: "twoja Got's Label.",
      seoTitle: 'Got`s Label to ukraińska marka wyrobów skórzanych',
      seoDescription:
        'W asortymencie marki znajdują się różne wariacje i kolory skóry naturalnej, od głębokiego burgunda po jaskrawy róż, od klasycznej czerni po odważny metalik – każdy kawałek skóry ma swoją historię.',
      seoLang: '/pl/about-us',
      lang: 'pl',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="about">
      <Helmet>
        <title>{selectedLanguageData.seoTitle}</title>
        <meta name="description" content={selectedLanguageData.seoDescription} />
        <link rel="canonical" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />
        {/* языки */}
        <html lang={selectedLanguageData.lang} />
        <link rel="alternate" hreflang="uk" href={`https://gotslabel.com/about-us`} />
        <link rel="alternate" hreflang="pl" href={`https://gotslabel.com/pl/about-us`} />
        <link rel="alternate" hreflang="en" href={`https://gotslabel.com/en/about-us`} />
        <link rel="alternate" hreflang="x-default" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />
      </Helmet>

      <div className="about__headline">
        <p className="about__headline-title">HELLO!</p>
        <h1 className="about__headline-text">{selectedLanguageData.descriptionOne}</h1>
        <h2 className="about__headline-text about__headline-text-transform">{selectedLanguageData.descriptionTwo}</h2>
      </div>
      <div className="about__main">
        <div className="about__main-left">
          <h3 className="about__main-left-text" dangerouslySetInnerHTML={{ __html: selectedLanguageData.text }} />

          <div className="about__main-left-signature-wrap">
            <p className="about__main-left-signature">{selectedLanguageData.our}</p>
            <div className="about__main-left-signature-icon">
              <img className="img-contain" alt="heart" src="/img/about/heart.svg" />
            </div>
          </div>

          <div className="about__main-left-img">
            <img src={isMobile ? '/img/about/about-big.webp' : '/img/about/about-small.webp'} alt="clothes" />
          </div>
        </div>
        <div className="about__main-right">
          <div className="about__main-right-img">
            <img src="/img/about/about-big.webp" alt="clothes" />
          </div>
        </div>
      </div>
    </div>
  );
}
