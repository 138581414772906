import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { menuItems, menuItemsTablet, menuItemsMobile, texts } from './FooterList';
import { Context } from '../../Context';
import './Footer.css';

export default function Footer() {
  const [inputValue, setInputValue] = useState('');

  const [inputActive, setInputActive] = useState(false);

  const { language } = useContext(Context);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputValue || !inputValue.includes('@')) {
      return;
    }

    const data = {
      'Заявка на підписку': inputValue,
    };
    try {
      await axios.post('https://messenger-8mp2.onrender.com/gots', data);
      setInputActive(true);
    } catch (error) {
      console.error('Помилка при виконанні запиту:', error);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <footer className="footer">
      <div className="footer__title">
        {selectedLanguageData.offerOne}
        <br />
        {selectedLanguageData.offerTwo}
      </div>
      <form onSubmit={handleSubmit} className="footer__form">
        <div className={`footer__form-window ${inputActive && 'footer__form-window-active'}`}>
          <div className="footer__form-window-img">
            <img className="img-contain" src="/img/about/heart.svg" alt="" />
          </div>
          {selectedLanguageData.window}
        </div>
        <input
          className={`footer__form-input ${inputActive && 'footer__form-input-active'}`}
          type="email"
          name="inputField"
          placeholder={selectedLanguageData.placeholder}
          value={inputValue}
          onChange={handleChange}
        />
        <button type="submit" className={`footer__form-button ${inputActive && 'footer__form-button-active'}`}>
          <img src="/img/footer/arrow.svg" alt="arrow" />
        </button>
      </form>
      <div className="footer__social">
        <div className="footer__social-icon">
          <a href="https://www.instagram.com/gots.label?igsh=MXlxa3JsZXB2YWV0" target="blank" className="footer__social-icon-button">
            <img src="/img/footer/instagram.svg" alt="instagram" />
          </a>
          <a href="https://t.me/+380660292069" target="blank" className="footer__social-icon-button">
            <img src="/img/footer/telegram.svg" alt="telegram" />
          </a>
        </div>
        <p className="footer__social-text mobile">{selectedLanguageData.find}</p>
      </div>
      <div className="footer__infoArea">
        <a className="footer_infoArea-logo" href={selectedLanguageData.link}>
          <img className="img-contain" src="/img/header/big-logo.svg" alt="big-logo" />
        </a>
        <div className="footer_infoArea-mobile">
          <div className="footer__infoArea-menu-tablet">
            {Object.entries(menuItemsTablet[language]).map(([category, items]) => (
              <div className="footer__infoArea-menu-tablet" key={category}>
                <ul className="footer__infoArea-menu-list-tablet">
                  {items.map(({ label, path }) => (
                    <li className="footer__infoArea-menu-list-item" key={label}>
                      <Link to={path}>{label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <ul className="footer__infoArea-menu-list">
            {menuItemsMobile[language].map(({ label, path }) => (
              <li className="footer__infoArea-menu-list-item" key={label}>
                <Link to={path}>{label}</Link>
              </li>
            ))}
          </ul>
          <div className="footer__infoArea-right-mobile">
            <div className="footer__infoArea-right-contact">
              <p className="footer__infoArea-right-contact-title">
                {selectedLanguageData.questionsOne}
                <br />
                {selectedLanguageData.questionsTwo}
              </p>
              <a href="mailto:gotslabel.cooperation@gmail.com" className="footer__infoArea-right-contact-mail">
                gotslabel.cooperation@gmail.com
              </a>
            </div>
            <div className="footer__infoArea-right-privacy">
              <p className="footer__infoArea-right-privacy-text">
                {selectedLanguageData.understoodOne()}
                <br />
                {selectedLanguageData.understoodTwo}
                <br />
                {selectedLanguageData.understoodThree}
              </p>
            </div>
          </div>
        </div>
        <div className="footer__infoArea-menu-wrap">
          <div className="footer__infoArea-menu-list-first">
            <ul className="footer__infoArea-menu-list">
              {menuItems[language].Information.map(({ label, path }) => (
                <li className="footer__infoArea-menu-list-item" key={label}>
                  <Link to={path}>{label}</Link>
                </li>
              ))}
            </ul>
          </div>

          <ul className="footer__infoArea-menu-list">
            {menuItems[language].Other.map(({ label, path }) => (
              <li className="footer__infoArea-menu-list-item" key={label}>
                <Link to={path}>{label}</Link>
              </li>
            ))}
          </ul>

          <ul className="footer__infoArea-menu-list">
            {menuItems[language].Terms.map(({ label, path }) => (
              <li className="footer__infoArea-menu-list-item" key={label}>
                <Link to={path}>{label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer__infoArea-right">
          <div className="footer__infoArea-right-contact">
            <p className="footer__infoArea-right-contact-title">
              {selectedLanguageData.questionsOne}
              <br />
              {selectedLanguageData.questionsTwo}
            </p>
            <a href="mailto:gotslabel.cooperation@gmail.com" className="footer__infoArea-right-contact-mail">
              gotslabel.cooperation@gmail.com
            </a>
          </div>
          <div className="footer__infoArea-right-privacy">
            <p className="footer__infoArea-right-privacy-text">
              {selectedLanguageData.understoodOne()}
              <br />
              {selectedLanguageData.understoodTwo}
              <br />
              {selectedLanguageData.understoodThree}
            </p>
          </div>
        </div>
      </div>
      <div className="footer__copiright">
        <p className="footer__copiright-text">© 2024 GOT`s label</p>
        <a className="footer__copiright-text" target="blank" href="https://circle.in.ua">
          made by circle studio
        </a>
      </div>
    </footer>
  );
}
