import React, { useContext } from 'react';
import { Context } from '../../Context';
import Basket from './Basket/Basket';
import ClientFilter from './ClientFilter/ClientFilter';
import ProductInfo from './ProductInfo/ProductInfo';
import './Window.css';

export default function Window() {
  const { activeWindow, setActiveWindow, cookiesActive } = useContext(Context);
  return (
    <>
      <div
        onClick={() => setActiveWindow(false)}
        className={`window__black ${activeWindow || cookiesActive ? 'window__black-active' : ''}`}
      ></div>
      <div className={`window ${activeWindow ? 'window-active' : ''}`}>
        {activeWindow === 'gots' || activeWindow === 'gots-favorite' ? (
          <Basket prop={activeWindow} />
        ) : (
          false
        )}
        {activeWindow === 'filter' && <ClientFilter />}
        {activeWindow[0] === 'characteristic' || activeWindow[0] === 'description' ? (
          <ProductInfo productText={activeWindow[1]} />
        ) : (
          false
        )}
        <button type="button" onClick={() => setActiveWindow(false)} className="window__close">
          <img src="/img/header/close.svg" alt="GOT's close" />
        </button>
      </div>
    </>
  );
}
