import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainRoute from './pages/MainRoute';
import { Context } from './Context';
import { HelmetProvider } from 'react-helmet-async';
import './fonts.css';
import './App.css';
// import 'normalize.css';
import DeleteAdmin from './pages/Admin/DeleteAdmin/DeleteAdmin';

import { getDocs, collection } from 'firebase/firestore';
import { auth, db } from './firebase';

function App() {
  // кнопки администратора
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // открытие меню
  const [activeHeader, setActiveHeader] = useState(false);

  // открытие окна
  const [activeWindow, setActiveWindow] = useState(false);

  // куки
  const [cookiesActive, setCookiesActive] = useState(false);

  // языки для сайта
  const [language, setLanguage] = useState('ukr');

  // данные
  const [data, setData] = useState(null);

  // лічильник корзини
  const [busketNumber, setBusketNumber] = useState([]);

  // лічильник Favorite
  const [wishList, setWishList] = useState([]);

  // сумма всех товаров
  const [sum, setSum] = useState(0);

  // записываем фильтр
  const [selectedItems, setSelectedItems] = useState({
    color: [],
    skin: [],
    category: [],
    number: 0,
  });

  // записываем кнопку в колекциях
  const [activeButton, setActiveButton] = useState('all');

  // записываем товары после фильтра
  const [list, setList] = useState(false);

  // сохраняем путь чтобы сбрасывать фильтр
  const [saveLocation, setSaveLocation] = useState(false);

  // открытие бургера
  const [activeBurger, setActiveBurger] = useState(false);

  // функция трансформации для seo
  const transformProductsToIdKeyedObject = (products) => {
    return products.reduce((acc, product) => {
      acc[product.id] = {
        article: product.article,
        category: product.category,
        characteristicEn: product.characteristicEn,
        characteristicPl: product.characteristicPl,
        characteristicUa: product.characteristicUa,
        collection: product.collection,
        color: product.color,
        descriptionEn: product.descriptionEn,
        descriptionPl: product.descriptionPl,
        descriptionUa: product.descriptionUa,
        h1en: product.h1en,
        h1pl: product.h1pl,
        h1ua: product.h1ua,
        img: product.img,
        interest: product.interest,
        newSale: product.newSale,
        priceEn: product.priceEn,
        priceEnSale: product.priceEnSale,
        priceUa: product.priceUa,
        priceUaSale: product.priceUaSale,
        seoDescriptionEn: product.seoDescriptionEn,
        seoDescriptionPl: product.seoDescriptionPl,
        seoDescriptionUa: product.seoDescriptionUa,
        seoTitleEn: product.seoTitleEn,
        seoTitlePl: product.seoTitlePl,
        seoTitleUa: product.seoTitleUa,
        skin: product.skin,
        titleEn: product.titleEn,
        titlePl: product.titlePl,
        titleUa: product.titleUa,
      };
      return acc;
    }, {});
  };

  // функция получения данных
  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'data'));
      if (!querySnapshot.empty) {
        // Преобразование документов в массив данных
        const newData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setData(newData);
        // console.log(newData);
        // const result = transformProductsToIdKeyedObject(newData);
        // console.log(result);
      }
    } catch (error) {
      console.error('Error getting products: ', error);
    }
  };

  // записываем корзину
  useEffect(() => {
    fetchData();
    const gots = localStorage.getItem('gots');
    const favorite = localStorage.getItem('gots-favorite');
    if (gots) {
      const gotsNumbers = JSON.parse(gots);
      setBusketNumber(gotsNumbers);
    }
    if (favorite) {
      const favoriteNumbers = JSON.parse(favorite);
      setWishList(favoriteNumbers);
    }
  }, []);

  // аторизация пользователя
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user); // Если пользователь авторизован, устанавливаем состояние isAuthenticated в true
    });

    return () => unsubscribe(); // Отписываемся от слушателя при размонтировании компонента
  }, []);

  return (
    <HelmetProvider>
      <Context.Provider
        value={{
          data,
          setData,
          activeHeader,
          setActiveHeader,
          language,
          setLanguage,
          activeWindow,
          setActiveWindow,
          busketNumber,
          setBusketNumber,
          sum,
          setSum,
          wishList,
          setWishList,
          selectedItems,
          setSelectedItems,
          saveLocation,
          setSaveLocation,
          list,
          setList,
          activeButton,
          setActiveButton,
          activeBurger,
          setActiveBurger,
          cookiesActive,
          setCookiesActive,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<MainRoute />} />
          </Routes>
          {isAuthenticated && <DeleteAdmin />}
        </BrowserRouter>
      </Context.Provider>
    </HelmetProvider>
  );
}

export default App;
