import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDI8wpR5OvrZIRp2TVHR96R17nXq5z_4rI',
  authDomain: 'gots-label.firebaseapp.com',
  projectId: 'gots-label',
  storageBucket: 'gots-label.appspot.com',
  messagingSenderId: '290244377225',
  appId: '1:290244377225:web:8ceaa24147483db5b56458',
  measurementId: 'G-VZV9YQJ4SS',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage();
export const auth = getAuth(app);
