import React, { useContext } from 'react';
import { Context } from '../../../Context';
import './EmptyBasket.css';

export default function EmptyBasket({ emptyBasketText, emptyBasketImg }) {
  const { setActiveWindow, activeWindow, language } = useContext(Context);

  const texts = {
    eng: {
      titleOne: 'OOPS... LOOKS LIKE YOUR',
      titleTwo: 'IS STILL EMPTY',
      back: 'return to products',
    },
    ukr: {
      titleOne: 'упс... здається, ваш',
      titleTwo: 'досі порожній',
      back: 'повернутися до товарів',
    },
    pl: {
      titleOne: 'ups... wygląda na to, że twój',
      titleTwo: 'jest jeszcze pusty',
      back: 'powrót do produktów',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="empty-basket">
      <div className="empty-basket__title">{emptyBasketText}</div>
      <div
        className={activeWindow === 'gots' ? 'empty-basket__img' : 'empty-basket__img--favourite'}
      >
        <img className="img-contain" src={emptyBasketImg} alt="shoulders" />
      </div>
      <div className="empty-basket__text-button-wrap">
        <p className="empty-basket__text">
          {selectedLanguageData.titleOne} {emptyBasketText} {selectedLanguageData.titleTwo}
        </p>
        <button
          type="button"
          onClick={() => setActiveWindow(false)}
          className="basket__cost-button"
        >
          {selectedLanguageData.back}
        </button>
      </div>
    </div>
  );
}
