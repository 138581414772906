import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link } from 'react-router-dom';
import { Context } from '../../../Context';
import { handleStorageUpdate } from '../../../functions/AddBasket';
import { colorsLanguage } from '../../../pages/Admin/Setting/Others';
import './BasketItem.css';

export default function BasketItem(props) {
  const [quantityMain, setQuantityMain] = useState(1);
  const { priceUa, priceEn, titleUa, titleEn, titlePl, color, img, quantity, id, priceUaSale, priceEnSale, category } = props.item;

  const removeItem = props.removeItem;
  const info = props.info;

  const { setBusketNumber, setActiveWindow, language } = useContext(Context);

  useEffect(() => {
    setQuantityMain(quantity);
  }, [quantity]);

  // кнопка минус и плюс
  const updateQuantity = (operation) => {
    const cartItems = localStorage.getItem('gots');
    const parsedCartItems = JSON.parse(cartItems);
    if (operation === '-' && parsedCartItems && quantityMain > 1) {
      const indexToRemove = parsedCartItems.indexOf(id);
      parsedCartItems.splice(indexToRemove, 1);
      setQuantityMain(quantityMain - 1);
    } else if (operation === '+') {
      parsedCartItems.push(id);
      setQuantityMain(quantityMain + 1);
    }
    setBusketNumber(parsedCartItems);
    localStorage.setItem('gots', JSON.stringify(parsedCartItems));
  };

  const totalItemPrice = quantityMain * (language === 'ukr' ? priceUa : priceEn);
  const totalItemPriceSale = quantityMain * (language === 'ukr' ? priceUaSale : priceEnSale);

  const [activeCard, setaCtiveCard] = useState(false);

  // перемещение в корзину
  const toBasket = () => {
    setActiveWindow(false);
    removeItem();
    handleStorageUpdate(id, 'gots', setBusketNumber, true);
    setTimeout(() => {
      setActiveWindow('gots');
    }, 300);
  };

  const texts = {
    ukr: {
      title: titleUa,
      priceSale: priceUaSale,
      color: 'колір:',
      size: 'розмір:',
      wallet: '₴',
      link: '/',
    },
    eng: {
      title: titleEn,
      priceSale: priceEnSale,
      color: 'color:',
      size: 'size:',
      wallet: '€',
      link: '/en/',
    },
    pl: {
      title: titlePl,
      priceSale: priceEnSale,
      color: 'kolor:',
      size: 'rozmiar:',
      wallet: '€',
      link: '/pl/',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];
  const selectedLanguageColors = colorsLanguage[language.toLowerCase()];

  return (
    <li className="basket__list-item">
      <Link
        onClick={() => setActiveWindow(false)}
        to={`${selectedLanguageData.link}${category}/${titleEn.toLowerCase().replace(/\s+/g, '-')}-${id}`}
        className="basket__list-item-img-wrap"
      >
        <img src={img} alt={selectedLanguageData.title} className="basket__list-item-img" />
      </Link>
      <div className="basket__list-item-wrap">
        <div className="basket__list-item-title-delete-wrap">
          <Link
            onClick={() => setActiveWindow(false)}
            to={`${selectedLanguageData.link}${category}/${titleEn.toLowerCase().replace(/\s+/g, '-')}-${id}`}
            className="basket__list-item-title"
          >
            {selectedLanguageData.title}
          </Link>
          <div className={`basket__list-item-delete ${info !== 'gots' ? 'basket__list-item-delete-mobile' : ''}`}>
            <img
              className="img-contain"
              src={info === 'gots' ? '/img/main/delete.svg' : '/img/header/favorite-full.svg'}
              alt="delete"
              onClick={removeItem}
            />
          </div>
        </div>

        <div className="basket__list-item-info">
          {color && (
            <p className="basket__list-item-info-p">
              {selectedLanguageData.color} {selectedLanguageColors[color]}
            </p>
          )}
          <p className="basket__list-item-info-p">{selectedLanguageData.size} oversize</p>
        </div>
        <div className="basket__list-item-priceCount-wrap">
          <div className="basket__list-item-price">
            {selectedLanguageData.wallet}{' '}
            {selectedLanguageData.priceSale && selectedLanguageData.priceSale !== '' ? (
              <>
                {totalItemPriceSale}{' '}
                <span className="basket__list-item-price-sale">
                  {selectedLanguageData.wallet} {totalItemPrice}
                </span>
              </>
            ) : (
              <>{totalItemPrice}</>
            )}
          </div>
          {info === 'gots' ? (
            <div className="basket__list-item-count">
              <RemoveIcon
                onClick={() => updateQuantity('-')}
                sx={{
                  cursor: 'pointer',
                  color: '#black',
                  fontSize: 'var(--MediumFont)',
                }}
                className="basket__list-item-count-svg"
              />
              <span className="basket__list-item-count-number">{quantityMain}</span>
              <AddIcon
                onClick={() => updateQuantity('+')}
                sx={{
                  cursor: 'pointer',
                  color: '#black',
                }}
                className="basket__list-item-count-svg"
              />
            </div>
          ) : (
            <button
              type="button"
              onMouseEnter={() => setaCtiveCard(true)}
              onMouseLeave={() => setaCtiveCard(false)}
              className="basket__list-item-delete"
              onClick={() => toBasket()}
            >
              <img className="img-contain" src={`${activeCard ? '/img/header/cart-full.svg' : '/img/header/cart.svg'}`} alt="cart" />
            </button>
          )}
        </div>
      </div>
    </li>
  );
}
