import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../Context';
import BasketItem from '../BasketItem/BasketItem';
import EmptyBasket from '../EmptyBasket/EmptyBasket';
import { handleStorageUpdate } from '../../../functions/AddBasket';
import './Basket.css';

export default function Basket({ prop }) {
  const {
    data,
    setSum,
    sum,
    setBusketNumber,
    wishList,
    setWishList,
    setActiveWindow,
    language,
    busketNumber,
  } = useContext(Context);

  // записываем товары
  const [itemList, setItemList] = useState(false);

  // логика отображенния товаров
  useEffect(() => {
    const localId = localStorage.getItem(prop);
    if (data && localId) {
      const idArray = JSON.parse(localId);
      const countMap = {};
      idArray.forEach((id) => {
        countMap[id] = (countMap[id] || 0) + 1;
      });
      const updatedProducts = data
        .filter((product) => countMap[product.id])
        .map((product) => ({
          ...product,
          quantity: countMap[product.id],
        }));
      setItemList(updatedProducts);

      // высчитываем всю сумму
      const total = updatedProducts.reduce((acc, item) => {
        const price = parseInt(
          language === 'ukr'
            ? item.priceUaSale && item.priceUaSale !== ''
              ? item.priceUaSale
              : item.priceUa
            : item.priceEnSale && item.priceUaSale !== ''
            ? item.priceEnSale
            : item.priceEn,
        );
        return acc + price * item.quantity;
      }, 0);
      setSum(total);
    }
    // eslint-disable-next-line
  }, [data, language, busketNumber]);

  // кнопка удаления
  const removeItem = (id) => {
    // обновляем обычный список
    const updatedProducts = itemList.filter((product) => product.id !== id);
    setItemList(updatedProducts);
    // удаляем товар через функцию из локала
    handleStorageUpdate(id, prop, prop === 'gots' ? setBusketNumber : setWishList, false);
  };

  // добавление всех товаров из избранного в корзину
  const handleAddAllToBasket = async () => {
    setActiveWindow(false);
    const localId = await localStorage.getItem('gots');
    if (localId) {
      const idArray = JSON.parse(localId);
      const updatedArray = [...idArray, ...wishList];
      localStorage.setItem('gots', JSON.stringify(updatedArray));
      setBusketNumber(updatedArray);
    } else {
      localStorage.setItem('gots', JSON.stringify(wishList));
      setBusketNumber(wishList);
    }
    localStorage.removeItem('gots-favorite');
    setWishList([]);
    setTimeout(() => {
      setActiveWindow('gots');
    }, 300);
  };

  const texts = {
    ukr: {
      subtotal: 'підсумок',
      currency: '₴',
      include: 'Ця ціна не включає доставку.',
      checkout: 'оформити замовлення',
      all: 'все в кошик',
      shopping: 'кошик для покупок',
      wishlist: 'список бажань',
      linkTo: '/payment',
    },
    eng: {
      subtotal: 'subtotal',
      currency: '€',
      include: 'This price does not include shipping.',
      checkout: 'proceed to checkout',
      all: 'all to shopping cart',
      shopping: 'shopping cart',
      wishlist: 'wishlist',
      linkTo: '/en/payment',
    },
    pl: {
      subtotal: 'suma częściowa',
      currency: '€',
      include: 'Cena ta nie zawiera dostawy.',
      checkout: 'złóż zamówienie',
      all: 'wszystko do koszyka',
      shopping: 'koszyk zakupowy',
      wishlist: 'lista życzeń',
      linkTo: '/pl/payment',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="basket">
      {Object.keys(itemList).length !== 0 ? (
        <>
          <div className="basket__title">
            {language === 'ukr'
              ? prop === 'gots'
                ? 'кошик для покупок'
                : 'список бажань'
              : language === 'eng'
              ? prop === 'gots'
                ? 'shopping cart'
                : 'wishlist'
              : language === 'pl'
              ? prop === 'gots'
                ? 'koszyk na zakupy'
                : 'lista życzeń'
              : null}
          </div>
          <ul className="basket__list">
            {itemList &&
              itemList.map((item, index) => {
                return (
                  <BasketItem
                    key={index}
                    item={item}
                    info={prop}
                    removeItem={() => removeItem(item.id)}
                    addItem={() => handleStorageUpdate(item.id)}
                  />
                );
              })}
          </ul>
          <div className="basket__cost-wrap">
            {prop === 'gots' && (
              <div className="basket__cost">
                <div className="basket__cost-title">{selectedLanguageData.subtotal}</div>
                <div className="basket__cost-totalPrice">
                  {selectedLanguageData.currency} {sum}
                </div>
              </div>
            )}
            {prop === 'gots' && (
              <p className="basket__cost-deliveryText">{selectedLanguageData.include}</p>
            )}
            {prop === 'gots' ? (
              <Link
                to={selectedLanguageData.linkTo}
                onClick={() => setActiveWindow(false)}
                className="basket__cost-button"
              >
                {selectedLanguageData.checkout}
              </Link>
            ) : (
              <button onClick={() => handleAddAllToBasket()} className="basket__cost-button">
                {selectedLanguageData.all}
              </button>
            )}
          </div>
        </>
      ) : prop === 'gots' ? (
        <EmptyBasket
          emptyBasketText={selectedLanguageData.shopping}
          emptyBasketImg="/img/main/shoulders.png"
        />
      ) : (
        <EmptyBasket
          emptyBasketText={selectedLanguageData.wishlist}
          emptyBasketImg="/img/main/wishlist.svg"
        />
      )}
    </div>
  );
}
