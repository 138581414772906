import React, { useContext } from 'react';
import { Context } from '../../Context';
import './PublicOffer.css';

export default function PublicOffer() {
  const { language } = useContext(Context);
  const texts = {
    ukr: {
      publicOffer: {
        title: 'ПУБЛІЧНА ОФЕРТА',
        text1:
          'Даний Договір публічної оферти (надалі – “Угода”), відповідно до Цивільного Кодексу України, є офіційною пропозицією (публічною офертою) необмеженому колу осіб (надалі – “Покупець”) Фізичної особи - підприємця Готочкіна Світлана Геннадіївна, (надалі - «Продавець»), і містить всі істотні умови, необхідні для купівлі-продажу товару Продавця на умовах приєднання. Дана Угода укладена відповідно до нормативно-правових актів України, зокрема, на підставі статей 633, 634, 641, 642 Цивільного кодексу України, а також умов і правил, які встановлені та діють у Продавця.',
        text2:
          'Текст цієї Угоди постійно доступний Покупцям в мережі Інтернет на офіційному сайті Продавця http://www.gotslabel.com та за адресами здійснення господарської діяльності Продавця. Розміщена на Сайті Продавця інформація, включаючи інформацію про пропоновані Продавцем товари, окремо від цієї Угоди сама по собі не є публічною офертою і містить відомості, спрямовані виключно на ознайомлення Покупця з:',
        text3: '-   діяльністю Продавця;',
        text4:
          '-   переліком, характеристиками і цінами на товари, які можуть бути реалізовані Продавцем Покупцеві за умови наявності відповідних товарів на складі Продавця в момент оформлення Покупцем замовлення;',
        text5:
          '-   умовами, на яких Продавець готовий встановити з Покупцем відносини купівлі-продажу товару, з урахуванням цієї Угоди і безумовним пріоритетом дії умов Угоди перед іншими умовами, які можуть бути розміщені на Сайті Продавця безвідносно контексту їх прочитання і способів тлумачення.',
      },
      conceptsTerms: {
        title: '1. ПОНЯТТЯ ТА ТЕРМІНИ',
        text1: '1.1. В цій Угоді використовуються терміни та поняття в такому розумінні:',
        text2:
          '1.1.1. Публічна оферта (пропозиція) – пропозиція Продавця (викладена на його офіційному Сайті), адресована невизначеному колу фізичних осіб, укласти Угоду на визначених умовах.',
        text3:
          "1.1.2. Акцепт - повне й беззастережне прийняття Покупцем умов Публічної оферти, викладених в цій Угоді, шляхом надання Продавцю згоди на оформлення Замовлення, приєднання до Угоди та оплати повної вартості Товару. Шляхом натискання на кнопку: «ОФОРМИТИ ЗАМОВЛЕННЯ» Покупець погоджується з умовами цієї Угоди та акцептує оферту Продавця про придбання у Продавця Товару на умовах цієї Угоди. Акцепт Покупцем пропозиції Продавця про придбання Товару є підтвердженням вираження Покупцем безумовного волевиявлення на придбання у Продавця Товару відповідно до Замовлення на наведених нижче умовах. Дія цієї Угоди про умови замовлення Товару поширюється тільки і виключно на ті відносини між Покупцем і Продавцем, які пов'язані із замовленням та купівлею-продажем тих товарів, перелік яких визначено в бланку Замовлення Покупця.",
        text4:
          '1.1.3. Угода – ця Угода, яка розміщена на веб-сайті Продавця та регулює взаємні відносини між Продавцем та Покупцем, згідно з якими ФОП Готочкіна Світлана Геннадіївна, як Продавець, через інтернет-магазин «GOT`S LABEL», розміщений офіційному сайті Продавця http://www.gotslabel.com  зобов’язується продавати, за умови наявності, Товар кожному Покупцю, який до нього звернеться, та встановлює однакові для всіх Покупців умови продажу цих Товарів, а Покупець зобов’язується своєчасно та у повному обсязі сплачувати їхню вартість. Угода публічно доводиться до відома усіх Покупців шляхом її розміщення (публікації) на офіційному веб-сайті Продавця.',
        text5:
          '1.1.4. Бланк Замовлення – це письмовий зразок документа, що заповнює Покупець для надання Продавцю обов’язкової інформації про себе та шляхом заповнення підтверджує:',
        text6: '-   факт прийняття публічної оферти (пропозиції) про приєднання до цієї Угоди;',
        text7: '-   факт ознайомлення з її змістом;',
        text8: '-   погодження з її умовами.',
        text9:
          'У Замовленні Покупець вказує своє прізвище, ім’я, по-батькові, поштову адресу, на яку буде відправлено товар, контактний телефон та за необхідності, додаткову інформацію. ',
        text10:
          '1.1.5. Покупець – дієздатна фізична особа, що уклала Угоду купівлі-продажу з Продавцем.',
        text11:
          '1.1.6. Продавець – - Фізична особа- підприємець Готочкіна Світлана Геннадіївна, Індивідуальний Податковий Номер 3192020323, адреса реєстрації: 54037, місто Миколаїв, вул. Коцюбинського 20.',
        text12:
          '1.1.7. Офіційний Сайт Продавця – офіційна веб-сторінка Продавця в мережі Інтернет, яка знаходиться за адресою: http://www.gotslabel.com (постійно доступна для ознайомлення), та є джерелом інформування Покупців.',
        text13: '1.1.8. Сторони – разом Продавець та Покупець.',
        text14:
          "1.1.9. Товар (партія товару) – об'єкт купівлі-продажу за цією Угодою, визначений у Замовленні, не вилучений з обороту, з інформацією щодо характеристик якого Покупець може ознайомитися на Сайті,  перелік найменувань асортименту представлений в інтернет-магазині, за якими вказується ціна, назва та опис товару та який супроводжується його зображенням, який є в наявності у Продавця, та який Продавець готовий передати у власність Покупцю в порядку та на умовах, які визначені цією Угодою.",
        text15:
          '1.1.10. Одержувач – Покупець, або особа, яка від імені Покупця фактично отримує Товар, що був доставлений власними силами Продавця або Компанією-перевізником.',
        text16:
          '1.1.11. Істотний недолік Товару – недолік, який робить неможливим чи недопустимим використання Товару відповідно до його цільового призначення, виник з вини виробника (Продавця), після його усунення проявляється знову з незалежних від споживача причин і при цьому наділений хоча б однією з нижченаведених ознак: а) він взагалі не може бути усунутий; б) його усунення потребує понад чотирнадцять календарних днів; в) він робить Товар суттєво іншим, ніж передбачено договором.',
        text17:
          '1.1.12. Кур’єрське доставлення – безпосередня передача Товару від співробітника служби доставлення або кур’єрської чи експедиторської служби Покупцю в місці, зазначеному Покупцем як адреса доставлення, на платних або безоплатних умовах.',
        text18:
          '1.1.13. Служба доставлення/Компанія-перевізник– треті особи, які надають послуги з доставлення Замовлень Покупцеві.',
        text19:
          '1.1.14. В Договорі можуть використовуватися терміни, не визначені в розділі 1 Договору. В такому разі тлумачення терміну проводиться згідно з текстом Договору. У випадку відсутності однозначного тлумачення терміну в тексті Договору слід користуватися тлумаченням терміну, що визначається: в першу чергу – законодавством України, в другу чергу – на сайті, потім загально прийнятим тлумаченням, що склалося.',
      },
      subjectOfAgreement: {
        title: '2. ПРЕДМЕТ УГОДИ',
        text1:
          "2.1. В порядку та на умовах, визначених цією Угодою, Продавець бере на себе зобов'язання передати у власність Покупця Товар, а Покупець зобов'язується прийняти Товар та оплатити його вартість.",
        text2:
          '2.2. Покупець підтверджує, що для здійснення покупки Товару за цією Угодою Покупець володіє достатнім обсягом цивільної дієздатності.',
        text3:
          '2.3. Для уникнення будь-яких суперечок, оформлення Замовлення Товару передбачає приєднання Покупця до умов цієї Угоди. Покупець має можливість ознайомитись з умовами цієї Угоди, а також з іншими (локальними) документами Продавця у відповідному розділі, що розташований на веб-сайті Продавця. Текст цієї Угоди розміщений на веб-сайті Продавця. Покупець має право та технічні можливості роздрукувати цю Угоду на матеріальному носії з веб-сайту Продавця.',
        text4:
          '2.4. Разом з Товаром Продавець передає у власність Покупця приналежності до Товару та необхідні документи, умови та порядок передання яких встановлюються цією Угодою та законодавством України.',
        text5: '2.5. Невід’ємною частиною цієї Угоди є:',
        text6: '-   Бланк Замовлення;',
        text7: '-   Згода на збір, обробку та використання Персональних даних;',
        text8:
          '2.6. У випадку виявлення невідповідності інформації, яка розміщена на веб-сайті Продавця положенням цієї Угоди, для врегулювання відносин між Продавцем та Покупцем, які виникатимуть під час оформлення Замовлення з подальшим придбанням товару у відповідності до Замовлення, текст даної Угоди має безперечний пріоритет та її положення визнаються умовами Угоди.',
        text9:
          '2.7. Моментом вираження Покупцем волевиявлення вступити в договірні відносини з Продавцем на умовах даної Угоди вважається Момент натискання Покупцем під час користування веб-сайтом Продавця кнопки «Оформити Замовлення».',
        text10:
          '2.8. Шляхом укладення цього Договору Покупець підтверджує, що він ознайомлений з інформацією про Товар, який продається за умовами цього Договору, а саме щодо його форми, зовнішнього вигляду, дизайну, кольору, розміру, якості, з характеристиками матеріалу, з якого Товар виготовлений, а також з інформацією про його виробника, усвідомлює такі характеристики Товару та їх значення, і згоден на придбання такого Товару з такими характеристиками.',
        text11:
          "2.9. Покупець зобов'язаний самостійно ознайомитися з умовами цього Договору, а Продавець не зобов’язаний додатково або будь-яким іншим способом інформувати Покупця про наявність Договору, крім як публікації його на сайті: http://www.gotslabel.com",
      },
      ruls: {
        title: '3. ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН',
        text1: '3.1. Покупець має право:',
        text2: '3.1.1. Отримувати Товар на умовах та в порядку, які передбачені цією Угодою;',
        text3:
          '3.1.2. Отримувати інформацію про готовність Продавця виконати Замовлення та передати Товар Покупцеві.',
        text4:
          '3.1.3 На повернення товару належної якості протягом 14 (чотирнадцяти) календарних днів, не враховуючи дня купівлі.',
        text5:
          '3.1.4. Отримувати додаткову інформацію про Товар, його доставку та інші Послуги Продавця.',
        text6: "3.2. Покупець зобов'язується:",
        text7:
          '3.2.1. У повному обсязі сплатити вартість Товару та його доставки за цією Угодою, що зазначаються в Замовленні Покупця.',
        text8:
          '3.2.2. Не передавати Замовлення іншим особам, крім випадків, які передбачені цією Угодою та Законом.',
        text9:
          '3.2.3. Дотримуватись усіх умов цієї Угоди, та інших внутрішніх (локальних) документів Продавця, якими регламентовано порядок та умови придбання товару;',
        text10:
          '3.2.5. Дотримуватися правил суспільної моралі, громадського порядку, етичних норм під час відвідування сайту Продавця та спілкування з Продавцем, або уповноваженою Продавцем особою.',
        text11:
          '3.2.6. До моменту прийняття умов цієї Угоди ознайомитись з розміщеною на сайті Продавця інформацією, в тому числі з інформацією про товар, його властивості, ціною, порядком придбання товару.',
        text12:
          '3.2.7. Надати актуальну та достовірну інформацію Продавцю в бланку Замовлення, що може ідентифікувати покупця та адресу доставки товару (персональні дані) та іншу інформацію, необхідну для виконання умов цієї Угоди Продавцем.',
        text13: '3.3. Продавець має право:',
        text14:
          '3.3.1. Вимагати від Покупця сплати вартості Товарів та/або вартість послуг поштової або кур’єрської доставки або додаткових послуг відповідно до умов цієї Угоди;',
        text15:
          '3.3.2. Вимагати від Покупця дотримання всіх умов цієї Угоди, Правил та інших внутрішніх (локальних) документів Продавця.',
        text16:
          '3.3.3. Встановлювати додаткові правила покупки, знижки, акції та інформувати про них Покупця.',
        text17:
          '3.3.4. Збирати, оброблювати, використовувати персональні дані Покупця та іншу конфіденційну інформацію для виконання умов цієї Угоди в порядку, передбаченому цією Угодою та додатками до неї та Згодою на збирання та обробку персональних даних.',
        text18: '3.3.5. Для виконання умов цієї Угоди передавати права Продавця іншим особам.',
        text19: '3.3.6. Інші права, передбачені цією Угодою.',
        text20: "3.4. Продавець зобов'язується:",
        text21:
          '3.4.1. Вчасно передати Покупцю у власність замовлений Товар та надати інші (додаткові) послуги за бажанням Покупця в порядку та на умовах, які передбачені цією Угодою.',
        text22: '3.4.2. Забезпечити належну якість товару, що надається Покупцю.',
      },
      registration: {
        title: '4. РЕЄСТРАЦІЯ НА САЙТІ',
        text1:
          '4.1. Реєструючись на веб-сайті інтернет-магазину GOT`S LABEL, Покупець гарантує, що він є повнолітнім та володіє необхідним обсягом цивільної дієздатності для використання веб-сайту інтернет-магазину.',
        text2:
          '4.2. Будь-яка особа, яка не досягла 18 років, може користуватися інтернет-магазином лише за згодою батьків або опікунів.',
        text3: '4.3. Реєстрація на сайті здійснюється за адресою:  http://www.gotslabel.com ',
        text4: '4.4. Реєстрація на сайті є не обов’язковою для оформлення Замовлення.',
        text5:
          '4.5. Реєструючись на веб-сайті інтернет-магазину та замовляючи Товари через інтернет-магазин, Покупець засвідчує свою повну та беззаперечну згоду зі всіма положеннями та правилами, розміщеними на цьому сайті.',
        text6:
          '4.6. Кожен, хто реєструється та здійснює покупки на сайті в інтернет-магазині, надає свої персональні дані та погоджується на їх обробку на умовах, визначених Політикою конфіденційності.',
        text7:
          '4.7. Під час реєстрація на веб-сайті в інтернет-магазині – створення облікового запису, Покупець приймає умови Політики конфіденційності, Договору публічної оферти шляхом акцептування одним із таких способів:',
        text8:
          '1) Шляхом додавання Товару у віртуальний кошик та натискання в інтернет-магазині на сторінці оформлення замовлення кнопки «Оформити замовлення».',
        text9: '2) Шляхом натиснення кнопки «Розмістити замовлення» або «Купити в 1 клік».',
        text10: '3) Шляхом натиснення кнопки «Створити обліковий запис» або «Зареєструватись».',
        text11: '4) Шляхом замовлення Товару через соціальні мережі або месенджери.',
        text12: '5) Шляхом оплати замовлення повністю або частково на умовах цього Договору.',
        text13:
          '4.8. Продавець не несе відповідальності за точність і правильність інформації, що надається Покупцем при реєстрації.',
        text14:
          "4.9. Покупець зобов'язується не повідомляти третім особам логін і пароль, вказані ним при реєстрації. У разі виникнення у Покупця підозр щодо безпеки його логіна та пароля або можливості їх несанкціонованого використання третіми особами, Покупець зобов'язується негайно повідомити про це Продавця, направивши відповідний електронний лист за адресою: gotslabel.cooperation@gmail.com ",
        text15:
          '4.10. Спілкування Покупця з представниками Продавця має будуватися на принципах загальноприйнятої моралі та комунікаційного етикету. Суворо заборонено використання нецензурних слів, лайки, образливих виразів, а також загроз і шантажу, в незалежності від того, в якому вигляді та кому вони були адресовані.',
      },
      orderingGoods: {
        title: '5. ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ ТОВАРУ',
        text1:
          '5.1. В порядку та на умовах цієї Угоди Покупець здійснює покупку Товару шляхом заповнення бланку Замовлення на веб-сайті Продавця. Форма бланку Замовлення та його реквізити встановлюються Продавцем.',
        text2:
          '5.2. Веб-сайт інтернет-магазину містить інформацію щодо характеристик Товарів, цін на Товари, матеріалів, з яких вони виготовлені та приблизну наявність цих Товарів у Продавця.',
        text3:
          '5.3. У разі анулювання повністю або частково передплаченого Замовлення вартість анульованого Товару повертається Продавцем Покупцю способом, яким Товар був оплачений.',
        text4:
          '5.4. Під час замовлення Товару Покупець обирає Товар із запропонованого Продавцем асортименту, указуючи, зокрема, розмір, колір, за необхідності надаючи іншу конкретну інформацію та зазначаючи кількість замовлених Товарів.',
        text5:
          '5.5. На будь-якій стадії розміщення замовлення до моменту його оформлення Покупець може скасувати замовлення, припинивши процес замовлення та покинувши сторінку замовлення. Замовлення, розміщення якого Покупець не завершив, не приймається Покупцем до виконання.',
        text6:
          '5.6. Заповнення Бланку Замовлення передбачає внесення Покупцем всієї необхідної інформації для ідентифікації Покупця, адреси доставки Товару, контактного номеру телефону Покупця або інші засоби зв’язку, а також інші відомості, які вказуються Продавцем в бланку Замовлення та є обов’язковими до заповнення Покупцем. ',
        text7:
          "5.7. Покупець несе повну відповідальність за надання неправдивих відомостей, що спричинило неможливість належного виконання Продавцем своїх зобов'язань перед Покупцем.",
        text8:
          '5.8. Покупець самостійно обирає кількість та асортимент Товару, перелік якого розміщується на веб-сайті Продавця та формує Замовлення в «кошику Замовлення» або через «Особистий кабінет».',
        text9:
          '5.9. Після заповнення бланку Замовлення, шляхом натискання кнопки «Оформлення Замовлення» на веб-сайті Продавця, Покупець погоджується з умовами цієї Угоди та іншими документами Продавця та вважається таким, що оформив замовлення Товару.',
        text10:
          '5.10. Покупець має право самостійно обирати способи отримання (доставки) Товару з тих способів, які запропоновані Продавцем  для кожного Товару окремо на офіційному веб-сайті Продавця.',
        text11:
          '5.11. З метою підтвердження Замовлення Продавець або уповноважена Продавцем особа має право здійснити телефонний дзвінок Покупцю та підтвердити Замовлення Покупця або отримати інформацію про скасування такого Замовлення. Підтвердження Замовлення може надсилатись Продавцем на електронну пошту Покупця, чи за допомогою СМС повідомлень, чи повідомлень в мессенджерах. У випадку неможливості отримати підтвердження Покупця протягом одного робочого дня, наступного після дня оформлення Замовлення, Продавець надсилає Товар Покупцю на вказану Покупцем адресу та вважається таким, який виконав умови цієї Угоди.',
        text12:
          '5.12. У випадку, якщо у Продавця на момент здійснення замовлення Покупцем відсутній товар в кількості та асортименті відповідно до Замовлення Покупця, Продавець або уповноважена Продавцем особа інформує про це Покупця за допомогою надсилання листа на електронну пошту,  здійснення телефонного дзвінка Покупцю на вказаний ним номер телефону або в інший доступний спосіб. В такому випадку Продавець має право запропонувати Покупцю інший товар або зачекати до появи необхідного товару. В будь-якому випадку, Сторони прийшли до згоди, що інформування Покупця про замовлення, підтвердження Замовлення, а також інші пропозиції Продавця не будуть визнаватись як агресивна форма підприємницької практики.',
        text13:
          '5.13. У разі неможливості поставки  замовлених Покупцем Товарів, Продавець має право виключити зазначений Товар із Замовлення/анулювати Замовлення Покупця, повідомивши про це Покупця шляхом направлення відповідного електронного повідомлення на адресу, зазначену Покупцем при реєстрації, або іншим способом.',
        text14:
          '5.14. Покупець має право змінити Замовлення або відмовитись від нього шляхом повідомлення Продавця письмово чи за допомогою засобів дистанційного зв’язку про необхідність змінити Замовлення до моменту передачі Замовлення оператору поштового зв’язку чи кур’єрської служби. Після передачі Товару у відділення оператора поштового зв’язку або кур’єрської служби Продавець вважається таким, що виконав умови цієї Угоди щодо відправлення Товару Покупцю.',
        text15:
          '5.15. Після отримання та підтвердження Замовлення Покупця, Продавець протягом 2 (двох) робочих днів надсилає Покупцю Товар, або повідомляє Покупця про неможливість виконати Замовлення в зв’язку з відсутністю такого Товару у Продавця або з інших причин, передбачених цією Угодою та чинним законодавством України. ',
        text16:
          '5.16. Строк формування Замовлення проводиться протягом двох робочих днів з моменту його оформлення. В разі, якщо Замовлення відправлено у вихідний або святковий день, строк формування починається з першого після вихідного робочого дня.',
        text17:
          '5.17. У разі виникнення у Покупця запитань, які стосуються властивостей і характеристик Товарів, перед оформленням Замовлення Покупець може звернутися до Продавця будь-яким доступним способом.',
        text18:
          '5.18. При здійсненні Замовлення Товару на сайті інтернет-магазину GOT`S LABEL Покупець зобов’язується надати наступну інформацію:',
        text19: '5.18.1. прізвище та ім’я Покупця або вказаної ним особи (Одержувача);',
        text20:
          '5.18.2. адреса, за якою слід доставити Товар (якщо доставлення на адресу Покупця);',
        text21: '5.18.3. адреса електронної пошти;',
        text22: '5.18.4. номер контактного телефона;',
        text23: '5.18.5. спосіб оплати;',
        text24: '5.18.6. спосіб доставлення.',
        text25: 'За необхідності Покупець може додати коментар.',
        text26:
          '5.19. Якщо Продавцю необхідна додаткова інформація, він має право запросити її у Покупця. У разі ненадання необхідної інформації Покупцем, Продавець не несе відповідальності за надання якісної послуги Покупцю при покупці Товарів в інтернет-магазині.',
        text27:
          '5.20. Продавець має право відмовити Покупцю в обробці замовлення та його оформленні у випадку, якщо Покупець два і більше рази підряд не забрав Товар з точки доставлення; якщо Покупець відмовився від прийняття Товару без обґрунтованих на те підстав у випадку кур’єрського доставлення; якщо кур’єр не зміг здійснити доставлення протягом трьох раз.',
      },
      deliveryGoods: {
        title: '6. ДОСТАВКА ТА ПЕРЕДАЧА ТОВАРУ',
        text1:
          '6.1. Після отримання Продавцем Замовлення та підтвердження Замовлення Покупцем, Продавець передає Товар оператору поштового відправлення або кур’єрській службі для подальшого відправлення Товару на адресу Покупця, яка вказується Покупцем у Замовленні. У випадку надання Покупцем недостовірної інформації стосовно адреси Покупця, Продавець має право притримувати відправку Товару до моменту отримання достовірних відомостей про адресу Покупця. В такому випадку строк відправлення Покупцю Товару, який передбачений п. 5.15.  цієї  Угоди починається з моменту отримання достовірних відомостей про адресу Покупця.',
        text2:
          '6.2. Покупець при оформленні Замовлення має право вибрати один з декількох видів доставки, які запропоновані Продавцем:',
        text3: '-   Доставка за допомогою поштового відправлення;',
        text4: '-   Кур’єрська доставка на адресу Покупця.',
        text5: 'Вартість та строки доставки вказуються Продавцем.',
        text6:
          'У випадку неможливості здійснити доставку протягом визначеного в цій Угоді строку, Продавець інформує про це Покупця.',
        text7:
          '6.3. У випадку, якщо Товар не був доставлений Покупцю з вини Покупця, повторна доставка такого Товару, вартість такої доставки та строки обговорюються та узгоджуються  з Продавцем.',
        text8:
          '6.4. Під час отримання Товару Покупець зобов’язаний оглянути Товар, впевнитись, що отриманий Товар відповідає замовленню Покупця, перевірити Товар на предмет належної якості та кількості, прийняти та оплатити Товар в порядку, визначеному цією Угодою та/або іншими документами.',
        text9:
          '6.5. Датою отримання Товару Покупцем вважається дата вручення Товару Покупцеві або отримання Покупцем Товару у відділенні служби поштової доставки або безпосередньо в місці здійснення господарської діяльності Продавця.',
        text10:
          '6.6. Під час отримання Товару Покупець зобов’язаний оглянути Товар, впевнитись, що отриманий Товар відповідає замовленню Покупця, перевірити Товар на предмет належної якості та кількості, прийняти та оплатити Товар в порядку, визначеному цією Угодою та/або іншими документами.',
        text11:
          '6.7. Право власності на Товар переходить від Продавця до Покупця в момент фактичного отримання Товару при умові повної сплати вартості Товару Покупцем. У випадку продажу Товару з розстроченням платежу, право власності на Товар переходить до Покупця після оплати повної вартості товару.',
        text12:
          '6.8. Покупець несе відповідальність за ризик випадкового знищення/загибелі чи пошкодження Товару з моменту отримання Товару.',
        text13:
          '6.9. Продавець забезпечує своєчасність підготовки та відправки Товару Покупцю і передачі Товару оператору поштового зв’язку чи кур’єрській службі відповідно до способу доставки, який вибраний Покупцем. Продавець не несе відповідальності перед Покупцем за затримку доставки Товару, яка виникла не з вини Продавця та /або в зв’язку з непередбачуваними обставинами та за пошкодження/псування/втрату Товару, що виникло з вини оператора поштового зв’язку або кур’єрської служби під час доставки Товару Покупцю.',
      },
      paymentProcedure: {
        title: '7. ВАРТІСТЬ ТА ПОРЯДОК ОПЛАТИ',
        text1:
          '7.1. Ціна кожного окремого Товару визначається Продавцем і вказується на сайті інтернет-магазину GOT`S LABEL. Ціна Замовлення визначається шляхом додавання ціни всіх включених в Замовлення Товарів, яка дорівнює сумі, що підлягає до сплати, що вказується на сайті інтернет-магазину при оформленні Замовлення в момент його розміщення.',
        text2:
          '7.2. Ціна Договору дорівнює ціні Замовлення. Сума Замовлення може змінюватися в залежності від ціни, кількості або номенклатури Товару.',
        text3:
          '7.3. Ціна на Товар, зазначена на сайті на момент оформлення замовлення, містить всі розрахунки податків та є кінцевою ціною, обов’язковою як для Покупця, так і для Продавця. Продавець може змінювати ціну Товару в односторонньому порядку до моменту оформлення замовлення.',
        text4:
          '7.4. Покупець здійснює оплату Товару згідно Замовлення. Покупець самостійно вибирає один з таких способів оплати: – готівковий розрахунок; – безготівковий розрахунок.',
        text5:
          '7.5. Оплата Товару за готівковий розрахунок може здійснюється у роздрібному магазині (Шоу Румі) в момент здійснення Замовлення або в момент передачі Товару.',
        text6: '7.6. Оплата Товару за безготівковим розрахунком здійснюється:',
        text7:
          '1) за допомогою банківського переказу грошей на поточний рахунок Продавця, що вказаний в рахунку, в т.ч. за допомогою Інтернет-банкінгу.',
        text8:
          '2) за післяплатою при отриманні Замовлення в представництві служби доставки або поштового оператора.  ',
        text9: '3) за післяплатою кур’єру при отриманні Товару кур’єрською доставкою.',
        text10:
          '4) через онлайн-сервіс оплати ТОВ «Нова пошта» «Контроль оплати». У разі оплати через вказаний сервіс вартість Товару збільшується на розмір комісії «Нової пошти».',
        text11:
          '5) Кредитною карткою наступного типу: Visa, Visa Electron, Mastercard, Mastercard Electronic, Maestro.',
        text12: '6) за допомогою платіжної системи WayForPay.',
        text13: '7) будь-яким іншим способом за домовленістю з Продавцем.',
        text14:
          '7.7. Покупець здійснює оплату в національній валюті України – гривні відповідно до вартості Замовлення. Оплата вартості Товару та вартості поштового або кур’єрського відправлення Товару здійснюється Покупцем в повному обсязі при отриманні Товару.',
        text15:
          '7.8. Замовлення вважається сплаченим з моменту надходження оплати на розрахунковий рахунок Продавця або його представника.',
        text16:
          '7.9. Інформація щодо оплати вартості Товару та поштової або кур’єрської доставки Товару вказана на веб-сайті Продавця. У випадку необхідності, Покупець має право отримувати інформацію у Продавця про вартість Товару та вартість поштової або кур’єрської відправки товару.',
        text17:
          '7.10. При замовленні Товару за межі території України Продавцем до вартості Товару додається вартість доставлення згідно з тарифами Компаній-перевізників, у такому випадку Покупець здійснює 100% передплату вартості Товару і доставлення.',
        text18:
          '7.11. Покупець розуміє та погоджується, що у випадку, якщо Товари, придбані в інтернет-магазині, доставляється за межі України, то вартість при отриманні посилки може залежати від додаткової вартості податку на території країни одержувача. Розмір мита визначається компетентними органами країни-одержувача.',
        text19:
          '7.12. Відповідно до статті 8 Закону України «Про захист прав споживачів» під час продажу Товару Продавець зобов’язаний видати платіжний документ (чек, рахунок тощо) встановленої форми із зазначенням дати продажу покупцю (клієнту). Він підтверджує факт покупки. Видача платіжного документа може бути реалізована за згодою покупця у вигляді електронного платіжного документа (e-document) на номер клієнта, через месенджери або на адресу електронної пошти, надану клієнтом. Платіжний документ повинен бути оформлений або створений в електронній формі не пізніше моменту відправлення/передачі Товару.',
      },
      returnsAndExchanges: {
        title: '8. ПОВЕРНЕННЯ ТА ОБМІН ТОВАРУ',
        text01:
          '8.1. Покупець має право здійснити повернення або обмін Товару в порядку та в строки, які встановлені цією Угодою.',
        text1:
          '8.2. У випадку отримання Товару неналежної якості, Покупець має право вимагати у Продавця обмін такого Товару на Товар належної якості. Якщо обмін Товару неналежної якості на Товар належної якості неможливий, Покупець має право вимагати розірвання Угоди та повернення оплаченої вартості Товару.',
        text2:
          '8.2.1. У випадку пред’явлення претензії Покупцем щодо неякісного Товару, така Претензія подається Покупцем у письмовому вигляді шляхом відправлення на поштову адресу Продавця або в електронному вигляді на електронну адресу Продавця gotslabel.cooperation@gmail.com',
        text3: '8.2.2. В претензії Покупець зобов’язаний вказати:',
        text4: '-   Прізвище, ім’я по-батькові;',
        text5: '-   Контактний номер телефону;',
        text6: '-   Поштову адресу (актуальну для листування);',
        text7: '-   Електронну адресу (за наявності);',
        text8: '-   Підстави подання претензії, опис пошкоджень чи ознак неякісного товару;',
        text9: '-   Зміст вимоги до Продавця (обміняти, повернути кошти);',
        text10: '-   Дату придбання товару;',
        text11:
          'До Претензії Покупець обов’язково повинен прикріпити копію чеку чи іншого документу, який підтверджує придбання Товару. Для уникнення будь-яких непорозумінь, після отримання претензії Продавець може вимагати фото або відео фіксацію, на основі яких Продавець зможе здійснити розгляд претензійних вимог Покупця.',
        text12:
          '8.2.3. Продавець розглядає претензію Покупця протягом 30 календарних днів з дати отримання претензії. Продавець може відмовити Покупцю в задоволенні претензії якщо:',
        text13: '-   Покупець не вказав в претензії відомості, передбачені п.7.2.2. цієї Угоди;',
        text14: '-   Покупець не надав копію документу, що підтверджує придбання Товару;',
        text15:
          '-   Покупець на вимогу Продавця не надав фото та/або відеоматеріали, що доводять наявність пошкодженого чи дефектного Товару;',
        text16: '-   Пошкодження або дефект товару виник не з вини Продавця;',
        text17:
          '-   Пошкодження або дефект Товару виникли через неналежну експлуатацію Товару Покупцем за правилами виробника чи Продавця, а також пошкодження, що виникли з вини (дій чи бездіяльності) Покупця;',
        text18:
          '-   Пошкодження або дефект Товару виник внаслідок дії обставин непереборної сили або форс-мажорних обставин.',
        text19:
          '8.2.4. Після розгляду претензії Продавець надсилає Покупцю повідомлення про результати розгляду претензії. Надсилання такого повідомлення відбувається за допомогою тих технічних засобів, якими претензія була відправлена Продавцю.',
        text20:
          '8.2.5. У випадку задоволення претензії, Продавець може обміняти придбаний Товар Покупця на інший Товар, який наявний у Продавця, а в разі неможливості обміну  повернути Покупцю вартість Товару. Повернення вартості Товару Покупцю здійснюється за допомогою безготівкового перерахунку на банківський рахунок Покупця. В будь-якому разі, повернення коштів Покупцю здійснюється протягом 14 календарних днів з моменту повідомлення Продавцем Покупця про результати розгляду претензії.',
        text21:
          '8.3. Покупець має право повернути Товар належної якості протягом 14 (чотирнадцяти) днів з моменту отримання Товару належної якості. Під час повернення Товару Покупець зобов’язаний передати Продавцеві чек, упаковку та інші елементи укомплектування Товару, приналежності, документи, які були отримані Покупцем під час прийому товару (партії товару). Поверненню Покупцем підлягає тільки товар, який є не пошкоджений (в тому числі приналежності та документи), має товарний вигляд та є придатним до продажу чи подальшого використання.',
        text22:
          '8.4. Положення п. 7.3. не застосовується у випадку, якщо Покупець не передав Продавцеві розрахунковий документ з зазначенням дати Покупки, та не передав Товар або передав Товар в неповній комплектації, чи пошкоджений Товар, у випадку, якщо таке пошкодження було спричинене після отримання Товару з вини (дії, бездіяльності) Покупця або у випадку, коли Товар придбаний Покупцем не в Продавця.',
        text23:
          '8.5. При поверненні Товару належної якості Покупцю повертається вартість Товару без вартості поштової або кур’єрської відправки Товару.',
        text24:
          '8.6. Повернення Товару належної якості приймається Продавцем тільки у випадку дотримання Покупцем наступних вимог:',
        text25:
          '8.6.1. Товар, документація (бірки, ярлики) не пошкоджені та зберігають товарний вигляд, придатні до подальшого використання або продажу.',
        text26:
          '8.6.2 Разом з Товаром передається чек (накладна) чи будь-який інший розрахунковий документ, що підтверджує купівлю Товару.',
        text27:
          '8.6.3. При поверненні Товару належної якості, Покупець зобов’язаний додати до Товару Заяву довільної форми з проханням повернути вартість Товару та обов’язковим зазначенням:',
        text28: '-   Прізвища, імені та по-батькові Покупця;',
        text29: '-   Поштової адреси;',
        text30: '-   Контактного номеру телефону;',
        text31: '-   Адресу електронної пошти (за наявності);',
        text32: '-   Дату отримання Товару;',
        text33: '-   Вартості Товару;',
        text34:
          '-   Банківські реквізити у випадку повернення шляхом безготівкового перерахунку на рахунок Покупця.',
        text35:
          'При поверненні Товару належної якості, Покупець зобов’язаний додати до Товару Заяву довільної форми з проханням повернути вартість Товару та обов’язковим зазначенням:',
        text36: '-   Прізвища, імені та по-батькові Покупця;',
        text37: '-   Поштової адреси;',
        text38: '-   Контактного номеру телефону;',
        text39: '-   Адресу електронної пошти (за наявності);',
        text40: '-   Дату отримання Товару;',
        text41: '-   Вартості Товару;',
        text42:
          '-   Банківські реквізити у випадку повернення шляхом безготівкового перерахунку на рахунок Покупця.',
        text43:
          '8.7. У випадку недотримання Покупцем вимог п.7.6. цієї Угоди, Продавець має право відмовити Покупцю в поверненні вартості Товару належної якості. Датою отримання Продавцем поверненого Товару вважається дата фактичного отримання Продавцем Товару.',
        text44:
          '8.8. Спосіб повернення Товару Продавцю Покупець визначає самостійно. У випадку повернення пошкодженого Товару, якщо таке пошкодження виникло з вини Покупця, або під час пересилання Покупцем Товару, Продавець має право відмовити Покупцю в поверненні вартості Товару.',
        text45:
          '8.9. Вимога Покупця щодо повернення Товару належної якості може бути реалізована Покупцем протягом 14 (чотирнадцяти) календарних днів, починаючи з наступного дня після отримання Товару.',
        text46: '8.10. Покупець не має права вимагати повернення Товару належної якості у випадку:',
        text47:
          '8.10.1. Якщо такий Товар був виготовлений на Замовлення Покупця з урахуванням індивідуальних характеристик;',
        text48:
          '8.10.2. Якщо такий Товар відноситься до категорії Товарів, які не підлягають поверненню відповідно до чинного законодавства України. Не підлягають поверненню непродовольчі Товари належної якості, перелік яких затверджено Постановою Кабінету Міністрів України №172 від 19.03.1994 р.',
        text49:
          '8.10.3. Якщо повернення стосується Товарів, доставка яких була здійснена за територію України та Польщі (міжнародна доставка). ',
        text50:
          '8.10.4. Якщо  повернення стосується  Товарів, які були використані для фото/відео-зйомок. Товари, які були задіяні у фото/відео-зйомках вважаються такими, що були в експлуатації та не підлягають обміну чи поверненню (навіть про збереженні товарного вигляду).',
        text51:
          '8.10.5. Якщо повернення стосується Товарів, придбаних за допомогою подарункових сертифікатів  (Не підлягають поверненню  також самі подарункові сертифікати).',
        text52:
          '8.11. У випадку дотримання Покупцем положень п. 7.3.-7.9. цієї Угоди, Продавець здійснює повернення вартості Товару належної якості Покупцю протягом 14 календарних днів починаючи з наступного дня після отримання Товару Продавцем.',
        text53:
          '8.12. Гарантійні строки для Товарів встановлюються тривалістю 30 календарних днів з дати продажу або з початку сезону. Протягом цього періоду Продавець гарантує належну якість Товару та придатність Товару для експлуатації.',
        text54:
          '8.13. Скарги (претензії) на недоліки Товару можна подати впродовж 30 тридцяти днів з моменту придбання Товару. Скарга може бути подана лише у випадку, якщо дефект виявлено протягом гарантійного строку, і дефект обумовлює невідповідність Товару умовам договору (викладеним на цьому веб-сайті). Відмінність елементів дизайну або оформлення, від заявлених в описі на Веб-сайті, не є ознакою Товару неналежної якості.',
        text55:
          '8.13. Не підлягає гарантійному ремонту Товар, який був пошкоджений з вини Покупця, Товар, який використовувався не за призначенням або з порушенням умов використання.',
        text56:
          '8.14. У випадку необхідності провести експертизу якості Товару, сторона, яка ініціює проведення експертизи якості Товару несе витрати пов’язані з проведенням такої експертизи, якщо інше не встановлено Законом.',
      },
      responsibilityParties: {
        title: '9. ВІДПОВІДАЛЬНІСТЬ СТОРІН',
        text1:
          '9.1. Сторона, винна в порушенні умов цієї Угоди несе відповідальність за збитки (прямі та непрямі) спричинені таким порушенням іншій Стороні в порядку передбаченому цією Угодою та Законом.',
        text2:
          '9.2. Продавець не несе відповідальності за збитки, спричинені Покупцю внаслідок неналежного використання ним Товарів, які були придбані у Продавця.',
        text3: '9.3. Продавець не несе відповідальності перед Покупцем за збитки, спричинені:',
        text4:
          '9.3.1.Неналежним заповненням Покупцем бланку Замовлення, в тому числі надання не вірних відомостей (персональних даних) для ідентифікації Покупця та адреси доставки Товару;',
        text5: '9.3.2.Неналежним виконанням Покупцем умов цієї Угоди;',
        text6: '9.3.3. Неправомірними діями третіх осіб;',
        text7: '9.3.4. В інших випадках, передбачених цією Угодою або Законом.',
        text8:
          '9.4. Продавець несе відповідальність за якість та комплектність товару. У випадку, якщо Покупець під час отримання товару виявив пошкодження, дефекти, неукомплектованість чи інші істотні ознаки, що унеможливлюють використання Товару за призначенням та/або не задовольняють, Покупець має право вимагати, а Продавець має право задовольнити одну з таких вимог Покупця:',
        text9: '1) Пропорційного зменшення ціни;',
        text10: '2) Безоплатного усунення недоліків в розумний строк;',
        text11: '3) Відшкодування витрат на усунення недоліків;',
        text12: '4) Безоплатної заміни товару на аналогічний товар.',
        text13:
          '9.4.1. Продавець несе відповідальність, передбачену п.7.2. цієї Угоди у випадку, якщо Покупець доведе, що пошкодження, дефекти, неукомплектованість Товару виникли до отримання цього товару Покупцем.',
        text14:
          "9.5. Сторона, яка порушила зобов'язання, звільняється від відповідальності за порушення зобов'язання, якщо вона доведе, що це порушення сталося в наслідок непереборної сили (форс–мажору).",
        text15:
          '9.6. Сторона не несе відповідальності за порушення цієї Угоди, якщо воно сталося не з її вини (умислу чи необережності).',
        text16:
          '9.7. Сторона вважається невинуватою і не несе відповідальності за порушення цієї Угоди, якщо вона доведе, що вжила всіх залежних від неї заходів щодо належного виконання цієї Угоди.',
        text17:
          '9.8.Продавець не несе відповідальності за безпеку, зміст та контент ресурсів мережі інтернет, які Покупець може відвідати шляхом переходу за посиланням (гіперпосиланням), які розміщені на сайті.',
        text18:
          '9.9. Покупець несе відповідальність за достовірність інформації та персональних даних, які вказуються Покупцем в Замовленні та при взаємодії з Продавцем.',
      },
      resolutionOfDisputes: {
        title: '10. ВИРІШЕННЯ СПОРІВ',
        text1:
          '10.1. Усі суперечки та розбіжності, що виникають у зв’язку з виконання цієї Угоди, Сторони вирішують шляхом переговорів.',
        text2:
          '10.2. Сторони зобов’язуються докласти всіх зусиль щоб вирішувати суперечки та розбіжності в порядку претензійного, досудового врегулювання таких суперечок чи розбіжностей.',
        text3:
          '10.3. У випадку, якщо суперечки та/або розбіжності, не можуть бути вирішені шляхом переговорів, вони вирішуються в судовому порядку за встановленою підвідомчістю та підсудністю такого спору відповідно до чинного законодавства України.',
      },
      forceMajeure: {
        title: '11. ФОРС-МАЖОР',
        text1:
          '11.1. Сторони звільняються від відповідальності за повне або часткове невиконання своїх зобов’язань по даній Угоді, якщо воно викликано обставинами непереборної сили (форс-мажор), таких як: стихійні лиха, пожежі, повені, землетруси, війна, блокада, громадські заворушення, окупація, ембарго, проведення антитерористичних операцій (АТО), введення військового стану на всій території держави або її частини, блокування транспортних шляхів чи будівель, приміщень в результаті громадянських акцій або громадських заворушень чи протестів, заборони на експорт (імпорт), акти або закони, видані державними органами України та/чи іншими органами, які контролюють відповідну територію, де виникли обставини не переробної сили, а також всі інші випадки, визначені ст.14.1. Закону України «Про торгово-промислові палати в Україні».',
        text2:
          '11.2. У випадку настання обставин непереборної сили, строк виконання зобов’язань відсувається відповідно до часу, протягом якого будуть діяти такі обставини. Якщо ці обставини і їх наслідки будуть продовжуватися більше 3 (трьох) місяців, то кожна зі Сторін буде мати право відмовитися від подальшого виконання зобов’язань по даній Угоді. У цьому випадку жодна зі Сторін не буде мати право на відшкодування іншою Стороною можливих збитків.',
        text3:
          '11.3. Сторона, для якої створилася неможливість виконання зобов’язань за Угодою, у зв’язку з настанням форс-мажорних обставин, зобов’язана повідомити (факсом, електронною поштою) іншу Сторону протягом 3-х календарних днів після виникнення таких обставин, а також протягом 45 (сорока п’яти) календарних днів із дня настання форс-мажорних обставин вислати повідомлення установленої форми (висновок або інший підтверджуючий документ), видане представником відповідної Торгово-промислової палати або іншого уповноваженого законодавством органу, з указівкою наявності зазначених вище подій, дати їх настання і тривалості.',
        text4:
          '11.4. Якщо обставини непереборної сили тривають більше 2 (двох) календарних місяців поспіль, Сторони зобов’язуються прийняти рішення щодо подальшої процедури виконання цієї Угоди.',
        text5:
          '11.5. При несприятливих погодних умовах (туман, ожеледь, снігові замети та таке інше), за яких, згідно з Умовами на перевезення небезпечного вантажу, забороняється рух транспортних засобів з небезпечним вантажем, термін виконання зобов’язань по цій Угоді подовжується на термін дії вищезазначених обставин. Сторони терміново інформують одна одну про початок та закінчення вказаних обставин. При цьому для підтвердження даних обставин висновок Торгово-промислової палати України  або іншої компетентної установи не вимагається.',
      },
      periodAgreement: {
        title: '12. УКЛАДАННЯ УГОДИ ТА СТРОК ДІЇ УГОДИ',
        text1:
          '12.1. Ця Угода вважається укладеною Сторонами та набирає юридичної сили з моменту приєднання Покупця до умов цієї Угоди шляхом натискання Покупцем кнопки «Оформити замовлення» на веб-сайті Продавця, шляхом підтвердження Замовлення Покупцем у випадку здійснення Замовлення по телефону або іншим передбаченим чинним законодавством України шляхом.',
        text2:
          '12.2. Ця Угода діє до повного виконання Сторонами своїх зобов’язань за цією Угодою.',
        text3:
          '12.3. Покупець підтверджує, що до моменту укладання цієї Угоди Продавець надав Покупцю всю необхідну інформацію про Товар, яка передбачена нормами Закону України «Про захист прав споживачів».',
        text4:
          '12.4. У випадку укладання періодичної поставки товару мінімальна тривалість дії Угоди становить 6 (шість) місяців.',
        text5:
          '12.5. Продавець може змінювати умови цієї Угоди попередньо повідомивши Покупця за 5 (п’ять) днів до набрання чинності нової редакції Угоди шляхом відправлення нової редакції Угоди (зі змінами та доповненнями) на електронну адресу Покупця. Якщо до набрання чинності нової редакції Угоди, Покупець не надіслав Продавцю заперечення та/або вимогу розірвати цю Угоду, така Угода в новій редакції вважається повністю прийнятою і погодженою Покупцем.',
        text6:
          '12.6. Ця Угода може бути змінена Продавцем в односторонньому порядку для всіх своїх клієнтів, Покупців з одночасною публікацією нової версії на офіційному веб-сайті Продавця, а також з повідомленням всіх клієнтів, Покупців за допомогою електронної пошти. У разі незгоди клієнтів, Покупців з внесеними змінами, укладається окрема письмова угода, в якому будуть врегульовані спірні питання.',
      },
      otherTherms: {
        title: '13. ІНШІ УМОВИ',
        text1:
          '13.1.Приєднання Покупцем до цієї Угоди здійснюється відповідно до акцепту (згоди) Покупця придбати Товар Продавця в порядку та на умовах визначених цією Угодою шляхом натискання кнопки «Оформлення Замовлення» на веб-сайті Продавця.',
        text2:
          '13.2. Приєднання до умов цієї Угоди відбувається шляхом вільного волевиявлення та приймання Покупцем умов цієї Угоди.',
        text3:
          '13.3. Покупець підтверджує, що на момент укладання цієї Угоди він володіє достатнім обсягом цивільної дієздатності та досяг відповідного віку, з досягненням якого передбачено можливість придбання Товару Продавця.',
        text4:
          '13.4. Покупець приймає та погоджується з умовами здійснення покупок на сайті Продавця, зобов’язується не порушувати правила, жодним чином не ображати відвідувачів сайту чи працівників або уповноважених Продавцем осіб, в тому числі не використовувати нецензурну лексику, грубе поводження, образи, приниження честі, гідності та ділової репутації Продавця чи інших осіб.',
        text5:
          '13.5. Умови цієї Угоди поширюються на необмежене коло осіб. Продавець не має права відмовити Покупцю в укладанні цієї Угоди, крім випадків, передбачених Законом.',
        text6:
          '13.6. Покупець не має права вимагати у Продавця змінювати умови цієї Угоди та набувати додаткових прав та обов’язків порівняно з іншими Покупцями.',
        text7:
          '13.7. Ця Угода може бути розірвана Продавцем в односторонньому порядку шляхом письмового повідомлення Покупця не раніше ніж за 30 календарних днів до очікуваної дати розірвання цієї Угоди.',
        text8:
          '13.8. У випадку, якщо Покупець в порядку, передбаченому умовами цієї Угоди не здійснить оплату за Товар Угода вважається неукладеною.',
        text9:
          '13.9.Текст цієї Угоди викладений українською мовою, доступний на сайті Продавця за посиланням http://www.gotslabel.com',
        text10:
          '13.10. Особою, що приймає претензії Покупців відносно якості, комплектності, кількості та асортименту Товару є Продавець.',
        text11:
          '13.11. Одночасно з укладанням цієї Угоди Покупець дає згоду на збір, та обробку його персональних даних та погоджується з іншими (локальними) документами Продавця.',
        text12:
          '13.12. В будь-якому разі, визнання недійсним одного положення цієї Угоди не передбачає визнання недійсним всієї Угоди.',
        text13:
          '13.13. Укладаючи цю Угоду, Покупець надає згоду Продавцю на передачу прав та обов’язків за цією Угодою третім особам.',
        text14:
          '13.14. Сайт інтернет-магазину містить матеріали, товарні знаки, фірмові найменування та інші охоронювані законом матеріали, включаючи, але не обмежуючись, знаки для товарів та послуг, тексти, фотографії, графічні зображення.',
        text15:
          "13.15. Всі об’єкти, доступні в інтернет-магазині https://www.gotslabel.com  , в  тому числі елементи дизайну, текст, графічні зображення, ілюстрації, відео, фото, програми для ЕОМ, бази даних, музика, звуки та інші об'єкти (далі – зміст інтернет-магазину), а також будь-який контент, розміщений на веб-сайті https://www.gotslabel.com  , є об'єктами виняткових прав Власника інтернет-магазину та інших правовласників.",
        text16:
          "13.16. Використання контенту, а також будь-яких інших елементів інтернет-магазинів можливо тільки в рамках функціонала, запропонованого інтернет-магазином. Ніякі елементи змісту інтернет-магазину не можуть бути використані іншим чином без попереднього дозволу/згоди правовласника. Під використанням маються на увазі, в тому числі: відтворення, копіювання, перероблення, поширення на будь-якій основі, відображення у розіграші тощо. Виняток становлять випадки, прямо передбачені законодавством України. Використання Користувачем елементів змісту інтернет-магазину, а також будь-якого контенту для особистого некомерційного використання, допускається за умови збереження всіх знаків охорони авторського права, суміжних прав, товарних знаків, інших повідомлень про авторство, збереження імені (або псевдоніма) автора/найменування правовласника в незмінному вигляді, збереженні відповідного об'єкта в незмінному вигляді. Виняток становлять випадки, прямо передбачені законодавством України.",
      },
      personalInformation: {
        title: '14. КОНТАКТНІ ДАНІ ПРОДАВЦЯ',
        text1:
          '14.1. Найменування продавця, контакті дані: Фізична особа підприємець Готочкіна Світлана Геннадіївна, № запису в ЄДР: 2005220000000088241 від 29.03.2021 року, адреса реєстрації: 54037, місто Миколаїв, вул. Коцюбинського, будинок  20, податковий номер: 3192020323.',
        text2:
          'Контактні дані: телефон +38 099 039 99 99,  телефон  для співпраці: +380660292069 (WhatsApp, Telegram) Email: gotslabel.cooperation@gmail.com, ',
        text3: 'адреса для листування:gotslabel.cooperation@gmail.com',
        text4: 'ШОУРУМ КИЇВ: вул. Лютеранська 11-А ',
        text5: 'Пн-нд: 11:00-19:30 ',
        text6: 'ШОУРУМ ВАРШАВА: Wilcza 44',
        text7: 'Пн-сб: 11:00-19:00',
        text8: 'Instagram Україна: gots.label  ',
        text9: 'Instagram Варшава: gotslabel.warsaw ',
        text10:
          '14.2. Користувач/Покупець може зв’язатися із Продавцем, який управляє інтернет-магазином, наведеними нижче способами:',
        text11:
          '▪ За фактичною адресою інтернет-магазину GOTS LABEL: Україна, 01133, місто Київ, вул. Лютеранська 11-А.',
        text12:
          '▪ Зателефонувати на гарячу лінію для стаціонарних і мобільних телефонів: +38 099 039 99 99 (Оплата за підключення згідно з тарифами оператора).',
        text13: '▪ Електронною поштою на адресу: електронна пошта gotslabel.cooperation@gmail.com.',
        text14: '▪ Через контактну форму на веб-сайті інтернет-магазину.',
      },
    },
    eng: {
      publicOffer: {
        title: 'PUBLIC OFFER',
        text1:
          "This Public Offer Agreement (hereinafter referred to as the “Agreement”), in accordance with the Civil Code of Ukraine, is an official proposal (public offer) to an unlimited number of individuals (hereinafter referred to as the “Buyer”) from the Individual Entrepreneur Svitlana Hotschkina (hereinafter referred to as the “Seller”), and contains all essential terms necessary for the purchase and sale of the Seller's product on the terms of accession. This Agreement is concluded in accordance with the legislative acts of Ukraine, in particular, based on Articles 633, 634, 641, 642 of the Civil Code of Ukraine, as well as the terms and rules established and valid at the Seller.",
        text2:
          "The text of this Agreement is constantly available to Buyers on the Internet on the official website of the Seller http://www.gotslabel.com and at the addresses of the Seller's business activities. Information posted on the Seller's Website, including information about the products offered by the Seller, is not a public offer in itself and contains information intended solely for the Buyer's acquaintance with:",
        text3: "- the Seller's activities;",
        text4:
          "- a list, characteristics, and prices of products that may be sold by the Seller to the Buyer subject to the availability of the respective products in the Seller's warehouse at the time of the Buyer's order;",
        text5:
          "- the terms on which the Seller is ready to establish buyer-seller relations, taking into account this Agreement and the unconditional priority of the terms of the Agreement over other terms that may be posted on the Seller's Website regardless of the context of their reading and interpretation.",
      },
      conceptsTerms: {
        title: '1. CONCEPTS AND TERMS',
        text1:
          '1.1. In this Agreement, the following terms and concepts are used in the following sense:',
        text2:
          "1.1.1. Public offer (proposal) – the Seller's proposal (posted on his official Website), addressed to an indefinite circle of individuals, to conclude an Agreement on specified terms.",
        text3:
          "1.1.2. Acceptance - the full and unconditional acceptance by the Buyer of the terms of the Public offer, set forth in this Agreement, by providing the Seller with consent to place an Order, acceding to the Agreement, and paying the full value of the Goods. By clicking the button: 'PLACE AN ORDER,' the Buyer agrees to the terms of this Agreement and accepts the Seller's offer to purchase Goods from the Seller on the terms of this Agreement. The Buyer's acceptance of the Seller's offer to purchase the Goods is a confirmation of the expression by the Buyer of unconditional will to purchase Goods from the Seller in accordance with the Order on the terms specified below. The operation of this Agreement on the terms of ordering the Goods applies only and exclusively to those relations between the Buyer and the Seller that are related to the order and sale of those goods whose list is determined in the Buyer's order form.",
        text4:
          "1.1.3. Agreement – this Agreement, which is posted on the Seller's website and regulates the mutual relations between the Seller and the Buyer, according to which the Individual Entrepreneur Svitlana Hotschkina, as the Seller, through the online store 'GOT`S LABEL' on the Seller's official website http://www.gotslabel.com undertakes to sell, if available, the Goods to each Buyer who applies to him, and establishes uniform conditions for the sale of these Goods for all Buyers, and the Buyer undertakes to pay their value in full and on time. The Agreement is publicly announced to all Buyers by its placement (publication) on the Seller's official website.",
        text5:
          '1.1.4. Order form – a written template of a document that the Buyer completes to provide the Seller with mandatory information about himself and by completing confirms:',
        text6: '-   the fact of acceptance of the public offer (proposal) to join this Agreement;',
        text7: '-   the fact of acquaintance with its content;',
        text8: '-   agreement to its terms.',
        text9:
          'In the order form, the Buyer specifies his surname, name, patronymic, mailing address to which the goods will be sent, contact phone number, and, if necessary, additional information.',
        text10:
          '1.1.5. Buyer – a legally capable individual who has entered into a purchase and sale agreement with the Seller.',
        text11:
          '1.1.6. Seller – Individual Entrepreneur Svitlana Hotschkina, Taxpayer Identification Number 3192020323, registration address: 54037, Mykolaiv, Kotsiubynskoho Street 20.',
        text12:
          '1.1.7. Official Website of the Seller – the official website of the Seller on the Internet, located at: http://www.gotslabel.com (permanently available for viewing), and is a source of information for Buyers.',
        text13: '1.1.8. Parties – collectively the Seller and the Buyer.',
        text14:
          '1.1.9. Goods (batch of goods) – the subject of the purchase and sale agreement under this Agreement, specified in the Order, not withdrawn from circulation, with information about its characteristics, which the Buyer can familiarize himself with on the Website, the list of assortment presented in the online store, in which the price, name, and description of the goods are indicated and which is available from the Seller, and which the Seller is ready to transfer to the ownership of the Buyer in the manner and on the terms determined by this Agreement.',
        text15:
          "1.1.10. Recipient – the Buyer or a person who actually receives the Goods delivered by the Seller's own efforts or by the Courier Company.",
        text16:
          '1.1.11. Material defect of the Goods – a defect that makes it impossible or unacceptable to use the Goods according to their intended purpose, arising from the fault of the manufacturer (Seller), after its elimination reappears from causes independent of the consumer and is equipped with at least one of the following signs: a) it cannot be eliminated at all; b) its elimination requires more than fourteen calendar days; c) it makes the Goods significantly different from what is provided for in the contract.',
        text17:
          '1.1.12. Courier delivery – direct delivery of the Goods by an employee of the courier or courier or forwarding service to the Buyer at the address specified by the Buyer as the delivery address, on paid or unpaid terms.',
        text18:
          '1.1.13. Delivery service/Transport company – third parties providing delivery services of Orders to the Buyer.',
        text19:
          '1.1.14. Terms may be used in the Agreement that are not defined in section 1 of the Agreement. In this case, the interpretation of the term is carried out in accordance with the text of the Agreement. In the absence of unambiguous definition of the term in the text of the Agreement, the interpretation of the term should be based on: first - Ukrainian legislation, secondly - on the website, then on the generally accepted interpretation that has arisen.',
      },
      subjectOfAgreement: {
        title: '2. SUBJECT OF AGREEMENT',
        text1:
          '2.1. In accordance with the terms and conditions set forth in this Agreement, the Seller undertakes to transfer ownership of the Goods to the Buyer, and the Buyer undertakes to accept the Goods and pay its price.',
        text2:
          '2.2. The Buyer confirms that for the purchase of the Goods under this Agreement, the Buyer has a sufficient level of legal capacity.',
        text3:
          "2.3. To avoid any disputes, the ordering of the Goods implies the Buyer's adherence to the terms of this Agreement. The Buyer has the opportunity to familiarize himself with the terms of this Agreement, as well as with other (local) documents of the Seller in the relevant section located on the Seller's website. The text of this Agreement is posted on the Seller's website. The Buyer has the right and technical ability to print this Agreement on a tangible medium from the Seller's website.",
        text4:
          '2.4. Together with the Goods, the Seller transfers to the Buyer the belongings of the Goods and the necessary documents, the terms and procedure for the transfer of which are established by this Agreement and the legislation of Ukraine.',
        text5: '2.5. An integral part of this Agreement is:',
        text6: '-   Order form;',
        text7: '-   Consent to the collection, processing, and use of Personal Data;',
        text8:
          "2.6. In case of inconsistency between the information posted on the Seller's website and the provisions of this Agreement, for the settlement of relations between the Seller and the Buyer arising during the ordering with subsequent purchase of the goods in accordance with the Order, the text of this Agreement has unequivocal priority and its provisions are recognized as the terms of the Agreement.",
        text9:
          "2.7. The moment of the Buyer's expression of intent to enter into contractual relations with the Seller on the terms of this Agreement is considered to be the moment of pressing the 'Place an Order' button by the Buyer when using the Seller's website.",
        text10:
          '2.8. By entering into this Agreement, the Buyer confirms that he is familiar with the information about the Goods sold under the terms of this Agreement, namely regarding its form, appearance, design, color, size, quality, with the characteristics of the material from which the Goods are made, as well as with information about its manufacturer, understands such characteristics of the Goods and their significance, and agrees to purchase such Goods with such characteristics.',
        text11:
          '2.9. The Buyer is obliged to independently familiarize himself with the terms of this Agreement, and the Seller is not obliged to additionally or in any other way inform the Buyer about the existence of the Agreement, except for its publication on the website: http://www.gotslabel.com',
      },
      ruls: {
        title: '3. RIGHTS AND OBLIGATIONS OF THE PARTIES',
        text1: '3.1. The Buyer has the right:',
        text2:
          '3.1.1. To receive the Goods on the terms and in the manner provided by this Agreement;',
        text3:
          "3.1.2. To receive information about the Seller's readiness to fulfill the Order and deliver the Goods to the Buyer.",
        text4:
          '3.1.3. To return the goods of proper quality within 14 (fourteen) calendar days, excluding the day of purchase.',
        text5:
          "3.1.4. To receive additional information about the Goods, its delivery, and other Seller's Services.",
        text6: '3.2. The Buyer undertakes:',
        text7:
          "3.2.1. To fully pay the cost of the Goods and its delivery under this Agreement, as indicated in the Buyer's Order.",
        text8:
          '3.2.2. Not to transfer the Order to other persons, except as provided by this Agreement and the Law.',
        text9:
          '3.2.3. To comply with all the terms of this Agreement and other internal (local) documents of the Seller, which regulate the procedure and conditions for purchasing the goods;',
        text10:
          "3.2.5. To adhere to the rules of public morality, public order, ethical norms during visits to the Seller's website and communication with the Seller or an authorized person of the Seller.",
        text11:
          "3.2.6. Before accepting the terms of this Agreement, to familiarize oneself with the information posted on the Seller's website, including information about the product, its properties, price, and the procedure for purchasing the product.",
        text12:
          '3.2.7. To provide the Seller with current and accurate information in the Order form that can identify the buyer and the delivery address of the goods (personal data) and other information necessary for the Seller to fulfill the terms of this Agreement.',
        text13: '3.3. The Seller has the right:',
        text14:
          '3.3.1. To require the Buyer to pay the cost of the Goods and/or the cost of postal or courier delivery services or additional services under the terms of this Agreement;',
        text15:
          '3.3.2. To require the Buyer to comply with all the terms of this Agreement, Rules, and other internal (local) documents of the Seller.',
        text16:
          '3.3.3. To establish additional rules for purchasing, discounts, promotions, and inform the Buyer about them.',
        text17:
          "3.3.4. To collect, process, and use the Buyer's personal data and other confidential information to fulfill the terms of this Agreement in the manner provided by this Agreement and its appendices and the Consent to the collection and processing of personal data.",
        text18:
          '3.3.5. To transfer the rights of the Seller to other persons to fulfill the terms of this Agreement.',
        text19: '3.3.6. Other rights provided by this Agreement.',
        text20: '3.4. The Seller undertakes:',
        text21:
          "3.4.1. To timely transfer to the Buyer the ordered Goods and provide other (additional) services at the Buyer's request in the manner and on the terms provided by this Agreement.",
        text22: '3.4.2. To ensure proper quality of the Goods provided to the Buyer.',
      },
      registration: {
        title: '4. REGISTRATION ON THE WEBSITE',
        text1:
          "4.1. By registering on the website of the online store GOT'S LABEL, the Buyer guarantees that they are of legal age and possess the necessary legal capacity to use the website of the online store.",
        text2:
          '4.2. Any person who has not reached the age of 18 may use the online store only with the consent of their parents or guardians.',
        text3: '4.3. Registration on the website is done at the address: http://www.gotslabel.com',
        text4: '4.4. Registration on the website is not mandatory for placing an Order.',
        text5:
          '4.5. By registering on the website of the online store and ordering Goods through the online store, the Buyer confirms their full and unconditional agreement with all the provisions and rules posted on this website.',
        text6:
          '4.6. Everyone who registers and makes purchases on the website in the online store provides their personal data and agrees to their processing on the terms specified in the Privacy Policy.',
        text7:
          '4.7. During registration on the website in the online store - creating an account, the Buyer accepts the terms of the Privacy Policy, the Agreement on the provision of electronic services by accepting one of the following methods:',
        text8:
          "1) By adding the Product to the virtual cart and clicking on the 'Place Order' button on the online store page.",
        text9: "2) By clicking the 'Place Order' or 'Buy in 1 click' button.",
        text10: "3) By clicking the 'Create an account' or 'Register' button.",
        text11: '4) By ordering the Product through social networks or messengers.',
        text12:
          '5) By making payment for the order in full or in part on the terms of this Agreement.',
        text13:
          '4.8. The Seller is not responsible for the accuracy and correctness of the information provided by the Buyer during registration.',
        text14:
          '4.9. The Buyer undertakes not to disclose their login and password to third parties provided by them during registration. In case the Buyer suspects a breach of the security of their login and password or the possibility of their unauthorized use by third parties, the Buyer undertakes to immediately notify the Seller by sending a relevant email to: gotslabel.cooperation@gmail.com',
        text15:
          '4.10. Communication of the Buyer with the representatives of the Seller should be based on the principles of generally accepted morality and communication ethics. The use of profanity, offensive words, threats, and blackmail is strictly prohibited, regardless of the form and to whom they were addressed.',
      },
      orderingGoods: {
        title: '5. ORDERING PROCEDURE FOR GOODS',
        text1:
          "5.1. In accordance with the terms of this Agreement, the Buyer makes a purchase of the Goods by filling out the Order form on the Seller's website. The form of the Order form and its details are determined by the Seller.",
        text2:
          '5.2. The website of the online store contains information about the characteristics of the Goods, prices for the Goods, materials from which they are made, and the approximate availability of these Goods from the Seller.',
        text3:
          '5.3. In case of cancellation of the fully or partially prepaid Order, the cost of the canceled Goods is refunded to the Buyer by the Seller in the manner in which the Goods were paid for.',
        text4:
          '5.4. When ordering the Goods, the Buyer selects the Goods from the assortment offered by the Seller, indicating, among other things, the size, color, if necessary providing other specific information, and specifying the quantity of the ordered Goods.',
        text5:
          '5.5. At any stage of placing an order until its completion, the Buyer may cancel the order by interrupting the ordering process and leaving the order page. An order that the Buyer has not completed will not be accepted by the Buyer until it is completed.',
        text6:
          "5.6. Filling out the Order Form involves the Buyer entering all necessary information for the identification of the Buyer, the delivery address of the Goods, the Buyer's contact phone number or other means of communication, as well as other information specified by the Seller in the Order form and which are mandatory for filling in by the Buyer.",
        text7:
          '5.7. The Buyer bears full responsibility for providing false information that prevents the Seller from properly fulfilling its obligations to the Buyer.',
        text8:
          "5.8. The Buyer independently chooses the quantity and assortment of the Goods, the list of which is placed on the Seller's website, and places an Order in the 'Order basket' or through the 'Personal account'.",
        text9:
          "5.9. After filling out the Order form by pressing the 'Place Order' button on the Seller's website, the Buyer agrees to the terms of this Agreement and other documents of the Seller and is considered to have placed an order for the Goods.",
        text10:
          '5.10. The Buyer has the right to independently choose the methods of receiving (delivery) the Goods from those methods proposed by the Seller for each Goods separately on the official website of the Seller.',
        text11:
          "5.11. In order to confirm the Order, the Seller or a person authorized by the Seller has the right to make a telephone call to the Buyer and confirm the Buyer's Order or obtain information about the cancellation of such Order. Confirmation of the Order may be sent by the Seller to the Buyer's email, via SMS messages, or messenger messages. If it is not possible to obtain confirmation from the Buyer within one working day, the next working day after the day of placing the Order, the Seller sends the Goods to the Buyer at the address specified by the Buyer and is considered to have fulfilled the terms of this Agreement.",
        text12:
          "5.12. In the event that at the time of placing the order by the Buyer the Seller does not have the Goods in the quantity and assortment according to the Buyer's Order, the Seller or a person authorized by the Seller informs the Buyer about this by sending a letter to the email, making a phone call to the Buyer at the specified phone number or in any other available way. In this case, the Seller has the right to offer the Buyer another product or wait for the appearance of the required product. In any case, the Parties have agreed that informing the Buyer about the order, confirming the order, and other offers of the Seller will not be considered as aggressive forms of business practices.",
        text13:
          "5.13. In case of impossibility of delivery of the Goods ordered by the Buyer, the Seller has the right to exclude the specified Goods from the Order/cancel the Buyer's Order, informing the Buyer by sending a corresponding email message to the address specified by the Buyer during registration or by another method.",
        text14:
          '5.14. The Buyer has the right to change the Order or refuse it by informing the Seller in writing or by means of distance communication about the need to change the Order before the moment of transferring the Order to the postal operator or courier. After the transfer of the Goods to the branch of the postal operator or courier company, the Seller is considered to have fulfilled the terms of this Agreement regarding the shipment of the Goods to the Buyer.',
        text15:
          "5.15. After receiving and confirming the Buyer's Order, the Seller sends the Goods to the Buyer within 2 (two) working days or informs the Buyer about the impossibility of fulfilling the Order due to the absence of such Goods from the Seller or for other reasons provided for in this Agreement and the current legislation of Ukraine.",
        text16:
          '5.16. The term of order processing is two working days from the moment of its submission. In case the Order is sent on a weekend or holiday, the processing period starts from the first working day after the weekend.',
        text17:
          '5.17. In case the Buyer has questions regarding the properties and characteristics of the Goods, before placing the Order, the Buyer may contact the Seller by any available means.',
        text18:
          '5.18. When placing an Order for Goods on the website of the online store GOT`S LABEL, the Buyer undertakes to provide the following information:',
        text19:
          '5.18.1. last name and first name of the Buyer or the person specified by them (Recipient);',
        text20:
          "5.18.2. address to which the Goods should be delivered (if delivery is to the Buyer's address);",
        text21: '5.18.3. email address;',
        text22: '5.18.4. contact phone number;',
        text23: '5.18.5. payment method;',
        text24: '5.18.6. delivery method.',
        text25: 'If necessary, the Buyer may add a comment.',
        text26:
          '5.19. If the Seller requires additional information, they have the right to request it from the Buyer. In case of failure to provide the necessary information by the Buyer, the Seller is not responsible for providing a quality service to the Buyer when purchasing Goods in the online store.',
        text27:
          '5.20. The Seller has the right to refuse the Buyer in processing the order and its execution if the Buyer has not collected the Goods from the delivery point two or more times; if the Buyer refused to accept the Goods without justified reasons in the case of courier delivery; if the courier failed to deliver the shipment three times.',
      },
      deliveryGoods: {
        title: '6. DELIVERY AND TRANSFER OF GOODS',
        text1:
          "6.1. After receiving the Order by the Seller and confirmation of the Order by the Buyer, the Seller transfers the Goods to the postal operator or courier service for further shipment of the Goods to the address specified by the Buyer in the Order. In case the Buyer provides inaccurate information regarding the Buyer's address, the Seller has the right to withhold the shipment of the Goods until receiving accurate information about the Buyer's address. In this case, the delivery period to the Buyer of the Goods specified in clause 5.15. of this Agreement starts from the moment of receiving accurate information about the Buyer's address.",
        text2:
          '6.2. The Buyer, when placing the Order, has the right to choose one of several types of delivery offered by the Seller:',
        text3: '- Postal delivery;',
        text4: "- Courier delivery to the Buyer's address.",
        text5: 'The cost and terms of delivery are specified by the Seller.',
        text6:
          'In case it is impossible to make the delivery within the specified period in this Agreement, the Seller informs the Buyer about it.',
        text7:
          "6.3. In case the Goods were not delivered to the Buyer due to the Buyer's fault, the repeated delivery of such Goods, the cost of such delivery, and the terms are discussed and agreed upon with the Seller.",
        text8:
          "6.4. Upon receipt of the Goods, the Buyer is obliged to inspect the Goods, ensure that the received Goods correspond to the Buyer's order, check the Goods for proper quality and quantity, accept and pay for the Goods in accordance with this Agreement and/or other documents.",
        text9:
          "6.5. The date of receipt of the Goods by the Buyer is considered to be the date of delivery of the Goods to the Buyer or the date of receipt of the Goods by the Buyer at the branch of the postal delivery service or directly at the Seller's place of business.",
        text10:
          "6.6. Upon receipt of the Goods, the Buyer is obliged to inspect the Goods, ensure that the received Goods correspond to the Buyer's order, check the Goods for proper quality and quantity, accept and pay for the Goods in accordance with this Agreement and/or other documents.",
        text11:
          '6.7. The ownership right to the Goods passes from the Seller to the Buyer at the moment of actual receipt of the Goods by the Buyer provided full payment for the Goods by the Buyer. In case of sale of the Goods on installment terms, the ownership right to the Goods passes to the Buyer after payment of the full cost of the goods.',
        text12:
          '6.8. The Buyer bears the risk of accidental destruction/loss or damage to the Goods from the moment of receipt of the Goods.',
        text13:
          "6.9. The Seller ensures the timely preparation and dispatch of the Goods to the Buyer and the transfer of the Goods to the postal operator or courier service according to the delivery method chosen by the Buyer. The Seller is not responsible to the Buyer for the delay in the delivery of the Goods that occurred not due to the Seller's fault and/or due to unforeseen circumstances, and for the damage/spoilage/loss of the Goods caused by the fault of the postal operator or courier service during the delivery of the Goods to the Buyer.",
      },
      paymentProcedure: {
        title: '7. COST AND PAYMENT PROCEDURE',
        text1:
          "7.1. The price of each individual Product is determined by the Seller and indicated on the website of the GOT'S LABEL online store. The Order price is determined by adding the price of all included Products in the Order, which equals the total amount to be paid, indicated on the website of the online store when placing the Order at the time of its placement.",
        text2:
          '7.2. The Contract price is equal to the Order price. The Order amount may vary depending on the price, quantity, or assortment of the Product.',
        text3:
          '7.3. The price of the Product indicated on the website at the time of ordering includes all tax calculations and is the final price, mandatory for both the Buyer and the Seller. The Seller may unilaterally change the price of the Product until the order is placed.',
        text4:
          '7.4. The Buyer makes payment for the Product according to the Order. The Buyer independently chooses one of the following payment methods: - cash payment; - non-cash payment.',
        text5:
          '7.5. Payment for the Product by cash payment can be made at the retail store (Showroom) at the time of placing the Order or at the time of transfer of the Product.',
        text6: '7.6. Payment for the Product by non-cash settlement is made:',
        text7:
          "1) by bank transfer of money to the Seller's current account specified in the invoice, including through Internet banking.",
        text8:
          '2) by cash on delivery upon receipt of the Order at the representative office of the delivery service or postal operator.',
        text9:
          '3) by cash on delivery to the courier upon receipt of the Product by courier delivery.',
        text10:
          "4) through the online payment service of TOV 'Nova Poshta' 'Payment Control'. In case of payment through the specified service, the cost of the Product is increased by the commission of 'Nova Poshta'.",
        text11:
          '5) With the following type of credit card: Visa, Visa Electron, Mastercard, Mastercard Electronic, Maestro.',
        text12: '6) through the WayForPay payment system.',
        text13: '7) by any other method agreed with the Seller.',
        text14:
          '7.7. The Buyer makes payment in the national currency of Ukraine - hryvnia according to the Order amount. The payment of the Product cost and the cost of postal or courier delivery of the Product is made by the Buyer in full upon receipt of the Product.',
        text15:
          "7.8. The Order is considered paid from the moment of receipt of payment to the Seller's current account or his representative.",
        text16:
          "7.9. Information regarding the payment of the Product cost and postal or courier delivery of the Product is indicated on the Seller's website. If necessary, the Buyer has the right to receive information from the Seller about the cost of the Product and the cost of postal or courier delivery of the Product.",
        text17:
          "7.10. When ordering the Product outside the territory of Ukraine, the cost of delivery according to the carrier companies' tariffs is added to the Product cost by the Seller, in this case, the Buyer makes a 100% prepayment of the Product cost and delivery.",
        text18:
          '7.11. The Buyer understands and agrees that if the Products purchased in the online store are delivered outside Ukraine, the cost upon receiving the parcel may depend on the additional value-added tax in the recipient country. The amount of duty is determined by the competent authorities of the recipient country.',
        text19:
          "7.12. In accordance with Article 8 of the Law of Ukraine 'On Protection of Consumer Rights,' when selling the Product, the Seller is obliged to issue a payment document (receipt, invoice, etc.) of the established form with the indication of the date of sale to the buyer (client). It confirms the fact of purchase. The issuance of a payment document may be implemented with the consent of the buyer in the form of an electronic payment document (e-document) to the customer's number, through messengers, or to the email address provided by the customer. The payment document must be issued or created in electronic form no later than the moment of dispatch/transfer of the Product.",
      },
      returnsAndExchanges: {
        title: '8. RETURNS AND EXCHANGES OF GOODS',
        text01:
          '8.1. The Buyer has the right to return or exchange the Goods in the manner and within the timeframes established by this Agreement.',
        text1:
          '8.2. In case of receiving Goods of improper quality, the Buyer has the right to demand from the Seller the exchange of such Goods for Goods of proper quality. If the exchange of Goods of improper quality for Goods of proper quality is impossible, the Buyer has the right to demand the termination of the Agreement and the return of the paid value of the Goods.',
        text2:
          "8.2.1. In case of filing a claim by the Buyer regarding the substandard Goods, such a Claim is submitted by the Buyer in writing by sending to the postal address of the Seller or in electronic form to the Seller's email address gotslabel.cooperation@gmail.com.",
        text3: '8.2.2. In the claim, the Buyer is obliged to indicate:',
        text4: '- Surname, first name, patronymic;',
        text5: '- Contact phone number;',
        text6: '- Mailing address (current for correspondence);',
        text7: '- Email address (if available);',
        text8:
          '- Grounds for filing a claim, description of damages or signs of substandard goods;',
        text9: '- Content of the demand to the Seller (exchange, refund);',
        text10: '- Date of purchase of the goods;',
        text11:
          "When filing a Claim, the Buyer must attach a copy of the receipt or other document confirming the purchase of the Goods. To avoid any misunderstandings, after receiving the Claim, the Seller may request photo or video documentation, based on which the Seller will be able to consider the Buyer's claim demands.",
        text12:
          "8.2.3. The Seller considers the Buyer's claim within 30 calendar days from the date of receiving the claim. The Seller may refuse to satisfy the Buyer's claim if:",
        text13:
          '- The Buyer did not specify in the claim the information provided for in clause 7.2.2. of this Agreement;',
        text14:
          '- The Buyer did not provide a copy of the document confirming the purchase of the Goods;',
        text15:
          '- The Buyer, at the request of the Seller, did not provide photos and/or videos proving the presence of damaged or defective Goods;',
        text16:
          '- The damage or defect of the goods did not occur through the fault of the Seller;',
        text17:
          '- The damage or defect of the Goods occurred due to improper use of the Goods by the Buyer in accordance with the rules of the manufacturer or Seller, as well as damage caused by the fault (actions or inaction) of the Buyer;',
        text18:
          '- The damage or defect of the Goods occurred due to force majeure circumstances or extraordinary events.',
        text19:
          '8.2.4. After considering the claim, the Seller sends the Buyer a notification of the results of the claim consideration. The sending of such notification is carried out using the same technical means by which the claim was sent to the Seller.',
        text20:
          "8.2.5. In case of satisfaction of the claim, the Seller may exchange the Goods purchased by the Buyer for other Goods available from the Seller, and in case of impossibility of exchange, refund the value of the Goods to the Buyer. The return of the value of the Goods to the Buyer is carried out by non-cash transfer to the Buyer's bank account. In any case, the return of funds to the Buyer is made within 14 calendar days from the date of the Seller's notification to the Buyer of the results of the claim consideration.",
        text21:
          '8.3. The Buyer has the right to return Goods of proper quality within 14 (fourteen) days from the date of receiving Goods of proper quality. When returning the Goods, the Buyer is obliged to transfer to the Seller the receipt, packaging, and other elements of the Goods set, accessories, documents received by the Buyer during the acceptance of the goods (batches of goods). Only such goods that are undamaged (including accessories and documents), have a commercial appearance, and are suitable for sale or further use are subject to return by the Buyer.',
        text22:
          '8.4. The provisions of clause 7.3. do not apply in case the Buyer did not transfer to the Seller a settlement document with the purchase date, and did not transfer the Goods or transferred the Goods in incomplete configuration, or damaged Goods, if such damage was caused after receiving the Goods through the fault (actions, inaction) of the Buyer or in case the Goods were purchased by the Buyer not from the Seller.',
        text23:
          '8.5. When returning Goods of proper quality, the Buyer receives a refund of the value of the Goods without the cost of postal or courier delivery of the Goods.',
        text24:
          '8.6. The return of Goods of proper quality is accepted by the Seller only if the Buyer meets the following requirements:',
        text25:
          '8.6.1. The Goods, documentation (labels, tags) are not damaged and retain a commercial appearance, suitable for further use or sale.',
        text26:
          '8.6.2. Together with the Goods, a receipt (invoice) or any other settlement document confirming the purchase of the Goods is transferred.',
        text27:
          '8.6.3. When returning Goods of proper quality, the Buyer is obliged to attach to the Goods an Application of any form requesting a refund of the value of the Goods with mandatory indication of:',
        text28: "- Buyer's surname, first name, and patronymic;",
        text29: '- Mailing address;',
        text30: '- Contact phone number;',
        text31: '- Email address (if available);',
        text32: '- Date of receiving the Goods;',
        text33: '- Cost of the Goods;',
        text34: "- Bank details in case of refund by non-cash transfer to the Buyer's account.",
        text35:
          'When returning Goods of proper quality, the Buyer is obliged to attach to the Goods an Application of any form requesting a refund of the value of the Goods with mandatory indication of:',
        text36: "- Buyer's surname, first name, and patronymic;",
        text37: '- Mailing address;',
        text38: '- Contact phone number;',
        text39: '- Email address (if available);',
        text40: '- Date of receiving the Goods;',
        text41: '- Cost of the Goods;',
        text42: "- Bank details in case of refund by non-cash transfer to the Buyer's account.",
        text43:
          '8.7. In case of non-compliance by the Buyer with the requirements of clauses 7.6. of this Agreement, the Seller has the right to refuse the Buyer to return the value of the Goods of proper quality. The date of receipt by the Seller of the returned Goods is considered to be the date of actual receipt of the Goods by the Seller.',
        text44:
          '8.8. The method of returning the Goods to the Seller is determined by the Buyer independently. In case of returning damaged Goods, if such damage was caused by the fault of the Buyer, or during the shipment of the Goods by the Buyer, the Seller has the right to refuse the Buyer to return the value of the Goods.',
        text45:
          "8.9. The Buyer's demand for the return of Goods of proper quality can be fulfilled by the Buyer within 14 (fourteen) calendar days, starting from the day following the receipt of the Goods.",
        text46:
          '8.10. The Buyer has no right to demand the return of Goods of proper quality in the following cases:',
        text47:
          "8.10.1. If such Goods were manufactured to the Buyer's Order taking into account individual characteristics;",
        text48:
          '8.10.2. If such Goods belong to the category of Goods that are not subject to return in accordance with the current legislation of Ukraine. Non-food Goods of proper quality are not subject to return, the list of which is approved by the Resolution of the Cabinet of Ministers of Ukraine No. 172 dated 19.03.1994.',
        text49:
          '8.10.3. If the return concerns Goods delivered outside the territory of Ukraine and Poland (international delivery).',
        text50:
          '8.10.4. If the return concerns Goods that have been used for photo/video shooting. Goods involved in photo/video shooting are considered to have been used and are not subject to exchange or return (even if they retain their commercial appearance).',
        text51:
          '8.10.5. If the return concerns Goods purchased using gift certificates (Gift certificates themselves are also not subject to return).',
        text52:
          '8.11. In case of compliance by the Buyer with the provisions of clauses 7.3.-7.9. of this Agreement, the Seller refunds the value of the Goods of proper quality to the Buyer within 14 calendar days starting from the day following the receipt of the Goods by the Seller.',
        text53:
          '8.12. Warranty periods for Goods are established for a period of 30 calendar days from the date of sale or the beginning of the season. During this period, the Seller guarantees the proper quality of the Goods and their suitability for use.',
        text54:
          '8.13. Complaints (claims) about the shortcomings of the Goods can be submitted within 30 thirty days from the date of purchase of the Goods. A complaint can be filed only if the defect is detected within the warranty period and the defect indicates a discrepancy of the Goods with the terms of the contract (set out on this website). Differences in design elements or design from those stated in the description on the Website are not a sign of Goods of improper quality.',
        text55:
          '8.13. Goods that have been damaged due to the fault of the Buyer, Goods that have been used improperly or in violation of the conditions of use, are not subject to warranty repair.',
        text56:
          '8.14. In case of the need for quality expertise of the Goods, the party initiating the quality expertise of the Goods bears the costs associated with conducting such expertise, unless otherwise provided by the Law.',
      },
      responsibilityParties: {
        title: '9. LIABILITY OF PARTIES',
        text1:
          '9.1. The party responsible for the breach of the terms of this Agreement shall be liable for damages (direct and indirect) caused by such breach to the other Party in accordance with this Agreement and the Law.',
        text2:
          '9.2. The Seller shall not be liable for damages caused to the Buyer due to improper use of the Goods purchased from the Seller by the Buyer.',
        text3: '9.3. The Seller shall not be liable to the Buyer for damages caused by:',
        text4:
          '9.3.1. Improper completion of the Order form by the Buyer, including providing incorrect information (personal data) for the identification of the Buyer and the delivery address of the Goods;',
        text5: '9.3.2. Improper performance by the Buyer of the terms of this Agreement;',
        text6: '9.3.3. Unlawful actions of third parties;',
        text7: '9.3.4. In other cases provided for by this Agreement or the Law.',
        text8:
          '9.4. The Seller is responsible for the quality and completeness of the goods. In the event that the Buyer finds damage, defects, incompleteness, or other significant characteristics that prevent the use of the Goods for its intended purpose and/or are unsatisfactory, the Buyer has the right to demand, and the Seller has the right to satisfy one of the following demands of the Buyer:',
        text9: '1) Proportional reduction of the price;',
        text10: '2) Free removal of defects within a reasonable period;',
        text11: '3) Reimbursement of costs for defect removal;',
        text12: '4) Free replacement of the goods with a similar item.',
        text13:
          '9.4.1. The Seller is liable as provided in clause 7.2. of this Agreement if the Buyer proves that the damage, defects, or incompleteness of the Goods occurred before the Buyer received such goods.',
        text14:
          '9.5. The party that breaches its obligation is released from liability for the breach of the obligation if it proves that the breach occurred due to force majeure.',
        text15:
          '9.6. The party shall not be liable for the breach of this Agreement if such breach occurred without its fault (intentionally or negligently).',
        text16:
          '9.7. The party shall be deemed innocent and shall not be liable for the breach of this Agreement if it proves that it has taken all necessary actions within its power to properly perform this Agreement.',
        text17:
          '9.8. The Seller shall not be liable for the security, content, and content of Internet resources that the Buyer may visit by clicking on links (hyperlinks) placed on the website.',
        text18:
          '9.9. The Buyer is responsible for the accuracy of information and personal data provided by the Buyer in the Order and in interaction with the Seller.',
      },
      resolutionOfDisputes: {
        title: '10. RESOLUTION OF DISPUTES',
        text1:
          '10.1. All disputes and discrepancies arising in connection with the performance of this Agreement shall be resolved by negotiations between the Parties.',
        text2:
          '10.2. The Parties undertake to make every effort to resolve disputes and discrepancies through the procedure of claims settlement, pre-trial resolution of such disputes or discrepancies.',
        text3:
          '10.3. In case disputes and/or discrepancies cannot be resolved through negotiations, they shall be settled in court in accordance with the jurisdiction and competence of such dispute under the current legislation of Ukraine.',
      },
      forceMajeure: {
        title: '11. FORCE MAJEURE',
        text1:
          "11.1. The Parties are released from liability for full or partial non-performance of their obligations under this Agreement if it is caused by force majeure circumstances, such as: force majeure, fires, floods, earthquakes, war, blockade, civil unrest, occupation, embargo, conducting anti-terrorist operations (ATO), imposition of martial law throughout the territory of the state or its parts, blocking of transport routes or buildings, premises as a result of civil actions or riots or protests, export (import) bans, acts or laws issued by the state authorities of Ukraine and/or other bodies controlling the relevant territory where force majeure circumstances have arisen, as well as all other cases specified in Art. 14.1. of the Law of Ukraine 'On Chambers of Commerce and Industry in Ukraine'.",
        text2:
          '11.2. In case of force majeure circumstances, the term for the performance of obligations is postponed in accordance with the period during which such circumstances will be in effect. If these circumstances and their consequences last longer than 3 (three) months, each of the Parties shall have the right to refuse further performance of obligations under this Agreement. In this case, none of the Parties shall be entitled to compensation by the other Party for possible damages.',
        text3:
          '11.3. The Party for which the impossibility of performing obligations under the Agreement has arisen due to force majeure is obliged to notify (by fax, email) the other Party within 3 calendar days from the occurrence of such circumstances, and also within 45 (forty-five) calendar days from the day of occurrence of force majeure circumstances to send a notification in the established form (opinion or other confirming document) issued by a representative of the relevant Chamber of Commerce and Industry or another authorized body under the law, indicating the above events, the date of their occurrence and their duration.',
        text4:
          '11.4. If force majeure circumstances persist for more than 2 (two) consecutive calendar months, the Parties undertake to make a decision regarding further implementation of this Agreement.',
        text5:
          '11.5. In case of adverse weather conditions (fog, ice, snowdrifts, etc.), under which, according to the terms of carriage of dangerous goods, the movement of vehicles with dangerous goods is prohibited, the term for the performance of obligations under this Agreement is extended for the duration of these circumstances. The Parties promptly inform each other about the beginning and end of these circumstances. In order to confirm these circumstances, the opinion of the Chamber of Commerce and Industry of Ukraine or another competent authority is not required.',
      },
      periodAgreement: {
        title: '12. CONCLUSION OF THE AGREEMENT AND TERM OF THE AGREEMENT',
        text1:
          "12.1. This Agreement is deemed concluded by the Parties and enters into legal force from the moment the Buyer joins the terms of this Agreement by clicking the 'Place Order' button on the Seller's website, by confirming the Order by the Buyer in case of ordering by phone or by any other means provided for by the current legislation of Ukraine.",
        text2:
          '12.2. This Agreement shall remain in effect until the Parties have fully performed their obligations under this Agreement.',
        text3:
          "12.3. The Buyer confirms that until the conclusion of this Agreement, the Seller has provided the Buyer with all necessary information about the Product, as required by the norms of the Law of Ukraine 'On Protection of Consumer Rights'.",
        text4:
          '12.4. In case of concluding a contract for periodic delivery of the Product, the minimum term of the Agreement is 6 (six) months.',
        text5:
          "12.5. The Seller has the right to change the terms of this Agreement, notifying the Buyer in advance 5 (five) days before the new version of the Agreement comes into force by sending the new version of the Agreement (with amendments and additions) to the Buyer's email address. If before the new version of the Agreement comes into force the Buyer does not send the Seller an objection or a request to terminate this Agreement, such Agreement in the new version shall be considered fully accepted and agreed by the Buyer.",
        text6:
          '12.6. This Agreement may be changed by the Seller unilaterally for all its clients, Buyers, simultaneously publishing a new version on the official website of the Seller, as well as informing all clients, Buyers by email. In case of disagreement of clients, Buyers with the changes introduced, a separate written agreement will be concluded, in which disputed issues will be settled.',
      },
      otherTherms: {
        title: '13. OTHER TERMS',
        text1:
          "13.1. The Buyer's accession to this Agreement is made in accordance with the acceptance (consent) of the Buyer to purchase the Product from the Seller under the terms and conditions specified in this Agreement by clicking the 'Place Order' button on the Seller's website.",
        text2:
          '13.2. Accession to the terms of this Agreement is made by the free will and acceptance of the terms of this Agreement by the Buyer.',
        text3:
          '13.3. The Buyer confirms that at the time of entering into this Agreement, he/she has sufficient legal capacity and has reached the appropriate age, enabling him/her to purchase the Product from the Seller.',
        text4:
          "13.4. The Buyer accepts and agrees to the terms of making purchases on the Seller's website, undertakes not to violate the rules, in no way offend visitors to the website or employees or authorized persons of the Seller, including not to use profanity, aggressive behavior, insults, humiliation of the dignity, honor, and business reputation of the Seller or other persons.",
        text5:
          '13.5. The terms of this Agreement apply to an unlimited circle of persons. The Seller has no right to refuse the Buyer to conclude this Agreement, except in cases provided by law.',
        text6:
          '13.6. The Buyer has no right to demand that the Seller change the terms of this Agreement or acquire additional rights and obligations compared to other Buyers.',
        text7:
          '13.7. This Agreement may be terminated by the Seller unilaterally by written notice to the Buyer no earlier than 30 calendar days before the expected date of termination of this Agreement.',
        text8:
          '13.8. If the Buyer does not make payment for the Product under the terms of this Agreement, the Agreement is considered void.',
        text9:
          "13.9. The text of this Agreement is written in Ukrainian and is available on the Seller's website at http://www.gotslabel.com.",
        text10:
          '13.10. The entity receiving claims from Buyers regarding the quality, completeness, quantity, and assortment of the Product is the Seller.',
        text11:
          '13.11. Simultaneously with the conclusion of this Agreement, the Buyer consents to the collection and processing of his/her personal data by the Seller and agrees to other (local) documents of the Seller.',
        text12:
          '13.12. In any case, the invalidity of one provision of this Agreement does not imply the invalidity of the entire Agreement.',
        text13:
          '13.13. By entering into this Agreement, the Buyer agrees to transfer the rights and obligations under this Agreement to third parties by the Seller.',
        text14:
          '13.14. The website of the online store contains materials, trademarks, trade names, and other materials protected by law, including, but not limited to, trademarks, texts, photographs, graphics.',
        text15:
          '13.15. All objects available in the online store https://www.gotslabel.com, including design elements, text, graphics, illustrations, videos, photos, computer software, databases, music, sounds, and other objects (hereinafter referred to as the content of the online store), as well as any content posted on the website https://www.gotslabel.com, are objects of exclusive rights of the Owner of the online store and other right holders.',
        text16:
          '13.16. The use of content and other elements of online stores is possible only within the functionality offered by the online store. No elements of the content of the online store can be used in any other way without the prior consent/agreement of the copyright owner. Use includes, among other things: reproduction, copying, modification, distribution on any basis, display in a lottery, etc. Exceptions are cases directly provided for by Ukrainian law. The use by the User of elements of the content of the online store and any content for personal non-commercial purposes is permitted provided that all copyright notices, related rights, trademarks, other copyright notices, the name (or pseudonym) of the author/rights holder are preserved unchanged, the appropriate object is preserved unchanged. Exceptions are cases directly provided for by Ukrainian law.',
      },
      personalInformation: {
        title: '14. CONTACT INFORMATION OF THE SELLER',
        text1:
          "14.1. Seller's name, contact details: Individual entrepreneur Hotochkina Svitlana Hennadiyivna, EDRPOU number: 2005220000000088241 dated March 29, 2021, registration address: 54037, Mykolaiv, Kotsiubynskoho Street, building 20, tax number: 3192020323.",
        text2:
          'Contact details: phone +38 099 039 99 99, cooperation phone: +380660292069 (WhatsApp, Telegram), Email: gotslabel.cooperation@gmail.com,',
        text3: 'mailing address: gotslabel.cooperation@gmail.com',
        text4: 'SHOWROOM KYIV: Lyuteranska Street 11-A',
        text5: 'Mon-Sun: 11:00-19:30',
        text6: 'SHOWROOM WARSAW: Wilcza 44',
        text7: 'Mon-Sat: 11:00-19:00',
        text8: 'Instagram Ukraine: gots.label',
        text9: 'Instagram Warsaw: gotslabel.warsaw',
        text10:
          '14.2. The User/Buyer may contact the Seller managing the online store by the following means:',
        text11:
          '▪ By the actual address of the online store GOTS LABEL: Ukraine, 01133, Kyiv, Lyuteranska Street 11-A.',
        text12:
          "▪ Call the hotline for landline and mobile phones: +38 099 039 99 99 (Connection fee according to the operator's tariffs).",
        text13: '▪ By email to: email gotslabel.cooperation@gmail.com.',
        text14: '▪ Through the contact form on the website of the online store.',
      },
    },
    pl: {
      publicOffer: {
        title: 'OFERTA PUBLICZNA',
        text1:
          'Niniejsza Umowa oferty publicznej (dalej: „Umowa”), zgodnie z Kodeksem Cywilnym Ukrainy, stanowi oficjalną propozycję (ofertę publiczną) nieograniczonej liczbie osób (dalej: „Kupujący”) od Osoby Fizycznej Prowadzącej Działalność Gospodarczą Svitlana Hotschkina (dalej: „Sprzedawca”), i zawiera wszystkie istotne warunki niezbędne do dokonania transakcji kupna-sprzedaży produktu Sprzedawcy na warunkach przystąpienia. Umowa ta została zawarta zgodnie z obowiązującymi przepisami prawa Ukrainy, w szczególności na podstawie artykułów 633, 634, 641, 642 Kodeksu cywilnego Ukrainy, a także warunków i zasad ustalonych i obowiązujących u Sprzedawcy.',
        text2:
          'Tekst niniejszej Umowy jest stale dostępny dla Kupujących w Internecie na oficjalnej stronie internetowej Sprzedawcy http://www.gotslabel.com oraz pod adresami prowadzenia działalności gospodarczej Sprzedawcy. Informacje umieszczone na stronie internetowej Sprzedawcy, w tym informacje dotyczące oferowanych przez Sprzedawcę produktów, same w sobie nie stanowią oferty publicznej i zawierają dane służące wyłącznie zapoznaniu się Kupującego z:',
        text3: '- działalnością Sprzedawcy;',
        text4:
          '- listą, charakterystykami i cenami produktów, które mogą być sprzedawane przez Sprzedawcę Kupującemu pod warunkiem dostępności odpowiednich produktów na magazynie Sprzedawcy w chwili składania przez Kupującego zamówienia;',
        text5:
          '- warunkami, na jakich Sprzedawca jest gotowy nawiązać z Kupującym relacje kupna-sprzedaży produktu, z uwzględnieniem niniejszej Umowy i bezwzględnego pierwszeństwa warunków Umowy przed innymi warunkami, które mogą być umieszczone na stronie internetowej Sprzedawcy bez względu na kontekst ich przeczytania i interpretacji.',
      },
      conceptsTerms: {
        title: '1. POJĘCIA I DEFINICJE',
        text1:
          '1.1. W niniejszej Umowie używane są następujące terminy i pojęcia w następującym znaczeniu:',
        text2:
          '1.1.1. Oferta publiczna (propozycja) – propozycja Sprzedawcy (umieszczona na jego oficjalnej Stronie internetowej), skierowana do nieokreślonego kręgu osób fizycznych, zawarcia Umowy na określonych warunkach.',
        text3:
          '1.1.2. Akceptacja - pełne i bezwarunkowe przyjęcie przez Kupującego warunków Oferty publicznej, przedstawionych w niniejszej Umowie, poprzez wyrażenie przez Kupującego zgody na złożenie Zamówienia, przystąpienie do Umowy i zapłatę pełnej wartości Towaru. Poprzez kliknięcie przycisku: „ZŁÓŻ ZAMÓWIENIE” Kupujący zgadza się na warunki niniejszej Umowy i akceptuje ofertę Sprzedawcy dotyczącą zakupu u Sprzedawcy Towaru na warunkach niniejszej Umowy. Akceptacja przez Kupującego oferty Sprzedawcy dotyczącej zakupu Towaru jest potwierdzeniem wyrażenia przez Kupującego bezwarunkowej woli nabycia u Sprzedawcy Towaru zgodnie z Zamówieniem na poniższych warunkach. Działanie niniejszej Umowy dotyczącej warunków zamówienia Towaru obejmuje tylko i wyłącznie te relacje między Kupującym i Sprzedawcą, które są związane z zamówieniem i sprzedażą tych towarów, których lista została określona w formularzu zamówienia przez Kupującego.',
        text4:
          '1.1.3. Umowa – niniejsza Umowa, która jest umieszczona na stronie internetowej Sprzedawcy i reguluje wzajemne stosunki między Sprzedawcą a Kupującym, zgodnie z którymi Osoba fizyczna prowadząca działalność gospodarczą Svitlana Hotschkina, jako Sprzedawca, za pośrednictwem sklepu internetowego „GOT`S LABEL” na oficjalnej stronie internetowej Sprzedawcy http://www.gotslabel.com zobowiązuje się sprzedawać, o ile jest dostępny, Towar każdemu Kupującemu, który się do niego zwróci, i ustala jednolite warunki sprzedaży tych Towarów dla wszystkich Kupujących, a Kupujący zobowiązuje się w terminie i w pełnej wysokości płacić za nie ich wartość. Umowa jest publicznie ogłaszana wszystkim Kupującym poprzez jej umieszczenie (publikację) na oficjalnej stronie internetowej Sprzedawcy.',
        text5:
          '1.1.4. Formularz zamówienia – jest to pisemny wzór dokumentu, który Kupujący wypełnia, aby przekazać Sprzedającemu obowiązkowe informacje o sobie i poprzez wypełnienie potwierdza:',
        text6:
          '-   fakt przyjęcia oferty publicznej (propozycji) w celu przystąpienia do niniejszej Umowy;',
        text7: '-   fakt zapoznania się z jej treścią;',
        text8: '-   zgoda na jej warunki.',
        text9:
          'W formularzu zamówienia Kupujący podaje swoje nazwisko, imię, nazwisko, adres, na który ma być wysłany towar, numer telefonu kontaktowego oraz w razie potrzeby dodatkowe informacje.',
        text10:
          '1.1.5. Kupujący – pełnoletnia osoba fizyczna, która zawarła Umowę kupna-sprzedaży z Sprzedawcą.',
        text11:
          '1.1.6. Sprzedawca – Osoba fizyczna prowadząca działalność gospodarczą Svitlana Hotschkina, Numer identyfikacji podatkowej 3192020323, adres rejestracji: 54037, Miasto Mykolaiv, ul. Kotsiubynskoho 20.',
        text12:
          '1.1.7. Oficjalna Strona internetowa Sprzedawcy – oficjalna strona internetowa Sprzedawcy w Internecie, znajdująca się pod adresem: http://www.gotslabel.com (stałe dostępna do wglądu), i jest źródłem informacji dla Kupujących.',
        text13: '1.1.8. Strony – łącznie Sprzedawca i Kupujący.',
        text14:
          '1.1.9. Towar (partia towaru) – przedmiot umowy kupna-sprzedaży zgodnie z niniejszą Umową, określony w Zamówieniu, nie wycofany z obrotu, z informacjami o jego cechach, z którymi Kupujący może zapoznać się na Stronie internetowej, lista asortymentu przedstawiona w sklepie internetowym, w której podane są cena, nazwa i opis towaru i który jest dostępny u Sprzedawcy, a Sprzedawca jest gotów przekazać go w posiadanie Kupującemu w sposób i na warunkach określonych niniejszą Umową.',
        text15:
          '1.1.10. Odbiorca – Kupujący lub osoba, która faktycznie odbiera Towar dostarczony przez Sprzedawcę własnymi siłami lub Firmą kurierską.',
        text16:
          '1.1.11. Istotna wada Towaru – wada, która uniemożliwia lub uniemożliwia zastosowanie Towaru zgodnie z jego przeznaczeniem, powstała z winy producenta (Sprzedawcy), po jej usunięciu ponownie pojawia się z niezależnych od konsumenta przyczyn i jest wyposażona w co najmniej jeden z poniższych znaków: a) nie można jej w ogóle usunąć; b) jej usunięcie wymaga więcej niż czternastu dni kalendarzowych; c) czyni ona Towar znacznie innym niż przewidziano w umowie.',
        text17:
          '1.1.12. Dostawa kurierska – bezpośrednie przekazanie Towaru przez pracownika firmy kurierskiej lub służby kurierskiej lub spedycyjnej Kupującemu w miejscu wskazanym przez Kupującego jako adres dostawy, na odpłatnych lub nieodpłatnych warunkach.',
        text18:
          '1.1.13. Usługa dostawy/Firma przewozowa – osoby trzecie, które świadczą usługi dostawy Zamówień Kupującemu.',
        text19:
          '1.1.14. W Umowie mogą być używane terminy, które nie są zdefiniowane w rozdziale 1 Umowy. W takim przypadku interpretacja terminu odbywa się zgodnie z tekstem Umowy. W przypadku braku jednoznacznego określenia terminu w tekście Umowy należy posługiwać się interpretacją terminu, która jest określona: po pierwsze - przez prawo Ukrainy, po drugie - na stronie internetowej, następnie ogólnie przyjętą interpretacją, która powstała.',
      },
      subjectOfAgreement: {
        title: '2. PRZEDMIOT UMOWY',
        text1:
          '2.1. Zgodnie z warunkami określonymi w niniejszej Umowie Sprzedawca zobowiązuje się przekazać w posiadanie Kupującemu Towar, a Kupujący zobowiązuje się przyjąć Towar i zapłacić jego wartość.',
        text2:
          '2.2. Kupujący potwierdza, że w celu dokonania zakupu Towaru na podstawie niniejszej Umowy posiada wystarczający poziom zdolności prawnej.',
        text3:
          '2.3. W celu uniknięcia sporów składanie Zamówienia na Towar implikuje przystąpienie Kupującego do warunków niniejszej Umowy. Kupujący ma możliwość zapoznania się z warunkami niniejszej Umowy oraz z innymi (lokalnymi) dokumentami Sprzedawcy w odpowiednim dziale znajdującym się na stronie internetowej Sprzedawcy. Tekst niniejszej Umowy jest zamieszczony na stronie internetowej Sprzedawcy. Kupujący ma prawo i możliwość techniczną wydrukowania niniejszej Umowy na nośniku materialnym ze strony internetowej Sprzedawcy.',
        text4:
          '2.4. Razem z Towarem Sprzedawca przekazuje Kupującemu należności do Towaru oraz niezbędne dokumenty, warunki i sposób przekazania których są ustalane niniejszą Umową oraz przepisami prawa Ukrainy.',
        text5: '2.5. Nierozerwalną częścią niniejszej Umowy są:',
        text6: '-   Formularz zamówienia;',
        text7: '-   Zgoda na zbieranie, przetwarzanie i wykorzystanie Danych Osobowych;',
        text8:
          '2.6. W przypadku niezgodności informacji zamieszczonych na stronie internetowej Sprzedawcy z postanowieniami niniejszej Umowy, dla rozstrzygnięcia stosunków między Sprzedawcą a Kupującym powstałych podczas składania Zamówienia z późniejszym zakupem towaru zgodnie z Zamówieniem, tekst niniejszej Umowy ma bezsporne pierwszeństwo, a jej postanowienia uznaje się za warunki Umowy.',
        text9:
          '2.7. Momentem wyrażenia przez Kupującego woli zawarcia stosunków umownych z Sprzedawcą na warunkach niniejszej Umowy uważa się moment naciśnięcia przez Kupującego przycisku „Złóż zamówienie” podczas korzystania ze strony internetowej Sprzedawcy.',
        text10:
          '2.8. Wchodząc w niniejszą Umowę, Kupujący potwierdza, że zapoznał się z informacjami o Towarze sprzedawanym na warunkach niniejszej Umowy, a mianowicie dotyczącymi jego formy, wyglądu, projektu, koloru, rozmiaru, jakości, z charakterystykami materiału, z którego Towar jest wykonany, a także z informacjami o jego producencie, rozumie takie cechy Towaru i ich znaczenie, oraz zgadza się na zakup takiego Towaru z takimi cechami.',
        text11:
          '2.9. Kupujący zobowiązany jest samodzielnie zapoznać się z warunkami niniejszej Umowy, a Sprzedawca nie jest zobowiązany dodatkowo ani w jakikolwiek inny sposób informować Kupującego o istnieniu Umowy, oprócz jej publikacji na stronie: http://www.gotslabel.com',
      },
      ruls: {
        title: '3. PRAWA I OBOWIĄZKI STRON',
        text1: '3.1. Kupujący ma prawo:',
        text2: '3.1.1. Otrzymywać Towar na warunkach i w sposób określony w niniejszej Umowie;',
        text3:
          '3.1.2. Otrzymywać informacje o gotowości Sprzedawcy do wykonania Zamówienia i przekazania Towaru Kupującemu.',
        text4:
          '3.1.3. Zwrotu towaru należytej jakości w ciągu 14 (czternastu) dni kalendarzowych, nie licząc dnia zakupu.',
        text5:
          '3.1.4. Otrzymywać dodatkowe informacje o Towarze, jego dostawie i innych Usługach Sprzedawcy.',
        text6: '3.2. Kupujący zobowiązuje się:',
        text7:
          '3.2.1. W pełni zapłacić cenę Towaru i jego dostawy na podstawie niniejszej Umowy, zgodnie z zamówieniem Kupującego.',
        text8:
          '3.2.2. Nie przekazywać Zamówienia innym osobom, z wyjątkiem przypadków określonych w niniejszej Umowie i przepisach prawa.',
        text9:
          '3.2.3. Przestrzegać wszystkich warunków niniejszej Umowy oraz innych wewnętrznych (lokalnych) dokumentów Sprzedawcy, które regulują procedurę i warunki zakupu towaru;',
        text10:
          '3.2.5. Przestrzegać zasad moralności społecznej, porządku publicznego, norm etycznych podczas odwiedzania strony internetowej Sprzedawcy i komunikowania się ze Sprzedawcą lub osobą upoważnioną przez Sprzedawcę.',
        text11:
          '3.2.6. Przed akceptacją warunków niniejszej Umowy zapoznać się z informacjami zamieszczonymi na stronie internetowej Sprzedawcy, w tym z informacjami o produkcie, jego właściwościach, cenie oraz procedurze zakupu produktu.',
        text12:
          '3.2.7. Przekazać Sprzedawcy aktualne i prawdziwe informacje w formularzu zamówienia, które mogą zidentyfikować kupującego oraz adres dostawy towaru (dane osobowe) i inne informacje niezbędne dla Sprzedawcy do realizacji warunków niniejszej Umowy.',
        text13: '3.3. Sprzedawca ma prawo:',
        text14:
          '3.3.1. Wymagać od Kupującego zapłaty za Towary i/lub koszt usług pocztowych lub kurierskich dostawy lub dodatkowych usług zgodnie z warunkami niniejszej Umowy;',
        text15:
          '3.3.2. Wymagać od Kupującego przestrzegania wszystkich warunków niniejszej Umowy, Regulaminu i innych wewnętrznych (lokalnych) dokumentów Sprzedawcy.',
        text16:
          '3.3.3. Ustanawiać dodatkowe zasady zakupów, rabaty, promocje i informować o nich Kupującego.',
        text17:
          '3.3.4. Gromadzić, przetwarzać i wykorzystywać dane osobowe Kupującego i inne poufne informacje w celu realizacji warunków niniejszej Umowy zgodnie z niniejszą Umową i jej załącznikami oraz zgodą na gromadzenie i przetwarzanie danych osobowych.',
        text18:
          '3.3.5. W celu realizacji warunków niniejszej Umowy przekazywać prawa Sprzedawcy innym osobom.',
        text19: '3.3.6. Inne prawa przewidziane w niniejszej Umowie.',
        text20: '3.4. Sprzedawca zobowiązuje się:',
        text21:
          '3.4.1. Terminowo przekazać Kupującemu zamówiony Towar i świadczyć inne (dodatkowe) usługi na życzenie Kupującego zgodnie z warunkami niniejszej Umowy.',
        text22: '3.4.2. Zapewnić właściwą jakość dostarczanego Kupującemu Towaru.',
      },
      registration: {
        title: '4. REJESTRACJA NA STRONIE',
        text1:
          "4.1. Rejestrując się na stronie internetowego sklepu GOT'S LABEL, Kupujący gwarantuje, że jest pełnoletni i posiada odpowiednią zdolność do czynności prawnych umożliwiającą korzystanie ze strony internetowego sklepu.",
        text2:
          '4.2. Każda osoba niepełnoletnia może korzystać ze sklepu internetowego wyłącznie za zgodą rodziców lub opiekunów.',
        text3: '4.3. Rejestracja na stronie odbywa się pod adresem: http://www.gotslabel.com',
        text4: '4.4. Rejestracja na stronie nie jest obowiązkowa do składania zamówienia.',
        text5:
          '4.5. Rejestrując się na stronie internetowego sklepu i składając zamówienia za pośrednictwem sklepu internetowego, Kupujący potwierdza pełną i bezwarunkową zgodę na wszystkie postanowienia i zasady znajdujące się na tej stronie.',
        text6:
          '4.6. Każdy, kto rejestruje się i dokonuje zakupów na stronie w sklepie internetowym, udziela swoich danych osobowych i zgadza się na ich przetwarzanie na warunkach określonych w Polityce prywatności.',
        text7:
          '4.7. Podczas rejestracji na stronie w sklepie internetowym - tworzenia konta, Kupujący akceptuje warunki Polityki prywatności, Umowy o świadczenie usług drogą elektroniczną poprzez akceptację jednego z tych sposobów:',
        text8:
          '1) Poprzez dodanie Produktu do koszyka wirtualnego i kliknięcie na stronie sklepu internetowego na przycisk „Złóż zamówienie”.',
        text9: '2) Poprzez kliknięcie przycisku „Złóż zamówienie” lub „Kup w 1 kliknięciu”.',
        text10: '3) Poprzez kliknięcie przycisku „Utwórz konto” lub „Zarejestruj się”.',
        text11:
          '4) Poprzez zamówienie Produktu za pośrednictwem mediów społecznościowych lub komunikatorów.',
        text12:
          '5) Poprzez dokonanie płatności za zamówienie w całości lub częściowo na warunkach niniejszej Umowy.',
        text13:
          '4.8. Sprzedawca nie ponosi odpowiedzialności za dokładność i poprawność informacji podanych przez Kupującego podczas rejestracji.',
        text14:
          '4.9. Kupujący zobowiązuje się nie przekazywać osobom trzecim loginu i hasła podanego przez niego podczas rejestracji. W przypadku podejrzenia przez Kupującego naruszenia bezpieczeństwa jego loginu i hasła lub możliwości ich nieautoryzowanego wykorzystania przez osoby trzecie, Kupujący zobowiązuje się niezwłocznie powiadomić o tym Sprzedawcę, wysyłając odpowiedni e-mail na adres: gotslabel.cooperation@gmail.com',
        text15:
          '4.10. Komunikacja Kupującego z przedstawicielami Sprzedawcy powinna odbywać się zgodnie z zasadami ogólnie przyjętej moralności i etyki komunikacyjnej. Surowo zabrania się używania wulgaryzmów, obraźliwych słów, groźb i szantażu, bez względu na to, w jakiej formie i do kogo były one skierowane.',
      },
      orderingGoods: {
        title: '5. PROCEDURA SKŁADANIA ZAMÓWIENIA NA TOWARY',
        text1:
          '5.1. Zgodnie z warunkami niniejszej Umowy Kupujący dokonuje zakupu Towaru poprzez wypełnienie formularza Zamówienia na stronie internetowej Sprzedawcy. Forma formularza Zamówienia oraz jego dane określane są przez Sprzedawcę.',
        text2:
          '5.2. Strona internetowa sklepu internetowego zawiera informacje o cechach Towarów, cenach, materiałach, z których są wykonane oraz przybliżonej dostępności tych Towarów u Sprzedawcy.',
        text3:
          '5.3. W przypadku anulowania całkowicie lub częściowo przedpłaconego Zamówienia, koszt anulowanego Towaru zostaje zwrócony Kupującemu przez Sprzedawcę w sposób, którym Towar został opłacony.',
        text4:
          '5.4. Podczas składania Zamówienia Kupujący wybiera Towar z oferowanego przez Sprzedawcę asortymentu, wskazując m.in. rozmiar, kolor, w razie potrzeby podając inne konkretne informacje oraz podając ilość zamówionych Towarów.',
        text5:
          '5.5. W każdym momencie procesu składania zamówienia do momentu jego złożenia Kupujący może anulować zamówienie, przerywając proces zamówienia i opuszczając stronę zamówienia. Zamówienie, którego Kupujący nie zakończył, nie będzie akceptowane przez Kupującego do wykonania.',
        text6:
          '5.6. Wypełnienie Formularza Zamówienia wymaga wprowadzenia przez Kupującego wszystkich niezbędnych informacji do identyfikacji Kupującego, adresu dostawy Towaru, kontaktowego numeru telefonu Kupującego lub innych środków komunikacji, a także innych informacji wymaganych do wypełnienia przez Kupującego. przez Sprzedawcę w formularzu Zamówienia i są obowiązkowe do wypełnienia przez Kupującego.',
        text7:
          '5.7. Kupujący ponosi pełną odpowiedzialność za podanie nieprawdziwych informacji, co uniemożliwia należyte wykonanie przez Sprzedawcę jego zobowiązań wobec Kupującego.',
        text8:
          '5.8. Kupujący samodzielnie wybiera ilość i asortyment Towaru, który jest wymieniony na stronie internetowej Sprzedawcy i składa Zamówienie w „koszyku Zamówienia” lub za pośrednictwem „Konta osobistego”.',
        text9:
          '5.9. Po wypełnieniu formularza Zamówienia przez naciśnięcie przycisku „Złóż zamówienie” na stronie internetowej Sprzedawcy Kupujący akceptuje warunki niniejszej Umowy oraz innych dokumentów Sprzedawcy i uważany jest za złożył zamówienie na Towar.',
        text10:
          '5.10. Kupujący ma prawo samodzielnie wybierać sposoby odbioru (dostawy) Towaru spośród tych metod, które są proponowane przez Sprzedawcę dla każdego Towaru osobno na oficjalnej stronie internetowej Sprzedawcy.',
        text11:
          '5.11. W celu potwierdzenia Zamówienia Sprzedawca lub osoba upoważniona przez Sprzedawcę ma prawo zadzwonić do Kupującego i potwierdzić Zamówienie Kupującego lub uzyskać informację o anulowaniu takiego Zamówienia. Potwierdzenie Zamówienia może być wysyłane przez Sprzedawcę na adres e-mail Kupującego, za pomocą wiadomości SMS lub komunikatów w komunikatorach. W przypadku niemożności otrzymania potwierdzenia przez Kupującego w ciągu jednego dnia roboczego, następnego dnia roboczego po dniu złożenia Zamówienia, Sprzedawca wysyła Towar Kupującemu pod wskazany przez Kupującego adres i uważany jest za spełnił warunki niniejszej Umowy.',
        text12:
          '5.12. W przypadku braku Towaru przez Sprzedawcę w momencie składania przez Kupującego zamówienia w ilości i asortymencie zgodnie z Zamówieniem Kupującego, Sprzedawca lub osoba upoważniona przez Sprzedawcę informuje o tym Kupującego, wysyłając list na adres e-mail, dzwoniąc do Kupującego pod wskazany numer telefonu lub w inny dostępny sposób. W takim przypadku Sprzedawca ma prawo zaproponować Kupującemu inny towar lub poczekać na pojawienie się wymaganego towaru. W każdym przypadku strony uzgodniły, że informowanie Kupującego o zamówieniu, potwierdzenie zamówienia oraz inne oferty Sprzedawcy nie będą uznawane za agresywną formę praktyki handlowej.',
        text13:
          '5.13. W przypadku niemożności dostawy zamówionego przez Kupującego Towaru Sprzedawca ma prawo wykluczyć wskazany Towar z Zamówienia/anulować Zamówienie Kupującego, informując o tym Kupującego poprzez wysłanie odpowiedniej wiadomości e-mail na adres wskazany przez Kupującego podczas rejestracji lub innym sposobem.',
        text14:
          '5.14. Kupujący ma prawo zmienić Zamówienie lub odmówić od niego, informując Sprzedawcę pisemnie lub za pomocą środków komunikacji na odległość o konieczności zmiany Zamówienia przed momentem przekazania Zamówienia operatorowi pocztowemu lub kurierowi. Po przekazaniu Towaru do oddziału operatora pocztowego lub firmy kurierskiej Sprzedawca uważa się za spełnił warunki niniejszej Umowy dotyczące wysyłki Towaru Kupującemu.',
        text15:
          '5.15. Po otrzymaniu i potwierdzeniu Zamówienia Kupującego Sprzedawca w ciągu 2 (dwóch) dni roboczych wysyła Towar Kupującemu lub informuje Kupującego o niemożności zrealizowania Zamówienia z powodu braku takiego Towaru u Sprzedawcy lub z innych przyczyn przewidzianych w niniejszej Umowie i obowiązującym prawem Ukrainy.',
        text16:
          '5.16. Termin formowania Zamówienia wynosi dwa dni robocze od momentu jego złożenia. W przypadku wysłania Zamówienia w dzień wolny od pracy lub święto, termin formowania zaczyna się od pierwszego dnia roboczego po dniu wolnym od pracy.',
        text17:
          '5.17. W przypadku pytań Kupującego dotyczących właściwości i cech Towarów, przed złożeniem Zamówienia Kupujący może skontaktować się ze Sprzedawcą dowolnym dostępnym sposobem.',
        text18:
          '5.18. Przy składaniu Zamówienia na stronie internetowej sklepu internetowego GOT`S LABEL Kupujący zobowiązuje się podać następujące informacje:',
        text19: '5.18.1. nazwisko i imię Kupującego lub wskazanej przez niego osoby (Odbiorcy);',
        text20:
          '5.18.2. adres, pod którym należy dostarczyć Towar (jeśli dostawa jest na adres Kupującego);',
        text21: '5.18.3. adres e-mail;',
        text22: '5.18.4. numer telefonu kontaktowego;',
        text23: '5.18.5. sposób płatności;',
        text24: '5.18.6. sposób dostawy.',
        text25: 'Jeśli to konieczne, Kupujący może dodać komentarz.',
        text26:
          '5.19. Jeśli Sprzedawca potrzebuje dodatkowych informacji, ma prawo poprosić o nie Kupującego. W przypadku braku wymaganych informacji przez Kupującego, Sprzedawca nie ponosi odpowiedzialności za świadczenie jakościowej usługi Kupującemu przy zakupie Towarów w sklepie internetowym.',
        text27:
          '5.20. Sprzedawca ma prawo odmówić Kupującemu w realizacji zamówienia i jego składaniu, jeśli Kupujący dwukrotnie lub częściej nie odebrał Towaru z punktu dostawy; jeśli Kupujący odmówił przyjęcia Towaru bez uzasadnionej podstawy w przypadku dostawy kurierskiej; jeśli kurier nie mógł dostarczyć przesyłki przez trzy razy.',
      },
      deliveryGoods: {
        title: '6. DOSTAWA I PRZEKAZANIE TOWARU',
        text1:
          '6.1. Po otrzymaniu Zamówienia przez Sprzedawcę i potwierdzeniu Zamówienia przez Kupującego, Sprzedawca przekazuje Towar operatorowi pocztowemu lub firmie kurierskiej w celu dalszej wysyłki Towaru pod adres wskazany przez Kupującego w Zamówieniu. W przypadku podania przez Kupującego nieprawdziwych informacji dotyczących adresu Kupującego, Sprzedawca ma prawo wstrzymać wysyłkę Towaru do momentu otrzymania dokładnych informacji o adresie Kupującego. W takim przypadku okres dostawy do Kupującego Towaru określony w punkcie 5.15. niniejszej Umowy rozpoczyna się od momentu otrzymania dokładnych informacji o adresie Kupującego.',
        text2:
          '6.2. Kupujący podczas składania Zamówienia ma prawo wybrać jedną z kilku dostępnych opcji dostawy proponowanych przez Sprzedawcę:',
        text3: '- Dostawa pocztowa;',
        text4: '- Dostawa kurierska pod adres Kupującego.',
        text5: 'Koszt i terminy dostawy określa Sprzedawca.',
        text6:
          'W przypadku niemożności dokonania dostawy w określonym w niniejszej Umowie terminie, Sprzedawca informuje o tym Kupującego.',
        text7:
          '6.3. W przypadku gdy Towar nie został dostarczony do Kupującego z winy Kupującego, ponowna dostawa takiego Towaru, koszt takiej dostawy i terminy są omawiane i uzgadniane z Sprzedawcą.',
        text8:
          '6.4. Po otrzymaniu Towaru Kupujący ma obowiązek zbadania Towaru, upewnienia się, że otrzymany Towar odpowiada zamówieniu Kupującego, sprawdzenia Towaru pod kątem odpowiedniej jakości i ilości, akceptacji i zapłaty za Towar zgodnie z niniejszą Umową i/lub innymi dokumentami.',
        text9:
          '6.5. Datą otrzymania Towaru przez Kupującego uważa się datę dostarczenia Towaru Kupującemu lub datę odbioru Towaru przez Kupującego w oddziale usługi dostawy pocztowej lub bezpośrednio w miejscu prowadzenia działalności gospodarczej Sprzedawcy.',
        text10:
          '6.6. Po otrzymaniu Towaru Kupujący ma obowiązek zbadania Towaru, upewnienia się, że otrzymany Towar odpowiada zamówieniu Kupującego, sprawdzenia Towaru pod kątem odpowiedniej jakości i ilości, akceptacji i zapłaty za Towar zgodnie z niniejszą Umową i/lub innymi dokumentami.',
        text11:
          '6.7. Prawo własności do Towaru przechodzi od Sprzedawcy na Kupującego w momencie faktycznego otrzymania Towaru przez Kupującego przy pełnej zapłacie za Towar przez Kupującego. W przypadku sprzedaży Towaru na warunkach ratalnych, prawo własności do Towaru przechodzi na Kupującego po zapłacie pełnej wartości towaru.',
        text12:
          '6.8. Kupujący ponosi ryzyko przypadkowej utraty/zagubienia lub uszkodzenia Towaru od momentu otrzymania Towaru.',
        text13:
          '6.9. Sprzedawca zapewnia terminowe przygotowanie i wysyłkę Towaru do Kupującego oraz przekazanie Towaru operatorowi pocztowemu lub firmie kurierskiej zgodnie z wybraną przez Kupującego metodą dostawy. Sprzedawca nie ponosi odpowiedzialności przed Kupującym za opóźnienie w dostawie Towaru, które nie wynika z winy Sprzedawcy i/lub z nieprzewidzianych okoliczności, oraz za uszkodzenie/zepsucie/utracone Towaru spowodowane winą operatora pocztowego lub firmy kurierskiej podczas dostawy Towaru do Kupującego.',
      },
      paymentProcedure: {
        title: '7. KOSZT I PROCEDURA PŁATNOŚCI',
        text1:
          "7.1. Cena każdego indywidualnego Produktu jest ustalana przez Sprzedawcę i podawana na stronie internetowej sklepu internetowego GOT'S LABEL. Cena Zamówienia jest określana przez dodanie ceny wszystkich zawartych w Zamówieniu Produktów, co daje łączną kwotę do zapłaty, podaną na stronie internetowej sklepu internetowego podczas składania Zamówienia w momencie jego złożenia.",
        text2:
          '7.2. Cena Umowy jest równa cenie Zamówienia. Kwota Zamówienia może się różnić w zależności od ceny, ilości lub asortymentu Produktu.',
        text3:
          '7.3. Cena Produktu podana na stronie internetowej w momencie składania zamówienia obejmuje wszystkie obliczenia podatków i jest ostateczną ceną, obowiązującą zarówno dla Kupującego, jak i dla Sprzedawcy. Sprzedawca może jednostronnie zmienić cenę Produktu do momentu złożenia zamówienia.',
        text4:
          '7.4. Kupujący dokonuje płatności za Produkt zgodnie z Zamówieniem. Kupujący samodzielnie wybiera jedną z następujących metod płatności: - płatność gotówką; - płatność bezgotówkowa.',
        text5:
          '7.5. Płatność za Produkt gotówką może być dokonana w sklepie detalicznym (Showroom) w momencie składania Zamówienia lub w momencie przekazania Produktu.',
        text6: '7.6. Płatność za Produkt w formie bezgotówkowej jest dokonywana:',
        text7:
          '1) poprzez przelew pieniędzy na rachunek bieżący Sprzedawcy podany na fakturze, w tym za pośrednictwem bankowości internetowej.',
        text8:
          '2) za pobraniem przy odbiorze Zamówienia w biurze przedstawicielstwa firmy dostawczej lub operatora pocztowego.',
        text9:
          '3) za pobraniem u kuriera przy odbiorze Produktu za pośrednictwem dostawy kurierskiej.',
        text10:
          "4) za pośrednictwem usługi płatności online TOV 'Nova Poshta' 'Kontrola płatności'. W przypadku płatności za pośrednictwem tej usługi cena Produktu jest zwiększana o prowizję 'Nova Poshta'.",
        text11:
          '5) Kartą kredytową następującego typu: Visa, Visa Electron, Mastercard, Mastercard Electronic, Maestro.',
        text12: '6) za pośrednictwem systemu płatności WayForPay.',
        text13: '7) dowolnym innym sposobem uzgodnionym z Sprzedawcą.',
        text14:
          '7.7. Kupujący dokonuje płatności w krajowej walucie Ukrainy - hrywnie zgodnie z kwotą Zamówienia. Płatność za koszt Produktu i koszt przesyłki pocztowej lub kurierskiej Produktu jest dokonywana przez Kupującego w całości przy odbiorze Produktu.',
        text15:
          '7.8. Zamówienie uważa się za opłacone z chwilą wpłynięcia płatności na rachunek bieżący Sprzedawcy lub jego przedstawiciela.',
        text16:
          '7.9. Informacje dotyczące płatności za koszt Produktu i przesyłki pocztowej lub kurierskiej Produktu są podane na stronie internetowej Sprzedawcy. W razie potrzeby Kupujący ma prawo uzyskać informacje od Sprzedawcy dotyczące kosztu Produktu i kosztu przesyłki pocztowej lub kurierskiej Produktu.',
        text17:
          '7.10. Przy zamawianiu Produktu spoza terytorium Ukrainy, do ceny Produktu dodawana jest opłata za dostawę zgodnie z taryfami firm przewozowych, w tym przypadku Kupujący dokonuje 100% przedpłaty za cenę Produktu i dostawę.',
        text18:
          '7.11. Kupujący rozumie i zgadza się, że w przypadku dostarczania Produktów zakupionych w sklepie internetowym poza Ukrainą, cena przy odbiorze przesyłki może zależeć od dodatkowego podatku VAT w kraju odbiorcy. Wysokość opłaty określana jest przez właściwe organy kraju odbiorcy.',
        text19:
          "7.12. Zgodnie z art. 8 Ustawy Ukrainy 'O ochronie praw konsumentów' podczas sprzedaży Produktu Sprzedawca zobowiązany jest wydać dokument płatniczy (paragon, fakturę, itp.) ustalonego formularza z podaniem daty sprzedaży dla kupującego (klienta). Potwierdza to fakt zakupu. Wydanie dokumentu płatniczego może być zrealizowane za zgodą kupującego w formie elektronicznego dokumentu płatniczego (e-dokumentu) na numer klienta, za pośrednictwem komunikatorów lub na adres e-mail podany przez klienta. Dokument płatniczy musi być wydany lub utworzony w formie elektronicznej nie później niż w chwili wysyłki/przekazania Produktu.",
      },
      returnsAndExchanges: {
        title: '8. ZWROTY I WYMIANY TOWARU',
        text01:
          '7.1. Kupujący ma prawo do zwrotu lub wymiany Towaru zgodnie z warunkami i terminami określonymi w niniejszej Umowie.',
        text1:
          '7.2. W przypadku otrzymania Towaru nienależytej jakości Kupujący ma prawo żądać od Sprzedawcy wymiany takiego Towaru na Towar należytej jakości. Jeśli wymiana Towaru nienależytej jakości na Towar należytej jakości jest niemożliwa, Kupujący ma prawo żądać rozwiązania Umowy i zwrotu zapłaconej wartości Towaru.',
        text2:
          '7.2.1. W przypadku zgłoszenia reklamacji przez Kupującego dotyczącej niesatysfakcjonującej jakości Towaru, taka Reklamacja jest składana przez Kupującego w formie pisemnej poprzez wysłanie na adres pocztowy Sprzedawcy lub w formie elektronicznej na adres e-mail Sprzedawcy gotslabel.cooperation@gmail.com.',
        text3: '7.2.2. W reklamacji Kupujący jest zobowiązany podać:',
        text4: '- Nazwisko, imię i nazwisko ojca;',
        text5: '- Numer telefonu kontaktowego;',
        text6: '- Adres pocztowy (aktualny do korespondencji);',
        text7: '- Adres e-mail (jeśli dostępny);',
        text8:
          '- Podstawy zgłoszenia reklamacji, opis uszkodzeń lub oznak niesatysfakcjonującej jakości towaru;',
        text9: '- Treść żądania do Sprzedawcy (wymiana, zwrot środków);',
        text10: '- Datę zakupu towaru;',
        text11:
          'Do reklamacji Kupujący musi obowiązkowo dołączyć kopię paragonu lub innego dokumentu potwierdzającego zakup Towaru. W celu uniknięcia jakichkolwiek nieporozumień po otrzymaniu reklamacji Sprzedawca może żądać zdjęć lub nagrań wideo, na podstawie których Sprzedawca będzie mógł rozpatrzyć reklamowane żądania Kupującego.',
        text12:
          '7.2.3. Sprzedawca rozpatruje reklamację Kupującego w ciągu 30 dni kalendarzowych od daty otrzymania reklamacji. Sprzedawca może odmówić zaspokojenia reklamacji Kupującego, jeśli:',
        text13:
          '- Kupujący nie podał w reklamacji informacji, o których mowa w pkt 7.2.2. niniejszej Umowy;',
        text14: '- Kupujący nie dostarczył kopii dokumentu potwierdzającego zakup Towaru;',
        text15:
          '- Kupujący na żądanie Sprzedawcy nie dostarczył zdjęć i/lub materiałów wideo dowodzących obecności uszkodzonego lub wadliwego Towaru;',
        text16: '- Uszkodzenie lub wada towaru nie wynika z winy Sprzedawcy;',
        text17:
          '- Uszkodzenie lub wada Towaru powstały z niewłaściwego użytkowania Towaru przez Kupującego zgodnie z zasadami producenta lub Sprzedawcy, a także uszkodzenia wynikające z winy (działań lub zaniechań) Kupującego;',
        text18:
          '- Uszkodzenie lub wada Towaru powstały w wyniku siły wyższej lub okoliczności nadzwyczajnych.',
        text19:
          '7.2.4. Po rozpatrzeniu reklamacji Sprzedawca wysyła Kupującemu powiadomienie o wynikach rozpatrzenia reklamacji. Wysyłka takiego powiadomienia odbywa się za pomocą tych samych środków technicznych, którymi reklamacja została wysłana do Sprzedawcy.',
        text20:
          '7.2.5. W przypadku zaspokojenia reklamacji Sprzedawca może wymienić zakupiony przez Kupującego Towar na inny Towar dostępny u Sprzedawcy, a w przypadku niemożności wymiany zwrócić Kupującemu wartość Towaru. Zwrot wartości Towaru dla Kupującego odbywa się za pośrednictwem przelewu bezgotówkowego na konto bankowe Kupującego. W każdym przypadku zwrot środków dla Kupującego jest dokonywany w ciągu 14 dni kalendarzowych od daty powiadomienia Kupującego przez Sprzedawcę o wynikach rozpatrzenia reklamacji.',
        text21:
          '7.3. Kupujący ma prawo zwrócić Towar należytej jakości w ciągu 14 (czternastu) dni od daty otrzymania Towaru należytej jakości. Podczas zwrotu Towaru Kupujący zobowiązany jest przekazać Sprzedawcy paragon, opakowanie oraz inne elementy wyposażenia Towaru, akcesoria, dokumenty, które zostały otrzymane przez Kupującego podczas odbioru towaru (partie towaru). Do zwrotu przez Kupującego podlega tylko taki towar, który jest nienaruszony (w tym akcesoria i dokumenty), ma wygląd handlowy i nadaje się do sprzedaży lub dalszego użytku.',
        text22:
          '7.4. Postanowienia pkt 7.3. nie mają zastosowania w przypadku, gdy Kupujący nie przekazał Sprzedawcy dokumentu rozliczeniowego z datą zakupu, oraz nie przekazał Towaru lub przekazał Towar w niekompletnej konfiguracji, lub uszkodzony Towar, jeśli takie uszkodzenie zostało spowodowane po otrzymaniu Towaru z winy (działań, zaniechań) Kupującego lub w przypadku, gdy Towar został zakupiony przez Kupującego nie u Sprzedawcy.',
        text23:
          '7.5. Przy zwrocie Towaru należytej jakości Kupujący otrzymuje zwrot wartości Towaru bez kosztów wysyłki pocztowej lub kurierskiej Towaru.',
        text24:
          '7.6. Zwrot Towaru należytej jakości jest akceptowany przez Sprzedawcę tylko w przypadku spełnienia przez Kupującego następujących wymagań:',
        text25:
          '7.6.1. Towar, dokumentacja (etykiety, metki) nie są uszkodzone i zachowują wygląd handlowy, nadają się do dalszego użytku lub sprzedaży.',
        text26:
          '7.6.2. Wraz z Towarem przekazywany jest paragon (faktura) lub inny dokument rozliczeniowy potwierdzający zakup Towaru.',
        text27:
          '7.6.3. Przy zwrocie Towaru odpowiedniej jakości Kupujący zobowiązany jest dołączyć do Towaru Wniosek w dowolnej formie żądający zwrotu wartości Towaru z obowiązkowym podaniem:',
        text28: '- Nazwiska, imienia i ojca Kupującego;',
        text29: '- Adresu korespondencyjnego;',
        text30: '- Numeru telefonu kontaktowego;',
        text31: '- Adresu e-mail (jeśli dostępny);',
        text32: '- Daty otrzymania Towaru;',
        text33: '- Kosztu Towaru;',
        text34: '- Danych bankowych w przypadku zwrotu przelewem na konto Kupującego.',
        text35:
          'Przy zwrocie Towaru odpowiedniej jakości Kupujący zobowiązany jest dołączyć do Towaru Wniosek w dowolnej formie żądający zwrotu wartości Towaru z obowiązkowym podaniem:',
        text36: '- Nazwiska, imienia i ojca Kupującego;',
        text37: '- Adresu korespondencyjnego;',
        text38: '- Numeru telefonu kontaktowego;',
        text39: '- Adresu e-mail (jeśli dostępny);',
        text40: '- Daty otrzymania Towaru;',
        text41: '- Kosztu Towaru;',
        text42: '- Danych bankowych w przypadku zwrotu przelewem na konto Kupującego.',
        text43:
          '7.7. W przypadku niewykonania przez Kupującego wymagań z pkt. 7.6. niniejszej Umowy, Sprzedawca ma prawo odmówić Kupującemu zwrotu wartości Towaru odpowiedniej jakości. Datą otrzymania przez Sprzedawcę zwróconego Towaru jest data faktycznego otrzymania Towaru przez Sprzedawcę.',
        text44:
          '7.8. Metodę zwrotu Towaru do Sprzedawcy Kupujący określa samodzielnie. W przypadku zwrotu uszkodzonego Towaru, jeśli uszkodzenie to było spowodowane winą Kupującego lub w trakcie przesyłki Towaru przez Kupującego, Sprzedawca ma prawo odmówić Kupującemu zwrotu wartości Towaru.',
        text45:
          '7.9. Wniosek Kupującego o zwrot Towaru odpowiedniej jakości może być zrealizowany przez Kupującego w ciągu 14 (czternastu) dni kalendarzowych, licząc od dnia następnego po otrzymaniu Towaru.',
        text46:
          '7.10. Kupujący nie ma prawa żądać zwrotu Towaru odpowiedniej jakości w następujących przypadkach:',
        text47:
          '7.10.1. Jeśli taki Towar został wykonany na Zamówienie Kupującego uwzględniając indywidualne cechy;',
        text48:
          '7.10.2. Jeśli taki Towar należy do kategorii Towarów, które nie podlegają zwrotowi zgodnie z obowiązującym prawem Ukrainy. Towary niewyżywieni odpowiedniej jakości nie podlegają zwrotowi, których lista jest zatwierdzona postanowieniem Rady Ministrów Ukrainy nr 172 z dnia 19.03.1994 r.',
        text49:
          '7.10.3. Jeśli zwrot dotyczy Towarów dostarczonych poza terytorium Ukrainy i Polski (międzynarodowa dostawa).',
        text50:
          '7.10.4. Jeśli zwrot dotyczy Towarów, które były używane do sesji zdjęciowych/wideo. Towary używane do sesji zdjęciowych/wideo są uważane za używane i nie podlegają wymianie ani zwrotowi (nawet jeśli zachowują swój handlowy wygląd).',
        text51:
          '7.10.5. Jeśli zwrot dotyczy Towarów zakupionych za pomocą bonów podarunkowych (same bony podarunkowe również nie podlegają zwrotowi).',
        text52:
          '7.11. W przypadku zgodności przez Kupującego z postanowieniami pkt. 7.3.-7.9. niniejszej Umowy, Sprzedawca dokonuje zwrotu wartości Towaru odpowiedniej jakości Kupującemu w ciągu 14 dni kalendarzowych, licząc od dnia następnego po otrzymaniu Towaru przez Sprzedawcę.',
        text53:
          '7.12. Okresy gwarancji dla Towarów ustalane są na okres 30 dni kalendarzowych od daty sprzedaży lub początku sezonu. W ciągu tego okresu Sprzedawca gwarantuje odpowiednią jakość Towaru i jego przydatność do użytku.',
        text54:
          '7.13. Skargi (roszczenia) dotyczące wad Towaru można składać w ciągu 30 dni od daty zakupu Towaru. Skarga może być złożona tylko w przypadku stwierdzenia wady w okresie gwarancji, a wada wskazuje na niezgodność Towaru z warunkami umowy (opisanymi na tej stronie internetowej). Różnice w elementach projektu lub projektu w stosunku do tych podanych w opisie na stronie internetowej nie są oznaką Towaru niewłaściwej jakości.',
        text55:
          '7.13. Towary, które zostały uszkodzone z winy Kupującego, Towary, które zostały użyte nieprawidłowo lub naruszono warunki użytkowania, nie podlegają naprawie gwarancyjnej.',
        text56:
          '7.14. W przypadku konieczności przeprowadzenia ekspertyzy jakości Towaru, strona inicjująca ekspertyzę jakości Towaru ponosi koszty związane z przeprowadzeniem takiej ekspertyzy, chyba że przewiduje to prawo.',
      },
      responsibilityParties: {
        title: '8. ODPOWIEDZIALNOŚĆ STRON',
        text1:
          '8.1. Strona winna naruszeniu warunków niniejszej Umowy ponosi odpowiedzialność za szkody (bezpośrednie i pośrednie) spowodowane takim naruszeniem drugiej Stronie zgodnie z postanowieniami niniejszej Umowy i prawa.',
        text2:
          '8.2. Sprzedawca nie ponosi odpowiedzialności za szkody wyrządzone Kupującemu w wyniku niewłaściwego użytkowania przez niego Towarów zakupionych u Sprzedawcy.',
        text3:
          '8.3. Sprzedawca nie ponosi odpowiedzialności przed Kupującym za szkody spowodowane:',
        text4:
          '8.3.1. Niewłaściwym wypełnieniem przez Kupującego blankietu Zamówienia, w tym podaniem nieprawidłowych informacji (danych osobowych) do identyfikacji Kupującego i adresu dostawy Towaru;',
        text5: '8.3.2. Niewłaściwym wykonaniem przez Kupującego warunków niniejszej Umowy;',
        text6: '8.3.3. Nielegalnymi działaniami osób trzecich;',
        text7: '8.3.4. W innych przypadkach przewidzianych niniejszą Umową lub prawem.',
        text8:
          '8.4. Sprzedawca ponosi odpowiedzialność za jakość i kompletność towaru. W przypadku, gdy Kupujący podczas odbioru towaru stwierdzi uszkodzenia, wady, niekompletność lub inne istotne cechy uniemożliwiające użytkowanie Towaru zgodnie z przeznaczeniem i/lub niezadowalające, Kupujący ma prawo żądać, a Sprzedawca ma prawo zaspokoić jedno z takich żądań Kupującego:',
        text9: '1) Proporcjonalnego obniżenia ceny;',
        text10: '2) Bezpłatnego usunięcia wad w rozsądnym terminie;',
        text11: '3) Zwrotu kosztów usunięcia wad;',
        text12: '4) Bezpłatnej wymiany towaru na analogiczny towar.',
        text13:
          '8.4.1. Sprzedawca ponosi odpowiedzialność, o której mowa w punkcie 7.2. niniejszej Umowy, jeśli Kupujący udowodni, że uszkodzenia, wady lub niekompletność Towaru wystąpiły przed otrzymaniem tego towaru przez Kupującego.',
        text14:
          '8.5. Strona, która naruszyła zobowiązanie, jest zwolniona z odpowiedzialności za naruszenie zobowiązania, jeśli udowodni, że naruszenie to nastąpiło z powodu siły wyższej.',
        text15:
          '8.6. Strona nie ponosi odpowiedzialności za naruszenie niniejszej Umowy, jeśli takie naruszenie nastąpiło niezawinionie przez nią (umyślnie lub z nieostrożności).',
        text16:
          '8.7. Strona uważana jest za niewinną i nie ponosi odpowiedzialności za naruszenie niniejszej Umowy, jeśli udowodni, że podjęła wszystkie od niej zależne działania w celu należytego wykonania niniejszej Umowy.',
        text17:
          '8.8. Sprzedawca nie ponosi odpowiedzialności za bezpieczeństwo, treść i zawartość zasobów internetowych, które Kupujący może odwiedzić, klikając na linki (hiperłącza), umieszczone na stronie.',
        text18:
          '8.9. Kupujący ponosi odpowiedzialność za wiarygodność informacji i danych osobowych podanych przez Kupującego w Zamówieniu i w interakcji z Sprzedawcą.',
      },
      resolutionOfDisputes: {
        title: '9. ROZWIĄZYWANIE SPORÓW',
        text1:
          '9.1. Wszelkie spory i rozbieżności wynikające z wykonania niniejszej Umowy są rozstrzygane drogą negocjacji między Stronami.',
        text2:
          '9.2. Strony zobowiązują się do podejmowania wszelkich starań w celu rozwiązania sporów i rozbieżności w drodze polubownego, pozasądowego uregulowania takich sporów lub rozbieżności.',
        text3:
          '9.3. W przypadku, gdy spory i/lub rozbieżności nie mogą być rozwiązane drogą negocjacji, są one rozstrzygane przez sąd zgodnie z jurysdykcją i kompetencją takiego sporu zgodnie z obowiązującym prawem Ukrainy.',
      },
      forceMajeure: {
        title: '10. SIŁA WYŻSZA',
        text1:
          '10.1. Strony są zwolnione z odpowiedzialności za całkowite lub częściowe niewykonanie swoich zobowiązań wynikających z niniejszej Umowy, jeśli jest to spowodowane siłą wyższą (force majeure), taką jak: siły wyższe, pożary, powodzie, trzęsienia ziemi, wojna, blokada, zamieszki społeczne, okupacja, embargo, przeprowadzanie operacji antyterrorystycznych (ATO), wprowadzenie stanu wojennego na całym terytorium państwa lub jego części, blokada dróg transportowych lub budynków, lokali w wyniku akcji obywatelskich lub zamieszek lub protestów, zakaz eksportu (importu), akty lub ustawy wydane przez organy państwowe Ukrainy i/lub inne organy kontrolujące odpowiednią terytorię, na której wystąpiły okoliczności siły wyższej, a także wszystkie inne przypadki określone w art. 14.1. Ustawy Ukrainy „O izbach handlowo-przemysłowych na Ukrainie”.',
        text2:
          '10.2. W przypadku wystąpienia siły wyższej termin wykonania zobowiązań jest przesuwany zgodnie z okresem, w którym takie okoliczności będą obowiązywać. Jeśli te okoliczności i ich skutki będą trwać dłużej niż 3 (trzy) miesiące, każda ze Stron będzie miała prawo odmówić dalszego wykonywania zobowiązań wynikających z niniejszej Umowy. W takim przypadku żadna ze Stron nie będzie uprawniona do odszkodowania przez drugą Stronę ewentualnych szkód.',
        text3:
          '10.3. Strona, dla której powstała niemożność wykonania zobowiązań na mocy Umowy z powodu siły wyższej, zobowiązana jest powiadomić (faksem, pocztą elektroniczną) drugą Stronę w ciągu 3 dni kalendarzowych od wystąpienia takich okoliczności, a także w ciągu 45 (czterdziestu pięciu) dni kalendarzowych od dnia wystąpienia okoliczności siły wyższej przesłać powiadomienie w ustalonej formie (opinia lub inny dokument potwierdzający), wydane przez przedstawiciela odpowiedniej Izby Handlowo-Przemysłowej lub innego upoważnionego organu na mocy prawa, z podaniem ww. wydarzeń, daty ich wystąpienia i ich trwania.',
        text4:
          '10.4. Jeśli okoliczności siły wyższej utrzymują się przez ponad 2 (dwa) kolejne miesiące kalendarzowe, Strony zobowiązują się podjąć decyzję w sprawie dalszego postępowania w ramach wykonania niniejszej Umowy.',
        text5:
          '10.5. W przypadku niekorzystnych warunków pogodowych (mgła, gołoledź, zamiecie śnieżne i inne), w których, zgodnie z warunkami przewozu ładunków niebezpiecznych, zakazane jest poruszanie się środków transportu z ładunkiem niebezpiecznym, termin wykonania zobowiązań wynikających z niniejszej Umowy jest przedłużany o okres trwania tych okoliczności. Strony niezwłocznie informują się nawzajem o rozpoczęciu i zakończeniu tych okoliczności. W celu potwierdzenia tych okoliczności opinia Izby Handlowo-Przemysłowej Ukrainy lub innego właściwego organu nie jest wymagana.',
      },
      periodAgreement: {
        title: '11. ZAWIERANIE UMOWY I OKRES OBOWIĄZYWANIA UMOWY',
        text1:
          '11.1. Niniejsza Umowa uważana jest za zawartą przez Strony i nabiera mocy prawnej z chwilą przystąpienia Kupującego do warunków niniejszej Umowy poprzez kliknięcie przez Kupującego przycisku „Złóż zamówienie” na stronie internetowej Sprzedawcy, poprzez potwierdzenie Zamówienia przez Kupującego w przypadku składania Zamówienia telefonicznie lub innym sposobem przewidzianym przez obowiązujące prawo Ukrainy.',
        text2:
          '11.2. Niniejsza Umowa obowiązuje do pełnego wykonania przez Strony swoich zobowiązań na mocy niniejszej Umowy.',
        text3:
          '11.3. Kupujący potwierdza, że do momentu zawarcia niniejszej Umowy Sprzedawca udzielił Kupującemu wszystkich niezbędnych informacji o Produkcie, o których mowa w przepisach ustawy Ukrainy „O ochronie praw konsumentów”.',
        text4:
          '11.4. W przypadku zawarcia umowy na okresową dostawę Produktu minimalny okres obowiązywania Umowy wynosi 6 (sześć) miesięcy.',
        text5:
          '11.5. Sprzedawca ma prawo zmieniać warunki niniejszej Umowy, wcześniej informując o tym Kupującego na 5 (pięć) dni przed wejściem w życie nowej wersji Umowy poprzez przesłanie nowej wersji Umowy (z zmianami i uzupełnieniami) na adres e-mail Kupującego. Jeżeli przed wejściem w życie nowej wersji Umowy Kupujący nie przekaże Sprzedawcy sprzeciwu lub żądania rozwiązania niniejszej Umowy, taka Umowa w nowej wersji uważana jest za całkowicie przyjętą i zaakceptowaną przez Kupującego.',
        text6:
          '11.6. Niniejsza Umowa może być zmieniona przez Sprzedawcę jednostronnie dla wszystkich swoich klientów, Kupujących, jednocześnie publikując nową wersję na oficjalnej stronie internetowej Sprzedawcy, a także informując wszystkich klientów, Kupujących za pomocą poczty elektronicznej. W przypadku niezgody klientów, Kupujących na wprowadzone zmiany, zostaje zawarta odrębna pisemna umowa, w której uregulowane zostaną sporne kwestie.',
      },
      otherTherms: {
        title: '12. INNE WARUNKI',
        text1:
          '12.1. Przystąpienie Kupującego do niniejszej Umowy dokonuje się poprzez akceptację (zgodę) Kupującego na zakup Produktu od Sprzedawcy zgodnie z warunkami określonymi w niniejszej Umowie poprzez kliknięcie przycisku „Złożenie Zamówienia” na stronie internetowej Sprzedawcy.',
        text2:
          '12.2. Przystąpienie do warunków niniejszej Umowy następuje poprzez wolną wolę i akceptację warunków niniejszej Umowy przez Kupującego.',
        text3:
          '12.3. Kupujący potwierdza, że w chwili zawarcia niniejszej Umowy posiada odpowiednią zdolność do czynności prawnych oraz osiągnął odpowiedni wiek, co umożliwia mu zakup Produktu od Sprzedawcy.',
        text4:
          '12.4. Kupujący akceptuje i zgadza się na warunki dokonywania zakupów na stronie internetowej Sprzedawcy, zobowiązuje się do nie naruszania zasad, w żaden sposób nie obrażać odwiedzających stronę internetową ani pracowników lub upoważnionych przez Sprzedawcę osób, w tym nie używać wulgaryzmów, agresywnego zachowania, obrazy, poniżenia godności, honoru i reputacji biznesowej Sprzedawcy lub innych osób.',
        text5:
          '12.5. Warunki niniejszej Umowy mają zastosowanie do nieograniczonego kręgu osób. Sprzedawca nie ma prawa odmówić Kupującemu zawarcia niniejszej Umowy, z wyjątkiem przypadków przewidzianych przez Ustawę.',
        text6:
          '12.6. Kupujący nie ma prawa żądać od Sprzedawcy zmiany warunków niniejszej Umowy ani nabycia dodatkowych praw i obowiązków w porównaniu z innymi Kupującymi.',
        text7:
          '12.7. Niniejsza Umowa może zostać rozwiązana przez Sprzedawcę w drodze jednostronnego pisemnego powiadomienia Kupującego nie wcześniej niż na 30 dni kalendarzowych przed planowaną datą rozwiązania niniejszej Umowy.',
        text8:
          '12.8. W przypadku braku przez Kupującego w ramach warunków niniejszej Umowy dokonania płatności za Produkt, Umowa uważana jest za nieważną.',
        text9:
          '12.9. Tekst niniejszej Umowy jest sporządzony w języku ukraińskim, dostępny na stronie internetowej Sprzedawcy pod adresem http://www.gotslabel.com.',
        text10:
          '12.10. Osobą przyjmującą reklamacje Kupujących dotyczące jakości, kompletności, ilości i asortymentu Produktu jest Sprzedawca.',
        text11:
          '12.11. Jednocześnie z zawarciem niniejszej Umowy Kupujący wyraża zgodę na zbieranie i przetwarzanie jego danych osobowych oraz zgadza się na inne (lokalne) dokumenty Sprzedawcy.',
        text12:
          '12.12. W każdym przypadku uznania za nieważne jednego postanowienia niniejszej Umowy nie oznacza uznania za nieważną całej Umowy.',
        text13:
          '12.13. Zawierając niniejszą Umowę, Kupujący wyraża zgodę na przekazanie przez Sprzedawcę praw i obowiązków wynikających z niniejszej Umowy osobom trzecim.',
        text14:
          '12.14. Strona internetowa sklepu internetowego zawiera materiały, znaki towarowe, nazwy firm oraz inne materiały chronione prawem, w tym, ale nie ograniczając się do, znaków towarowych, tekstów, fotografii, grafik.',
        text15:
          '12.15. Wszystkie obiekty dostępne w sklepie internetowym https://www.gotslabel.com, w tym elementy projektu, tekst, grafika, ilustracje, wideo, zdjęcia, oprogramowanie komputerowe, bazy danych, muzyka, dźwięki i inne obiekty (dalej: treść sklepu internetowego), a także wszelka treść zamieszczona na stronie internetowej https://www.gotslabel.com, są obiektami praw własności Właściciela sklepu internetowego i innych podmiotów prawnych.',
        text16:
          '12.16. Wykorzystywanie treści oraz innych elementów sklepów internetowych jest możliwe wyłącznie w ramach funkcjonalności oferowanej przez sklep internetowy. Żadne elementy treści sklepu internetowego nie mogą być wykorzystywane w inny sposób bez uprzedniej zgody właściciela praw. Wykorzystanie obejmuje, między innymi: reprodukcję, kopiowanie, modyfikację, rozpowszechnianie na dowolnej podstawie, wyświetlanie w loterii itp. Wyjątkiem są przypadki wyraźnie przewidziane przez prawo ukraińskie. Wykorzystanie przez Użytkownika elementów treści sklepu internetowego oraz wszelkiej treści do celów osobistych i niekomercyjnych jest dopuszczalne pod warunkiem zachowania wszystkich znaków ochrony praw autorskich, praw pokrewnych, znaków towarowych, innych komunikatów o prawach autorskich, zachowania nazwiska (lub pseudonimu) autora/nazwy właściciela praw w niezmienionej formie, zachowania odpowiedniego obiektu w niezmienionej formie. Wyjątkiem są przypadki wyraźnie przewidziane przez prawo ukraińskie.',
      },
      personalInformation: {
        title: '13. DANE KONTAKTOWE SPRZEDAWCY',
        text1:
          '13.1. Nazwa sprzedawcy, dane kontaktowe: Przedsiębiorca indywidualny Hotochkina Svitlana Hennadiyivna, numer EDRPOU: 2005220000000088241 z dnia 29 marca 2021 roku, adres rejestracji: 54037, Mykolajów, ul. Kociubinskiego 20, numer podatkowy: 3192020323.',
        text2:
          'Dane kontaktowe: telefon +38 099 039 99 99, telefon do współpracy: +380660292069 (WhatsApp, Telegram), Email: gotslabel.cooperation@gmail.com,',
        text3: 'adres e-mail do korespondencji: gotslabel.cooperation@gmail.com',
        text4: 'SALON KYIV: ul. Lyuteranska 11-A',
        text5: 'Pon-Nd: 11:00-19:30',
        text6: 'SALON WARSZAWA: Wilcza 44',
        text7: 'Pon-Sob: 11:00-19:00',
        text8: 'Instagram Ukraina: gots.label',
        text9: 'Instagram Warszawa: gotslabel.warsaw',
        text10:
          '15.2. Użytkownik/Kupujący może skontaktować się ze Sprzedawcą zarządzającym sklepem internetowym za pomocą poniższych środków:',
        text11:
          '▪ Pod adresem rzeczywistym sklepu internetowego GOTS LABEL: Ukraina, 01133, Kijów, ul. Lyuteranska 11-A.',
        text12:
          '▪ Zadzwoń na infolinię dla telefonów stacjonarnych i komórkowych: +38 099 039 99 99 (Opłata za połączenie zgodnie z taryfami operatora).',
        text13: '▪ Za pomocą e-maila na adres: e-mail gotslabel.cooperation@gmail.com.',
        text14: '▪ Za pośrednictwem formularza kontaktowego na stronie internetowej sklepu.',
      },
    },
  };
  const selectedLanguageData = texts[language.toLowerCase()];
  return (
    <div className="public-offer">
      <h2>{selectedLanguageData.publicOffer.title}</h2>
      <p>{selectedLanguageData.publicOffer.text1}</p>
      <p>{selectedLanguageData.publicOffer.text2}</p>
      <p>{selectedLanguageData.publicOffer.text3}</p>
      <p>{selectedLanguageData.publicOffer.text4}</p>
      <p>{selectedLanguageData.publicOffer.text5}</p>
      <h2>{selectedLanguageData.conceptsTerms.title}</h2>
      <p>{selectedLanguageData.conceptsTerms.text1}</p>
      <p>{selectedLanguageData.conceptsTerms.text2}</p>
      <p>{selectedLanguageData.conceptsTerms.text3}</p>
      <p>{selectedLanguageData.conceptsTerms.text4}</p>
      <p>{selectedLanguageData.conceptsTerms.text5}</p>
      <p>{selectedLanguageData.conceptsTerms.text6}</p>
      <p>{selectedLanguageData.conceptsTerms.text7}</p>
      <p>{selectedLanguageData.conceptsTerms.text8}</p>
      <p>{selectedLanguageData.conceptsTerms.text9}</p>
      <p>{selectedLanguageData.conceptsTerms.text10}</p>
      <p>{selectedLanguageData.conceptsTerms.text11}</p>
      <p>{selectedLanguageData.conceptsTerms.text12}</p>
      <p>{selectedLanguageData.conceptsTerms.text13}</p>
      <p>{selectedLanguageData.conceptsTerms.text14}</p>
      <p>{selectedLanguageData.conceptsTerms.text15}</p>
      <p>{selectedLanguageData.conceptsTerms.text16}</p>
      <p>{selectedLanguageData.conceptsTerms.text17}</p>
      <p>{selectedLanguageData.conceptsTerms.text18}</p>
      <p>{selectedLanguageData.conceptsTerms.text19}</p>
      <h2>{selectedLanguageData.subjectOfAgreement.title}</h2>
      <p>{selectedLanguageData.subjectOfAgreement.text1}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text2}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text3}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text4}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text5}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text6}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text7}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text8}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text9}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text10}</p>
      <p>{selectedLanguageData.subjectOfAgreement.text11}</p>
      <h2>{selectedLanguageData.ruls.title}</h2>
      <p>{selectedLanguageData.ruls.text1}</p>
      <p>{selectedLanguageData.ruls.text2}</p>
      <p>{selectedLanguageData.ruls.text3}</p>
      <p>{selectedLanguageData.ruls.text4}</p>
      <p>{selectedLanguageData.ruls.text5}</p>
      <p>{selectedLanguageData.ruls.text6}</p>
      <p>{selectedLanguageData.ruls.text7}</p>
      <p>{selectedLanguageData.ruls.text8}</p>
      <p>{selectedLanguageData.ruls.text9}</p>
      <p>{selectedLanguageData.ruls.text10}</p>
      <p>{selectedLanguageData.ruls.text11}</p>
      <p>{selectedLanguageData.ruls.text12}</p>
      <p>{selectedLanguageData.ruls.text13}</p>
      <p>{selectedLanguageData.ruls.text14}</p>
      <p>{selectedLanguageData.ruls.text15}</p>
      <p>{selectedLanguageData.ruls.text16}</p>
      <p>{selectedLanguageData.ruls.text17}</p>
      <p>{selectedLanguageData.ruls.text18}</p>
      <p>{selectedLanguageData.ruls.text19}</p>
      <p>{selectedLanguageData.ruls.text20}</p>
      <p>{selectedLanguageData.ruls.text21}</p>
      <p>{selectedLanguageData.ruls.text22}</p>
      <h2>{selectedLanguageData.registration.title}</h2>
      <p>{selectedLanguageData.registration.text1}</p>
      <p>{selectedLanguageData.registration.text2}</p>
      <p>{selectedLanguageData.registration.text3}</p>
      <p>{selectedLanguageData.registration.text4}</p>
      <p>{selectedLanguageData.registration.text5}</p>
      <p>{selectedLanguageData.registration.text6}</p>
      <p>{selectedLanguageData.registration.text7}</p>
      <p>{selectedLanguageData.registration.text8}</p>
      <p>{selectedLanguageData.registration.text9}</p>
      <p>{selectedLanguageData.registration.text10}</p>
      <p>{selectedLanguageData.registration.text11}</p>
      <p>{selectedLanguageData.registration.text12}</p>
      <p>{selectedLanguageData.registration.text13}</p>
      <p>{selectedLanguageData.registration.text14}</p>
      <p>{selectedLanguageData.registration.text15}</p>
      <h2>{selectedLanguageData.orderingGoods.title}</h2>
      <p>{selectedLanguageData.orderingGoods.text1}</p>
      <p>{selectedLanguageData.orderingGoods.text2}</p>
      <p>{selectedLanguageData.orderingGoods.text3}</p>
      <p>{selectedLanguageData.orderingGoods.text4}</p>
      <p>{selectedLanguageData.orderingGoods.text5}</p>
      <p>{selectedLanguageData.orderingGoods.text6}</p>
      <p>{selectedLanguageData.orderingGoods.text7}</p>
      <p>{selectedLanguageData.orderingGoods.text8}</p>
      <p>{selectedLanguageData.orderingGoods.text9}</p>
      <p>{selectedLanguageData.orderingGoods.text10}</p>
      <p>{selectedLanguageData.orderingGoods.text11}</p>
      <p>{selectedLanguageData.orderingGoods.text12}</p>
      <p>{selectedLanguageData.orderingGoods.text13}</p>
      <p>{selectedLanguageData.orderingGoods.text14}</p>
      <p>{selectedLanguageData.orderingGoods.text15}</p>
      <p>{selectedLanguageData.orderingGoods.text16}</p>
      <p>{selectedLanguageData.orderingGoods.text17}</p>
      <p>{selectedLanguageData.orderingGoods.text18}</p>
      <p>{selectedLanguageData.orderingGoods.text19}</p>
      <p>{selectedLanguageData.orderingGoods.text20}</p>
      <p>{selectedLanguageData.orderingGoods.text21}</p>
      <p>{selectedLanguageData.orderingGoods.text22}</p>
      <p>{selectedLanguageData.orderingGoods.text23}</p>
      <p>{selectedLanguageData.orderingGoods.text24}</p>
      <p>{selectedLanguageData.orderingGoods.text25}</p>
      <p>{selectedLanguageData.orderingGoods.text26}</p>
      <p>{selectedLanguageData.orderingGoods.text27}</p>
      <h2>{selectedLanguageData.deliveryGoods.title}</h2>
      <p>{selectedLanguageData.deliveryGoods.text1}</p>
      <p>{selectedLanguageData.deliveryGoods.text2}</p>
      <p>{selectedLanguageData.deliveryGoods.text3}</p>
      <p>{selectedLanguageData.deliveryGoods.text4}</p>
      <p>{selectedLanguageData.deliveryGoods.text5}</p>
      <p>{selectedLanguageData.deliveryGoods.text6}</p>
      <p>{selectedLanguageData.deliveryGoods.text7}</p>
      <p>{selectedLanguageData.deliveryGoods.text8}</p>
      <p>{selectedLanguageData.deliveryGoods.text9}</p>
      <p>{selectedLanguageData.deliveryGoods.text10}</p>
      <p>{selectedLanguageData.deliveryGoods.text11}</p>
      <p>{selectedLanguageData.deliveryGoods.text12}</p>
      <p>{selectedLanguageData.deliveryGoods.text13}</p>
      <h2>{selectedLanguageData.paymentProcedure.title}</h2>
      <p>{selectedLanguageData.paymentProcedure.text1}</p>
      <p>{selectedLanguageData.paymentProcedure.text2}</p>
      <p>{selectedLanguageData.paymentProcedure.text3}</p>
      <p>{selectedLanguageData.paymentProcedure.text4}</p>
      <p>{selectedLanguageData.paymentProcedure.text5}</p>
      <p>{selectedLanguageData.paymentProcedure.text6}</p>
      <p>{selectedLanguageData.paymentProcedure.text7}</p>
      <p>{selectedLanguageData.paymentProcedure.text8}</p>
      <p>{selectedLanguageData.paymentProcedure.text9}</p>
      <p>{selectedLanguageData.paymentProcedure.text10}</p>
      <p>{selectedLanguageData.paymentProcedure.text11}</p>
      <p>{selectedLanguageData.paymentProcedure.text12}</p>
      <p>{selectedLanguageData.paymentProcedure.text13}</p>
      <p>{selectedLanguageData.paymentProcedure.text14}</p>
      <p>{selectedLanguageData.paymentProcedure.text15}</p>
      <p>{selectedLanguageData.paymentProcedure.text16}</p>
      <p>{selectedLanguageData.paymentProcedure.text17}</p>
      <p>{selectedLanguageData.paymentProcedure.text18}</p>
      <p>{selectedLanguageData.paymentProcedure.text19}</p>
      <h2>{selectedLanguageData.returnsAndExchanges.title}</h2>
      <p>{selectedLanguageData.returnsAndExchanges.text01}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text1}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text2}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text3}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text4}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text5}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text6}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text7}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text8}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text9}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text10}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text11}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text12}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text13}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text14}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text15}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text16}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text17}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text18}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text19}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text20}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text21}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text22}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text23}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text24}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text25}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text26}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text27}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text28}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text29}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text30}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text31}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text32}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text33}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text34}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text35}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text36}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text37}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text38}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text39}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text40}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text41}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text42}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text43}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text44}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text45}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text46}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text47}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text48}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text49}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text50}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text51}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text52}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text53}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text54}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text55}</p>
      <p>{selectedLanguageData.returnsAndExchanges.text56}</p>
      <h2>{selectedLanguageData.responsibilityParties.title}</h2>
      <p>{selectedLanguageData.responsibilityParties.text1}</p>
      <p>{selectedLanguageData.responsibilityParties.text2}</p>
      <p>{selectedLanguageData.responsibilityParties.text3}</p>
      <p>{selectedLanguageData.responsibilityParties.text4}</p>
      <p>{selectedLanguageData.responsibilityParties.text5}</p>
      <p>{selectedLanguageData.responsibilityParties.text6}</p>
      <p>{selectedLanguageData.responsibilityParties.text7}</p>
      <p>{selectedLanguageData.responsibilityParties.text8}</p>
      <p>{selectedLanguageData.responsibilityParties.text9}</p>
      <p>{selectedLanguageData.responsibilityParties.text10}</p>
      <p>{selectedLanguageData.responsibilityParties.text11}</p>
      <p>{selectedLanguageData.responsibilityParties.text12}</p>
      <p>{selectedLanguageData.responsibilityParties.text13}</p>
      <p>{selectedLanguageData.responsibilityParties.text14}</p>
      <p>{selectedLanguageData.responsibilityParties.text15}</p>
      <p>{selectedLanguageData.responsibilityParties.text16}</p>
      <p>{selectedLanguageData.responsibilityParties.text17}</p>
      <p>{selectedLanguageData.responsibilityParties.text18}</p>
      <h2>{selectedLanguageData.resolutionOfDisputes.title}</h2>
      <p>{selectedLanguageData.resolutionOfDisputes.text1}</p>
      <p>{selectedLanguageData.resolutionOfDisputes.text2}</p>
      <p>{selectedLanguageData.resolutionOfDisputes.text3}</p>
      <h2>{selectedLanguageData.forceMajeure.title}</h2>
      <p>{selectedLanguageData.forceMajeure.text1}</p>
      <p>{selectedLanguageData.forceMajeure.text2}</p>
      <p>{selectedLanguageData.forceMajeure.text3}</p>
      <p>{selectedLanguageData.forceMajeure.text4}</p>
      <p>{selectedLanguageData.forceMajeure.text5}</p>
      <h2>{selectedLanguageData.periodAgreement.title}</h2>
      <p>{selectedLanguageData.periodAgreement.text1}</p>
      <p>{selectedLanguageData.periodAgreement.text2}</p>
      <p>{selectedLanguageData.periodAgreement.text3}</p>
      <p>{selectedLanguageData.periodAgreement.text4}</p>
      <p>{selectedLanguageData.periodAgreement.text5}</p>
      <p>{selectedLanguageData.periodAgreement.text6}</p>

      <h2>{selectedLanguageData.otherTherms.title}</h2>
      <p>{selectedLanguageData.otherTherms.text1}</p>
      <p>{selectedLanguageData.otherTherms.text2}</p>
      <p>{selectedLanguageData.otherTherms.text3}</p>
      <p>{selectedLanguageData.otherTherms.text4}</p>
      <p>{selectedLanguageData.otherTherms.text5}</p>
      <p>{selectedLanguageData.otherTherms.text6}</p>
      <p>{selectedLanguageData.otherTherms.text7}</p>
      <p>{selectedLanguageData.otherTherms.text8}</p>
      <p>{selectedLanguageData.otherTherms.text9}</p>
      <p>{selectedLanguageData.otherTherms.text10}</p>
      <p>{selectedLanguageData.otherTherms.text11}</p>
      <p>{selectedLanguageData.otherTherms.text12}</p>
      <p>{selectedLanguageData.otherTherms.text13}</p>
      <p>{selectedLanguageData.otherTherms.text14}</p>
      <p>{selectedLanguageData.otherTherms.text15}</p>
      <p>{selectedLanguageData.otherTherms.text16}</p>

      <h2>{selectedLanguageData.personalInformation.title}</h2>
      <p>{selectedLanguageData.personalInformation.text1}</p>
      <p>{selectedLanguageData.personalInformation.text2}</p>
      <p>{selectedLanguageData.personalInformation.text3}</p>
      <p>{selectedLanguageData.personalInformation.text4}</p>
      <p>{selectedLanguageData.personalInformation.text5}</p>
      <p>{selectedLanguageData.personalInformation.text6}</p>
      <p>{selectedLanguageData.personalInformation.text7}</p>
      <p>{selectedLanguageData.personalInformation.text8}</p>
      <p>{selectedLanguageData.personalInformation.text9}</p>
      <p>{selectedLanguageData.personalInformation.text10}</p>
      <p>{selectedLanguageData.personalInformation.text11}</p>
      <p>{selectedLanguageData.personalInformation.text12}</p>
      <p>{selectedLanguageData.personalInformation.text13}</p>
    </div>
  );
}
