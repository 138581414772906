import React from 'react';
import Item from '../Item/Item';
import './ItemList.css';

export default function ItemList({ list }) {
  return (
    <ul className="item-list">
      {list.map((item) => {
        return <Item key={item.id} item={item} />;
      })}
    </ul>
  );
}
