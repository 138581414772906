import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

export default function RequireAdminAuth({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAuth = async () => {
      try {
        // Проверяем, авторизован ли пользователь
        const user = auth.currentUser;
        if (!user) {
          // Если пользователь не авторизован, перенаправляем на страницу входа
          navigate('/admin');
        }
      } catch (error) {
        console.error('Ошибка проверки аутентификации:', error);
      }
    };
    checkAdminAuth();
  }, [navigate]);
  
  return <>{children}</>;
}
