export const categoryArray = [
  { value: 'jackets', label: 'Куртки' },
  { value: 'bombers', label: 'Бомбери' },
  { value: 'vests', label: 'Жилети' },
  { value: 'trench', label: 'Тренчі' },
  { value: 'sheepdogs', label: 'Дублянки' },
  { value: 'coats', label: 'Шуби' },
  { value: 'dresses', label: 'Плаття' },
  { value: 'pants', label: 'Штани' },
  { value: 'shorts', label: 'Шорти' },
  { value: 'skirts', label: 'Cпідниці' },
  { value: 'jeans', label: 'Джинси' },
];

export const collectionArray = [
  { value: 'no', label: 'Без колекції' },
  { value: 'new-collection', label: 'New collection' },
  { value: 'winter-essentials', label: 'Winter essentials' },
  { value: 'jackets', label: 'Jackets 23` - 24`' },
];

export const colors = [
  { value: 'white', label: 'Білий' },
  { value: 'black', label: 'Чорний' },
  { value: 'grey', label: 'Сірий' },
  { value: 'vintage_grey', label: 'Сірий вінтажний' },
  { value: 'suede_grey', label: 'Сірий замшевий' },
  { value: 'blue', label: 'Голубий' },
  { value: 'pink', label: 'Рожевий' },
  { value: 'burgundy', label: 'Бордовий' },
  { value: 'khaki', label: 'Хакі' },
  { value: 'brown', label: 'Коричневий' },
  { value: 'brownVintage', label: 'Коричнево-вінтажний' },
  { value: 'beige', label: 'Бежевий' },
  { value: 'ginger', label: 'Рудий' },
  { value: 'pistachio', label: 'Фісташковий' },
  { value: 'sea', label: 'Морська хвиля' },
  { value: 'caramel', label: 'Карамельний' },
];

export const skin = [
  { value: 'smooth', label: 'Гладка' },
  { value: 'jumbo', label: 'Джумбо' },
  { value: 'lakova', label: 'Лакова' },
  { value: 'vintage', label: 'Вінтажна' },
  { value: 'crocodile', label: 'З тисненням крокодила' },
  { value: 'suede', label: 'Замшева' },
  { value: 'lama', label: 'Хутро лами' },
  { value: 'tigrado', label: 'Tigrado' },
  { value: 'sheepskin', label: 'Овчина' },
];

// export const fur = [
//     { value: 'lama', label: 'Хутро лами' },
//     { value: 'tigrado', label: 'Tigrado' },
//     { value: 'sheepskin', label: 'Овчина' },
// ];

export const size = [
  { value: 'oversize', label: 'oversize' },
  // { value: 'onesize', label: 'onesize' },
];

export const newSale = [
  { value: false, label: 'Скинути New або Sale' },
  { value: 'sale', label: 'Sale' },
  { value: 'new', label: 'New' },
];

export const inputFields = [
  { name: 'seoTitleUa', type: 'text', placeholder: 'SEO Title Укр' },
  { name: 'seoTitlePl', type: 'text', placeholder: 'SEO Title Pl' },
  { name: 'seoTitleEn', type: 'text', placeholder: 'SEO Title En' },
  { name: 'h1ua', type: 'text', placeholder: 'H1 Укр' },
  { name: 'h1en', type: 'text', placeholder: 'H1 En' },
  { name: 'h1pl', type: 'text', placeholder: 'H1 Pl' },
  { name: 'titleUa', type: 'text', placeholder: 'Назва товару Укр', req: true },
  { name: 'titlePl', type: 'text', placeholder: 'Назва товару Pl', req: true },
  { name: 'titleEn', type: 'text', placeholder: 'Назва товару En', req: true },
  { name: 'article', type: 'number', placeholder: 'Артикль товару', req: true },
  { name: 'priceUa', type: 'number', placeholder: 'Ціна UA', req: true },
  { name: 'priceEn', type: 'number', placeholder: 'Ціна EN', req: true },
];

export const inputSale = [
  { name: 'priceUaSale', type: 'number', placeholder: 'Ціна UA зі знишкою' },
  { name: 'priceEnSale', type: 'number', placeholder: 'Ціна EN зі знишкою' },
];

export const selectFields = [
  { name: 'category', placeholder: 'Обрати категорію *', options: categoryArray, req: true },
  { name: 'collection', placeholder: 'Обрати колекцію ++', options: collectionArray },
  { name: 'skin', placeholder: 'Обрати тип шкіри / хутра *', options: skin, req: true },
  // { name: "fur", placeholder: "Обрати тип хутра *", options: fur },
  { name: 'color', placeholder: 'Обрати колір *', options: colors, req: true },
  // { name: "size", placeholder: "Обрати розмір *", options: size },
  { name: 'newSale', placeholder: 'Обрати New або Sale ++', options: newSale },
];

export const textAreaFields = [
  { name: 'seoDescriptionUa', type: 'text', placeholder: 'SEO Description Укр' },
  { name: 'seoDescriptionPl', type: 'text', placeholder: 'SEO Description Pl' },
  { name: 'seoDescriptionEn', type: 'text', placeholder: 'SEO Description En' },
  { name: 'descriptionUa', placeholder: 'Деталі продукту Укр', req: true },
  { name: 'descriptionPl', placeholder: 'Деталі продукту Pl', req: true },
  { name: 'descriptionEn', placeholder: 'Деталі продукту En', req: true },
  { name: 'characteristicUa', placeholder: 'Склад Укр', req: true },
  { name: 'characteristicPl', placeholder: 'Склад Pl', req: true },
  { name: 'characteristicEn', placeholder: 'Склад En', req: true },
];

export const checkboxes = [
  { name: 'bestsellers', label: 'Bestsellers' },
  { name: 'hide', label: 'Сховати товар' },
];

export const colorsLanguage = {
  ukr: {
    white: 'Білий',
    black: 'Чорний',
    grey: 'Сірий',
    vintage_grey: 'Сірий вінтажний',
    suede_grey: 'Сірий замшевий',
    blue: 'Голубий',
    pink: 'Рожевий',
    burgundy: 'Бордовий',
    khaki: 'Хакі',
    brown: 'Коричневий',
    brownVintage: 'Коричнево-вінтажний',
    beige: 'Бежевий',
    ginger: 'Рудий',
    pistachio: 'Фісташковий',
    sea: 'Морська хвиля',
    caramel: 'Карамельний',
  },
  eng: {
    white: 'White',
    black: 'Black',
    grey: 'Grey',
    vintage_grey: 'Vintage grey',
    suede_grey: 'Suede grey',
    blue: 'Blue',
    pink: 'Pink',
    burgundy: 'Burgundy',
    khaki: 'Khaki',
    brown: 'Brown',
    brownVintage: 'Brown vintage',
    beige: 'Beige',
    ginger: 'Ginger',
    pistachio: 'Pistachio',
    sea: 'Sea wave',
    caramel: 'Caramel',
  },
  pl: {
    white: 'Biały',
    black: 'Czarny',
    grey: 'Szary',
    vintage_grey: 'Szary vintage',
    suede_grey: 'Szary zamszowy',
    blue: 'Niebieski',
    pink: 'Różowy',
    burgundy: 'Bordowy',
    khaki: 'Chaki',
    brown: 'Brązowy',
    brownVintage: 'Brązowy vintage',
    beige: 'Beżowy',
    ginger: 'Rudy',
    pistachio: 'Pistacja',
    sea: 'Fala morska',
    caramel: 'Karmel',
  },
};

