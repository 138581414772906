export const seoData = {
  2024510144454: {
    article: '0001',
    category: 'jackets',
    characteristicEn: 'A jacket with gloves made of a natural smooth leather',
    characteristicPl: 'Kurtka z rękawiczkami z naturalnej gładkiej skóry.\n',
    characteristicUa: 'Косуха з рукавичками з натуральної гладкої шкіри',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 106 cm\nBack length: 47 cm\nSleeve length from neck: 84 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 106 cm\nDługość tyłu: 47 cm\nDługość rękawa od szyi: 84 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи:\n\nОб'єм грудей: 106 см\nДовжина по спині: 47 см\nДовжина рукава від горловини: 84 см\n\nЗріст моделі: 175 см",
    h1en: 'White leather jacket women ',
    h1pl: 'Kurtka skórzana damska białа',
    h1ua: 'Жіноча шкіряна куртка біла з рукавичками',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F45g3pshm-60_ZEN_8364.jpeg?alt=media&token=2b0cdec4-018c-4201-8981-9398108acad7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6k1hab63-45_ZEN_8303.jpeg?alt=media&token=878e3c01-1cfc-49f6-9df3-980ce833d0d1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvb42zuv0-55_ZEN_8345.jpeg?alt=media&token=58c3d5d4-ff6a-42d2-9df7-aa1604c4eb37',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fe4cqm7je-51_ZEN_8329.jpeg?alt=media&token=cf658172-21a1-43aa-8c52-c333f94020f4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5zzgscj5-52_ZEN_8332.jpeg?alt=media&token=2d828254-0a54-40fe-a095-edf5fcc8be1b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzeb2l93l-68_ZEN_8400.jpeg?alt=media&token=5c92c9bd-0723-4713-a29e-d82b1337ef9a',
    ],
    interest: '',
    newSale: '',
    priceEn: '800',
    priceEnSale: '',
    priceUa: '36000',
    priceUaSale: '',
    seoDescriptionEn:
      "Discover the perfect blend of style and comfort with our white women's leather jackets. Crafted for elegance and durability, these jackets are a must-have for any fashion-forward wardrobe.",
    seoDescriptionPl:
      'Wzbogać swoją garderobę o wyjątkowy styl z naszą białą kurtką skórzaną oversize. Elegancka, modna i idealna na każdą okazję. Zobacz więcej!',
    seoDescriptionUa:
      'Шкіряна косуха біла: стильний та елегантний вибір для вашого гардеробу. Виготовлена з натуральної шкіри, ця косуха додає впевненості та шику будь-якому образу\n\n',
    seoTitleEn: 'White Women Leather Jacket With Gloves - Got`s Label',
    seoTitlePl: 'Kurtka Skórzana Białа z Rękawiczkami - Got`s Label',
    seoTitleUa: 'Косуха жіноча біла з рукавичками - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with gloves',
    titlePl: 'Kurtka z rękawiczkami ',
    titleUa: 'Косуха з рукавичками',
  },
  2024510144456: {
    article: '0001',
    category: 'jackets',
    characteristicEn: 'A jacket with gloves made of natural smooth leather.',
    characteristicPl: 'Kurtka z rękawiczkami z naturalnej gładkiej skóry.',
    characteristicUa: 'Косуха з рукавичками з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 106 cm\nBack length: 47 cm\nSleeve length from neck: 84 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 106 cm\nDługość tyłu: 47 cm\nDługość rękawa od szyi: 84 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 106 см \nДовжина по спині: 47 см \nДовжина рукава від горловини: 84 см \n\nЗріст моделі: 175 см",
    h1en: 'Black leather jacket women ',
    h1pl: 'Kurtka skórzana damska czarnа',
    h1ua: 'Жіноча шкіряна куртка чорна з рукавичками',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flxzsrajf-5_ZEN_8083.jpeg?alt=media&token=e3125395-c040-47ee-9294-7838ecd8be69',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ferbqvwav-IMG_6598.jpg?alt=media&token=fde3dd19-84ad-493f-b998-f52e7fcf4181',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fr2wlot35-6_ZEN_8085.jpeg?alt=media&token=14c5db3e-f2a0-49b8-83a5-e0e447736c37',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsjdubsgh-IMG_6599.jpg?alt=media&token=03ea3742-8978-4665-9404-184a8e9874d3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdqjwixq3-IMG_6600.jpg?alt=media&token=7f75d674-6f2b-4455-8df0-fe4720c131ee',
    ],
    interest: '',
    newSale: '',
    priceEn: '800',
    priceEnSale: '',
    priceUa: '36000',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your style with our premium black leather jackets for women. Timeless, sleek, and versatile, these jackets are the ultimate addition to any chic wardrobe.',
    seoDescriptionPl:
      'Odkryj naszą kolekcję i wyróżnij się z tłumu dzięki naszej czarnej kurtce skórzanej oversize. Stylowa, wygodna i idealna na każdą okazję. Sprawdź teraz!',
    seoDescriptionUa:
      'Шкіряна косуха чорна: класичний стиль та бездоганна якість. Натуральна шкіра, сучасний дизайн та універсальність роблять її ідеальним вибором для будь-якого гардеробу.\n\n',
    seoTitleEn: 'Black Women Leather Jacket With Gloves - Got`s Label',
    seoTitlePl: 'Kurtka Skórzana Czarnа z Rękawiczkami - Got`s Label',
    seoTitleUa: 'Косуха жіноча чорна з рукавичками - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with gloves',
    titlePl: 'Kurtka z rękawiczkami ',
    titleUa: 'Косуха з рукавичками',
  },
  2024510144458: {
    article: '0022',
    category: 'jackets',
    characteristicEn: 'Jacket with cones made of a smooth leather.',
    characteristicPl: 'Kurtka ze stożkami wykonana z naturalnej gładkiej skóry.',
    characteristicUa: 'Косуха з конусами з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'white',
    descriptionEn: '-',
    descriptionPl: '-',
    descriptionUa: '-',
    h1en: 'White leather jacket womens',
    h1pl: 'Biała kurtka skórzana damska',
    h1ua: 'Косуха жіноча оверсайз з конусами',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fslasw43z-38_ZEN_1945.jpeg?alt=media&token=6920ed0d-de0c-435f-b102-b4443900a3dd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2w9tcvmy-9_ZEN_1884.jpeg?alt=media&token=c9c2377f-ed77-4ed6-a00b-04f15db16677',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fszvpmgrm-13_ZEN_1890.jpeg?alt=media&token=bda1b976-a291-47f0-826d-3218b7a61b98',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqgbj0kas-15_ZEN_1892.jpeg?alt=media&token=b531d1d5-675b-4954-9cea-644fe66f0bf8',
    ],
    interest: '',
    newSale: '',
    priceEn: '600',
    priceEnSale: '',
    priceUa: '27000',
    priceUaSale: '',
    seoDescriptionEn:
      'Upgrade your wardrobe with a stylish white leather jacket. Perfect for a chic, modern look, this timeless piece adds elegance and versatility to any outfit.',
    seoDescriptionPl:
      'Dodaj odrobinę rockowego stylu do swojej garderoby z naszą białą ramoneską skórzaną. Modna, elegancka i idealna na każdą okazję. Sprawdź teraz!',
    seoDescriptionUa:
      'Жіноча куртка косуха: стильний акцент для вашого гардеробу. Виготовлена з високоякісної шкіри, ця косуха поєднує елегантність та сучасний дизайн, підкреслюючи вашу індивідуальність.\n\n',
    seoTitleEn: 'White Leather Jacket With Cones - Got`s Label',
    seoTitlePl: 'Biała Skórzana Ramoneska ze Stożkami - Got`s Label',
    seoTitleUa: 'Косуха жіноча оверсайз з конусами - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with cones',
    titlePl: 'Kurtka ze stożkami',
    titleUa: 'Косуха з конусами',
  },
  2024510144500: {
    article: '0003',
    category: 'jackets',
    characteristicEn: 'A short jacket with two buckles on the sleeves and a belt made of natural leather.',
    characteristicPl: 'Skrócona kurtka z dwoma klamrami na rękawach i paskiem, wykonana ze skóry naturalnej.\n',
    characteristicUa: 'Коротка косуха з натуральної шкіри з двома пряжками на рукавах та поясом.',
    collection: 'jackets',
    color: 'brown',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 100 cm\nBack length: 45 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 100 cm\nDługość tyłu:  45 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 100 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см",
    h1en: 'Brown leather jacket oversized',
    h1pl: 'Ramoneska damska skórzana brązowa',
    h1ua: 'Коричнева шкіряна косуха з двома пряжками на рукавах',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fffzduw9q-744_ZEN_1985.jpeg?alt=media&token=e09ef4fd-b8fc-4d3f-ae99-c3bf3988976b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0ua5sak0-540_ZEN_0951.jpeg?alt=media&token=6a6657b7-55c9-4511-b6fe-8234a3f66fe3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffbpj1lwc-743_ZEN_1981.jpeg?alt=media&token=606f2e89-eaf9-47f5-bab6-e1c21c1380db',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fy970sf5l-529_ZEN_0896.jpeg?alt=media&token=6b413240-cbdd-4af1-ade3-c017f2baca2a',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Shop now to buy the perfect brown leather jacket. Timeless, versatile, and crafted for quality, this essential piece elevates any wardrobe with classic style.',
    seoDescriptionPl:
      'Odkryj klasyczny styl z nutą rocka dzięki naszej brązowej ramonesce skórzanej. Elegancka, modna i idealna na każdą okazję. Zobacz naszą ofertę!',
    seoDescriptionUa:
      'Купити коричневу жіночу косуху: елегантність та стиль у кожній деталі. Виготовлена з натуральної шкіри, ця косуха стане ідеальним доповненням до вашого гардеробу, підкреслюючи вашу індивідуальність.\n\n',
    seoTitleEn: "Women's Brown Leather Jacket With Two Buckles On Sleeves - Got`s Label",
    seoTitlePl: 'Ramoneska Skórzana Brązowa Z Dwoma Klamrami Na Rękawach I Paskiem',
    seoTitleUa: 'Коричнева косуха з двома пряжками на рукавах - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with two buckles on sleeves',
    titlePl: 'Kurtka z dwoma klamrami na rękawach',
    titleUa: 'Косуха з двома пряжками на рукавах',
  },
  2024510144502: {
    article: '0004',
    category: 'jackets',
    characteristicEn: 'A short jacket with flowers in chest made of a natural leather.',
    characteristicPl: 'Krótka kurtka z kwiatami na piersi, wykonana z naturalnej skóry',
    characteristicUa: 'Коротка косуха з натуральної шкіри з квітами на грудях.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 94 cm\nBack length: 45 cm\nSleeve length from neck: 75 cm\n\nModel height: 174 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 174 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 94 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 174 см",
    h1en: 'White leather jacket women',
    h1pl: 'Białа kurtka skórzana ramoneska damska',
    h1ua: 'Білий жакет оверсайз з квітами ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fiky5ztbn-C0000650.jpg?alt=media&token=dadc024a-6289-40a3-9a28-80952f1173de',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fyxtn8m2g-C0000745.jpeg?alt=media&token=47f8ece1-793e-4816-9564-a0b0a3536383',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnmvaxx5h-C0000672.jpg?alt=media&token=3a7cebc3-5ba6-49af-bc42-46f0d54b37d4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fo0q7cx2w-C0000633.jpeg?alt=media&token=81972be1-0725-440f-9e4e-e5ef9acd7724',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your fashion game with a white excelled leather jacket. Luxuriously crafted and stylishly refined, this standout piece offers unparalleled elegance and versatility.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą białą skórzaną kurtką damską. Elegancka, modna i idealna na każdą okazję. Odkryj naszą kolekcję!\n\n',
    seoDescriptionUa:
      'Білий жіночий жакет: елегантність та універсальність у кожній деталі. Оберіть цей жакет для стильного акценту у вашому гардеробі та створіть бездоганний образ на будь-яку подію.\n\n',
    seoTitleEn: 'Cute White Leather Jacket With Flowers - Got`s Label',
    seoTitlePl: 'Skórzana Kurtka Damska Białа Z Kwiatam  - Got`s Label',
    seoTitleUa: 'Білий жакет з квітами - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with flowers',
    titlePl: 'Kurtka z kwiatami',
    titleUa: 'Жакет з квітами ',
  },
  2024510144504: {
    article: '0004',
    category: 'jackets',
    characteristicEn: 'A short jacket with flowers in chest made of a natural leather.',
    characteristicPl: 'Krótka kurtka z kwiatami na piersi, wykonana z naturalnej skóry',
    characteristicUa: 'Коротка косуха з натуральної шкіри з квітами на грудях.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:  \n\nChest volume: 94 cm \nBack length: 45 cm \nSleeve length from neck: 75 cm  \n\nModel height: 174 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 174 cm',
    descriptionUa:
      "Параметри косухи:   \n\nОб'єм грудей: 94 см  \nДовжина по спині: 45 см  \nДовжина рукава від горловини: 75 см   \n\nЗріст моделі: 174 см",
    h1en: 'Black leather jacket women',
    h1pl: 'Czarnа kurtka skórzana ramoneska damska',
    h1ua: 'Жакет жіночий чорний оверсайз з квітами  ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Feax3tce2-C0001022.jpg?alt=media&token=de08d459-6558-4e9f-99b4-da3ad04729ec',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjczma3vu-C0001063.jpg?alt=media&token=9b255d5b-1e41-47bf-9de6-8aa94ade8657',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Furzd55vf-C0000874.jpeg?alt=media&token=3793dbd9-21f5-49c0-b269-f683426f42b2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhvimbaky-C0001040.jpg?alt=media&token=7bd21cfe-8785-496a-b660-fbd5e9efa010',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnj2gkosf-8T1A9490.jpg?alt=media&token=6e14bec5-fa7c-4880-bb33-bfcb2a9acc18',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Discover the ultimate in sophistication with a black excelled leather jacket. Expertly crafted for a sleek, modern look, this jacket offers unmatched style and durability for any occasion.',
    seoDescriptionPl:
      'Dodaj klasyczny akcent do swojej garderoby z naszą czarną skórzaną kurtką damską. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Жакет жіночий купити: елегантний та стильний вибір для будь-якого гардеробу. Знайдіть ідеальний жакет для підкреслення вашого стилю та створення бездоганних образів.\n\n',
    seoTitleEn: 'Cute Black Leather Jacket With Flowers - Got`s Label',
    seoTitlePl: 'Skórzana Kurtka Damska Czarnа Z Kwiatam - Got`s Label',
    seoTitleUa: 'Чорний  жакет з квітами - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with flowers',
    titlePl: 'Kurtka z kwiatami',
    titleUa: 'Жакет з квітами ',
  },
  2024510144506: {
    article: '0005',
    category: 'jackets',
    characteristicEn: 'Base oversize jacket made of a vintage leather.',
    characteristicPl: 'Podstawowa kurtka oversize z naturalnej skóry vintage.',
    characteristicUa: 'Базова оверсайз косуха з натуральної вінтажної шкіри',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 53 cm\nSleeve length from neck: 76 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 175 см",
    h1en: 'Classic blue leather jacket',
    h1pl: 'Błękit  ramoneska skórzana damska oversize',
    h1ua: 'Базова оверсайз косуха синя',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F7m41ns6m-97_ZEN_8574%202.jpeg?alt=media&token=9583a8a9-01d4-4c94-9b11-04ef882ffbf9',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnodlhrxi-93_ZEN_8548.jpeg?alt=media&token=a19b77cc-1a01-4203-b2ee-795db18c8d5b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fly2xom5o-88_ZEN_8523.jpeg?alt=media&token=9e98721e-023e-45ea-9264-23d12e1275fd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F7w6o73bn-81_ZEN_8449.jpeg?alt=media&token=0730c3de-0c22-4e0c-9ffa-29a40af1eae4',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a bold fashion statement with an oversized blue leather jacket for women. This trendy, eye-catching piece offers a chic, relaxed fit and vibrant color, perfect for adding a modern twist to your wardrobe.',
    seoDescriptionPl:
      'Dodaj odrobinę koloru do swojej garderoby z naszą błękitną ramoneską skórzaną oversize. Modna, wygodna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити синю шкіряну куртку жіночу: стильний вибір для вашого гардеробу. Додайте елегантності та сучасного вигляду до будь-якого образу з нашою якісною шкіряною курткою.\n\n',
    seoTitleEn: 'Base Blue Leather Jacket Oversized - Got`s Label',
    seoTitlePl: 'Błękit Ramoneska Skórzana Oversize - Got`s Label',
    seoTitleUa: 'Базова оверсайз косуха синя - Got`s Label',
    skin: 'vintage',
    titleEn: 'Base oversize jacket',
    titlePl: 'Podstawowa kurtka oversize ',
    titleUa: 'Базова оверсайз косуха',
  },
  2024510144508: {
    article: '0021',
    category: 'jackets',
    characteristicEn: 'A transformer-jacket made of a natural vintage leather.',
    characteristicPl: 'Kurtka-transformer z naturalnej skóry vintage.',
    characteristicUa: 'Косуха-трансформер з натуральної вінтажної шкіри.',
    collection: 'jackets',
    color: 'vintage_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 98 cm\nBack length: 57 cm\nSleeve length from neck: 72 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 98 cm\nDługość tyłu: 57 cm\nDługość rękawa od szyi: 72 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 98 см \nДовжина по спині: 57 см \nДовжина рукава від горловини: 72 см \n\nЗріст моделі: 175 см",
    h1en: 'Leather black jacket womens',
    h1pl: 'Ramoneska czarna skórzana',
    h1ua: 'Шкіряна косуха жіноча чорна трансформер',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5whrnsz1-443_ZEN_3432.jpeg?alt=media&token=30f398ba-2d96-4ee6-83a0-05e0f4f02b08',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxfw5w870-437_ZEN_3405.jpeg?alt=media&token=b724a723-e093-4dff-a592-b2c3d57aa3b0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fi2gl28fq-428_ZEN_3346.jpeg?alt=media&token=c0aeb088-3cc8-468b-9f90-abd3c33e9007',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fx0oplx1v-419_ZEN_3316.jpeg?alt=media&token=cc0e33d6-c04e-426a-9b4d-f60047b273b7',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Refresh your style with a light black leather jacket. Combining a subtle, versatile hue with sleek design, this modern piece adds a refined touch to any outfit, perfect for both casual and polished looks.',
    seoDescriptionPl:
      'Dodaj odrobinę klasyki do swojej stylizacji z naszą czarną ramoneską skórzaną. Modna, elegancka i idealna na każdą okazję. Zobacz naszą ofertę!',
    seoDescriptionUa:
      'Купити косуху жіночу чорну: класичний стиль і сучасний дизайн для вашого гардеробу. Високоякісна шкіра та елегантний крій підкреслять вашу індивідуальність і додадуть розкішного вигляду.\n\n',
    seoTitleEn: 'Black Leather Transformer Jacket - Got`s Label',
    seoTitlePl: 'Czarna Skórzana Ramoneska Transformer - Got`s Label',
    seoTitleUa: 'Шкіряна косуха трансформер чорна - Got`s Label',
    skin: 'vintage',
    titleEn: 'Transformer jacket ',
    titlePl: 'Kurtka-transformer ',
    titleUa: 'Косуха трансформер',
  },
  2024510144510: {
    article: '0006',
    category: 'jackets',
    characteristicEn: 'A jacket made of natural leather with an elongated back.',
    characteristicPl: 'Kurtka wykonana ze skóry naturalnej z wydłużonym tyłem.',
    characteristicUa: 'Косуха з натуральної шкіри з подовженою спинкою.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 112 cm\nBack length: 62 cm\nSleeve length from neck: 81 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 112 cm\nDługość tyłu: 62 cm\nDługość rękawa od szyi: 81 cm\n\nWzrost modelki: 175 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 112 см \nДовжина по спині: 62 см \nДовжина рукава від горловини: 81 см \n\nЗріст моделі: 175 см",
    h1en: 'White leather jacket',
    h1pl: 'Białа ramoneska skórzana damska',
    h1ua: 'Натуральна шкіряна куртка жіноча біла з подовженою спинкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fpivx0eif-718_ZEN_1817.jpeg?alt=media&token=2ff5887a-beb9-4ec2-9883-152b5c095cc2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffqi90qz6-714_ZEN_1805.jpeg?alt=media&token=4e66c5de-ec47-452c-9e06-da4e2d773687',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwfku9p6g-720_ZEN_1823.jpeg?alt=media&token=46636d5c-8ee0-4da7-a1f8-dd69ab1dca24',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fml4y31sk-194_ZEN_9131.jpeg?alt=media&token=2107afec-1d61-494d-9c53-8540677a09da',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3qwtcahv-290_ZEN_9833.jpeg?alt=media&token=4e237fb9-dda2-412a-8514-deb359a6a63e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsehrf019-IMG_1665.png?alt=media&token=9beb9e8d-ea4b-4ce5-84d0-452c339496af',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9pamhkox-IMG_1666.png?alt=media&token=88b2ede5-4658-4de0-afe8-ed9edc980029',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Transform your wardrobe with a white leather jacket. Combining elegance and versatility, this chic piece adds a clean, contemporary edge to any outfit, perfect for a standout look.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o styl z naszą białą skórzaną kurtką oversize. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити стильну жіночу куртку білу: додайте елегантності та сучасного вигляду вашому гардеробу. Високоякісні матеріали та бездоганний дизайн підкреслять вашу індивідуальність.\n\n',
    seoTitleEn: 'Jacket White With An Elongated Back - Got`s Label',
    seoTitlePl: 'Skórzana Białа Ramoneska Z Wydłużonym Tyłem - Got`s Label',
    seoTitleUa: 'Жіноча куртка біла з подовженою спинкою - Got`s Label',
    skin: 'smooth',
    titleEn: 'A jacket with an elongated back',
    titlePl: 'Kurtka z wydłużonym tyłem',
    titleUa: 'Косуха з подовженою спинкою',
  },
  2024510144512: {
    article: '0003',
    category: 'jackets',
    characteristicEn: 'A short jacket with two buckles on the sleeves and a belt made of natural leather.',
    characteristicPl: 'Skrócona kurtka ze skóry naturalnej z klamrami na rękawach i paskiem',
    characteristicUa: 'Коротка косуха з натуральної шкіри з двома пряжками на рукавах та поясом.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 100 cm\nBack length: 45 cm\nSleeve length from neck: 79 cm\n\nModel height: 167 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 100 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 167 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 100 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 167 см",
    h1en: 'White leather jacket oversized',
    h1pl: 'Ramoneska damska skórzana białа',
    h1ua: 'Біла шкіряна косуха  з двома пряжками на рукавах',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fq1stbn5n-sehrf019-IMG_1665.webp?alt=media&token=e72225ab-ee50-4ab3-9575-3b82613de5fc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flyn6a4em-IMG_0896.png?alt=media&token=9033fc16-2b61-4711-93ec-306267f2b49d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fejm1qgi6-IMG_1906.jpeg?alt=media&token=c78f6c62-5e8d-43db-9cc3-1a5b481fccb7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fe3kbfigh-IMG_1873.jpeg?alt=media&token=7551cd6c-40ca-42bf-bb4d-b9a4a12d09a9',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fk9m7aoub-IMG_1898.jpeg?alt=media&token=3e761ec3-defc-42d2-859d-40afe1dd8a95',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzbmdcqt5-IMG_1917.jpeg?alt=media&token=65475a88-65cb-4301-b641-4f71fc67ec53',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Buy your perfect white leather jacket today. Sleek, stylish, and versatile, this wardrobe essential adds a touch of elegance to any outfit.',
    seoDescriptionPl:
      'Dodaj wyjątkowy akcent do swojej stylizacji z naszą białą ramoneską skórzaną. Modna, elegancka i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити жіночу косуху: стильний акцент вашого образу. Оберіть ідеальну косуху з нашого асортименту, щоб підкреслити свою індивідуальність.\n\n',
    seoTitleEn: "Women's White Leather Jacket With Two Buckles On Sleeves - Got`s Label",
    seoTitlePl: 'Ramoneska Skórzana Białа Z Dwoma Klamrami Na Rękawach I Paskiem',
    seoTitleUa: 'Біла косуха з двома пряжками на рукавах - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with two buckles on sleeves',
    titlePl: 'kurtka z klamrami na rękawach',
    titleUa: 'Косуха з двома пряжками на рукавах',
  },
  2024510144514: {
    article: '0007',
    category: 'jackets',
    characteristicEn: 'A classic elongated khaki jacket made of natural vintage leather.',
    characteristicPl: 'Klasyczna wydłużona kurtka z naturalnej skóry vintage w kolorze khaki.',
    characteristicUa: 'Класична подовжена косуха з натуральної вінтажної шкіри кольору хакі.',
    collection: 'jackets',
    color: 'khaki',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 108 cm\nBack length: 58 cm\nSleeve length from neck: 77 cm\n\nModel height: 167 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 108 cm\nDługość tyłu: 58 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 167 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 108 см \nДовжина по спині: 58 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 167 см",
    h1en: 'Brown womens leather jacket',
    h1pl: 'Brązowa kurtka skórzana damska długa',
    h1ua: 'Коричнева косуха подовжена класична',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0s6lcwa8-IMG_0892.png?alt=media&token=d4b91f15-82eb-4f3f-8ac5-24b21dc93927',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F31sw4rku-IMG_3731.jpeg?alt=media&token=bf5f7adb-8ba7-4245-a0a7-502a5537e129',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fc275pfgg-IMG_3907.jpeg?alt=media&token=56d0e3b3-6d59-4dd3-97c7-b6534754ed26',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8k5vbiq6-IMG_3691.jpeg?alt=media&token=b58f29c5-bcbf-475e-8a03-5d3cd6fb2b78',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnhacp8s3-IMG_3744.jpeg?alt=media&token=a53dce2f-3545-4e0a-b6d8-b963b91b3b8b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3qn1jvjr-IMG_3903.jpeg?alt=media&token=98ab2bb9-d08c-4697-8edb-e207adb9fa33',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Discover the timeless appeal of a brown leather jacket. Classic and versatile, this essential piece adds a touch of rugged elegance to any wardrobe.',
    seoDescriptionPl:
      'Dodaj odrobinę elegancji do swojej garderoby z naszą brązową skórzaną kurtką damską oversize. Modna, wygodna i doskonała na każdą okazję. Zobacz naszą ofertę!',
    seoDescriptionUa:
      'Коричнева косуха куртка: стильний акцент вашого образу. Виготовлена з якісних матеріалів, ця косуха-куртка додасть елегантності та сучасного вигляду будь-якому наряду.\n\n',
    seoTitleEn: 'Brown Leather Jacket A Classic Elongated - Got`s Label',
    seoTitlePl: 'Damska Wydłużona Kurtka Skórzana Brązowa - Got`s Label',
    seoTitleUa: 'Класична подовжена косуха коричнева - Got`s Label',
    skin: 'vintage',
    titleEn: 'Classic elongated jacket',
    titlePl: 'Klasyczna wydłużona kurtka ',
    titleUa: 'Косуха подовжена класична',
  },
  2024510144516: {
    article: '0007',
    category: 'jackets',
    characteristicEn: 'A classic elongated white jacket made of natural leather.',
    characteristicPl: 'Klasyczna wydłużona kurtka z naturalnej skóry w kolorze białym.',
    characteristicUa: 'Класична подовжена косуха з натуральної шкіри білого кольору.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 108 cm\nBack length: 58 cm\nSleeve length from neck: 77 cm\n\nModel height: 169 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 108 cm\nDługość tyłu: 58 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 169 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 108 см \nДовжина по спині: 58 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 169 см",
    h1en: 'White womens leather jacket',
    h1pl: 'Białа kurtka skórzana damska długa',
    h1ua: 'Біла косуха подовжена класична',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0jvk0wux-IMG_0994.png?alt=media&token=5d16e13b-a4ab-48fc-9b62-3426f543786a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Forzgay2e-316%20zen%20-%207766.jpeg?alt=media&token=78a9185b-4281-45e7-a16e-b170aed21692',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fw15980h6-299%20zen%20-%207671.jpeg?alt=media&token=64966f82-9d9d-4fd3-8b5c-aafbc3fbf344',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5wg14e7r-303%20zen%20-%207690.jpeg?alt=media&token=ecef463f-afed-4b14-8062-a11cdeabbe10',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ft14k8x9u-306%20zen%20-%207709.jpeg?alt=media&token=406be957-e7fe-45d0-87e6-69d16d62f31c',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Embrace elegance with a white leather jacket. Chic and versatile, this stylish piece adds a crisp, modern edge to any ensemble, making it a standout wardrobe staple.',
    seoDescriptionPl:
      'Podkreśl swój wyjątkowy styl z naszą białą skórzaną kurtką damską oversize. Elegancka, nowoczesna i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Біла косуха куртка: символ стилю та елегантності. Оберіть цю косуху-куртку, щоб підкреслити вашу неповторність та додати вишуканості до вашого образу.\n\n',
    seoTitleEn: 'White Leather Jacket A Classic Elongated - Got`s Label',
    seoTitlePl: 'Damska Wydłużona Kurtka Skórzana Białа - Got`s Label',
    seoTitleUa: 'Класична подовжена косуха біла - Got`s Label',
    skin: 'smooth',
    titleEn: 'Classic elongated jacket',
    titlePl: 'Klasyczna wydłużona kurtka ',
    titleUa: 'Косуха подовжена класична',
  },
  2024510144518: {
    article: '0007',
    category: 'jackets',
    characteristicEn: 'A classic elongated blue jacket made of natural vintage leather.',
    characteristicPl: 'Klasyczna wydłużona kurtka z naturalnej skóry vintage w kolorze niebieskim.\n',
    characteristicUa: 'Класична подовжена косуха з натуральної вінтажної шкіри голубого кольору.',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 108 cm\nBack length: 58 cm\nSleeve length from neck: 77 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 108 cm\nDługość tyłu: 58 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 108 см \nДовжина по спині: 58 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 175 см",
    h1en: 'Sky blue womens leather jacket',
    h1pl: 'Niebieski kurtka skórzana damska długa',
    h1ua: 'Блакитна косуха подовжена класична',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fi6cb5r74-IMG_0891.png?alt=media&token=eeb912e0-32b8-4a9b-ae47-11db5fc14f40',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0zeu5n0l-207%20%20zen%20grain%20-%207216_.jpeg?alt=media&token=cc4d9d98-3977-426b-ae20-c1b4fcf8665a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvdv81mpe-216%20%20zen%20grain%20-%207261_.jpeg?alt=media&token=5e07360d-d8e5-46f3-8a84-42455160ac13',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fx0gy53av-215%20%20zen%20grain%20-%207255_.jpeg?alt=media&token=32fa41d5-e094-426e-be4e-fcf02150dc81',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fshs7hnk3-211%20%20zen%20grain%20-%207234_.jpeg?alt=media&token=e6ad73e3-f80e-42d6-8657-4bf40640cd16',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Stand out in style with a sky blue leather jacket. This vibrant and unique piece adds a fresh, modern twist to your wardrobe, perfect for making a bold fashion statement.',
    seoDescriptionPl:
      'Odkryj odświeżający styl z naszą niebieską skórzaną kurtką damską oversize. Modna, wygodna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Блакитна косуха куртка: стильний акцент вашого гардеробу. Оберіть цю косуху-куртку, щоб додати яскравого кольору та модного шарму до вашого образу.\n\n',
    seoTitleEn: 'Sky Blue Leather Jacket A Classic Elongated - Got`s Label',
    seoTitlePl: 'Damska Wydłużona Kurtka Skórzana Niebieski - Got`s Label',
    seoTitleUa: 'Класична подовжена косуха блакитна - Got`s Label',
    skin: 'vintage',
    titleEn: 'Classic elongated jacket',
    titlePl: 'Klasyczna wydłużona kurtka ',
    titleUa: 'Косуха подовжена класична',
  },
  2024510144520: {
    article: '0008',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with large pockets and neck stand, with vintage copper hardware\n',
    characteristicPl: 'Kurtka oversize z dużymi kieszeniami i stójką z miedzianą galanterią, z naturalnej skóry vintage',
    characteristicUa: 'Оверсайз косуха з великими кишенями та стійкою, з вінтажною фурнітурою кольору міді',
    collection: 'jackets',
    color: 'brown',
    descriptionEn:
      "Параметри косухи: \n\nОб'єм грудей: 120 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 173 см",
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 120 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 120 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 173 см",
    h1en: 'Burgundy leather jacket oversized',
    h1pl: 'Bordowa kurtka damska skórzana',
    h1ua: 'Бордо куртка жіноча шкіряна з великими кишенями і стійкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3g6cbwr1-C0000174.jpg?alt=media&token=8163b818-4687-4886-bc6a-0490cd2ed015',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fei1l2yag-C0000203.jpg?alt=media&token=a6e8b763-84aa-44bf-a562-f0a261057123',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fpqac7jtd-C0000177.jpg?alt=media&token=3b8ec32c-1202-40c0-850c-571339c6463a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F02euw049-C0000248.jpeg?alt=media&token=6ee79495-ff25-485d-be95-fb2e3cf596d0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F57tjz1mq-8T1A8036.jpg?alt=media&token=452388f1-846e-4b21-95be-de0d11b3cb5c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftwjv6yce-8T1A8091.jpg?alt=media&token=7335aa08-b32a-44f1-9e86-015adbc7d21d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbh16h1j3-8T1A8176.jpg?alt=media&token=9aae88b2-6663-4f24-a87c-8ee667bf58cc',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your wardrobe with a burgundy leather jacket for women. Rich and stylish, this bold piece adds a touch of luxury and sophistication, perfect for making a striking fashion statement.',
    seoDescriptionPl:
      'Dodaj odrobinę elegancji i charakteru do swojej garderoby z naszą bordową kurtką damską skórzaną. Stylowa, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Косуха оверсайз бордо: стильний вибір для модних образів. Додайте розкішний колір та сучасний дизайн до вашого гардеробу з цією елегантною косухою.\n\n',
    seoTitleEn: 'Oversized Burgundy Leather Jacket With Large Pockets And Neck Stand',
    seoTitlePl: 'Bordowa Skórzana Kurtka Oversize - Got`s Label',
    seoTitleUa: 'Бордо косуха oversize з великими кишенями і стійкою - Got`s Label ',
    skin: 'jumbo',
    titleEn: 'Oversized jacket with large pockets and neck stand',
    titlePl: 'Kurtka oversize z dużymi kieszeniami i stójką',
    titleUa: 'Косуха oversize з великими кишенями і стійкою',
  },
  2024510144522: {
    article: '0009',
    category: 'bombers',
    characteristicEn: 'Bomber with pockets on the arms and chest made of natural vintage leather.',
    characteristicPl: 'Bomber z kieszeniami na rękawach i na piersi, wykonana z naturalnej skóry vintage',
    characteristicUa: 'Бомбер з кишенями на руках та грудях з натуральної вінтажної шкіри.',
    collection: 'jackets',
    color: 'vintage_grey',
    descriptionEn: 'Measurements:\n\nChest volume: 96 cm\nBack length: 48 cm\nSleeve length from neck: 75 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry:\n\nObwód klatki piersiowej: 96 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри: \n\nОб'єм грудей: 96 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 175 см",
    h1en: 'Grey leather bomber jacket',
    h1pl: 'Skórzana bomberka damska',
    h1ua: 'Графітова куртка бомбер з кишенями на руках та грудях',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgfau98el-IMG_0890.png?alt=media&token=0dcc2826-4250-453e-9cb8-4af97ffa6add',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F12t415z8-tempImage0uU2HK.jpg?alt=media&token=ccbdfbad-45f2-41f8-8472-4e1ccd248b66',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fuqvsxdrs-IMG_9793.jpeg?alt=media&token=04d0436b-f6c6-4fbc-8a0c-b80b36684ed3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fma3sh3u8-tempImageQYkxWv.jpg?alt=media&token=ce870d81-260d-48cb-8b69-524f77698a7a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fw28ej8ml-IMG_9791.jpeg?alt=media&token=e84dcf8d-ed7c-47c3-89b4-5a32546ab705',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fulxv3j8u-IMG_9789.jpeg?alt=media&token=c0c76398-442b-4e7e-8a56-457ec935426e',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Upgrade your style with a grey leather bomber jacket. This sleek, modern piece combines a classic bomber design with a sophisticated grey hue, offering a versatile and contemporary edge to any outfit.',
    seoDescriptionPl:
      'Dodaj nowoczesnego stylu do swojej garderoby z naszą szarą bomberką. Modna, wszechstronna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Графітовий шкіряний бомбер: сучасний та стильний вибір для вашого гардеробу. Додайте елегантності та міського шику до вашого образу з цією якісною шкіряною курткою.\n\n',
    seoTitleEn: 'Grey Leather Bomber With Pockets On The Arms And Chest  - Got`s Label',
    seoTitlePl: 'Szara Bomberka Z Kieszeniami Na Rękawach I Na Piersi',
    seoTitleUa: 'Графітова куртка бомбер з кишенями на руках та грудях - Got`s Label',
    skin: 'vintage',
    titleEn: 'Bomber with pockets on the arms and chest',
    titlePl: 'Bomber z kieszeniami na rękawach i na piersi',
    titleUa: 'Бомбер з кишенями на руках та грудях ',
  },
  2024510144524: {
    article: '0009',
    category: 'bombers',
    characteristicEn: 'Bomber with pockets on the arms and chest made of natural leather.',
    characteristicPl: 'Bomber z kieszeniami na rękawach i na piersi, wykonana z naturalnej skóry.',
    characteristicUa: 'Бомбер з кишенями на руках та грудях з натуральної шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn: 'Measurements:\n\nChest volume: 96 cm\nBack length: 48 cm\nSleeve length from neck: 75 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry:\n\nObwód klatki piersiowej: 96 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри: \n\nОб'єм грудей: 96 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 175 см",
    h1en: 'Black leather bomber jacket',
    h1pl: 'Czarnа skórzana bomberka damska',
    h1ua: 'Чорна куртка бомбер з кишенями на руках та грудях',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbu4o1uxi-112_ZEN_8660.jpeg?alt=media&token=4555817d-7121-473b-9868-b034b20fd879',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1dwj62xb-113_ZEN_8665.jpeg?alt=media&token=c2d6e6b2-6cf3-4844-b800-b7c7a0d08166',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1kdgmr0w-102_ZEN_8608.jpeg?alt=media&token=74642ba0-c340-45e0-8912-c7ef5080bf57',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fd146831m-122_ZEN_8706.jpeg?alt=media&token=b5e80b5f-7e1b-45fe-8360-5aa83c9a7000',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F89n3ffp5-101_ZEN_8603.jpeg?alt=media&token=15d7f989-cc04-4282-b2e7-d2e594b5e314',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F52izqn86-132_ZEN_8757.jpeg?alt=media&token=14af0fa9-166e-4965-a73e-c4f94df78e21',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Revitalize your wardrobe with a black leather bomber jacket. This iconic piece combines timeless design with a sleek black finish, adding a stylish and versatile edge to any outfit.',
    seoDescriptionPl:
      'Wzbogać swoją stylizację z naszą czarną bomberką. Klasyczna, elegancka i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Чорний шкіряний бомбер: класичний і стильний вибір для вашого гардеробу. Високоякісна шкіра та елегантний дизайн додадуть сучасного вигляду та витонченості вашому образу.\n\n',
    seoTitleEn: 'Black Leather Bomber With Pockets On The Arms And Chest  - Got`s Label',
    seoTitlePl: 'Czarnа Bomberka Z Kieszeniami Na Rękawach I Na Piersi',
    seoTitleUa: 'Чорна куртка бомбер з кишенями на руках та грудях - Got`s Label',
    skin: 'smooth',
    titleEn: 'Bomber with pockets on the arms and chest',
    titlePl: 'Bomber z kieszeniami na rękawach i na piersi',
    titleUa: 'Бомбер з кишенями на руках та грудях ',
  },
  2024510144526: {
    article: '0001',
    category: 'jackets',
    characteristicEn: 'A jacket with gloves made of natural leather.',
    characteristicPl: 'Kurtka z rękawiczkami z naturalnej skóry.',
    characteristicUa: 'Косуха з рукавичками з натуральної шкіри.',
    collection: 'jackets',
    color: 'grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 106 cm\nBack length: 47 cm\nSleeve length from neck: 84 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 106 cm\nDługość tyłu: 47 cm\nDługość rękawa od szyi: 84 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 106 см \nДовжина по спині: 47 см \nДовжина рукава від горловини: 84 см \n\nЗріст моделі: 175 см",
    h1en: 'Grey leather jacket women ',
    h1pl: 'Kurtka skórzana damska szara',
    h1ua: 'Жіноча шкіряна куртка сіра з рукавичками',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frnthluis-IMG_0893.png?alt=media&token=b30d37f1-070c-4115-b71e-15210321954a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbz6efiu1-IMG_0894.png?alt=media&token=42a2fcf0-3771-4ce1-84a8-f08a5ce6c79c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwcuyue2e-tempImage9e388b.jpg?alt=media&token=c43a30a8-f467-4bd6-9e11-c2d003f3b133',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ful4qlu3d-tempImageSh8VV9.jpg?alt=media&token=2a8c6923-08f4-4d06-9e06-488e8f857702',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fn3tg3n5y-IMG_9744.jpeg?alt=media&token=1ea72d29-72be-4268-99ca-50354bf646cc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fb69kzvst-tempImagevLeOiq.jpg?alt=media&token=d5674f89-bc69-480f-ae45-5f0f6a558e45',
    ],
    interest: '',
    newSale: '',
    priceEn: '800',
    priceEnSale: '',
    priceUa: '36000',
    priceUaSale: '',
    seoDescriptionEn:
      'Step up your fashion game with our grey leather jackets for women. Sophisticated and versatile, these jackets offer a modern twist on a classic style for any occasion.',
    seoDescriptionPl:
      'Odkryj niezrównany styl i komfort z naszą szarą kurtką skórzaną oversize. Idealna na każdą okazję, modna i wyjątkowa. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Шкіряна косуха сіра: поєднання елегантності та сучасного стилю. Виготовлена з натуральної шкіри, ця косуха стане універсальним та стильним доповненням до вашого гардеробу.\n\n',
    seoTitleEn: 'Grey Women Leather Jacket With Gloves - Got`s Label',
    seoTitlePl: 'Kurtka Skórzana Szara z Rękawiczkami - Got`s Label',
    seoTitleUa: 'Косуха жіноча сіра з рукавичками - Got`s Label',
    skin: 'jumbo',
    titleEn: 'Jacket with gloves',
    titlePl: 'Kurtka z rękawiczkami ',
    titleUa: 'Косуха з рукавичками',
  },
  2024510144528: {
    article: '00023',
    category: 'dresses',
    characteristicEn: 'Dress-jacket made of a natural pink leather',
    characteristicPl: 'Sukienka-marynarka wykonana z różowej skóry naturalnej',
    characteristicUa: 'Плаття-піджак з рожевої натуральної шкіри',
    collection: 'jackets',
    color: 'pink',
    descriptionEn:
      'Measurements:\n\nChest volume: 102 cm\nBack length: 78 cm\nSleeve length from neck: 77 cm\nWaist volume: 81\nHips volume: 103\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 103 cm\nDługość tyłu: 78 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри: \n\nОб'єм грудей: 102 см \nОб'єм талії: 81\nОб'єм бедер: 103\nДовжина по спині: 78 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 175 см",
    h1en: ' Pink dress-jacket',
    h1pl: 'Sukienka-marynarka',
    h1ua: 'Рожева сукня-піджак',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqnr8lye4-169_ZEN_8962.jpeg?alt=media&token=69cc3d1a-836b-417f-8eb5-51065275a86b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F81rkzeg2-157_ZEN_8908.jpeg?alt=media&token=9838fe1e-5410-4e52-8c79-9b68ca8400fd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsngwhmok-156_ZEN_8905.jpeg?alt=media&token=8a9c48a1-1f4f-4905-bd4b-04ce1a6e18ae',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffzvfdx61-170_ZEN_8969.jpeg?alt=media&token=f5eba625-9280-459e-80e0-449bf1ced0c8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frbhy8hoj-163_ZEN_8932.jpeg?alt=media&token=ae33711e-9f80-4794-af52-1af0b1bd8c2d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F7fuik9w7-175_ZEN_9013.jpeg?alt=media&token=1aba565b-53ae-43de-ae68-47114023f789',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Pink jacket dress: a stylish choice for your wardrobe. Add a modern touch to your look with this trendy cropped jacket.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o elegancję z naszą sukienką-marynarką. Stylowa i uniwersalna, idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Рожева піджак сукня: стильний вибір для вашого гардеробу. Додайте сучасного вигляду до вашого образу з цією модною вкороченою косухою.\n\n',
    seoTitleEn: ' Dress-jacket - Got`s Label',
    seoTitlePl: 'Sukienka-marynarka - Got`s Label',
    seoTitleUa: 'Сукня піджак - Got`s Label',
    skin: 'smooth',
    titleEn: 'Dress-jacket',
    titlePl: 'Sukienka-marynarka ',
    titleUa: 'Сукня-піджак',
  },
  2024510144530: {
    article: '0024',
    category: 'jackets',
    characteristicEn: 'Short-sleeved jacket with details on the chest made of natural leather',
    characteristicPl: 'Kurtka z skróconymi rękawami i detalami na piersi, wykonana z naturalnej skóry',
    characteristicUa: 'Куртка з укороченими рукавами з деталями на грудях з натуральної шкіри',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 95 cm\nBack length: 45 cm\nSleeve length from neck: 53 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 95 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 53. cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 95 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 53 см \n\nЗріст моделі: 173 см",
    h1en: 'Black crop leather jacket',
    h1pl: 'Kurtka skórzana damska czarna',
    h1ua: 'Чорна косуха з укороченими рукавами з деталями на грудях ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fizrbdho7-683_ZEN_4470.jpeg?alt=media&token=a92cf612-cc44-4a2f-b35d-b19d391dc59f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwyv0nwuu-684_ZEN_4473.jpeg?alt=media&token=54a1b9bf-3eea-4bf3-960f-2a5cfdab3103',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fmrfsfiy8-686_ZEN_4477.jpeg?alt=media&token=62ae6405-70c8-41ab-93f2-873a46d369bb',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4xquf0b7-690_ZEN_4486.jpeg?alt=media&token=635b8a7f-9dbe-45ad-9b49-e15a3dcb6f27',
    ],
    interest: '',
    newSale: '',
    priceEn: '550',
    priceEnSale: '',
    priceUa: '24750',
    priceUaSale: '',
    seoDescriptionEn:
      'Indulge in the ultimate elegance with a luxury leather jacket. Crafted from the finest materials, this exquisite piece combines superior craftsmanship with timeless style for a sophisticated and enduring addition to your wardrobe.',
    seoDescriptionPl:
      'Wzbogać swoją stylizację o klasyczny akcent z naszą czarną skórzaną kurtką damską. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Жіноча куртка чорна: класичний та елегантний вибір для будь-якого гардеробу. Високоякісні матеріали та стильний дизайн додадуть вашому образу витонченості та універсальності.',
    seoTitleEn: 'Black Leather Jacket Women With Details On The Chest - Got`s Label',
    seoTitlePl: 'Czarna Skórzana Kurtka Z Skróconymi Rękawami I Detalami Na Piersi',
    seoTitleUa: 'Чорна косуха з укороченими рукавами з деталями на грудях - Got`s Label',
    skin: 'smooth',
    titleEn: 'Short-sleeved jacket with details on the chest',
    titlePl: 'Kurtka z skróconymi rękawami i detalami na piersi',
    titleUa: 'Куртка з укороченими рукавами з деталями на грудях',
  },
  2024510144532: {
    article: '0010',
    category: 'trench',
    characteristicEn: 'Trench-transformer made of natural leather, which turns into a jacket.',
    characteristicPl: 'Trencz-transformer z naturalnej skóry, który przekształca się w kurtkę',
    characteristicUa: '  Тренч-трансформер з натуральної шкіри, що перетворюється у куртку.',
    collection: 'jackets',
    color: 'burgundy',
    descriptionEn:
      'Measurements:\n\nChest volume: 92 cm\nHips volume: 100 cm\nLentch of a jacket: 51 cm\nLength of a trench: 133 cm\nSleeve length from neck: 77 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 92 cm\nObwód bioder: 100 cm\nDługość kurtki: 51 cm\nDługość płaszcza: 133 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри: \n\nОб'єм грудей: 92 см \nОб'єм бедер: 100 см\nДовжина куртки: 51 см\nДовжина тренчу: 133 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 173 см",
    h1en: 'Trench transformer',
    h1pl: 'Trencz-transformer',
    h1ua: 'Тренч жіночий трансформер ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ff81m034r-C0002022.jpeg?alt=media&token=872472f5-866f-4370-8670-75237b46d4e3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsrlcjott-C0001957.jpeg?alt=media&token=e30bce34-cef5-4aee-99b6-54ab8675b679',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5jpw9tst-C0001984.jpeg?alt=media&token=5a7a69ba-5beb-4c2d-af09-bc691d8be46d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqa9pj2fp-C0001870.jpeg?alt=media&token=64d2a4ad-cb34-4bc2-8b0b-f2d250986a8b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frccunivl-C0002192.jpeg?alt=media&token=74adb8a3-a692-4eae-9423-c0e41019c63b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fx8v8oay6-C0002163.jpeg?alt=media&token=0023f005-fd9e-4e41-9959-e906a31b5ef6',
    ],
    interest: '',
    newSale: '',
    priceEn: '850',
    priceEnSale: '',
    priceUa: '38250',
    priceUaSale: '',
    seoDescriptionEn:
      'Red trench transformer: a bright and stylish accent for your wardrobe. Add a bold color and modern look to your look with this trendy jacket',
    seoDescriptionPl:
      'Odkryj nowoczesny styl i funkcjonalność z naszym trenczem-transformerem. Stylowy, wszechstronny i idealny do tworzenia różnych looków na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Червоний тренч трансформер : яскравий та стильний акцент для вашого гардеробу. Додайте сміливого кольору та сучасного вигляду до вашого образу з цим модним жакетом.\n\n',
    seoTitleEn: 'Trench transformer - Got`s Label',
    seoTitlePl: 'Trencz-transformer - Got`s Label',
    seoTitleUa: 'Тренч трансформер - Got`s Label',
    skin: 'smooth',
    titleEn: 'Transformer trench',
    titlePl: 'Trencz-transformer ',
    titleUa: 'Тренч-трансформер',
  },
  2024510144534: {
    article: '0030',
    category: 'skirts',
    characteristicEn: 'Pleated short skirt with removable suspenders made of natural leather.',
    characteristicPl: 'Plisowana krótka spódnica z odpinanymi szelkami z naturalnej skóry',
    characteristicUa: 'Плісована укорочена спідниця зі зйомними підтяжками з натуральної шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn: 'Measurements of the skirt:\n\nLength: 42 cm\nWaist volume: 67 cm\nHips volume: 101 cm\n\nModel height: 173 cm',
    descriptionPl: 'Parametry spódnicy:\n\nDługość: 42 cm\nObwód talii: 67 cm\nObwód bioder: 101 cm\n\nWzrost modelki: 173 ',
    descriptionUa: 'Параметри спідниці: \n\nДовжина: 42 см \nОб`єм талії: 67 см\nОб`єм бедер: 101 см\n\nЗріст моделі: 173 см',
    h1en: " Women's short pleated skirt",
    h1pl: 'Plisowana krótka spódnica',
    h1ua: 'Плісована укорочена спідниця жіноча',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdffl7u16-C0001162.jpeg?alt=media&token=6d46f6c1-09f0-411b-bf41-2413c50e3f73',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fm8e6wypd-C0001129.jpeg?alt=media&token=630d2ceb-64a3-461e-81ec-a4a925ce1fa1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fb6tumx2e-C0001109.jpeg?alt=media&token=b809f0f0-3e8b-41e1-9ab2-78088d3fddc4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fumf92urq-C0001122.jpeg?alt=media&token=b634d774-ebe9-4958-b32e-491bae4dca67',
    ],
    interest: '',
    newSale: '',
    priceEn: '300',
    priceEnSale: '',
    priceUa: '13500',
    priceUaSale: '',
    seoDescriptionEn:
      'Shop Black Skirt: Find the perfect skirt for your wardrobe. Choose from our assortment of stylish and high-quality models for any season.',
    seoDescriptionPl:
      'Ożyw swoją stylizację z naszą plisowaną krótką spódnicą. Modna, elegancka i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити чорну спідницю: знайдіть ідеальну спідницю для вашого гардеробу. Оберіть з нашого асортименту стильних та якісних моделей для будь-якої пори року.\n\n',
    seoTitleEn: 'Pleated short skirt - Got`s Label',
    seoTitlePl: 'Plisowana Krótka Spódnica - Got`s Label',
    seoTitleUa: 'Плісована укорочена спідниця - Got`s Label',
    skin: 'smooth',
    titleEn: 'Pleated short skirt',
    titlePl: 'Plisowana krótka spódnica ',
    titleUa: 'Плісована укорочена спідниця',
  },
  2024510144536: {
    article: '0011',
    category: 'jackets',
    characteristicEn: 'The elongated jacket with pockets made of natural leather.',
    characteristicPl: 'Kurtka wydłużona z kieszeniami, wykonywana ze skóry naturalnej.',
    characteristicUa: 'Косуха подовжена з кишенями з натуральної шкіри.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 118 cm\nHips volume: 110 cm\nBack length: 60 cm\nSleeve length from neck: 78 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 118 cm\nObwód bioder: 110 cm\nDługość tyłu: 60 cm\nDługość rękawa od szyi: 78 cm\n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 118 см \nОб'єм бедер: 110 см\nДовжина по спині: 60 см \nДовжина рукава від горловини: 78 см \n\nЗріст моделі: 177 см",
    h1en: 'White long leather jacket',
    h1pl: 'Skórzana Białа Damska Ramoneska',
    h1ua: 'Косуха довга біла з кишенями',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fei39j9n5-C0001380.jpg?alt=media&token=b903add8-2b48-47d7-a0ac-fe564328780d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhg3yxr4e-C0001407.jpg?alt=media&token=392ddcb2-51fc-4b8e-845f-818d76ff5f1e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fyceu0ktm-C0001374.jpg?alt=media&token=64843f1e-169b-4f55-bb61-4f3b2151aad2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F83r8041k-C0001209.jpeg?alt=media&token=bc1dd872-fff7-415e-ac45-19aceb96011f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fohi4mezw-C0001202.jpeg?alt=media&token=b65f94f5-e1c3-4500-aeec-01a16ea45cc8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnjw2ev98-8T1A9401.jpg?alt=media&token=d72f0d6b-da0c-4a4b-949c-c1320851af67',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      "Elevate your style with a ladies' white leather jacket. This chic and versatile piece offers a modern, sophisticated look, perfect for adding a touch of elegance and freshness to any outfit.",
    seoDescriptionPl:
      'Dodaj stylowy akcent do swojej garderoby z naszą białą skórzaną damską ramoneską. Elegancka, modna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Косуха жіноча шкіряна біла: стильний і елегантний вибір для вашого гардеробу. Виготовлена з високоякісної шкіри, ця косуха додасть вашому образу сучасного вигляду та неповторності.\n\n',
    seoTitleEn: 'White Elongated Leather Long Jacket With Pocked - Got`s Label',
    seoTitlePl: 'Skórzana Białа Kurtka Ramoneska Wydłużona Z Kieszeniami',
    seoTitleUa: 'Косуха довга біла з кишенями  - Got`s Label',
    skin: 'smooth',
    titleEn: 'Elongated jacket with pockets',
    titlePl: 'Kurtka wydłużona z kieszeniami',
    titleUa: 'Косуха подовжена з кишенями',
  },
  2024510144538: {
    article: '0005',
    category: 'jackets',
    characteristicEn: 'Base oversized jacket made of smooth leather.',
    characteristicPl: 'Podstawowa kurtka oversize z naturalnej gładkiej skóry.',
    characteristicUa: 'Базова оверсайз косуха з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 53 cm\nSleeve length from neck: 76 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 175 см",
    h1en: 'Classic grey leather jacket',
    h1pl: 'Szara ramoneska skórzana damska oversize',
    h1ua: 'Базова оверсайз косуха графітова',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fx19b27w6-451_ZEN_0535.jpeg?alt=media&token=97811fe0-8423-4b3f-a578-5b199f1c26cc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fi6g4i1jm-456_ZEN_0555.jpeg?alt=media&token=d845ab86-47f9-44d1-be14-38f52b300949',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvcp5ic7t-457_ZEN_0556.jpeg?alt=media&token=1d030e89-973e-4429-b5c2-69ff8a6184b8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqxp7q5jj-463_ZEN_0599.jpeg?alt=media&token=0b6b2d08-ca4a-4431-8728-39866fc17637',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Embrace effortless style with an oversized grey leather jacket for women. This chic, relaxed-fit piece adds a touch of sophistication and modern flair to any outfit, perfect for a contemporary look.',
    seoDescriptionPl:
      'Podkreśl swój unikalny styl z naszą szarą ramoneską skórzaną oversize. Modna, wygodna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити графітову шкіряну куртку жіночу: стильний вибір для сучасних модниць. Високоякісна шкіра і елегантний дизайн додадуть неповторності вашому образу.\n\n',
    seoTitleEn: 'Base Grey Leather Jacket Oversized - Got`s Label',
    seoTitlePl: 'Szara Ramoneska Skórzana Oversize - Got`s Label',
    seoTitleUa: 'Базова оверсайз косуха графітова - Got`s Label',
    skin: 'smooth',
    titleEn: 'Base oversize jacket',
    titlePl: 'Podstawowa kurtka oversize ',
    titleUa: 'Базова оверсайз косуха',
  },
  2024510144540: {
    article: '0012',
    category: 'jackets',
    characteristicEn: 'Jacket with lacing on the sleeves and back, with cuffs on the sleeves, with silver hardware.',
    characteristicPl: 'Kurtka ze sznurowaniem na rękawach i z tyłu, z mankietami na rękawach, z srebrną galanterią',
    characteristicUa: 'Косуха зі шнурівкою на руках та спині, та з манжетами на рукавах зі сріблястою фурнітурою.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 47 cm\nSleeve length from neck: 74 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 47 cm\nDługość rękawa od szyi: 74 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 47 см \nДовжина рукава від горловини: 74 см \n\nЗріст моделі: 173 см",
    h1en: 'Black leather oversized jacket',
    h1pl: 'Czarna kurtka skórzana damska',
    h1ua: 'Косуха чорна зі шнуровкою на руках та спині ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2uyr7mdm-C0003919.jpeg?alt=media&token=9895af67-a934-4dba-aed9-7e9fad743b60',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnqqz0i3n-C0003847.jpeg?alt=media&token=56e8b21c-bf78-4a7d-93f9-d825c97a0f3a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8uvpu3v6-C0003976.jpeg?alt=media&token=4fa5637d-10f3-433c-a762-2499ae1c8d75',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fkln9v0h4-C0003903.jpeg?alt=media&token=0e0ed731-9389-4bf6-a158-830e07d5f754',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhttz9x2x-8T1A0031.jpg?alt=media&token=7ed5d1da-2c0c-4933-a800-a0cca3e87768',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fyqu09y4i-8T1A0035.jpg?alt=media&token=973e30ca-d92b-4ad8-835d-dffcb6eeaac8',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Upgrade your wardrobe with a black slim fit leather jacket. Sleek and modern, this tailored piece offers a sharp, refined silhouette, perfect for adding a touch of edgy sophistication to any outfit.',
    seoDescriptionPl:
      'Dodaj klasyczny akcent do swojej garderoby z naszą czarną skórzaną kurtką damską. Elegancka, modna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Oversize косуха чорна: сучасний і стильний вибір для вашого гардеробу. Додайте елегантності та розкутості до вашого образу з цією модною косухою.\n\n',
    seoTitleEn: 'Black oversized leather jacket With Lacing On The Sleeves And Back',
    seoTitlePl: 'Kurtka Skórzana Czarna Ze Sznurowaniem Na Rękawach I Z Tyłu',
    seoTitleUa: 'Косуха чорна зі шнуровкою на руках та спині - Got`s Label',
    skin: 'jumbo',
    titleEn: 'Jacket with lacing on the sleeves and back',
    titlePl: 'Kurtka ze sznurowaniem na rękawach i z tyłu',
    titleUa: 'Косуха зі шнурівкою на руках та спині',
  },
  2024510144542: {
    article: '0008',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with large pockets and neck stand, with vintage copper hardware.',
    characteristicPl: 'Kurtka oversize z dużymi kieszeniami i stójką z miedzianą galanterią, wykonywana z naturalnej skóry vintage ',
    characteristicUa: 'Оверсайз косуха з великими кишенями та стійкою, з вінтажною фурнітурою кольору міді.',
    collection: 'jackets',
    color: 'brownVintage',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 120 cm\nBack length: 53 cm\nSleeve length from neck: 75 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 120 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 120 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 173 см",
    h1en: 'Brown leather jacket oversized',
    h1pl: 'Brązowa kurtka damska skórzana',
    h1ua: 'Коричнева куртка жіноча шкіряна з великими кишенями і стійкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbpu2arkn-C0001743.jpeg?alt=media&token=a0fe4b7d-7d97-4a01-a778-60bda0afa724',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ff0ebe5bp-C0001785.jpeg?alt=media&token=5d0308c1-d9bd-4b20-89cc-c7febd61d54c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhi8z2y2r-C0001791.jpeg?alt=media&token=d3280b67-bc5d-425c-af43-92e6a60cee9a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fduuxi38k-C0001811.jpeg?alt=media&token=8a892352-7e90-4794-8f3a-2636f97a7538',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fx8malcdo-8T1A8940.jpg?alt=media&token=116bb350-b5df-4bfb-b350-4e96c89f8995',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Discover timeless elegance with a brown leather jacket for women. This classic piece combines rich, warm tones with a versatile design, perfect for adding a touch of sophistication to any outfit.',
    seoDescriptionPl:
      'Odkryj elegancję z naszą brązową kurtką damską skórzaną. Klasyczny design, modny wygląd i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Косуха оверсайз коричнева: стильний і комфортний вибір для вашого гардеробу. Додайте неповторний шарм і елегантність до вашого образу з цією моделлю.\n\n',
    seoTitleEn: 'Oversized Brown Leather Jacket With Large Pockets And Neck Stand',
    seoTitlePl: 'Brązowa Skórzana Kurtka Oversize - Got`s Label',
    seoTitleUa: 'Коричнева косуха oversize з великими кишенями і стійкою - Got`s Label ',
    skin: 'vintage',
    titleEn: 'Oversized jacket with large pockets and neck stand',
    titlePl: 'Kurtka oversize z dużymi kieszeniami i stójką',
    titleUa: 'Косуха oversize з великими кишенями і стійкою',
  },
  2024510144544: {
    article: '0007',
    category: 'jackets',
    characteristicEn: 'A classic elongated jacket made of natural vintage leather.',
    characteristicPl: 'Klasyczna wydłużona kurtka z naturalnej skóry vintage.',
    characteristicUa: 'Класична подовжена косуха з натуральної вінтажної шкіри.',
    collection: 'jackets',
    color: 'vintage_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 108 cm\nBack length: 58 cm\nSleeve length from neck: 77 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 108 cm\nDługość tyłu: 58 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 173 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 108 см \nДовжина по спині: 58 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 173 см",
    h1en: 'Grey womens leather jacket',
    h1pl: 'Szara kurtka skórzana damska długa',
    h1ua: 'Сіра косуха подовжена класична',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2za3yvrv-C0001497.jpeg?alt=media&token=0317790c-d894-49a1-b196-d3415f1df7c8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwqcvhv6h-C0001471.jpeg?alt=media&token=57e14b72-40f5-4a47-a466-127757d699ca',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fj48jdunx-C0001481.jpeg?alt=media&token=31fc349b-92e3-4889-ab16-e8a29d1b2e62',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsg2zq9rx-C0001494.jpeg?alt=media&token=6a75862b-d7d0-4eb0-9cd1-1224b8fe9532',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Update your wardrobe with a grey leather jacket. Sleek and versatile, this modern classic adds a sophisticated touch to any outfit, perfect for both casual and refined looks.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o klasyczny wygląd z naszą szarą skórzaną kurtką damską oversize. Stylowa, wygodna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Сіра косуха куртка: елегантний вибір для будь-якого стилю. Виготовлена з високоякісних матеріалів, ця косуха-куртка додає сучасного вигляду та універсальності вашому гардеробу.\n\n',
    seoTitleEn: 'Grey Leather Jacket A Classic Elongated - Got`s Label',
    seoTitlePl: 'Damska Wydłużona Kurtka Skórzana Szara - Got`s Label',
    seoTitleUa: 'Класична подовжена косуха сіра - Got`s Label',
    skin: 'vintage',
    titleEn: 'Classic elongated jacket',
    titlePl: 'Klasyczna wydłużona kurtka ',
    titleUa: 'Косуха подовжена класична',
  },
  2024510144546: {
    article: '0005',
    category: 'jackets',
    characteristicEn: 'Base oversize jacket made of grained leather.',
    characteristicPl: 'Podstawowa kurtka oversize z naturalnej skóry z ziarnistą fakturą\n',
    characteristicUa: 'Базова оверсайз косуха з натуральної зернистої шкіри.',
    collection: 'jackets',
    color: 'brown',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 53 cm\nSleeve length from neck: 76 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 175 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 175 см",
    h1en: 'Classic brown leather jacket',
    h1pl: 'Brązowa ramoneska skórzana damska oversize',
    h1ua: 'Базова оверсайз косуха коричнева',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fr7brp7ra-o99axmvq-C0002398.webp?alt=media&token=8780cfbe-7dbc-4358-87a4-c975fe970e07',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgrr7o60p-av121kma-C0002344.webp?alt=media&token=58041255-a094-447a-a240-617d86a12a1d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvbjw4n0e-8enijlff-C0002404.webp?alt=media&token=622902b9-d95c-44fa-9627-a92368eae0c2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fmhk7k3fl-pu9syhat-C0002406.webp?alt=media&token=afbdd723-e9fe-41de-a004-6f527f9f9c68',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxlscc492-3j1e7u4c-C0002423.webp?alt=media&token=b63829a3-4380-4b78-b494-f5eabdb2b3aa',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Update your wardrobe with an oversized brown leather jacket for women. This stylish, relaxed-fit piece combines classic brown tones with a contemporary silhouette, offering a chic and comfortable addition to your collection.',
    seoDescriptionPl:
      'Odkryj klasyczny styl z naszą brązową ramoneską skórzaną oversize. Elegancka, wygodna i idealna na każdą okazję. Zobacz naszą kolekcję!',
    seoDescriptionUa:
      'Купити коричневу шкіряну куртку жіночу: класичний і стильний вибір. Високоякісна шкіра та сучасний дизайн додають елегантності та комфорту вашому гардеробу.\n\n',
    seoTitleEn: 'Base Brown Leather Jacket Oversized - Got`s Label',
    seoTitlePl: 'Brązowa Ramoneska Skórzana Oversize - Got`s Label',
    seoTitleUa: 'Базова оверсайз косуха коричнева - Got`s Label',
    skin: 'jumbo',
    titleEn: 'Base oversize jacket',
    titlePl: 'Podstawowa kurtka oversize ',
    titleUa: 'Базова оверсайз косуха',
  },
  2024510144548: {
    article: '0008',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with large pockets and neck stand, with black hardware.',
    characteristicPl: 'Kurtka oversize z dużymi kieszeniami i stójką z czarną galanterią.',
    characteristicUa: 'Оверсайз косуха з великими кишенями та стійкою з чорною фурнітурою.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 120 cm\nBack length: 53 cm\nSleeve length from neck: 75 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 120 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 120 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 173 см",
    h1en: 'Black leather jacket oversized',
    h1pl: 'Czarnа kurtka damska skórzana',
    h1ua: 'Чорна куртка жіноча шкіряна з великими кишенями і стійкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F85krda4d-C0002270.jpg?alt=media&token=a41b765c-60d9-41ec-b9d5-95dea1664b8a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fm4759wd3-8T1A8397.jpg?alt=media&token=5c52e085-2afd-48e7-b1be-bab468661e29',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fia2dzz9u-8T1A8176.jpg?alt=media&token=c888babc-6fcd-4b73-93ee-1eb37f4015f3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwhqzqtvx-8T1A8368.jpg?alt=media&token=9e98da2a-7cb4-495c-8e1f-7fa701e33898',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fo1mj7a7i-8T1A8477.jpg?alt=media&token=6fd8f4fb-3cf7-4c4a-b5c8-5494441a5526',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your style with a black leather jacket for women. Sleek and versatile, this essential piece adds a chic edge to any outfit, blending timeless sophistication with modern flair.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o ponadczasowy styl z naszą czarną kurtką damską skórzaną. Elegancka, modna i idealna na każdą okazję. Odkryj naszą ofertę!',
    seoDescriptionUa:
      'Косуха оверсайз чорна: сучасний і стильний вибір для будь-якого гардеробу. Оберіть цю косуху, щоб додати елегантності та розкутості до вашого образу.\n\n',
    seoTitleEn: 'Oversized Black Leather Jacket With Large Pockets And Neck Stand',
    seoTitlePl: 'Czarnа Skórzana Kurtka Oversize - Got`s Label',
    seoTitleUa: 'Чорна косуха oversize з великими кишенями і стійкою - Got`s Label ',
    skin: 'jumbo',
    titleEn: 'Oversized jacket with large pockets and neck stand',
    titlePl: 'Kurtka oversize z dużymi kieszeniami i stójką ',
    titleUa: 'Косуха oversize з великими кишенями і стійкою',
  },
  2024510144550: {
    article: '0005',
    category: 'jackets',
    characteristicEn: 'Base oversize jacket made of natural smooth leather.',
    characteristicPl: 'Podstawowa kurtka oversize z naturalnej gładkiej skóry.\n\n',
    characteristicUa: 'Базова оверсайз косуха з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 53 cm\nSleeve length from neck: 76 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 175 см",
    h1en: 'Classic black leather jacket',
    h1pl: 'Czarnа ramoneska skórzana damska oversize',
    h1ua: 'Базова оверсайз косуха чорна ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhuydpcn2-IMG_0888.png?alt=media&token=b1d43224-4bc8-4764-b438-7b3d4a7169b4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsymnrxmi-tempImageGbkbiD.jpg?alt=media&token=a1cbc956-28ee-462a-9233-d2ecb027fa73',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhangq0tb-IMG_9818.jpeg?alt=media&token=30bba457-dd23-4e87-b9ca-01a1f85922a1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqsqfvykx-IMG_9804.jpeg?alt=media&token=ab47bf0b-efde-4d57-86a4-7bbf7d951750',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8u6cp8af-tempImage2r1xoH.jpg?alt=media&token=59f67a1d-fcc3-453c-b371-0bea1cb8739e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhyu5dwcl-tempImageU1v5Pj.jpg?alt=media&token=ccbc853c-804a-4d3e-82d5-0f9acbbd9d03',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Step into modern fashion with an oversized black leather jacket for women. This trendy, relaxed-fit piece delivers both style and comfort, adding a bold, sophisticated edge to any outfit.',
    seoDescriptionPl:
      'Dodaj odrobinę rockowego stylu do swojej garderoby z naszą czarną ramoneską skórzaną oversize. Modna, wygodna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити чорну шкіряну куртку жіночу: елегантний і стильний вибір для вашого гардеробу. Виготовлена з високоякісної шкіри, ця куртка додасть вашому образу неповторність та розкіш.\n\n',
    seoTitleEn: 'Base Black Leather Jacket Oversized - Got`s Label',
    seoTitlePl: 'Czarnа Ramoneska Skórzana Oversize - Got`s Label',
    seoTitleUa: 'Базова оверсайз косуха чорна - Got`s Label',
    skin: 'smooth',
    titleEn: 'Base oversize jacket',
    titlePl: 'Podstawowa kurtka oversize ',
    titleUa: 'Базова оверсайз косуха',
  },
  2024510144552: {
    article: '0013',
    category: 'jackets',
    characteristicEn: 'Natural leather classic fit cropped jacket with zip cuffs and silver hardware.',
    characteristicPl: 'Krótki żakiet z naturalnej skóry o klasycznym kroju z mankietami na rękawach z srebrną galanterią.',
    characteristicUa: 'Укорочений жакет з натуральної шкіри класичного крою з манжетами на рукавах зі сріблястою фурнітурою.\n',
    collection: 'jackets',
    color: 'grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 105 cm\nBack length: 45 cm\nSleeve length from neck: 78 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 105 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 78 cm\n\nWzrost modelki: 173 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 105 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 78 см \n\nЗріст моделі: 173 см",
    h1en: 'Oversized grey leather jacket',
    h1pl: 'Krótka szara kurtka skórzana damska',
    h1ua: 'Сірий жакет укорочений класичного крою з манжетами',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fuwjszhx0-C0000457.jpg?alt=media&token=5db86c1c-6c35-40c6-8b7a-c5de08bf9ce7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fpqkjx8w7-C0000472.jpg?alt=media&token=d22a7b43-d986-4e7f-8335-b215fd062362',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fekj9dzvr-C0000413.jpeg?alt=media&token=c8adbdc8-4baf-44d4-9323-a6db9b52fd57',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fcenbugs2-C0000452.jpg?alt=media&token=8f1b83a7-9db2-4d04-b697-e9d60223ada7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxivjry06-C0000462.jpg?alt=media&token=dfbc04ac-5c3c-47d7-9d0a-7aea15a8b8a4',
    ],
    interest: '',
    newSale: '',
    priceEn: '600',
    priceEnSale: '',
    priceUa: '27000',
    priceUaSale: '',
    seoDescriptionEn:
      'Refresh your wardrobe with a light brown leather jacket. This elegant and versatile piece offers a soft, contemporary twist on classic style, perfect for adding a warm, sophisticated touch to any outfit.',
    seoDescriptionPl:
      'Dodaj nowoczesny akcent do swojej stylizacji z naszą szarą, krótką skórzaną kurtką. Modna, elegancka i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити жіночий жакет: знайдіть ідеальний жакет для вашого гардеробу. Стильні, елегантні та сучасні варіанти додадуть вашому образу бездоганності та розкоші.\n\n',
    seoTitleEn: 'Classic Fit Cropped Leather Jacket Grey  - Got`s Label',
    seoTitlePl: 'Szara Kurtka Skórzana Krótka O Klasycznym Kroju Z Mankietami',
    seoTitleUa: 'Укорочений жакет класичного крою з манжетами - Got`s Label',
    skin: 'vintage',
    titleEn: 'Classic fit cropped jacket',
    titlePl: 'Krótki żakiet o klasycznym kroju z mankietami ',
    titleUa: 'Укорочений жакет класичного крою з манжетами ',
  },
  2024510144554: {
    article: '0014',
    category: 'jackets',
    characteristicEn: 'Jacket with lacing on the chest and cuffs on the sleeves, with silver hardware.',
    characteristicPl: 'Kurtka ze sznurowaniem na piersi i mankietami na rękawach, z srebrną galanterią',
    characteristicUa: 'Косуха зі шнурівкою на грудях та манжетами на рукавах, зі сріблястою фурнітурою.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 102 cm\nBack length: 44 cm\nSleeve length from neck: 75 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 102 cm\nDługość tyłu: 44 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 177 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 102 см \nДовжина по спині: 44 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 177 см",
    h1en: 'Black leather jacket',
    h1pl: 'Kurtka czarna skórzana',
    h1ua: 'Чорна куртка-косуха зі шнуровкою на грудях ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqpo3m5yi-C0003719.jpg?alt=media&token=220685a2-a4ee-4e6e-aa25-2c4f1ec78f1a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fb1cr7f64-C0003786.jpeg?alt=media&token=a4da4042-3f6b-4da6-8d33-9b474ae80fd4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3hyhf0ad-C0003794.jpeg?alt=media&token=e58f0d20-127b-40fd-959e-ce70f4ffd06e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fpobu0j99-C0003641.jpeg?alt=media&token=e4bc9c02-972d-4184-ac5f-f9b01248396c',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Discover the ultimate in sophistication with a women’s black leather jacket. Combining sleek design with classic appeal, this versatile piece adds a refined, edgy touch to any outfit.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą czarną skórzaną kurtką. Klasyczna, modna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Виберіть чорну куртку шкіряну косуху для елегантного та стильного образу. Повітряна шкіра та класичний дизайн додадуть вашому гардеробу неповторності та сучасного вигляду.\n\n',
    seoTitleEn: 'Leather Black Jacket With Lacing On The Chest - Got`s Label',
    seoTitlePl: 'Czarna Kurtka Skórzana Ze Sznurowaniem Na Piersi - Got`s Label',
    seoTitleUa: 'Чорна куртка-косуха зі шнуровкою на грудях - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with lacing on the chest ',
    titlePl: 'Kurtka ze sznurowaniem na piersi',
    titleUa: 'Косуха зі шнурівкою на грудях ',
  },
  2024510144556: {
    article: '0014',
    category: 'jackets',
    characteristicEn: 'Jacket with lacing on the chest and cuffs on the sleeves, with silver hardware.',
    characteristicPl: 'Kurtka ze sznurowaniem na piersi i mankietami na rękawach, z srebrną galanterią',
    characteristicUa: 'Косуха зі шнурівкою на грудях та манжетами на рукавах, зі сріблястою фурнітурою.',
    collection: 'jackets',
    color: 'vintage_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 102 cm\nBack length: 44 cm\nSleeve length from neck: 75 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 102 cm\nDługość tyłu: 44 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 177 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 102 см \nДовжина по спині: 44 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 177 см",
    h1en: 'Grey leather jacket',
    h1pl: 'Kurtka szara skórzana',
    h1ua: 'Сіра куртка-косуха зі шнуровкою на грудях',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvh4khhbv-C0002896.jpeg?alt=media&token=205a18bf-6f2b-43b2-8154-7844e7e528a7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F74r2rgy4-C0002893.jpeg?alt=media&token=34216f5f-1484-4815-af30-776bf6c25adc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fx1dob0t9-C0002744.jpeg?alt=media&token=8c3d6528-a4a7-4b79-8e1a-5fa5eca471cd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0ni69qkx-C0002851.jpeg?alt=media&token=734d1e83-c657-4365-9ee9-5cfd11a6492e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fypnjjq88-j484qufc-C0002756.webp?alt=media&token=d954452a-ff0a-4113-ba10-706a7e2be506',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your wardrobe with a women’s grey leather jacket. This sleek, versatile piece offers a modern twist on classic style, perfect for adding a touch of sophistication to any outfit.',
    seoDescriptionPl:
      'Dodaj elegancji swojej garderobie z naszą szaryą skórzaną kurtką. Stylowa, uniwersalna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Сіра шкіряна косуха: стильний вибір для вашого гардеробу. Додайте елегантності та модного вигляду до вашого образу з цією якісною курткою.\n\n',
    seoTitleEn: 'Leather Grey Jacket With Lacing On The Chest - Got`s Label',
    seoTitlePl: 'Szara Kurtka Skórzana Ze Sznurowaniem Na Piersi - Got`s Label',
    seoTitleUa: 'Сіра куртка-косуха зі шнуровкою на грудях - Got`s Label',
    skin: 'vintage',
    titleEn: 'Jacket with lacing on the chest ',
    titlePl: 'Kurtka ze sznurowaniem na piersi',
    titleUa: 'Косуха зі шнурівкою на грудях ',
  },
  2024510144558: {
    article: '0015',
    category: 'jackets',
    characteristicEn: 'Long oversized jacket with wide sleeves, pockets, and silver hardware details.',
    characteristicPl: 'Długa kurtka oversize z szerokimi rękawami, kieszeniami i detalami ze srebrną galanterią',
    characteristicUa: 'Довга оверсайз косуха з широкими рукавами, кишенями та деталями зі сріблястою фурнітурою.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 118 cm\nBack length: 56 cm\nSleeve length from neck: 74 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 118 cm\nDługość tyłu: 56 cm\nDługość rękawa od szyi: 74 cm\n\nWzrost modelki: 173 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 118 см \nДовжина по спині: 56 см \nДовжина рукава від горловини: 74 см \n\nЗріст моделі: 173 см",
    h1en: 'Black leather jacket long',
    h1pl: 'Skórzana czarnа ramoneska oversize',
    h1ua: 'Чорна куртка косуха оверсайз з широкими рукавами ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fim5x25zw-C0002496.jpeg?alt=media&token=6f8838c4-cdab-40e1-9cc6-990a49cc75c4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fkkpkeqxq-C0002700.jpeg?alt=media&token=d90f3803-5b86-4692-ba85-19937cebf3e6',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fuhdfzap5-C0002467.jpeg?alt=media&token=bc5708c5-f4b1-4b00-b917-a0ce12fe091d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdscxtsif-C0002477.jpeg?alt=media&token=97115fcd-95a2-41f5-9a45-9d16c4f458dd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvhwhr692-C0002543.jpeg?alt=media&token=962938cf-f672-4351-a27f-146a3c090506',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a bold statement with a woman’s black leather jacket. Combining timeless elegance with a modern edge, this versatile piece enhances any outfit with its sleek, sophisticated design.',
    seoDescriptionPl:
      'Wzbogać swój styl z naszą czarną skórzaną ramoneską oversize. Modna, wygodna i doskonała na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Чорна шкіряна куртка косуха: класичний стиль і сучасний вигляд. Виготовлена з високоякісної шкіри, ця косуха додає елегантності та характеру вашому образу.\n\n',
    seoTitleEn: 'Long Black Oversized Leather Jacket With Wide Sleeves - Got`s Label',
    seoTitlePl: 'Skórzana Czarnа Kurtka Z Szerokimi Rękawami - Got`s Label',
    seoTitleUa: 'Чорна куртка косуха оверсайз з широкими рукавами - Got`s Label',
    skin: 'smooth',
    titleEn: 'Long oversized jacket with wide sleeves',
    titlePl: 'Długa kurtka oversize z szerokimi rękawami',
    titleUa: 'Довга оверсайз косуха з широкими рукавами',
  },
  2024510144600: {
    article: '0016',
    category: 'bombers',
    characteristicEn: 'Long oversized bomber jacket with elastic shoulder pads and voluminous sleeves.\n',
    characteristicPl: 'Długi bomber oversize z naramiennikami, z objętościowymi rękawami na ściągaczu.',
    characteristicUa: 'Довгий оверсайз бомбер з підплічниками на резинці з об’ємними рукавами.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the bomber:\n\nChest volume: 149 cm\nBack length: 75 cm\nSleeve length from neck: 80 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 149 cm\nDługość tyłu: 75 cm\nDługość rękawa od szyi: 80 cm\n\nWzrost modelki: 175 cm\n',
    descriptionUa:
      "Параметри бомбера: \n\nОб'єм грудей: 149 см \nДовжина по спині: 75 см \nДовжина рукава від горловини: 80 см \n\nЗріст моделі: 175 см",
    h1en: 'White leather bomber women',
    h1pl: 'Bomberka kurtka',
    h1ua: 'Довгий білий бомбер жіночий оверсайз',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwb5bm7r6-8T1A8605.jpg?alt=media&token=6aa850cc-7893-4644-88d6-952f2a0f1056',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgkv83nim-8T1A8822.jpg?alt=media&token=ee0e02c4-b27e-465b-9a27-d04d366294f7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fyx03ynr6-8T1A8647.jpg?alt=media&token=3f6a8a86-1018-485d-a699-a5fdaa44ee41',
    ],
    interest: '',
    newSale: 'false',
    priceEn: '850',
    priceEnSale: '',
    priceUa: '38250',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your wardrobe with a white leather bomber jacket. This chic and contemporary piece combines a classic bomber silhouette with a crisp, modern finish, perfect for adding a stylish edge to any outfit.',
    seoDescriptionPl:
      'Odkryj nowoczesny styl z naszą bomberką kurtką. Modna, wszechstronna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити білий жіночий бомбер: стильний і універсальний вибір для вашого гардеробу. Додайте елегантності та сучасного вигляду до вашого образу з цією класичною бомбер-курткою.\n\n',
    seoTitleEn: 'Long Oversize White Leather Bomber Jacket  - Got`s Label',
    seoTitlePl: 'Bomberka Oversize Z Naramiennikami - Got`s Label',
    seoTitleUa: 'Довгий білий бомбер жіночий оверсайз - Got`s Label',
    skin: 'smooth',
    titleEn: 'Long oversized bomber jacket',
    titlePl: 'Długi bomber oversize ',
    titleUa: 'Довгий оверсайз бомбер',
  },
  2024510144602: {
    article: '0008',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with large pockets and neck stand, with copper hardware.',
    characteristicPl: 'Kurtka oversize z dużymi kieszeniami i stójką z miedzianą galanterią.',
    characteristicUa: 'Оверсайз косуха з великими кишенями та стійкою з  фурнітурою кольору міді.\n',
    collection: 'jackets',
    color: 'ginger',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 120 cm\nBack length: 53 cm\nSleeve length from neck: 75 cm\n\nModel height: 168 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 120 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 168 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 120 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 168 см",
    h1en: 'Orange leather jacket oversized',
    h1pl: 'Pomarańczowy kurtka damska skórzana',
    h1ua: 'Помаранчева куртка жіноча шкіряна з великими кишенями і стійкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Faz3jig9y-IMG_0889.png?alt=media&token=3028a53b-6c5e-40df-a7e7-bc3c68245e27',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0thymyt3-IMG_0191%202.jpg?alt=media&token=9d19078b-81eb-47a7-914b-1d4d25b68233',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frrzbf5vv-IMG_0187%202.jpg?alt=media&token=9f92e4f3-d336-42d4-95ef-3be864a95556',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8gl1gcca-IMG_0181%202.jpg?alt=media&token=3122e789-94ab-4f09-ab16-ed2dbe247724',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flglnutjn-IMG_0190%202.jpg?alt=media&token=a2e3c721-fec4-425f-a5ae-37d4edec3a36',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fa4yudaj5-IMG_0188%202.jpg?alt=media&token=7cebc551-6f58-48f9-80c1-5326e4b68d72',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '32250',
    priceUaSale: '',
    seoDescriptionEn:
      'Brighten up your wardrobe with an orange leather jacket for women. Bold and stylish, this eye-catching piece adds a vibrant pop of color and a contemporary edge to any outfit.',
    seoDescriptionPl:
      'Ożyw swoją garderobę z naszą pomarańczową kurtką damską skórzaną. Modna, wyrazista i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Помаранчева косуха оверсайз: яскравий і модний вибір для вашого гардеробу. Додайте кольору та сучасного стилю до вашого образу з цією оригінальною косухою.\n\n',
    seoTitleEn: 'Oversized Orange Leather Jacket With Large Pockets And Neck Stand',
    seoTitlePl: 'Pomarańczowy Skórzana Kurtka Oversize - Got`s Label',
    seoTitleUa: 'Помаранчева косуха з великими кишенями і стійкою - Got`s Label',
    skin: 'jumbo',
    titleEn: 'Oversized jacket with large pockets and neck stand',
    titlePl: 'Kurtka oversize z dużymi kieszeniami i stójką',
    titleUa: 'Оверсайз косуха з великими кишенями та стійкою',
  },
  2024510144604: {
    article: '0008',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with large pockets and neck stand, with silver hardware.',
    characteristicPl: 'Kurtka oversize z dużymi kieszeniami i stójką z srebrną galanterią.',
    characteristicUa: 'Оверсайз косуха з великими кишенями та стійкою з серебристою фурнітурою.',
    collection: 'jackets',
    color: 'suede_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 120 cm\nBack length: 53 cm\nSleeve length from neck: 75 cm\n\nModel height: 174 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 120 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 174 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 120 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 174 см",
    h1en: 'Grey leather jacket oversized',
    h1pl: 'Szara kurtka damska skórzana',
    h1ua: 'Сіра куртка жіноча шкіряна з великими кишенями і стійкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1z375nu8-IMG_0886.png?alt=media&token=ff27ee50-2097-472d-9a38-6d0b1cb97e20',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxftmrtsy-IMG_7251.jpg?alt=media&token=0ec7e1ce-eb02-4682-972a-2cc9ba22ffb1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3dbuld1c-IMG_7252.jpg?alt=media&token=9311d307-34f1-4467-a1ea-773723dee23d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fj0zl5nza-IMG_7250.jpg?alt=media&token=a91bd5f5-63c4-4579-bb21-10ad7a6116f7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fiu0jcygh-IMG_7253.jpg?alt=media&token=232f307b-e602-4085-b2f4-9a4ff93d74fa',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Revitalize your look with a grey leather jacket for women. Sleek and sophisticated, this versatile piece offers a modern twist on classic style, perfect for enhancing any outfit with elegance.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą szarą kurtką damską skórzaną. Elegancka, uniwersalna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Сіра косуха оверсайз: стильний та сучасний вибір для вашого гардеробу. Додайте елегантності та комфорту до вашого образу з цією модною косухою.',
    seoTitleEn: 'Oversized Grey Leather Jacket With Large Pockets And Neck Stand',
    seoTitlePl: 'Szara Skórzana Kurtka Oversize - Got`s Label',
    seoTitleUa: 'Сіра косуха з великими кишенями і стійкою - Got`s Label',
    skin: 'vintage',
    titleEn: 'Oversized jacket with large pockets and neck stand',
    titlePl: 'Kurtka oversize z dużymi kieszeniami i stójką',
    titleUa: 'Оверсайз косуха з великими кишенями та стійкою',
  },
  2024510144606: {
    article: '0005',
    category: 'jackets',
    characteristicEn: 'Base oversize jacket made of natural smooth leather.',
    characteristicPl: 'Podstawowa kurtka oversize z naturalnej gładkiej skóry.',
    characteristicUa: 'Базова оверсайз косуха з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'beige',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 53 cm\nSleeve length from neck: 76 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 175 см",
    h1en: 'Classic beige leather jacket',
    h1pl: 'Beżowa ramoneska skórzana damska oversize',
    h1ua: 'Базова оверсайз косуха бежева',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsizg81vx-IMG_0887.png?alt=media&token=81499c54-2f5e-4830-9ac9-39080af72070',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flfbr8yq6-tempImagen9kguV.jpg?alt=media&token=540e5c3e-3736-45ed-968e-5fd659e82f0b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9odjkkr4-tempImagepg30PC.jpg?alt=media&token=e92a4f55-d598-4791-8b27-3ac4bf6dc0e0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdpqyapql-tempImagec5rH7w.jpg?alt=media&token=f8b35f7f-4f6d-46fe-9777-518e3a015e2b',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your look with an oversized beige leather jacket for women. Combining a relaxed fit with a versatile color, this stylish piece adds a chic and contemporary touch to any outfit.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą beżową ramoneską skórzaną oversize. Modna, wygodna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити бежеву шкіряну куртку жіночу: стильний та універсальний вибір для вашого гардеробу. Високоякісна шкіра та елегантний дизайн додадуть розкоші та сучасного вигляду будь-якому образу.\n\n',
    seoTitleEn: 'Base Beige Leather Jacket Oversized - Got`s Label',
    seoTitlePl: 'Beżowa Ramoneska Skórzana Oversize - Got`s Label',
    seoTitleUa: 'Базова оверсайз косуха бежева - Got`s Label',
    skin: 'smooth',
    titleEn: 'Base oversize jacket',
    titlePl: 'Podstawowa kurtka oversize ',
    titleUa: 'Базова оверсайз косуха',
  },
  2024510144608: {
    article: '0017',
    category: 'jackets',
    characteristicEn: 'A transformer jacket made of natural vintage leather that turns into a vest.',
    characteristicPl: 'Kurtka-transformer, która przekształca się w kamizelkę z naturalnej szaro-niebieskiej skóry vintage',
    characteristicUa: 'Косуха-трансформер з натуральної вінтажної шкіри, що перетворюється у жилетку.',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 61 cm\nSleeve length from neck: 78 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 61 cm\nDługość rękawa od szyi: 78 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 61 см \nДовжина рукава від горловини: 78 см \n\nЗріст моделі: 173 см",
    h1en: 'Vintage leather transformer jacket',
    h1pl: 'Damska ramoneska skórzana',
    h1ua: 'Жіноча шкіряна косуха трансформер',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F066oxyd4-307_ZEN_2902.jpeg?alt=media&token=a892bdd1-c13c-42b9-b2db-fc9c86418e96',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxunp65po-305_ZEN_2897.jpeg?alt=media&token=40f94f1e-9db7-4c66-af9c-4c4a8dc20a49',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffxn5j59f-324_ZEN_2955.jpeg?alt=media&token=4b792d68-346a-4e45-87ad-df09fd854271',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdfhrvwc4-306_ZEN_2900.jpeg?alt=media&token=3980050f-3788-40f8-97e2-83b3d1723299',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsrz317f8-348_ZEN_3054.jpeg?alt=media&token=a97b1831-691c-4a91-abb9-6708e06b4eca',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6eh6zogc-368_ZEN_3132.jpeg?alt=media&token=bc765535-77fc-47b0-9984-f3578ddb90f8',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Buy a jacket: find the perfect model for a stylish look. Choose from our wide selection of jackets to add elegance and sophistication to your wardrobe',
    seoDescriptionPl:
      'Dodaj odrobinę nowoczesnego stylu z naszą krótką kurtką skórzaną. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Купити косуху: знайдіть ідеальну модель для стильного образу. Оберіть з нашого великого вибору косух, щоб додати елегантності і витонченості вашому гардеробу.\n\n',
    seoTitleEn: 'Natural Vintage Leather Transformer Jacket - Got`s Label',
    seoTitlePl: 'Krótka Kurtka Skórzana Transformer - Got`s Label',
    seoTitleUa: 'Косуха графіт трансформер - Got`s Label',
    skin: 'vintage',
    titleEn: 'Transformer-jacket',
    titlePl: 'Kurtka-transformer',
    titleUa: 'Косуха-трансформер',
  },
  2024510144610: {
    article: '0018',
    category: 'jackets',
    characteristicEn: 'Jacket with an elongated triangular silhouette made of natural leather.',
    characteristicPl: 'Kurtka o wydłużonej trójkątnej sylwetce, wykonana ze skóry naturalnej.',
    characteristicUa: 'Жакет з подовженим трикутним силуетом з натуральної шкіри.',
    collection: 'jackets',
    color: 'burgundy',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 111 cm\nBack length: 50 cm\nSleeve length from neck: 77 cm\n\nModel height: 175 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 111 cm\nDługość tyłu: 50 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 111 см \nДовжина по спині: 50 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 175 см",
    h1en: 'Leather jacket red',
    h1pl: 'Czerwona Skórzana Kurtka',
    h1ua: 'Червона косуха з подовженим трикутним силуетом ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flla1u8nc-C0001538.jpeg?alt=media&token=4463be15-57e3-42b6-9a03-33f469b392f9',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fh24cf2hr-C0001516.jpg?alt=media&token=7ee089ea-94f6-49b4-b600-dae540a9b039',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fglrlx6pg-C0001596.jpg?alt=media&token=dc487d17-2c3d-4899-aaac-4de378fa3a58',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F799y370e-IMG_7265.jpg?alt=media&token=70fd5d98-66b8-45ca-8ce7-5ba5fc9741a9',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzoa9n2o2-IMG_7269.jpg?alt=media&token=e39004b1-0de8-4234-91e8-021ee9653161',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffn9pe6pq-IMG_7264.jpg?alt=media&token=409b37b0-ea5b-49e7-a024-0db8b2653cdd',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Stand out with a women’s red leather jacket. Bold and stylish, this vibrant piece adds a striking pop of color and modern flair to any outfit, perfect for making a confident fashion statement.',
    seoDescriptionPl:
      'Ożyw swoją garderobę z naszą czerwoną skórzaną kurtką. Wyrazista, modna i idealna na każdą okazję. Odkryj naszą ofertę!',
    seoDescriptionUa:
      'Червона шкіряна косуха жіноча: яскравий стиль та елегантність у кожній деталі. Додайте сміливого кольору та сучасного вигляду до вашого гардеробу з цією ексклюзивною косухою.\n\n',
    seoTitleEn: 'Oversized Red Leather Jacket With An Elongated Triangular Silhouette',
    seoTitlePl: 'Czerwona Kurtka Skórzana O Wydłużonej Trójkątnej Sylwetce - Got`s Label',
    seoTitleUa: 'Червона косуха з подовженим трикутним силуетом  - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with an elongated triangular silhouette',
    titlePl: 'Kurtka o wydłużonej trójkątnej sylwetce',
    titleUa: 'Жакет з подовженим трикутним силуетом',
  },
  2024510144612: {
    article: '0018',
    category: 'jackets',
    characteristicEn: 'Jacket with an elongated triangular silhouette made of natural leather.',
    characteristicPl: 'Kurtka o wydłużonej trójkątnej sylwetce, wykonana ze skóry naturalnej.',
    characteristicUa: 'Жакет з подовженим трикутним силуетом з натуральної шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 111 cm\nBack length: 50 cm\nSleeve length from neck: 77 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 111 cm\nDługość tyłu: 50 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 178 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 111 см \nДовжина по спині: 50 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 175 см",
    h1en: 'Leather jacket black',
    h1pl: 'Czarnа skórzana kurtka',
    h1ua: 'Чорна косуха з подовженим трикутним силуетом ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fs0vu7c5x-C0001287.jpeg?alt=media&token=a83d0a05-632e-4c1d-9100-04ece7981d94',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0vc0adg3-C0001268.jpg?alt=media&token=e7244dc9-3324-455c-a1f8-faff0454c5a4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fho8jrdaz-C0001236.jpg?alt=media&token=78fa9b57-dfb7-4290-87aa-b2bac11bc1de',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F12te6upi-8T1A9127.jpg?alt=media&token=c6eec7e8-6b9d-44ca-b196-b6364a400590',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fk6a6dp8f-8T1A9160.jpg?alt=media&token=0d98e21d-8639-4de3-8d0a-8cee9022784e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Feqwggvv1-8T1A9055.jpg?alt=media&token=1e05f479-1826-4c38-ac10-cbd03b725b14',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Embrace contemporary style with an oversized black leather jacket. This trendy, relaxed-fit piece adds a chic, modern edge to your wardrobe, offering both comfort and sophistication.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą czarną kurtką skórzaną. Klasyczna, elegancka i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Чорна шкіряна косуха жіноча: символ елегантності та стилю. Оберіть цю косуху, щоб додати вишуканості та бездоганного вигляду вашому образу.\n\n',
    seoTitleEn: 'Oversized Black Leather Jacket With An Elongated Triangular Silhouette',
    seoTitlePl: 'Czarnа Kurtka Skórzana O Wydłużonej Trójkątnej Sylwetce - Got`s Label',
    seoTitleUa: 'Чорна косуха з подовженим трикутним силуетом  - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with an elongated triangular silhouette',
    titlePl: 'Kurtka o wydłużonej trójkątnej sylwetce',
    titleUa: 'Жакет з подовженим трикутним силуетом',
  },
  2024510144614: {
    article: '0019',
    category: 'jackets',
    characteristicEn: 'Short jacket with buckles on the sleeves made of natural leather.',
    characteristicPl: 'Skrócona kurtka z klamrami na rękawach, wykonana ze skóry naturalnej.',
    characteristicUa: 'Укорочена косуха з пряжками на рукавах з натуральної шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 102 cm\nBack length: 48 cm\nSleeve length from neck: 82 cm\n\nModel height: 174 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 102 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 82 cm\n\nWzrost modelki: 174 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 102 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 82 см \n\nЗріст моделі: 174 см",
    h1en: 'Dark black leather jacket',
    h1pl: 'Kurtka skórzana damska czarnа',
    h1ua: 'Чорна укорочена косуха з пряжками на рукавах ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1g3kz113-IMG_7261.jpg?alt=media&token=84722032-b88c-4dbc-85e3-a5b65d8deec8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsmn1nu22-IMG_7256.jpg?alt=media&token=14ec4ff4-b277-4dac-a5ed-84e27d9d5f97',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqyebgjyw-IMG_7259.jpg?alt=media&token=206a1eb4-4b9c-4264-96da-511e886e5b4a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1t9hh81k-IMG_7257.jpg?alt=media&token=d9fc02c9-66c6-40f7-94aa-a6b1625cf3f9',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your style with a black leather jacket. Combining classic sophistication with a sleek, modern design, this versatile piece adds a refined edge to any outfit, perfect for every occasion.',
    seoDescriptionPl:
      'Dodaj elegancji swojej garderobie z naszą czarną skórzaną kurtką damską. Stylowa, modna i idealna na każdą okazję. Odkryj naszą ofertę!',
    seoDescriptionUa:
      'Косуха коротка чорна: стильний і сучасний вибір для вашого гардеробу. Додайте елегантності та сміливого вигляду до вашого образу з цією модною короткою косухою.',
    seoTitleEn: 'Short Black Leather Jacket With Buckles On The Sleeves  - Got`s Label',
    seoTitlePl: 'Czarnа Kurtka Skórzana Z Klamrami Na Rękawach - Got`s Label',
    seoTitleUa: 'Чорна укорочена косуха з пряжками на рукавах - Got`s Label',
    skin: 'smooth',
    titleEn: 'Short jacket with buckles on the sleeves',
    titlePl: 'Skrócona kurtka z klamrami na rękawach',
    titleUa: 'Укорочена косуха з пряжками на рукавах',
  },
  2024510144616: {
    article: '0019',
    category: 'jackets',
    characteristicEn: 'Short jacket with buckles on the sleeves made of natural leather.',
    characteristicPl: 'Skrócona kurtka ze skóry naturalnej, z klamrami na rękawach .',
    characteristicUa: 'Укорочена косуха з пряжками на рукавах з натуральної шкіри.',
    collection: 'jackets',
    color: 'burgundy',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 102 cm\nBack length: 48 cm\nSleeve length from neck: 82 cm\n\nModel height: 174 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 102 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 82 cm\n\nWzrost modelki: 174 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 102 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 82 см \n\nЗріст моделі: 174 см",
    h1en: 'Red leather jacket',
    h1pl: 'Kurtka skórzana damska brązowa',
    h1ua: 'Червона укорочена косуха з пряжками на рукавах',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6f192t5n-IMG_0992.png?alt=media&token=65274250-fffa-45d8-989f-06a813e18039',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzm0spmkz-IMG_0993.png?alt=media&token=5ecdbfda-b227-4dc8-8b4b-0eb483f26d6b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftuy93cnh-tempImageKKbeNk.jpg?alt=media&token=b55acbc1-0004-4f83-8c82-a780c296a378',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzj6ssidh-tempImage1F6yLB.jpg?alt=media&token=1b8e76e4-62f7-4461-8b8f-5f2830ca6c84',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6qaz3876-tempImageRvhm8P.jpg?alt=media&token=c258a685-6e4a-48cd-aa07-349b8b0255a2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fkn7pslji-tempImageR0IIto.jpg?alt=media&token=db39c679-9fcc-4930-ac22-d132d058e728',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a bold impression with a red leather jacket. This vibrant and stylish piece adds a dynamic pop of color and a touch of modern flair to any outfit, perfect for standing out in style.',
    seoDescriptionPl:
      'Odkryj ponadczasowy styl z naszą brązową kurtką skórzaną. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Косуха коротка червона: яскравий акцент для вашого гардеробу. Додайте сміливого кольору та сучасного стилю до вашого образу з цією ефектною короткою косухою.\n\n',
    seoTitleEn: 'Short Red Leather Jacket With Buckles On The Sleeves  - Got`s Label',
    seoTitlePl: 'Brązowa Kurtka Skórzana Z Klamrami Na Rękawach - Got`s Label',
    seoTitleUa: 'Червона укорочена косуха з пряжками на рукавах - Got`s Label',
    skin: 'smooth',
    titleEn: 'Short jacket with buckles on the sleeves ',
    titlePl: 'Skrócona kurtka z klamrami na rękawach .',
    titleUa: 'Укорочена косуха з пряжками на рукавах',
  },
  2024510144618: {
    article: '0007',
    category: 'jackets',
    characteristicEn: 'A classic elongated jacket made of natural vintage leather.',
    characteristicPl: 'Klasyczna wydłużona kurtka z naturalnej skóry vintage w kolorze szarym.',
    characteristicUa: 'Класична подовжена косуха з натуральної вінтажної шкіри сірого кольору.\n',
    collection: 'jackets',
    color: 'grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 108 cm\nBack length: 58 cm\nSleeve length from neck: 77 cm\n\nModel height: 174 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 108 cm\nDługość tyłu: 58 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 174 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 108 см \nДовжина по спині: 58 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 174 см",
    h1en: 'Black womens leather jacket',
    h1pl: 'Czarnа kurtka skórzana damska długa',
    h1ua: 'Чорна  косуха подовжена класична',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffg1pu0f2-629_ZEN_1358.jpeg?alt=media&token=d9058470-7aa5-4a69-968f-31aca09399db',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5jxfljmo-630_ZEN_1369.jpeg?alt=media&token=bafdc967-4f5c-46e7-ab0e-d744cdc6b485',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdgdl8qeg-628_ZEN_1332.jpeg?alt=media&token=56569fb3-7c0b-4e7b-ba2d-733c36f7742c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fibra1nni-642_ZEN_1413.jpeg?alt=media&token=1347725a-d1e8-453e-be9f-3b37e82df639',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Revamp your style with a black leather jacket. Timeless and versatile, this essential piece offers a sleek, edgy look that complements any outfit and enhances your wardrobe.',
    seoDescriptionPl:
      'Dodaj odrobinę elegancji do swojej stylizacji z naszą czarną skórzaną kurtką damską oversize. Modna, wygodna i doskonała na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Чорна косуха куртка: класичний та стильний вибір. Додайте нотку елегантності та сучасного дизайну до вашого гардеробу з цією універсальною косухою-курткою.\n\n',
    seoTitleEn: 'Black Leather Jacket A Classic Elongated - Got`s Label',
    seoTitlePl: 'Damska Wydłużona Kurtka Skórzana Czarnа - Got`s Label',
    seoTitleUa: 'Класична подовжена косуха чорна - Got`s Label',
    skin: 'vintage',
    titleEn: 'Сlassic elongated jacket',
    titlePl: 'Klasyczna wydłużona kurtka ',
    titleUa: 'Косуха подовжена класична',
  },
  2024510144620: {
    article: '0020',
    category: 'jackets',
    characteristicEn: 'Short biker jacket made of khaki vintage natural leather.',
    characteristicPl: 'Ramoneska z szerokimi ramionami z naturalnej skóry vintage w kolorze khaki.',
    characteristicUa: 'Коротка байкерська косуха з натуральної шкіри кольору хакі.',
    collection: 'jackets',
    color: 'khaki',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 95 cm\nBack length: 41 cm\nSleeve length from neck: 76 cm\n\nModel height: 173 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 95 cm\nDługość tyłu: 41 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 95 см \nДовжина по спині: 41 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 173 см",
    h1en: 'Womens brown leather jacket',
    h1pl: 'Kurtka skórzana brązowa damska',
    h1ua: 'Чорна вкорочена косуха байкерська',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbet144oy-473_ZEN_3615.jpeg?alt=media&token=1bca6490-6723-4259-ad7d-8bba9925ce2b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fps8xfkhq-475_ZEN_3620.jpeg?alt=media&token=00d312a1-4dc9-492d-9686-928b69f7e152',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ff8vs2f24-514_ZEN_3770.jpeg?alt=media&token=c5f4f38e-8fea-4741-9a0c-f28501281d36',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Foht4h6rr-481_ZEN_3638.jpeg?alt=media&token=ffa16d50-271c-4fcd-ba08-6edde13940a8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F068zg20w-516_ZEN_3776.jpeg?alt=media&token=d191eea8-efc7-4594-b027-3b1eec65e6ac',
    ],
    interest: '',
    newSale: '',
    priceEn: '600',
    priceEnSale: '',
    priceUa: '27000',
    priceUaSale: '',
    seoDescriptionEn:
      'Discover timeless style with a brown leather jacket for women. This classic piece combines rich, warm tones with a versatile design, perfect for adding a touch of elegance and sophistication to any outfit.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o elegancję z naszą brązową skórzaną kurtką damską. Modna, klasyczna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Чорна вкорочена косуха: стильний вибір для сучасного гардеробу. Додайте елегантності та модного вигляду до вашого образу з цією трендовою вкороченою косухою.\n\n',
    seoTitleEn: 'Short Biker Brown Leather Jacket Women  - Got`s Label',
    seoTitlePl: 'Brązowa Skórzana Kurtka - Got`s Label',
    seoTitleUa: 'Байкерська косуха чорна коротка  - Got`s Label',
    skin: 'vintage',
    titleEn: 'Short biker jacket',
    titlePl: 'Skrócona ramoneska ',
    titleUa: 'Коротка байкерська косуха',
  },
  2024510144622: {
    article: '0016',
    category: 'bombers',
    characteristicEn: 'Long oversized bomber jacket with elastic shoulder pads and voluminous sleeves.',
    characteristicPl: 'Długi bomber oversize z naramiennikami, z objętościowymi rękawami na ściągaczu.\n',
    characteristicUa: 'Довгий оверсайз бомбер з підплічниками на резинці з об’ємними рукавами.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the bomber:\n\nChest volume: 149 cm\nBack length: 75 cm\nSleeve length from neck: 80 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 149 cm\nDługość tyłu: 75 cm\nDługość rękawa od szyi: 80 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри бомбера: \n\nОб'єм грудей: 149 см \nДовжина по спині: 75 см \nДовжина рукава від горловини: 80 см \n\nЗріст моделі: 175 см\n",
    h1en: 'Black leather bomber women',
    h1pl: 'Czarna bomberka ',
    h1ua: 'Довгий чорний бомбер жіночий оверсайз',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fpl3kjy3a-C0002961.jpeg?alt=media&token=3defe300-1188-4b82-8632-9d1786e39870',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwizcth64-C0003003.jpeg?alt=media&token=47226b79-583e-48bd-b793-bbf00ead6744',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6vl1nmpv-C0003094.jpeg?alt=media&token=efe58164-b2b5-47be-b430-f81a53c81539',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F96fdrvnq-C0003027.jpeg?alt=media&token=57146e26-0ee1-45ca-8fb7-4093e83d67dc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9qt8ph33-w6djamdu-C0003319.webp?alt=media&token=5b77d98d-4d78-4a8d-9b44-0653ddfc6f60',
    ],
    interest: '',
    newSale: '',
    priceEn: '850',
    priceEnSale: '',
    priceUa: '38250',
    priceUaSale: '',
    seoDescriptionEn:
      'Upgrade your style with a black leather bomber jacket. This timeless and versatile piece features a sleek design that effortlessly adds a sophisticated edge to any outfit, ideal for a polished, modern look.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą czarną bomberką damską. Elegancka, uniwersalna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити чорний жіночий бомбер: класичний і стильний вибір для будь-якого гардеробу. Додайте елегантності та універсальності до вашого образу з цією модною та якісною бомбер-курткою.\n\n',
    seoTitleEn: 'Long Oversize Black Leather Bomber Jacket  - Got`s Label',
    seoTitlePl: 'Czarna Bomberka Oversize Z Naramiennikami - Got`s Label',
    seoTitleUa: 'Довгий чорний бомбер жіночий оверсайз - Got`s Label',
    skin: 'smooth',
    titleEn: 'Long oversized bomber jacket ',
    titlePl: 'Długi bomber oversize ',
    titleUa: 'Довгий оверсайз бомбер',
  },
  2024510144624: {
    article: '0016',
    category: 'bombers',
    characteristicEn: 'Long oversized bomber jacket with elastic shoulder pads and voluminous sleeves.',
    characteristicPl: 'Długi bomber oversize z naramiennikami, z objętościowymi rękawami na ściągaczu.\n',
    characteristicUa: 'Довгий оверсайз бомбер з підплічниками на резинці з об’ємними рукавами.',
    collection: 'jackets',
    color: 'brownVintage',
    descriptionEn:
      'Measurements of the bomber:\n\nChest volume: 149 cm\nBack length: 75 cm\nSleeve length from neck: 80 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 149 cm\nDługość tyłu: 75 cm\nDługość rękawa od szyi: 80 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри бомбера: \n\nОб'єм грудей: 149 см \nДовжина по спині: 75 см \nДовжина рукава від горловини: 80 см \n\nЗріст моделі: 175 см\n",
    h1en: 'Brown leather bomber women',
    h1pl: 'Kurtka bomberka ',
    h1ua: 'Довгий коричневий бомбер жіночий оверсайз',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frew2bjoi-C0003194.jpeg?alt=media&token=70bcc602-6b65-4ad4-a8d5-20cf83f6c6d4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fp84leee0-C0003210.jpeg?alt=media&token=04bebfdd-2716-4f33-9297-e9c39425ef88',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9lxurztg-C0003165.jpeg?alt=media&token=7d78d2b5-64d8-40d2-a28c-8be593f83ef1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6v7jy8gg-C0003231.jpeg?alt=media&token=7dc82444-f211-4f3c-b9e9-89741345176a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fukkoonrm-w6djamdu-C0003319.webp?alt=media&token=42fb83f4-90e4-4488-8f74-77b9171c8834',
    ],
    interest: '',
    newSale: '',
    priceEn: '850',
    priceEnSale: '',
    priceUa: '38250',
    priceUaSale: '',
    seoDescriptionEn:
      'Add a touch of classic style with a brown leather bomber jacket. This rugged yet refined piece offers a rich, warm tone and timeless design, perfect for enhancing any outfit with a sophisticated edge.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o nowoczesny akcent z naszą bomberką damską. Stylowa, wszechstronna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Коричневий жіночий бомбер купити: стильний і сучасний вибір для вашого гардеробу. Оберіть цей елегантний бомбер, щоб додати тепло та характер вашому образу.\n\n',
    seoTitleEn: 'Long Oversize Brown Leather Bomber Jacket  - Got`s Label',
    seoTitlePl: 'Bomberka Damska Z Naramiennikami - Got`s Label',
    seoTitleUa: 'Довгий коричневий бомбер жіночий оверсайз - Got`s Label',
    skin: 'vintage',
    titleEn: 'Long oversized bomber jacket ',
    titlePl: 'Długi bomber oversize',
    titleUa: 'Довгий оверсайз бомбер',
  },
  2024513131006: {
    article: '0003',
    category: 'jackets',
    characteristicEn: 'A short jacket with two buckles on the sleeves and a belt made of natural leather.',
    characteristicPl: 'Skrócona kurtka z dwoma klamrami na rękawach i paskiem, wykonana z naturalnej skóry',
    characteristicUa: 'Коротка косуха з натуральної шкіри з двома пряжками на рукавах та поясом.\n',
    collection: 'jackets',
    color: 'beige',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 100 cm\nBack length: 45 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 100 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 100 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см",
    h1en: 'Beige leather jacket oversized',
    h1pl: 'Ramoneska damska skórzana beżowa',
    h1ua: 'Бежева  шкіряна косуха  з двома пряжками на рукавах',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fe4ant10i-IMG_1258%202.jpg?alt=media&token=1048fe8a-50a6-4732-89e0-1d7a3aa4204f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3v2t0osz-IMG_1257%202.jpg?alt=media&token=62fa3fd2-5c53-4ad0-a17e-2a7e50af1f95',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Foutl1gek-C0001639%202.jpg?alt=media&token=06c42676-9c23-4b15-83c7-42bdc46137d4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3utw6i9c-C0001660%202.jpg?alt=media&token=8fe6db0d-c7c4-44cd-be00-0ffe3edb0d59',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fatdz6xlq-C0001642%202.jpg?alt=media&token=d1df5fc7-36ef-4bce-b7da-783c7f60356d',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Buy a beige leather jacket that blends style and versatility effortlessly. Perfect for any season, this timeless piece is a must-have for your wardrobe.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą beżową ramoneską skórzaną. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити бежеву жіночу косуху: елегантний та універсальний вибір для вашого гардеробу. Виготовлена з високоякісних матеріалів, ця косуха додасть стилю та розкоші будь-якому образу.\n\n',
    seoTitleEn: "Women's Beige Leather Jacket With Two Buckles On Sleeves - Got`s Label",
    seoTitlePl: 'Ramoneska Skórzana Beżowa Z Dwoma Klamrami Na Rękawach I Paskiem',
    seoTitleUa: 'Бежева косуха з двома пряжками на рукавах - Got`s Label',
    skin: 'jumbo',
    titleEn: 'Jacket with two buckles on sleeves',
    titlePl: 'Skrócona kurtka z dwoma klamrami na rękawach ',
    titleUa: 'Косуха з двома пряжками на рукавах',
  },
  2024513131139: {
    article: '0003',
    category: 'jackets',
    characteristicEn: 'A short jacket with two buckles on the sleeves and a belt made of natural leather.',
    characteristicPl: 'Skrócona kurtka z dwoma klamrami na rękawach i paskiem, wykonana z naturalnej skóry\n',
    characteristicUa: 'Коротка косуха з натуральної шкіри з двома пряжками на рукавах та поясом.\n',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 100 cm\nBack length: 45 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 100 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 100 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см",
    h1en: 'Black leather jacket oversized',
    h1pl: 'Ramoneska damska skórzana czarnа',
    h1ua: 'Чорна  шкіряна косуха  з двома пряжками на рукавах',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjt4m5sx2-IMG_1051%202.jpg?alt=media&token=c2d25d06-f303-49d1-91f8-966f3f5d8c4f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftyc68fwt-IMG_1050%202.jpg?alt=media&token=2337b334-5913-4f35-b905-484588a11fac',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjhhk80ub-IMG_1053%202.jpg?alt=media&token=4a004a23-79a8-41c4-be7a-8dcafa48a1c5',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzisb45tj-C0002256%202.jpg?alt=media&token=d3da954f-1d10-4864-b6df-dce77cf29dc3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9eoosb00-C0002254%202.jpg?alt=media&token=f49cf7c2-148b-4adb-8688-c34441a8d66d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwtz4y3wg-IMG_1052%202.jpg?alt=media&token=e233a43f-cfc4-45cc-a909-186f8c967ae3',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Buy a black leather jacket that combines timeless style with unmatched versatility. Perfect for any occasion, this wardrobe essential is a must-have for every fashion-forward individual.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o klasyczny styl z naszą czarną ramoneską skórzaną. Elegancka, modna i idealna na każdą okazję. Zobacz naszą kolekcję!',
    seoDescriptionUa:
      'Купити чорну жіночу косуху: елегантний акцент вашого стилю. Оберіть косуху, яка підкреслить вашу індивідуальність та додасть вишуканості вашому гардеробу.\n\n',
    seoTitleEn: "Women's Black Leather Jacket With Two Buckles On Sleeves - Got`s Label",
    seoTitlePl: 'Ramoneska Skórzana Czarnа Z Dwoma Klamrami Na Rękawach I Paskiem',
    seoTitleUa: 'Чорна косуха з двома пряжками на рукавах - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with two buckles on sleeves',
    titlePl: 'Skrócona kurtka z dwoma klamrami na rękawach ',
    titleUa: 'Косуха  з двома пряжками на рукавах',
  },
  2024513131534: {
    article: '0009',
    category: 'bombers',
    characteristicEn: 'Bomber with pockets on the arms made of natural leather.',
    characteristicPl: 'Bomber z kieszeniami na rękawach, wykonana z naturalnej skóry.',
    characteristicUa: 'Бомбер з кишенями на руках з натуральної шкіри.\n',
    collection: 'jackets',
    color: 'pink',
    descriptionEn: 'Measurements:\n\nChest volume: 96 cm\nBack length: 48 cm\nSleeve length from neck: 75 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry:\n\nObwód klatki piersiowej: 96 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри: \n\nОб'єм грудей: 96 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 175 см\n",
    h1en: 'Pink leather bomber jacket',
    h1pl: 'Różowа skórzana bomberka damska',
    h1ua: 'Рожева куртка бомбер з кишенями на руках та грудях',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5ysuysba-IMG_1270%202.jpg?alt=media&token=9e0f51a0-54b5-4432-9ca7-f9d126296ddd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgwbu5l2r-IMG_1269%202.jpg?alt=media&token=f1de14d9-227a-453a-a8a8-6b8d4d97d798',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frn9i0vdu-IMG_1271%202.jpg?alt=media&token=ec860c37-08ad-4691-99ed-65342362878d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1i60sxqn-C0000262%202.jpg?alt=media&token=0d390df7-e441-4471-a1b7-392eb18337f6',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Feugyzdb6-C0000249%202.jpg?alt=media&token=aec44674-e3db-4b3f-8feb-952c7c119f04',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a bold statement with a pink leather bomber jacket. This vibrant and stylish piece offers a fresh, modern twist on a classic design, adding a chic and eye-catching touch to your wardrobe.',
    seoDescriptionPl:
      'Dodaj odrobinę koloru do swojej garderoby z naszą różową bomberką. Stylowa, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Рожевий шкіряний бомбер: яскравий і стильний акцент для вашого гардеробу. Додайте ніжного кольору та сучасного вигляду до вашого образу з цим модним бомбером.\n\n',
    seoTitleEn: 'Pink Leather Bomber With Pockets On The Arms And Chest  - Got`s Label',
    seoTitlePl: 'Różowа Bomberka Z Kieszeniami Na Rękawach I Na Piersi',
    seoTitleUa: 'Рожева куртка бомбер з кишенями на руках та грудях - Got`s Label',
    skin: 'smooth',
    titleEn: 'Bomber with pockets on the arm',
    titlePl: 'Bomber z kieszeniami na rękawach',
    titleUa: 'Бомбер з кишенями на руках',
  },
  2024513131804: {
    article: '0008',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with large pockets and neck stand, with gold hardware.\n',
    characteristicPl: 'Kurtka oversize z dużymi kieszeniami i stójką z srebrną galanterią.',
    characteristicUa: 'Оверсайз косуха з великими кишенями та стійкою з золотою фурнітурою.\n',
    collection: 'jackets',
    color: 'burgundy',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 120 cm\nBack length: 53 cm\nSleeve length from neck: 75 cm\n\nModel height: 174 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 120 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 174 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 120 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 174 см",
    h1en: 'Red leather jacket oversized',
    h1pl: 'Czerwonykurtka damska skórzana',
    h1ua: 'Червона куртка жіноча шкіряна з великими кишенями і стійкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fy3ydu4xm-8xr6i55f-IMG_1663.webp?alt=media&token=d4d296d4-7d4c-4bb1-af05-7e458bd352d8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4tqnmvet-xabmn2t0-IMG_0456%202.webp?alt=media&token=296d154e-2054-4ff4-949c-b6612ebbc7a3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0wtsjy5h-8u2dnbj6-IMG_0548%202.webp?alt=media&token=32bc3c63-b359-4f04-b153-7fe4723dce61',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0ypwpsmd-8r39ir6m-IMG_0397%202.webp?alt=media&token=2cd39fde-87ca-4d0f-afeb-3cd518ac41a8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjkoe5k8c-lgd5wl3h-IMG_0448%202.webp?alt=media&token=5e5483e2-3424-4197-9045-cba2ef70f49a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fkhspyqya-gb1x2ai0-IMG_0541%202.webp?alt=media&token=1ceb3831-4128-4345-9e2e-1010cfd43022',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a bold impression with a red leather jacket for women. This striking piece combines vibrant color with edgy style, adding a dynamic touch to your wardrobe and turning heads wherever you go.',
    seoDescriptionPl:
      'Dodaj odrobinę wyrazistego koloru do swojej garderoby z naszą czerwoną kurtką damską skórzaną. Modna, elegancka i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Червона косуха oversize: сміливий і модний вибір для вашого гардеробу. Додайте яскравий акцент та сучасний стиль до вашого образу з цією ефектною косухою.\n\n',
    seoTitleEn: 'Oversized Red Leather Jacket With Large Pockets And Neck Stand',
    seoTitlePl: 'Czerwony Skórzana Kurtka Oversize - Got`s Label',
    seoTitleUa: 'Червона косуха з великими кишенями і стійкою - Got`s Label',
    skin: 'smooth',
    titleEn: 'Oversized jacket with large pockets and neck stand',
    titlePl: 'Kurtka oversize z dużymi kieszeniami i stójką',
    titleUa: 'Оверсайз косуха з великими кишенями та стійкою',
  },
  2024513132542: {
    article: '0013',
    category: 'jackets',
    characteristicEn: 'Natural leather classic fit cropped jacket with zip cuffs and cooper hardware.',
    characteristicPl: 'Krótki żakiet z naturalnej skóry o klasycznym kroju z mankietami na rękawach z srebrną galanterią.',
    characteristicUa: 'Укорочений жакет з натуральної шкіри класичного крою з манжетами на рукавах із фурнітурою кольору міді.',
    collection: 'jackets',
    color: 'brownVintage',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 105 cm\nBack length: 45 cm\nSleeve length from neck: 78 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 105 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 78 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 105 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 78 см \n\nЗріст моделі: 173 см",
    h1en: 'Oversized brown leather jacket',
    h1pl: 'Krótka brązowa kurtka skórzana damska',
    h1ua: 'Жакет жіночий укорочений класичного крою з манжетами',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flf8n9awr-f0s2dw7u-IMG_1670.webp?alt=media&token=b7ad343b-6f8f-4b74-9cfd-3642063e975a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fd0zm8pow-IMG_1272%202.jpg?alt=media&token=d4af4ebd-9aa0-4ee6-9ae7-d712ccfd123b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbnncdpdy-IMG_1273%202.jpg?alt=media&token=d9adf0e0-6c2f-4718-9028-d95ef44ec322',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxhgvak2i-C0000190%202.jpg?alt=media&token=159288f2-357a-494f-883a-0665886df143',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '600',
    priceEnSale: '',
    priceUa: '27000',
    priceUaSale: '',
    seoDescriptionEn:
      'Embrace timeless style with a light brown leather jacket. This versatile and chic piece adds a soft, modern touch to any outfit, combining elegance with a warm, neutral tone.',
    seoDescriptionPl:
      'Odkryj klasyczny styl z naszą brązową, krótką skórzaną kurtką. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити жакет жіночий: стильний та елегантний вибір для будь-якого гардеробу. Оберіть з нашого асортименту сучасні та якісні жакети для створення бездоганних образів.\n\n',
    seoTitleEn: 'Classic Fit Cropped Leather Jacket Brown  - Got`s Label',
    seoTitlePl: 'Brązowa Kurtka Skórzana Krótka O Klasycznym Kroju Z Mankietami',
    seoTitleUa: 'Короткий жакет класичного крою з манжетами - Got`s Label',
    skin: 'vintage',
    titleEn: 'Classic fit cropped jacket',
    titlePl: 'Krótki żakiet o klasycznym kroju z mankietami ',
    titleUa: 'Укорочений жакет класичного крою з манжетами ',
  },
  2024513132808: {
    article: '0021',
    category: 'jackets',
    characteristicEn: 'A transformer-jacket made of a natural vintage leather.',
    characteristicPl: 'Kurtka-transformer z naturalnej skóry vintage.',
    characteristicUa: 'Косуха-трансформер з натуральної вінтажної шкіри.',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 98 cm\nBack length: 57 cm\nSleeve length from neck: 72 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 98 cm\nDługość tyłu: 57 cm\nDługość rękawa od szyi: 72 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 98 см \nДовжина по спині: 57 см \nДовжина рукава від горловини: 72 см \n\nЗріст моделі: 175 см",
    h1en: 'Leather blue jacket womens',
    h1pl: 'Ramoneska błękit skórzana',
    h1ua: 'Шкіряна косуха жіноча синя трансформер',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Feqbk3075-IMG_1044%202.jpg?alt=media&token=3317c184-769b-4105-8f83-15639e9d92a5',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1vxw5e4b-IMG_1045%202.jpg?alt=media&token=655ff03f-1144-47a9-876e-66475599735e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4iroarze-IMG_1046%202.jpg?alt=media&token=202ecb46-8f0b-42ef-8139-49522a62327c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fq3tjh9ws-C0002744%202.jpg?alt=media&token=29a6ad60-cec9-4e22-8520-92d8ca5a35c3',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Stand out with a light blue leather jacket. This refreshing and stylish piece adds a unique, vibrant touch to your wardrobe, perfect for elevating any outfit with a modern edge.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą błękitną ramoneską skórzaną. Modna, elegancka i doskonała na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити косуху жіночу синю: стильний акцент для вашого гардеробу. Виготовлена з якісних матеріалів, ця косуха додасть сучасного вигляду та яскравих ноток до будь-якого образу.\n\n',
    seoTitleEn: 'Blue Leather Transformer Jacket - Got`s Label',
    seoTitlePl: 'Błękit Skórzana Ramoneska Transformer - Got`s Label',
    seoTitleUa: 'Шкіряна косуха трансформер синя - Got`s Label',
    skin: 'vintage',
    titleEn: 'A transformer-jacket',
    titlePl: 'Kurtka-transformer',
    titleUa: 'Косуха трансформер',
  },
  2024513133950: {
    article: '0012',
    category: 'jackets',
    characteristicEn: 'Jacket with lacing on the sleeves and back, with cuffs on the sleeves, with cooper hardware.',
    characteristicPl: 'Kurtka ze sznurowaniem na rękawach i z tyłu oraz ściągaczami na rękawach z elementami w kolorze miedzi.',
    characteristicUa: 'Косуха зі шнурівкою на руках та спині, та з манжетами на рукавах із  фурнітурою кольору міді.',
    collection: 'jackets',
    color: 'suede_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 47 cm\nSleeve length from neck: 74 cm\n\nModel height: 173 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 47 cm\nDługość rękawa od szyi: 74 cm\n\nWzrost modelki: 173 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 47 см \nДовжина рукава від горловини: 74 см \n\nЗріст моделі: 173 см",
    h1en: 'Grey leather oversized jacket',
    h1pl: 'Szara kurtka skórzana damska',
    h1ua: 'Косуха сіра зі шнуровкою на руках та спині ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F7s3un1j4-53ylcxx2-IMG_2377.webp?alt=media&token=3b65fc1a-4c1c-494d-98d8-661ca2fd3711',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fiynbfura-3urjnoks-IMG_2378.webp?alt=media&token=5634ad2a-5ad6-4dc1-9e0d-48fe6d939be8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbc37n39k-IMG_1061%202.jpg?alt=media&token=d0b311c9-22cf-4fea-96f9-0141212a9b5d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fv9gn89a5-IMG_1062%202.jpg?alt=media&token=a785608d-7684-4ac6-abda-1428a86b676a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6fuyiq2b-IMG_1060%202.jpg?alt=media&token=84e7c6e3-448b-4b0e-9781-e889782786b1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ff0ewli1o-C0001993%202.jpg?alt=media&token=dec29b16-a106-47d3-9358-a71cf10e1cf6',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fn2pz8wt8-C0001985%202.jpg?alt=media&token=cca21e5a-3457-49e1-944f-5411f49fc483',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Enhance your style with a grey slim fit leather jacket. This sleek, modern piece offers a tailored silhouette and versatile color, perfect for achieving a sharp, sophisticated look.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą szarą skórzaną kurtką damską. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!\n\n',
    seoDescriptionUa:
      'Oversize косуха сіра: стильний акцент для вашого гардеробу. Додайте елегантності та сучасного вигляду до вашого образу з цією модною сірою косухою.\n\n',
    seoTitleEn: 'Grey oversized leather jacket With Lacing On The Sleeves And Back',
    seoTitlePl: 'Kurtka Skórzana Szara Ze Sznurowaniem Na Rękawach I Z Tyłu',
    seoTitleUa: 'Косуха сіра зі шнуровкою на руках та спині - Got`s Label',
    skin: 'suede',
    titleEn: 'Jacket with lacing on the sleeves and back',
    titlePl: 'Kurtka ze sznurowaniem na rękawach i z tyłu',
    titleUa: 'Косуха зі шнурівкою на руках та спині',
  },
  2024513214638: {
    article: '0006',
    category: 'jackets',
    characteristicEn: 'A jacket made of natural leather with an elongated back.',
    characteristicPl: 'Kurtka wykonana ze skóry naturalnej z wydłużonym tyłem.',
    characteristicUa: 'Косуха з натуральної шкіри з подовженою спинкою.',
    collection: 'jackets',
    color: 'suede_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 112 cm\nBack length: 62 cm\nSleeve length from neck: 81 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 112 cm\nDługość tyłu: 62 cm\nDługość rękawa od szyi: 81 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 112 см \nДовжина по спині: 62 см \nДовжина рукава від горловини: 81 см \n\nЗріст моделі: 175 см",
    h1en: 'Graphite leather jacket',
    h1pl: 'Grafit ramoneska skórzana damska',
    h1ua: 'Натуральна шкіряна куртка жіноча сіра з подовженою спинкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftkpm2hx9-15vfrxxt-IMG_1668.webp?alt=media&token=bdecf197-fce2-463d-9184-9272f8763e35',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgiy0epqm-IMG_1063%202.jpg?alt=media&token=50a1fbd8-936b-4c6d-8a8f-a318ba0662ef',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fr607ww8f-IMG_1064%202.jpg?alt=media&token=4a292768-f654-44ce-b3a3-feb1688d9746',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fs3jbidbt-C0001761%202.jpg?alt=media&token=472d18c0-0466-4000-8e06-cd09ff7e5a5d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fujfmjdfc-C0001772%202.jpg?alt=media&token=a055c98c-4bad-4a7a-96bf-66580e3c8667',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Upgrade your style with a graphite leather jacket. This sleek and modern piece offers a sophisticated touch with its versatile grey tones, perfect for adding a refined edge to any outfit.',
    seoDescriptionPl:
      'Dodaj nowoczesnego stylu z naszą grafitową skórzaną kurtką oversize. Modna, wygodna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити стильну жіночу куртку сіру: елегантний та сучасний вибір для вашого гардеробу. Виготовлена з якісних матеріалів, ця куртка додасть шику та універсальності вашому образу.\n\n',
    seoTitleEn: 'Jacket Graphite With An Elongated Back - Got`s Label',
    seoTitlePl: 'Skórzana Grafit Ramoneska Z Wydłużonym Tyłem - Got`s Label',
    seoTitleUa: 'Жіноча куртка сіра з подовженою спинкою - Got`s Label',
    skin: 'suede',
    titleEn: 'A jacket with an elongated back',
    titlePl: 'Kurtka z wydłużonym tyłem',
    titleUa: 'Косуха з подовженою спинкою',
  },
  2024513215136: {
    article: '0025',
    category: 'jackets',
    characteristicEn: 'Cropped jacket made of pink natural leather.',
    characteristicPl: 'Skrócona kurtka wykonana z naturalnej skóry w kolorze różowym.',
    characteristicUa: 'Укорочена косуха з натуральної рожевої шкіри.',
    collection: 'jackets',
    color: 'pink',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 98 cm\nBack length: 36 cm\nSleeve length from neck: 72 cm\n\nModel height: 175 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 98 cm\nDługość tyłu: 36 cm\nDługość rękawa od szyi: 72 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 98 см \nДовжина по спині: 36 см \nДовжина рукава від горловини: 72 см \n\nЗріст моделі: 175 см",
    h1en: 'Oversized leather bomber jacket',
    h1pl: 'Skrócona kurtka wykonana z naturalnej skóry',
    h1ua: 'Рожева косуха укорочена',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fa5667j1i-IMG_1266%203.jpg?alt=media&token=b2233d7b-ecd5-445c-959d-9d56cc279cb1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Femum13f9-IMG_1265%202.jpg?alt=media&token=9e57c6c0-0e38-4124-b533-7d5c70dbddd3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fktplueiu-C0000874%202.jpg?alt=media&token=cbe189f6-fa57-414e-bcda-737f99d8ae92',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fclakkgf2-C0000855%202.jpg?alt=media&token=2e2e7c33-f7fb-478a-831e-f2ea506327d9',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flkso4bk6-C0000849%202.jpg?alt=media&token=f43b6879-6163-4ef8-a0b0-c42422db8535',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      "Revamp your look with a women's leather bomber jacket. Combining rugged charm with a stylish design, this versatile piece adds a bold, contemporary edge to any outfit, perfect for both casual and chic occasions. ",
    seoDescriptionPl:
      'Ożyw swoją stylizację z naszą różową skróconą kurtką. Modna, świeża i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Рожева вкорочена косуха: стильний вибір для вашого гардеробу. Додайте сучасного вигляду до вашого образу з цією модною вкороченою косухою.\n\n',
    seoTitleEn: 'Short Sleeve Cropped Leather Jacket - Got`s Label',
    seoTitlePl: 'Skrócona Kurtka Różowa - Got`s Label',
    seoTitleUa: 'Косуха рожева укорочена  - Got`s Label',
    skin: 'smooth',
    titleEn: 'Cropped jacket',
    titlePl: 'Skrócona kurtka',
    titleUa: 'Укорочена косуха ',
  },
  2024513215550: {
    article: '0026',
    category: 'jackets',
    characteristicEn: 'Classic jacket with shoulder pads made of natural leather.',
    characteristicPl: 'Klasyczna kurtka z poduszkami na ramionach, wykonana z naturalnej skóry',
    characteristicUa: 'Класична косуха з підплечниками з натуральної шкіри.',
    collection: 'jackets',
    color: 'suede_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 49 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 49 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 49 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см",
    h1en: 'Grey leather jacket',
    h1pl: 'Kurtka grafit damska',
    h1ua: 'Сіра шкіряна жіноча куртка з підплечниками',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fp3bhq7hj-IMG_1047%202.jpg?alt=media&token=ce2adbb9-6f4a-42f5-9292-b891fe98b49b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fu4ocsk1p-IMG_1048%202.jpg?alt=media&token=a19f8b52-40ce-4394-b12a-781c0257e680',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fr7hfnu8x-C0002549%202.jpg?alt=media&token=3440b2bc-fd81-4f04-9cf0-68e20b6b32f5',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F23r0np9x-C0002552%202.jpg?alt=media&token=bfa484e1-18e4-409a-8d10-8d07dcaa3283',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgczxke9l-IMG_1049%202.jpg?alt=media&token=a9d0a1e5-99f5-4320-943f-0fdab6b1f93a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5t3lsfdi-C0002565%202.jpg?alt=media&token=e49344a7-0595-4fbe-87ee-ce2c75335bb6',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      "Enhance your wardrobe with a ladies' grey leather jacket. This chic and versatile piece offers a modern twist on classic style, adding a sophisticated and neutral touch to any outfit.",
    seoDescriptionPl:
      'Podkreśl swój styl z naszą grafitową skórzaną ramoneską. Modna, elegancka i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити сіру куртку: сучасний та універсальний вибір для вашого гардеробу. Знайдіть ідеальну сіру куртку, яка додасть стильного вигляду та комфорту будь-якому образу.\n\n',
    seoTitleEn: 'Classic Grey Jacket With Shoulder Pads - Got`s Label',
    seoTitlePl: 'Ramoneska Grafit Skórzana Z Poduszkami Na Ramionach - Got`s Label',
    seoTitleUa: 'Сіра шкіряна жіноча куртка з підплечниками - Got`s Label',
    skin: 'suede',
    titleEn: 'Classic jacket with shoulder pads',
    titlePl: 'Klasyczna kurtka z poduszkami na ramionach',
    titleUa: 'Класична косуха з підплечниками',
  },
  2024513215710: {
    article: '0026',
    category: 'jackets',
    characteristicEn: 'Classic jacket with shoulder pads made of natural pink leather.',
    characteristicPl: 'Klasyczna kurtka z poduszkami na ramionach, wykonana z naturalnej różowej skóry.',
    characteristicUa: 'Класична косуха з підплечниками з натуральної рожевої шкіри.',
    collection: 'jackets',
    color: 'pink',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 49 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 49 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 49 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см",
    h1en: 'Pink leather jacket',
    h1pl: 'Kurtka różowа damska',
    h1ua: 'Рожева шкіряна жіноча куртка з підплечниками ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4y33zxpk-41m9g8la-IMG_1664.webp?alt=media&token=82ecb16c-818d-4f3f-8eff-ab6cbd62e658',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fmm3649me-IMG_1041%202.jpg?alt=media&token=a73db47d-3fed-4923-a4cc-2d84b1408681',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fejbxvgt9-IMG_1040%202.jpg?alt=media&token=2ec27718-c06b-4b90-981c-5e0c338901e2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhuz5sf5v-IMG_1043%202.jpg?alt=media&token=6b87a625-e667-4031-9da4-bc6f69b1d64c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffspa1s8t-IMG_1042%202.jpg?alt=media&token=5b9da711-5ec8-4c49-8000-151360eedc73',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      "Add a pop of color with a ladies' pink leather jacket. This stylish and playful piece brings a fresh, vibrant touch to your wardrobe, perfect for making a fashionable statement with a modern flair.",
    seoDescriptionPl:
      'Dodaj wyjątkowego akcentu do swojej garderoby z naszą różową skórzaną ramoneską. Modna, elegancka i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити рожеву жіночу куртку: додайте яскравого кольору та сучасного стилю до вашого гардеробу. Оберіть цю елегантну куртку, щоб підкреслити вашу індивідуальність і додати романтичного акценту до образу.\n\n',
    seoTitleEn: 'Classic Pink Jacket With Shoulder Pads - Got`s Label',
    seoTitlePl: 'Ramoneska Różowа Skórzana Z Poduszkami Na Ramionach - Got`s Label',
    seoTitleUa: 'Рожева шкіряна жіноча куртка з підплечниками - Got`s Label',
    skin: 'smooth',
    titleEn: 'Classic jacket with shoulder pads ',
    titlePl: 'Klasyczna kurtka z poduszkami na ramionach',
    titleUa: 'Класична косуха з підплечниками',
  },
  2024513221534: {
    article: '0026',
    category: 'jackets',
    characteristicEn: 'Classic jacket with shoulder pads made of natural vintage leather.\n',
    characteristicPl: 'Klasyczna kurtka z poduszkami na ramionach, wykonana z naturalnej skóry vintage',
    characteristicUa: 'Класична косуха з підплечниками з натуральної вінтажної шкіри.\n',
    collection: 'jackets',
    color: 'grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 49 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 49 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 49 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см",
    h1en: 'Graphite leather jacket',
    h1pl: 'Kurtka szara damska',
    h1ua: 'Графітова шкіряна жіноча куртка з підплечниками',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fiiv7edo5-8dilladh-IMG_1672.webp?alt=media&token=680b9ddb-1a4d-4a31-b17c-ef129931b9d0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fafwuxj48-IMG_1057%202.jpg?alt=media&token=8a78bab2-a3a0-4795-af00-588866dcdc42',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fl1z59i19-IMG_1056%202.jpg?alt=media&token=47e9d704-fbeb-4113-a0e9-4587f7366e47',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fi04aiox9-IMG_1059%202.jpg?alt=media&token=ede93f1c-4a03-417f-915b-f66c679a1f9c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fx8uw7p5t-IMG_1058%202.jpg?alt=media&token=f0edb930-0981-4a3b-bf7a-591f5bc3206e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fya9d0ydp-C0002031%202.jpg?alt=media&token=e5fdf115-5b1e-44e9-bc51-468b81e25151',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fznty1x2a-C0002030%202.jpg?alt=media&token=c370edff-b552-4c3d-9d36-40b7e3123208',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      "Upgrade your wardrobe with a ladies' graphite leather jacket. This sleek and sophisticated piece offers a contemporary twist on classic style, adding a refined, versatile touch to any outfit.",
    seoDescriptionPl:
      'Wzbogać swoją stylizację z naszą szarą skórzaną ramoneską. Klasyczna, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити графітову жіночу куртку: стильний вибір для сучасного гардеробу. Додайте елегантності та універсальності до вашого образу з цією елегантною курткою в графітовому кольорі.\n\n',
    seoTitleEn: 'Classic Graphite Jacket With Shoulder Pads - Got`s Label',
    seoTitlePl: 'Ramoneska Szara Skórzana Z Poduszkami Na Ramionach - Got`s Label',
    seoTitleUa: 'Графітова шкіряна жіноча куртка з підплечниками - Got`s Label',
    skin: 'vintage',
    titleEn: 'Classic jacket with shoulder pads',
    titlePl: 'Klasyczna kurtka z poduszkami na ramionach',
    titleUa: 'Класична косуха з підплечниками ',
  },
  2024513225040: {
    article: '0027',
    category: 'jackets',
    characteristicEn: 'A classic jacket of medium length made of natural leather.',
    characteristicPl: 'Klasyczna kurtka średniej długości wykonana ze skóry naturalnej.',
    characteristicUa: 'Класична косуха середньої довжини з натуральної шкіри.',
    collection: 'jackets',
    color: 'beige',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 108 cm\nBack length: 47 cm\nSleeve length from neck: 80 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 108 cm\nDługość tyłu: 47 cm\nDługość rękawa od szyi: 80 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 108 см \nДовжина по спині: 47 см \nДовжина рукава від горловини: 80 см \n\nЗріст моделі: 175 см",
    h1en: 'Beige leather jacket',
    h1pl: 'Ramoneska skórzana beżowa',
    h1ua: 'Бежева класична косуха середньої довжини ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F046o5hsf-IMG_1055%202.jpg?alt=media&token=0751c426-0e58-47ba-b38b-5bc2d0050911',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvqedj140-IMG_1054%202.jpg?alt=media&token=d13387c1-99e1-46b6-9f87-8a6d4dbbc5d7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Futfnke2h-C0002092%202.jpg?alt=media&token=887b13db-1656-49f6-b213-e422a47f7f1d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fkk88vr0n-C0002077%202.jpg?alt=media&token=fb121e36-3ea8-42ab-b055-f4abd2d09913',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2y1auoxu-C0002072%202.jpg?alt=media&token=90cd6d80-914f-479a-a56d-58a52be90ad9',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Refresh your look with a cream leather jacket. This elegant and versatile piece adds a soft, sophisticated touch to your wardrobe, combining classic style with a modern, neutral tone.',
    seoDescriptionPl:
      'Dodaj eleganckiego akcentu do swojej garderoby z naszą beżową skórzaną ramoneską. Modna, stylowa i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Косуха жіноча бежева: стильний вибір для вашого гардеробу. Оберіть цю елегантну бежеву косуху, щоб додати сучасного вигляду та ніжного кольору до вашого образу.\n\n',
    seoTitleEn: 'Classic Cream Leather Jacket Of Medium Length - Got`s Label',
    seoTitlePl: 'Beżowa Ramoneska Skórzana Sredniej Długości  - Got`s Label',
    seoTitleUa: 'Класична косуха середньої довжини - Got`s Label',
    skin: 'jumbo',
    titleEn: 'A classic jacket of medium length',
    titlePl: 'Klasyczna kurtka średniej długości ',
    titleUa: 'Класична косуха середньої довжини',
  },
  2024513230012: {
    article: '0029',
    category: 'jackets',
    characteristicEn: 'Elongated jacket with belt made of natural  leather.',
    characteristicPl: 'Wydłużona kurtka z paskiem, wykonana z naturalnej białej skóry ',
    characteristicUa: 'Подовжена косуха з поясом з натуральної білої шкіри.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 114 cm\nBack length: 64 cm\nSleeve length from neck: 76 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 114 cm\nDługość tyłu: 64 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 175 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 114 см \nДовжина по спині: 64 см \nДовжина рукава від горловини: 76 см\n\nЗріст моделі: 175 см",
    h1en: 'Short leather jacket',
    h1pl: 'Wydłużona kurtka wykonana z naturalnej białej skóry',
    h1ua: 'Косуха світла подовжена з поясом',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvyiz8j90-IMG_1261%202.jpg?alt=media&token=f64862f8-f6d7-43f2-83f3-33db804e43cc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flom99kuu-IMG_1260%202.jpg?alt=media&token=bee8c82c-006e-4fe4-91c0-dfef0399ca91',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5ep4n3w0-C0001412%202.jpg?alt=media&token=07462196-e948-43af-ae26-9d86d1ce7af8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffpn25p07-C0001432%202.jpg?alt=media&token=80f93abb-8891-4847-bfdf-b07fa9da9aaf',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0og7ohka-IMG_1262%202.jpg?alt=media&token=9a89f1cb-ccbf-492e-a9fe-e944602c3dd5',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your wardrobe with a leather jacket for women. Combining timeless elegance with modern style, this versatile piece adds a chic, sophisticated edge to any outfit, perfect for any occasion.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o nowoczesny akcent z naszą wydłużoną kurtką. Elegancka, stylowa i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Біла косуха купити: елегантний вибір для вашого гардеробу. Оберіть цю стильну білу косуху, щоб додати свіжості та класичного стилю до вашого образу.\n\n',
    seoTitleEn: 'Elongated Leather Jacket For Women With Belt - Got`s Label',
    seoTitlePl: 'Wydłużona Kurtka Z Paskiem - Got`s Label',
    seoTitleUa: 'Косуха світла подовжена з поясом  - Got`s Label',
    skin: 'smooth',
    titleEn: 'Elongated jacket with belt',
    titlePl: 'Wydłużona kurtka z paskiem',
    titleUa: 'Подовжена косуха з поясом',
  },
  2024513230440: {
    article: '0028',
    category: 'jackets',
    characteristicEn: 'Jacket with zips and belt made of natural leather.',
    characteristicPl: 'Kurtka z zamkami i paskiem, wykonana z naturalnej skóry',
    characteristicUa: 'Косуха з блискавками та поясом з натуральної шкіри.',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 54 cm\nSleeve length from neck: 75 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 54 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 175 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 54 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 175 см",
    h1en: 'Girls leather jacket',
    h1pl: 'Kurtka Z Zamkami i Paskiem',
    h1ua: 'Куртка косуха сіра з блискавками та поясом',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxy3o24dz-IMG_1268%202.jpg?alt=media&token=8bdbac10-6ffb-41b1-85db-ffad753248a3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3ath8iuo-IMG_1267%202.jpg?alt=media&token=e46ad2a4-953d-4f20-bf1f-7b6ab0f2ceb0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fiqnkfd1a-C0000694%202.jpg?alt=media&token=f6d90ac7-c672-4a6c-a87e-00899ab7bbcd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fp0m012qg-C0000703%202.jpg?alt=media&token=111f3aec-6ad1-4f53-a300-848d274d9a69',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5k7znbfl-C0000692%202.jpg?alt=media&token=af99a0ed-06fb-4d37-b99a-fae1ca243f3c',
    ],
    interest: '',
    newSale: '',
    priceEn: '600',
    priceEnSale: '',
    priceUa: '27000',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a bold fashion statement with a crop leather jacket. This stylish, edgy piece offers a contemporary twist on classic leather, adding a chic and modern touch to any outfit.',
    seoDescriptionPl:
      'Odkryj wyjątkowy styl z naszą kurtką z zamkami i paskiem. Modna, funkcjonalna i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Сіра косуха жіноча: сучасний і стильний вибір для вашого гардеробу. Додайте елегантності та модного вигляду до вашого образу з цією універсальною косухою.\n\n',
    seoTitleEn: 'Crop Leather Jacket With Zips And Belt - Got`s Label',
    seoTitlePl: 'Kurtka Z Zamkami i Paskiem - Got`s Label',
    seoTitleUa: 'Косуха сіра з блискавками та поясом - Got`s Label',
    skin: 'vintage',
    titleEn: 'Jacket with zips and belt',
    titlePl: 'Kurtka z zamkami i paskiem',
    titleUa: 'Косуха з блискавками та поясом',
  },
  2024612202025: {
    article: '0035',
    category: 'jackets',
    characteristicEn: 'Short jacket with shoulder pads made of natural-grained jacket.',
    characteristicPl: 'Skrócona kurtka z poduszkami na ramionach, wykonana z naturalnej ziarnistej skóry.',
    characteristicUa: 'Коротка косуха з підплечниками з натуральної зернистої шкіри. ',
    collection: 'jackets',
    color: 'ginger',
    descriptionEn:
      'Measurements of the jacket:  \n\nChest volume: 94 cm \nBack length: 45 cm \nSleeve length from neck: 77 cm  \n\nModel height: 165 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 165 cm',
    descriptionUa:
      "Параметри косухи:   \n\nОб'єм грудей: 94 см  \nДовжина по спині: 45 см  \nДовжина рукава від горловини: 77 см   \n\nЗріст моделі: 165 см",
    h1en: 'Cropped orange leather jacket',
    h1pl: 'Brązowa ramoneska skórzana',
    h1ua: 'Коричнева куртка косуха жіноча з підплечниками',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fyoikfi2n-IMG_8670.jpg?alt=media&token=8c61fdb8-bfb7-4802-a5fc-9dc23210194e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fatg1jhqy-IMG_8676.jpeg?alt=media&token=e8ea7b0e-bf02-4ae2-bfd0-31f9702f7924',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgxmj540f-vqt82cyz-IMG_8674.webp?alt=media&token=6a0d93c2-040a-464b-8a98-28480a4b8a90',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2zqvv02x-f0r6kgw3-IMG_8711.webp?alt=media&token=73048834-d219-4cd0-9797-8212bfe5e3e5',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Brighten up your wardrobe with an orange leather jacket. This vibrant, eye-catching piece adds a bold pop of color and a modern flair, perfect for standing out with style and confidence.',
    seoDescriptionPl:
      'Dodaj klasycznego stylu do swojej garderoby z naszą brązową skórzaną ramoneską. Elegancka, modna i idealna na każdą okazję. Odkryj naszą ofertę!',
    seoDescriptionUa:
      '\nКоричнева коротка косуха жіноча: стильний вибір для сучасного гардеробу. Додайте елегантності та модного акценту до вашого образу з цією оригінальною короткою косухою.\n\n\n',
    seoTitleEn: 'Short Orange Leather Jacket With Shoulder Pads  - Got`s Label',
    seoTitlePl: 'Brązowa Skórzana Ramoneska Z Poduszkami Na Ramionach - Got`s Label',
    seoTitleUa: 'Коричнева коротка косуха з підплечниками- Got`s Label',
    skin: 'jumbo',
    titleEn: 'Short jacket with shoulder pads ',
    titlePl: 'Skrócona kurtka z poduszkami na ramionach',
    titleUa: 'Коротка косуха з підплечниками',
  },
  2024612202621: {
    article: '0004',
    category: 'jackets',
    characteristicEn: 'A short jacket with flowers in chest made of a natural leather.',
    characteristicPl: 'Krótka kurtka z kwiatami na piersi, wykonana z naturalnej skóry.',
    characteristicUa: 'Коротка косуха з натуральної шкіри з квітами на грудях.\n',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:  \n\nChest volume: 94 cm \nBack length: 45 cm \nSleeve length from neck: 75 cm  \n\nModel height: 165 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 165 cm',
    descriptionUa:
      "Параметри косухи:   \n\nОб'єм грудей: 94 см  \nДовжина по спині: 45 см  \nДовжина рукава від горловини: 75 см   \n\nЗріст моделі: 165 см",
    h1en: 'Leather jacket store',
    h1pl: 'Ramoneska skórzana naturalna damska',
    h1ua: 'Шкіряний жакет з квітами',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqqrttatp-tfx38ri4-IMG_1029.webp?alt=media&token=16a889c1-8a0f-429d-a200-71119b7a797e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6ckzn9fb-IMG_1014.jpeg?alt=media&token=1529c495-4f91-4626-a69f-082f58227979',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnxcviu5u-8ymir8xf-IMG_1057.webp?alt=media&token=03675e8a-2a46-436c-9666-0199833f7c07',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fow8ce05n-agbuvcix-IMG_1022.webp?alt=media&token=74ae5ddf-b54f-4d1e-b006-0c155ae6d17d',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Don’t miss out on our leather jacket sale! Find premium quality jackets at unbeatable prices. Shop now to upgrade your wardrobe with classic style and modern flair.',
    seoDescriptionPl:
      'Wzbogać swoją stylizację dzięki naszej krótkiej ramonesce skórzanej. Modna, elegancka i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Шкіряний жакет жіночий: елегантність і сучасний стиль. Виготовлений з високоякісної шкіри, цей жакет додасть розкоші та неповторності вашому гардеробу.\n\n',
    seoTitleEn: 'Best Leather Jacket With Flowers - Got`s Label',
    seoTitlePl: 'Krótka Ramoneska Skórzana - Got`s Label',
    seoTitleUa: 'Куртка  жакет з квітами - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with flowers',
    titlePl: 'Kurtka z kwiatami',
    titleUa: 'Жакет з квітами ',
  },
  2024612202942: {
    article: '0035',
    category: 'jackets',
    characteristicEn: 'Short jacket with shoulder pads made of natural-grained jacket.',
    characteristicPl: 'Skrócona kurtka z poduszkami na ramionach, wykonana z naturalnej ziarnistej skóry.',
    characteristicUa: 'Коротка косуха з підплечниками з натуральної зернистої шкіри. ',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:  \n\nChest volume: 94 cm \nBack length: 45 cm \nSleeve length from neck: 77 cm  \n\nModel height: 165 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 165 cm',
    descriptionUa:
      "Параметри косухи:   \n\nОб'єм грудей: 94 см  \nДовжина по спині: 45 см  \nДовжина рукава від горловини: 77 см   \n\nЗріст моделі: 165 см",
    h1en: 'Cropped grey leather jacket',
    h1pl: 'Szara ramoneska skórzana',
    h1ua: 'Сіра куртка косуха жіноча з підплечниками',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9gichm1a-_MG_9316.webp?alt=media&token=b119547d-0139-4913-b1ec-93b55e1826c4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fz5zfxi3i-39v4l7zl-_MG_9245.webp?alt=media&token=505ced08-b347-41ca-8f8b-a6a6450a0ead',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjrvhy2x2-_MG_9292.webp?alt=media&token=c839fbde-c07c-42c1-b37b-23da2b48b627',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F1h6hfo2g-_MG_9291.webp?alt=media&token=07e4b125-6398-4172-be25-b1bbf468ba80',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flwmg8fr0-_MG_9464.webp?alt=media&token=fcb9fa47-2cd3-4cb7-9991-e83766ae9eba',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Elevate your style with a grey leather crop jacket. This sleek and modern piece combines contemporary design with a versatile color, adding a chic and edgy touch to any outfit.',
    seoDescriptionPl:
      'Ożyw swój look z naszą szaro skórzaną ramoneską damską. Modna, elegancka i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Сіра коротка косуха жіноча: сучасний та стильний вибір для вашого гардеробу. Додайте елегантності та модного вигляду до вашого образу з цією універсальною короткою косухою.',
    seoTitleEn: 'Short Grey Leather Jacket With Shoulder Pads  - Got`s Label',
    seoTitlePl: 'Szara Skórzana Ramoneska Z Poduszkami Na Ramionach - Got`s Label',
    seoTitleUa: 'Сіра коротка косуха з підплечниками - Got`s Label',
    skin: 'vintage',
    titleEn: 'Short jacket with shoulder pads',
    titlePl: 'Skrócona kurtka z poduszkami na ramionach',
    titleUa: 'Коротка косуха з підплечниками',
  },
  2024612203200: {
    article: '0004',
    category: 'jackets',
    characteristicEn: 'A short jacket with flowers in chest made of a natural leather.',
    characteristicPl: 'Krótka kurtka z kwiatami na piersi, wykonana z naturalnej skóry.',
    characteristicUa: 'Коротка косуха з натуральної шкіри з квітами на грудях.\n',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:  \n\nChest volume: 94 cm \nBack length: 45 cm \nSleeve length from neck: 75 cm  \n\nModel height: 165 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 165 cm',
    descriptionUa:
      "Параметри косухи:   \n\nОб'єм грудей: 94 см  \nДовжина по спині: 45 см  \nДовжина рукава від горловини: 75 см   \n\nЗріст моделі: 165 см",
    h1en: 'Cool leather jacket',
    h1pl: 'Białа ramoneska skórzana naturalna damska',
    h1ua: 'Жакет жіночий оверсайз з квітами',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgmfawfkm-zktib5kp-_MG_9643.webp?alt=media&token=bd463f4d-8b24-44fb-9afa-7075e7d39878',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Feqk1aeoa-oumdgg8a-_MG_9798.webp?alt=media&token=0d7cdb52-d095-4b01-83d9-b077af0a18fc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3fxsa6f1-nvkyl9ty-_MG_9674.webp?alt=media&token=fb3d012c-3625-458c-8e65-9f9d4557b089',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F35wnf0r8-cgasv546-_MG_9657.webp?alt=media&token=22271245-ebe0-42bd-9db4-3ee9f1b3cf76',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fja4pi8o3-beb4qxu6-_MG_9677.webp?alt=media&token=a95c316d-d632-4764-9502-a925f614f326',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Experience the luxury of an authentic leather jacket. Crafted from genuine, high-quality leather, this timeless piece combines durability with unparalleled style for a truly sophisticated look.',
    seoDescriptionPl:
      'Odkryj wyjątkowy styl z naszą białą, krótką ramoneską skórzaną. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Жакет купити: оберіть стильний акцент для вашого гардеробу. Великий вибір і доступні ціни на найкращі жіночі жакети чекають на вас!\n\n',
    seoTitleEn: 'Custom Leather Jacket With Flowers - Got`s Label',
    seoTitlePl: 'Krótka Ramoneska Skórzana Białа - Got`s Label',
    seoTitleUa: 'Жакет жіночий оверсайз з квітами - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with flowers',
    titlePl: 'Kurtka z kwiatami',
    titleUa: 'Жакет з квітами ',
  },
  2024629110853: {
    article: '0007',
    category: 'jackets',
    characteristicEn: 'A classic elongated blue jacket made of natural leather.',
    characteristicPl: 'Klasyczna wydłużona kurtka z naturalnej skóry w kolorze niebieskim.',
    characteristicUa: 'Класична подовжена косуха з натуральної шкіри синього кольору.',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 108 cm\nBack length: 58 cm\nSleeve length from neck: 77 cm\n\nModel height: 170 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 108 cm\nDługość tyłu: 58 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 108 см \nДовжина по спині: 58 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 170 см",
    h1en: 'Blue womens leather jacket',
    h1pl: 'Błękit kurtka skórzana damska długa',
    h1ua: 'Синя косуха подовжена класична',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5bogx5pe-IMG_4155%202.jpg?alt=media&token=db01545b-8da9-424f-86c9-2fd98195fd36',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flwll9rc1-IMG_4159%202.jpg?alt=media&token=4abf78e6-3855-49d9-bcdc-0ec3fb3e4cb1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fesr77s1i-IMG_4153%202.jpg?alt=media&token=a8bfbe32-1b65-4904-a861-151fbe750728',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5i2h4ofr-IMG_4160%202.jpg?alt=media&token=c8c50a1a-969d-473a-a77c-2c8589f15cb1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fp46owapt-IMG_4158%202.jpg?alt=media&token=a99bbe39-8518-4a4c-a331-006d640a84ad',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a bold statement with a blue leather jacket. Vibrant and stylish, this eye-catching piece adds a unique touch to any outfit, perfect for standing out in any crowd.',
    seoDescriptionPl:
      'Wyróżnij się z tłumu dzięki naszej błękitnej skórzanej kurtce damskiej oversize. Stylowa, wygodna i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Синя косуха куртка: стильна та універсальна. Оберіть цю косуху-куртку, щоб додати нотку модного шарму та комфорту до вашого гардеробу.\n\n',
    seoTitleEn: 'Blue Leather Jacket A Classic Elongated - Got`s Label',
    seoTitlePl: 'Damska Wydłużona Kurtka Skórzana Błękit - Got`s Label',
    seoTitleUa: 'Класична подовжена косуха синя - Got`s Label',
    skin: 'jumbo',
    titleEn: 'A classic elongated jacket ',
    titlePl: 'Klasyczna wydłużona kurtka ',
    titleUa: 'Косуха подовжена класична',
  },
  2024629111321: {
    article: '0021',
    category: 'jackets',
    characteristicEn: 'A transformer-jacket made of a natural smooth white leather.',
    characteristicPl: 'Kurtka-transformer z naturalnej białej skóry.',
    characteristicUa: 'Косуха-трансформер з натуральної гладкої білої шкіри.',
    collection: 'jackets',
    color: 'white',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 98 cm\nBack length: 57 cm\nSleeve length from neck: 72 cm\n\nModel height: 170 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 98 cm\nDługość tyłu: 57 cm\nDługość rękawa od szyi: 72 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 98 см \nДовжина по спині: 57 см \nДовжина рукава від горловини: 72 см \n\nЗріст моделі: 170 см\n",
    h1en: 'Leather white jacket womens',
    h1pl: 'Ramoneska białа skórzana',
    h1ua: 'Шкіряна косуха жіноча біла трансформер',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzoop4yll-IMG_4241_VSCO%202.jpg?alt=media&token=71cf2e74-6446-4e82-a1eb-ecb638d42592',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fyr7nopd6-IMG_4244_VSCO%202.jpg?alt=media&token=13fd0138-4f19-492e-a129-7f8467cc956a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fog3ox9if-IMG_4245_VSCO%202.jpg?alt=media&token=05340d7a-6307-46b0-9dda-968001522163',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvz03gqxg-IMG_4243_VSCO%202.jpg?alt=media&token=d033cea0-3b33-421a-8716-6cbd8b61e6ef',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Futdd38ui-IMG_4240_VSCO%202.jpg?alt=media&token=f3d1ce68-a3b3-4cc6-8021-4d34d9b4c72c',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Illuminate your wardrobe with a light white leather jacket. This elegant, versatile piece offers a fresh, contemporary look that effortlessly enhances any outfit with its crisp and modern style.',
    seoDescriptionPl:
      'Dodaj świeżości swojej garderobie z naszą białą ramoneską skórzaną. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити косуху жіночу білу: елегантний та стильний вибір для вашого гардеробу. Виготовлена з високоякісних матеріалів, ця косуха додасть вашому образу свіжості та неповторності.\n\n',
    seoTitleEn: 'White Leather Transformer Jacket - Got`s Label',
    seoTitlePl: 'Białа Skórzana Ramoneska Transformer - Got`s Label',
    seoTitleUa: 'Шкіряна косуха трансформер біла - Got`s Label',
    skin: 'smooth',
    titleEn: 'A transformer-jacket',
    titlePl: 'Kurtka-transformer',
    titleUa: 'Косуха трансформер',
  },
  2024629115208: {
    article: '0033',
    category: 'jackets',
    characteristicEn: 'A short elegant jacket made of natural smooth leather.',
    characteristicPl: 'Krótka elegancka marynarka wykonana z naturalnej gładkiej skóry.',
    characteristicUa: 'Короткий елегантний жакет з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:  \n\nChest volume: 94 cm \nBack length: 45 cm \nSleeve length from neck: 75 cm  \n\nModel height: 170 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи:   \n\nОб'єм грудей: 94 см  \nДовжина по спині: 45 см  \nДовжина рукава від горловини: 75 см   \n\nЗріст моделі: 170 см",
    h1en: 'Leather blue jacket woman',
    h1pl: 'Ramoneska skórzana niebieski',
    h1ua: 'Короткий блакитний приталений жакет',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ff4cv2bx8-IMG_4257_VSCO%202.jpg?alt=media&token=65d955b8-9d69-4e7c-8630-7c79b7091fdf',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fakbof5ik-IMG_4258_VSCO%202.jpg?alt=media&token=17480c64-106d-4e47-8a09-917390f33192',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fj1ngx4ib-IMG_4259_VSCO%202.jpg?alt=media&token=4de1f2b6-9625-4193-95b6-2811231b3ac6',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fkskwguuo-IMG_4260_VSCO%202.jpg?alt=media&token=22c029ff-dfd4-4dc3-95a4-091f336a51ae',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      "Stand out with a leather blue jacket for women. This striking piece blends bold color with sleek design, offering a modern and stylish update to your wardrobe that's perfect for making a statement.",
    seoDescriptionPl:
      'Dodaj odrobinę koloru do swojej garderoby z naszą niebieską kurtką skórzaną. Stylowa, nowoczesna i idealna na każdą okazję. Odkryj naszą ofertę!',
    seoDescriptionUa:
      'Блакитний жакет оверсайз: стильний і модний вибір для вашого гардеробу. Додайте сучасного вигляду та розслабленого шарму до вашого образу з цим елегантним жакетом.\n\n',
    seoTitleEn: 'Short Elegant Light Blue Leather Jacket  - Got`s Label',
    seoTitlePl: 'Niebieski Kurtka Skórzana - Got`s Label',
    seoTitleUa: 'Короткий елегантний блакитний жакет  - Got`s Label',
    skin: 'jumbo',
    titleEn: 'Short elegant jacket',
    titlePl: 'Krótka elegancka marynarka ',
    titleUa: 'Короткий елегантний жакет',
  },
  2024629115422: {
    article: '0021',
    category: 'jackets',
    characteristicEn: 'A transformer-jacket made of a natural smooth pink leather.',
    characteristicPl: 'Kurtka-transformer z naturalnej różowej skóry\n',
    characteristicUa: 'Косуха-трансформер з натуральної рожевої гладкої шкіри.',
    collection: 'jackets',
    color: 'pink',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 98 cm\nBack length: 57 cm\nSleeve length from neck: 72 cm\n\nModel height: 170 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 98 cm\nDługość tyłu: 57 cm\nDługość rękawa od szyi: 72 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 98 см \nДовжина по спині: 57 см \nДовжина рукава від горловини: 72 см \n\nЗріст моделі: 170 см",
    h1en: 'Leather pink jacket womens',
    h1pl: 'Ramoneska różowа skórzana',
    h1ua: 'Шкіряна косуха жіноча рожева трансформер',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Faetg4tg2-IMG_4228%202.jpg?alt=media&token=50115431-de0a-4494-91d3-07b3f0d90155',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fl23pxin7-IMG_4229%202.jpg?alt=media&token=d50e70d5-0f6d-438f-a2d7-b11ea54247c5',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flr1brufg-IMG_4231%202.jpg?alt=media&token=0bfcad62-239f-4283-b6ab-df40fb2dd7d0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fb6kb9tc0-IMG_4230%202.jpg?alt=media&token=f509722a-4980-4eb7-849a-c47625046b35',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F89mn9ale-IMG_4221%202.jpg?alt=media&token=fde6e1f5-ed4c-40ae-b4d1-6613bbae5294',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Add a touch of charm with a light pink leather jacket. This playful yet sophisticated piece brings a soft, modern twist to your wardrobe, perfect for making a stylish statement with ease.',
    seoDescriptionPl:
      'Ożyw swoją stylizację z naszą różową ramoneską skórzaną. Modna, elegancka i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити косуху жіночу рожеву: чуттєвий вибір для вашого стилю. Знайдіть ідеальну рожеву косуху, щоб додати ніжності та елегантності до вашого гардеробу.\n\n',
    seoTitleEn: 'Pink Leather Transformer Jacket - Got`s Label',
    seoTitlePl: 'Różowа Skórzana Ramoneska Transformer - Got`s Label',
    seoTitleUa: 'Шкіряна косуха трансформер рожева - Got`s Label',
    skin: 'smooth',
    titleEn: 'A transformer-jacket ',
    titlePl: 'Kurtka-transformer',
    titleUa: 'Косуха трансформер',
  },
  202469204500: {
    article: '0026',
    category: 'jackets',
    characteristicEn: 'Classic jacket with shoulder pads made of natural eather.\n',
    characteristicPl: 'Klasyczna kurtka z poduszkami na ramionach, wykonana z naturalnej skóry.',
    characteristicUa: 'Класична косуха з підплечниками з натуральної шкіри.',
    collection: 'jackets',
    color: 'khaki',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 49 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 49 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 49 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см",
    h1en: 'Brown  leather jacket',
    h1pl: 'Kurtka brązowa damska',
    h1ua: 'Коричнева шкіряна жіноча куртка з підплечниками ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fiwrft3lu-itqs3d6j-tempImageZhVWum.webp?alt=media&token=1cd6443f-840d-42db-a2b9-6f24816a5cbd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbym4xt5e-tempImageWKwinN.jpg?alt=media&token=1f66ee51-5f70-4f2e-ba91-22632c053d59',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fscmblgpr-x867tu0x-tempImagedcmk2q.webp?alt=media&token=36dc451d-375e-4d73-951f-346f995fc832',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fevsatzq4-ydhg39pi-tempImagecCG1if.webp?alt=media&token=426187cb-e9a6-4e81-8b24-c233e5d26086',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      "Embrace timeless elegance with a ladies' brown leather jacket. This classic piece offers a rich, warm tone and versatile design, perfect for adding a touch of sophistication to any outfit.",
    seoDescriptionPl:
      'Odkryj ponadczasowy styl z naszą brązową kurtką. Elegancka, wszechstronna i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Купити коричневу жіночу куртку: елегантний вибір для вашого гардеробу. Оберіть цю стильну куртку, щоб додати теплого тону та класичного стилю до вашого образу.\n\n',
    seoTitleEn: 'Classic Brown Jacket With Shoulder Pads - Got`s Label',
    seoTitlePl: 'Ramoneska Brązowa Skórzana Z Poduszkami Na Ramionach - Got`s Label',
    seoTitleUa: 'Коричнева шкіряна жіноча куртка з підплечниками - Got`s Label',
    skin: 'jumbo',
    titleEn: 'Classic jacket with shoulder pads',
    titlePl: 'Klasyczna kurtka z poduszkami na ramionach',
    titleUa: 'Класична косуха з підплечниками ',
  },
  202469204754: {
    article: '0003',
    category: 'jackets',
    characteristicEn: 'A short jacket with two buckles on the sleeves and a belt made of natural leather.\n',
    characteristicPl: 'Skrócona kurtka z dwoma klamrami na rękawach i paskiem, wykonana z naturalnej skóry',
    characteristicUa: 'Коротка косуха з натуральної шкіри з двома пряжками на рукавах та поясом.\n',
    collection: 'jackets',
    color: 'suede_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 100 cm\nBack length: 45 cm\nSleeve length from neck: 79 cm\n\nModel height: 175 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 100 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 100 см \nДовжина по спині: 45 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 175 см\n",
    h1en: 'Graphite leather jacket oversized',
    h1pl: 'Ramoneska damska skórzana grafit',
    h1ua: 'Сіра шкіряна косуха з двома пряжками на рукавах',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fpv1pnme6-tempImageT5A6Nk.jpg?alt=media&token=c70b4f27-26a0-4075-b2fc-3e8af7b398ff',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F87e65ehd-tempImage6v1N3A.jpg?alt=media&token=abbe61a4-97b7-4285-a267-3071ff065d73',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0s43il1l-porna1eu-tempImageyfqnGw.webp?alt=media&token=e6f0b1c3-ffe1-40ac-aa80-97dcb47e5065',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4wsxjc4t-llw9g2xs-tempImagesvpHho.webp?alt=media&token=f786a3c5-94e6-49f6-9f10-ce15e5fcebf3',
    ],
    interest: '',
    newSale: '',
    priceEn: '650',
    priceEnSale: '',
    priceUa: '29250',
    priceUaSale: '',
    seoDescriptionEn:
      'Buy a graphite leather jacket that exudes modern sophistication. Sleek, stylish, and versatile, this must-have piece enhances any wardrobe with a contemporary edge.',
    seoDescriptionPl:
      'Podkreśl swój unikalny styl z naszą grafitową ramoneską skórzaną. Modna, elegancka i idealna na każdą okazję. Odkryj naszą ofertę!',
    seoDescriptionUa:
      'Купити сіру жіночу косуху: стильний і практичний вибір для вашого гардеробу. Оберіть цю косуху, щоб додати елегантності та модного шарму до вашого повсякденного або вечірнього образу.\n\n',
    seoTitleEn: "Women's Graphite Leather Jacket With Two Buckles On Sleeves - Got`s Label",
    seoTitlePl: 'Ramoneska Skórzana Grafit Z Dwoma Klamrami Na Rękawach I Paskiem',
    seoTitleUa: 'Сіра косуха з двома пряжками на рукавах - Got`s Label',
    skin: 'suede',
    titleEn: 'Jacket with two buckles on sleeves',
    titlePl: 'Skrócona kurtka z dwoma klamrami na rękawach ',
    titleUa: 'Косуха з двома пряжками на рукавах',
  },
  202469205127: {
    article: '0011',
    category: 'jackets',
    characteristicEn: 'The elongated jacket with pockets made of natural leather.',
    characteristicPl: 'Kurtka wydłużona z kieszeniami, wykonywana ze skóry naturalnej.\n',
    characteristicUa: 'Косуха подовжена з кишенями з натуральної шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 118 cm\nHips volume: 110 cm\nBack length: 60 cm\nSleeve length from neck: 78 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 118 cm\nObwód bioder: 110 cm\nDługość tyłu: 60 cm\nDługość rękawa od szyi: 78 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 118 см \nОб'єм бедер: 110 см\nДовжина по спині: 60 см \nДовжина рукава від горловини: 78 см \n\nЗріст моделі: 175 см",
    h1en: 'Black long leather jacket',
    h1pl: 'Skórzana czarnа damska ramoneska',
    h1ua: 'Косуха довга чорна з кишенями',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6hgbvsk9-tempImageRVPd18.jpg?alt=media&token=5b230111-7db7-45ef-8ef6-7a34854b727a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fakluokjl-tempImagehAuWQc.jpg?alt=media&token=6e1248f7-333f-4444-bcfd-8d4cc9b50be1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fo61nhycv-tempImagegZmvme.jpg?alt=media&token=8252b34c-499a-48af-b28c-3bc43aa93642',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fw77ram3m-tempImageM253iL.jpg?alt=media&token=f3736c46-c1da-4bb1-b34a-b072383977ae',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      "Discover the perfect blend of style and sophistication with a ladies' black leather jacket. Timeless and versatile, this essential piece enhances any outfit with its sleek design and classic appeal.",
    seoDescriptionPl:
      'Podkreśl swój styl z naszą czarną skórzaną kurtką ramoneską. Modna, elegancka i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Косуха жіноча шкіряна чорна: класичний і стильний вибір для вашого гардеробу. Виготовлена з високоякісної шкіри, ця косуха додає вашому образу елегантності та сучасного вигляду.\n\n',
    seoTitleEn: 'Black Elongated Leather Long Jacket With Pocked - Got`s Label',
    seoTitlePl: 'Skórzana Czarnа Kurtka Ramoneska Wydłużona Z Kieszeniami',
    seoTitleUa: 'Косуха довга чорна з кишенями  - Got`s Label',
    skin: 'smooth',
    titleEn: ' Elongated jacket with pockets',
    titlePl: 'Kurtka wydłużona z kieszeniami',
    titleUa: 'Косуха подовжена з кишенями',
  },
  202469205329: {
    article: '0015',
    category: 'jackets',
    characteristicEn: 'Long oversized jacket with wide sleeves, pockets, and silver hardware details.\n',
    characteristicPl: 'Długa kurtka oversize z szerokimi rękawami, kieszeniami i detalami ze srebrną galanterią',
    characteristicUa: 'Довга оверсайз косуха з широкими рукавами, кишенями та деталями зі сріблястою фурнітурою.\n',
    collection: 'jackets',
    color: 'vintage_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 118 cm\nBack length: 56 cm\nSleeve length from neck: 74 cm\n\nModel height: 175 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 118 cm\nDługość tyłu: 56 cm\nDługość rękawa od szyi: 74 cm\n\nWzrost modelki: 175 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 118 см \nДовжина по спині: 56 см \nДовжина рукава від горловини: 74 см \n\nЗріст моделі: 175 см",
    h1en: 'Grey leather jacket long',
    h1pl: 'Skórzana szara ramoneska oversize',
    h1ua: 'Сіра куртка косуха оверсайз з широкими рукавами ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Foz8bjfxp-tempImageXYQX5q.jpg?alt=media&token=d9f37cc2-4184-41c2-8264-5b6c85eaae82',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftc5yk9tj-tempImageUyGqvZ.jpg?alt=media&token=6e2259c4-95ae-4318-a700-13163642a2a8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9isp24bk-tempImagerzFDS2.jpg?alt=media&token=ba9601b3-85cc-4b86-b9bd-5f7310452cfe',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ff1xudx2g-pxpk6qil-tempImageWecqcQ.webp?alt=media&token=1a7b6fce-f905-408a-a77f-f5ef3c14614d',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Refine your look with a woman’s grey leather jacket. This stylish and versatile piece blends modern sophistication with classic charm, perfect for elevating any outfit with a touch of elegance.',
    seoDescriptionPl:
      'Odkryj nowoczesny styl z naszą szarą skórzaną ramoneską oversize. Modna, wygodna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Сіра шкіряна куртка косуха: сучасний стиль та елегантний вигляд. Виготовлена з високоякісної шкіри, ця косуха додає неповторності та шику будь-якому образу.\n\n',
    seoTitleEn: 'Long Grey Oversized Leather Jacket With Wide Sleeves - Got`s Label',
    seoTitlePl: 'Skórzana Szara Kurtka Z Szerokimi Rękawami - Got`s Label',
    seoTitleUa: 'Сіра куртка косуха оверсайз з широкими рукавами - Got`s Label',
    skin: 'vintage',
    titleEn: 'Long oversized jacket with wide sleeves',
    titlePl: 'Długa kurtka oversize z szerokimi rękawami',
    titleUa: 'Довга оверсайз косуха з широкими рукавами',
  },
  202469210114: {
    article: '0005',
    category: 'jackets',
    characteristicEn: 'Base oversize jacket made of natural vintage grained leather.',
    characteristicPl: 'Podstawowa kurtka oversize z naturalnej ziarnistej skóry vintage.\n\n',
    characteristicUa: 'Базова оверсайз косуха з натуральної вінтажної зернистої шкіри.',
    collection: 'jackets',
    color: 'vintage_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nBack length: 53 cm\nSleeve length from neck: 76 cm\n\nModel height: 165 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 110 cm\nDługość tyłu: 53 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 165 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см \nДовжина по спині: 53 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 165 см",
    h1en: 'Classic graphite leather jacket',
    h1pl: 'Grafit ramoneska skórzana damska oversize',
    h1ua: 'Базова оверсайз косуха сіра ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6z1ve46c-47w5s5cu-IMG_8938.webp?alt=media&token=05eb29f5-2753-4d73-88d0-d4f73ab636bd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fuqsm06sc-69a59irq-IMG_8956.webp?alt=media&token=d19386e2-9154-4a2b-ac29-51534116489a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftx8plarc-cyodqigz-_MG_8913.webp?alt=media&token=5ff25c61-15c5-4fe0-b437-8ca434726786',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhqkc36oe-s37gwtyg-_MG_8909.webp?alt=media&token=eb80bc48-d349-43a3-b0ef-23f8e0f41bfe',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fucfww4p7-yihjo2je-_MG_8892.webp?alt=media&token=e6d89dd8-ad4f-4489-ade0-4319ab07308d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fl8zvk6fy-vivj5gz8-IMG_8892.webp?alt=media&token=9c5ce930-299c-46ba-8ac2-924585d5a040',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fouu6w0p7-45bcnqkc-IMG_8893.webp?alt=media&token=12e9461d-98bf-456c-9b45-0e2d765460e9',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Discover the perfect blend of style and comfort with an oversized graphite leather jacket for women. This modern, relaxed-fit piece offers a sleek, sophisticated look that enhances any wardrobe.',
    seoDescriptionPl:
      'Odkryj nowoczesny styl z naszą grafitową ramoneską skórzaną oversize. Elegancka, wygodna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити сіру шкіряну куртку жіночу: стильний і сучасний вибір для вашого гардеробу. Виготовлена з високоякісної шкіри, ця куртка додає елегантності та універсальності вашому образу.\n\n',
    seoTitleEn: 'Base Graphite Leather Jacket Oversized - Got`s Label',
    seoTitlePl: 'Grafit Ramoneska Skórzana Oversize - Got`s Label',
    seoTitleUa: 'Базова оверсайз косуха сіра - Got`s Label',
    skin: 'vintage',
    titleEn: 'Base oversize jacket ',
    titlePl: 'Podstawowa kurtka oversize ',
    titleUa: 'Базова оверсайз косуха',
  },
  202469210956: {
    article: '0033',
    category: 'jackets',
    characteristicEn: 'A short elegant jacket made of natural smooth leather.',
    characteristicPl: 'Krótka elegancka marynarka wykonana z naturalnej gładkiej skóry.',
    characteristicUa: 'Короткий елегантний жакет з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'burgundy',
    descriptionEn:
      'Measurements of the jacket:  \n\nChest volume: 94 cm \nBack length: 45 cm \nSleeve length from neck: 75 cm  \n\nModel height: 165 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 45 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 165 cm',
    descriptionUa:
      "Параметри косухи:   \n\nОб'єм грудей: 94 см  \nДовжина по спині: 45 см  \nДовжина рукава від горловини: 75 см   \n\nЗріст моделі: 165 см",
    h1en: 'Leather red jacket woman',
    h1pl: 'Ramoneska skórzana bordowa',
    h1ua: 'Короткий червоний приталений жакет',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fflko47wj-cgh5fu8b-IMG_8727.webp?alt=media&token=f77a0885-f487-432a-956f-32de469fde6e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9ym3bc8e-pl807urn-_MG_8646.webp?alt=media&token=dd3613e8-2550-4090-8ad2-18aab6f993b9',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0a2nym6d-5uqcjwzg-_MG_8641.webp?alt=media&token=16cd99ef-1a12-4a0f-b1ed-82120464c0ea',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F01vhtbax-g6rxr4vv-_MG_8648.webp?alt=media&token=30a49192-b1f2-4870-96f6-fb07404be529',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhoscn45i-ffkykcge-_MG_8659.webp?alt=media&token=3b0dc302-fc0f-4cf6-acc9-209164570fe0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvvvo0ch2-qyzgej8g-IMG_8880.webp?alt=media&token=03c3fe5b-ff30-4414-9dcf-a0ef5a7c2858',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Add a pop of color with a light red leather jacket. This vibrant and stylish piece brings a fresh, modern twist to your wardrobe, perfect for making a bold, fashionable statement.',
    seoDescriptionPl:
      'Wzbogać swoją garderobę o elegancję z naszą bordową kurtką skórzaną. Wyrazista, modna i idealna na każdą okazję. Sprawdź naszą kolekcję!',
    seoDescriptionUa:
      'Червоний жакет оверсайз: яскравий та стильний акцент для вашого гардеробу. Додайте сміливого кольору та сучасного вигляду до вашого образу з цим модним жакетом.\n\n',
    seoTitleEn: 'Short Elegant Light Red Leather Jacket  - Got`s Label',
    seoTitlePl: 'Bordowa Kurtka Skórzana - Got`s Label',
    seoTitleUa: 'Короткий елегантний червоний жакет  - Got`s Label',
    skin: 'smooth',
    titleEn: 'Short elegant jacket',
    titlePl: 'Krótka elegancka marynarka ',
    titleUa: 'Короткий елегантний жакет',
  },
  2024715124753: {
    article: '0006',
    category: 'jackets',
    characteristicEn: 'A jacket made of natural leather with an elongated back.',
    characteristicPl: 'Kurtka wykonana ze skóry naturalnej z wydłużonym tyłem.',
    characteristicUa: 'Косуха з натуральної шкіри з подовженою спинкою.',
    collection: 'jackets',
    color: 'blue',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 112 cm\nBack length: 62 cm\nSleeve length from neck: 81 cm\n\nModel height: 170 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 112 cm\nDługość tyłu: 62 cm\nDługość rękawa od szyi: 81 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 112 см \nДовжина по спині: 62 см \nДовжина рукава від горловини: 81 см \n\nЗріст моделі: 170 см",
    h1en: 'Blue leather jacket',
    h1pl: 'Niebieski ramoneska skórzana damska',
    h1ua: 'Натуральна шкіряна куртка жіноча синя з подовженою спинкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fas5dboyc-343_ZEN_0093%202.jpeg?alt=media&token=746a0ec9-a301-4eb9-a69f-335965aa5849',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fud1ah57e-345_ZEN_0095%202.jpeg?alt=media&token=896583ac-d69d-450a-bb9c-1e8d9279bc4f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F23uqmb7z-350_ZEN_0108%202.jpeg?alt=media&token=3a2d7db9-1dde-494a-b480-1622eaf32a6d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8q8u1n19-353_ZEN_0116%202.jpeg?alt=media&token=f3872499-ea2c-4e13-9db4-7137286c03c4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fr1h252op-346_ZEN_0096%202.jpeg?alt=media&token=11c8697d-55c7-4277-9653-58f7b0fdd87d',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Make a statement with a blue leather jacket. This vibrant and stylish piece adds a bold pop of color to your wardrobe, combining contemporary flair with timeless appeal for a standout look.',
    seoDescriptionPl:
      'Odkryj świeżość i styl z naszą niebieską skórzaną kurtką oversize. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Купити стильну жіночу куртку синю: елегантний та сучасний вибір для вашого гардеробу. Виготовлена з якісних матеріалів, ця куртка додасть шику та універсальності вашому образу.\n\n',
    seoTitleEn: 'Jacket Blue With An Elongated Back - Got`s Label',
    seoTitlePl: 'Skórzana Niebieski Ramoneska Z Wydłużonym Tyłem - Got`s Label',
    seoTitleUa: 'Жіноча куртка синя з подовженою спинкою - Got`s Label',
    skin: 'vintage',
    titleEn: 'A jacket with an elongated back',
    titlePl: 'Kurtka z wydłużonym tyłem',
    titleUa: 'Косуха з подовженою спинкою',
  },
  202475191942: {
    article: '0036',
    category: 'bombers',
    characteristicEn: 'Oversize bomber with art design made of a natural vintage brown leather.',
    characteristicPl: 'Bomber oversize z art wzorem, wykonana z naturalnej brązowej skóry w stylu vintage.',
    characteristicUa: 'Оверсайз бомбер з арт дизайном з натуральної вінтажної коричневої шкіри.',
    collection: 'jackets',
    color: 'brownVintage',
    descriptionEn: 'Measurements:  \n\nChest volume: 96 cm \nBack length: 48 cm \nSleeve length from neck: 75 cm  \n\nModel height: 170 cm',
    descriptionPl:
      'Parametry:\n\nObwód klatki piersiowej: 96 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 170 cm\n',
    descriptionUa:
      "Параметри:   \n\nОб'єм грудей: 96 см  \nДовжина по спині: 48 см  \nДовжина рукава від горловини: 75 см   \n\nЗріст моделі: 170 см",
    h1en: 'Oversized leather bomber jacket',
    h1pl: 'Skórzana bomberka',
    h1ua: 'Бомбер жіночий з арт дизайном',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fa0eb4cfe-IMG_4138%202.jpg?alt=media&token=12a7cbf6-0cf1-46f2-ae0b-62bbf63a9bfc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8efg4ths-IMG_4141%202.jpg?alt=media&token=5da18a8b-8721-420f-815c-0cee1cc0fe62',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fk1uwoyxu-IMG_4145%202.jpg?alt=media&token=16eb3bfa-03d5-4d05-8976-6911363d2114',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fj5xcjtih-IMG_4152%202.jpg?alt=media&token=1358ea4f-edef-432c-8bca-a835fd054d5d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ft9u1vlr7-IMG_4149%202.jpg?alt=media&token=c8a01f67-8f09-4c98-92f5-1cffc7848c1e',
    ],
    interest: '',
    newSale: '',
    priceEn: '800',
    priceEnSale: '',
    priceUa: '36000',
    priceUaSale: '',
    seoDescriptionEn:
      "Discover the perfect blend of style and comfort with a women's leather bomber jacket. This versatile piece combines classic design with modern sophistication, adding a chic, edgy touch to any outfit.",
    seoDescriptionPl:
      'Dodaj elegancji swojej stylizacji z naszą skórzaną bomberką. Modna, wytrzymała i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити бомбер для дівчинки: стильний і комфортний вибір для будь-якого гардеробу. Оберіть з нашого асортименту модних і якісних бомберів, щоб додати яскравих акцентів і зручності в образ.\n\n',
    seoTitleEn: 'Real Leather Bomber Jacket With Art Design - Got`s Label',
    seoTitlePl: 'Bomberka Skórzana Z Art Wzorem - Got`s Label',
    seoTitleUa: 'Бомбер з арт дизайном - Got`s Label',
    skin: 'vintage',
    titleEn: 'Bomber with art design',
    titlePl: 'Bomber z art wzorem',
    titleUa: 'Бомбер з арт дизайном',
  },
  202475193147: {
    article: '0037',
    category: 'jackets',
    characteristicEn: 'Jacket with a knotting and art design made of natural smooth leather.',
    characteristicPl: 'Kurtka z frędzlami i art wzorem, wykonana z naturalnej gładkiej skóry.',
    characteristicUa: 'Косуха з бахромою та з арт дизайном, виготовлена з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 44 cm\nSleeve length from neck: 73 cm\n\nModel height: 170 cm\n',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 44 cm\nDługość rękawa od szyi: 73 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 44 см \nДовжина рукава від горловини: 73 см \n\nЗріст моделі: 170 см",
    h1en: 'Leather bomber jacket women',
    h1pl: 'Ramoneska skórzana czarna damska',
    h1ua: 'Косуха чорна жіноча з бахромою та арт дизайном ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2748cqj1-IMG_4167%202.jpg?alt=media&token=4ffc5556-d51e-4f7f-b349-c865f900e127',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdov3ihw8-IMG_4174%202.jpg?alt=media&token=16203ccf-99c6-4a4e-8ec2-6dcefad25c81',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftlk17pfb-IMG_4169%202.jpg?alt=media&token=507b94c3-4ab1-4558-a182-f77a52a76aa1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fthfym8gk-IMG_4176%202.jpg?alt=media&token=db6f3b9a-a23a-4802-afea-5feeaccebddd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4w8fgt98-IMG_4168%202.jpg?alt=media&token=200da34d-47a5-43bf-a3a8-c4d9f70f78d9',
    ],
    interest: '',
    newSale: '',
    priceEn: '850',
    priceEnSale: '',
    priceUa: '38250',
    priceUaSale: '',
    seoDescriptionEn:
      'Revamp your look with a leather bomber jacket. Combining classic design with rugged charm, this versatile piece adds a timeless and stylish edge to any outfit, perfect for both casual and polished occasions.',
    seoDescriptionPl:
      'Podkreśl swój styl z naszą czarną skórzaną ramoneską damską. Elegancka, modna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити жіночу куртку: знайдіть ідеальну куртку для вашого гардеробу. Оберіть з нашого асортименту стильні та якісні моделі для будь-якої пори року.\n\n',
    seoTitleEn: 'Leather Bomber Jacket With A Knotting And Art Desing  - Got`s Label',
    seoTitlePl: 'Kurtka Z Frędzlami I Art Wzorem - Got`s Label',
    seoTitleUa: 'Косуха чорна жіноча з бахромою та арт дизайном  - Got`s Label',
    skin: 'smooth',
    titleEn: 'Jacket with a knotting and art design',
    titlePl: 'Kurtka z frędzlami i art wzorem',
    titleUa: 'Косуха з бахромою з арт дизайном',
  },
  202475193710: {
    article: '0026',
    category: 'jackets',
    characteristicEn: 'Classic jacket with shoulder pads made of natural smooth leather.\n',
    characteristicPl: 'Klasyczna kurtka z poduszkami na ramionach, wykonana z naturalnej gładkiej skóry .',
    characteristicUa: 'Класична косуха з підплечниками з натуральної гладкої шкіри.',
    collection: 'jackets',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 49 cm\nSleeve length from neck: 79 cm\n\nModel height: 170 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 49 cm\nDługość rękawa od szyi: 79 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 49 см \nДовжина рукава від горловини: 79 см \n\nЗріст моделі: 170 см",
    h1en: ' Black leather jacket',
    h1pl: 'Kurtka czarnа damska',
    h1ua: 'Чорна шкіряна жіноча куртка з підплечниками ',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fsu4w81w3-IMG_4250_VSCO%202.jpg?alt=media&token=82ffd273-bd99-4490-bf5c-511d2effce1e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqxpsrm07-IMG_4251_VSCO%202.jpg?alt=media&token=4b0b4e32-fac2-4be3-af8d-32a33cc94ebd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fv2zwx5q0-IMG_4252_VSCO%202.jpg?alt=media&token=5d593a93-0f40-4122-b945-5286e7f45e77',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ff4uzswqm-IMG_4254_VSCO%202.jpg?alt=media&token=ce993892-c6a9-4cde-b598-646f9f5dbd50',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      "Discover the perfect blend of style and sophistication with a ladies' black leather jacket. This versatile and sleek piece enhances any outfit, offering a timeless and modern edge for every occasion.",
    seoDescriptionPl:
      'Podkreśl swój styl z naszą czarną kurtką damską. Elegancka, uniwersalna i idealna na każdą okazję. Odkryj naszą ofertę!',
    seoDescriptionUa:
      'Купити чорну жіночу куртку: класичний та універсальний вибір для будь-якого гардеробу. Знайдіть ідеальну чорну куртку, яка додасть елегантності та стилю вашому образу.\n\n',
    seoTitleEn: 'Classic Black Jacket With Shoulder Pads - Got`s Label',
    seoTitlePl: 'Ramoneska Czarnа Skórzana Z Poduszkami Na Ramionach - Got`s Label',
    seoTitleUa: 'Чорна шкіряна жіноча куртка з підплечниками - Got`s Label',
    skin: 'smooth',
    titleEn: 'Classic jacket with shoulder pads ',
    titlePl: 'Klasyczna kurtka z poduszkami na ramionach',
    titleUa: 'Класична косуха з підплечниками',
  },
  202475194356: {
    article: '0009',
    category: 'bombers',
    characteristicEn: 'Bomber with pockets on the arms made of natural leather.',
    characteristicPl: 'Bomber z kieszeniami na rękawach, wykonana z naturalnej skóry.',
    characteristicUa: 'Бомбер з кишенями на руках з натуральної шкіри.',
    collection: 'jackets',
    color: 'white',
    descriptionEn: 'Measurements:\n\nChest volume: 96 cm\nBack length: 48 cm\nSleeve length from neck: 75 cm\n\nModel height: 170 cm',
    descriptionPl:
      'Parametry:\n\nObwód klatki piersiowej: 96 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри: \n\nОб'єм грудей: 96 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 170 см",
    h1en: 'White leather bomber jacket',
    h1pl: 'Białа skórzana bomberka damska',
    h1ua: 'Біла куртка бомбер з кишенями на руках та грудях',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwrwmpe15-IMG_4197%202.jpg?alt=media&token=0f16e180-af49-43e9-99b7-e0707ca1cb53',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5fu6zhs8-IMG_4198%202.jpg?alt=media&token=e43782ea-1178-4cfc-b728-05d50b7eb7b6',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F698zuoom-IMG_4192%202.jpg?alt=media&token=e23a08f2-3be1-443d-a8e2-004611555603',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fstho331z-IMG_4193%202.jpg?alt=media&token=e6fda486-af1e-4aef-be4a-3b5a972a96ac',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Refresh your style with a white leather bomber jacket. This sleek and modern piece offers a clean, contemporary look, adding a crisp and sophisticated edge to any outfit.',
    seoDescriptionPl: 'Ożyw swój look z naszą białą bomberką. Elegancka, modna i idealna na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Білий шкіряний бомбер: елегантний і сучасний вибір для вашого гардеробу. Додайте яскравого кольору та стильного вигляду до вашого образу з цією витонченою шкіряною курткою.\n\n',
    seoTitleEn: 'White Leather Bomber With Pockets On The Arms And Chest  - Got`s Label',
    seoTitlePl: 'Białа  Bomberka Z Kieszeniami Na Rękawach I Na Piersi',
    seoTitleUa: 'Біла куртка бомбер з кишенями на руках та грудях - Got`s Label',
    skin: 'smooth',
    titleEn: 'Bomber with pockets on the arms',
    titlePl: 'Bomber z kieszeniami na rękawach',
    titleUa: 'Бомбер з кишенями на руках',
  },
  202475200011: {
    article: '0020',
    category: 'jackets',
    characteristicEn: 'Short biker jacket with handmade design made of vintage natural leather.',
    characteristicPl: 'Krótka kurtka motocyklowa o ręcznie robionym kroju, wykonana ze skóry naturalnej.',
    characteristicUa: 'Коротка байкерська косуха з дизайном ручної роботи з натуральної шкіри.',
    collection: 'jackets',
    color: 'vintage_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 95 cm\nBack length: 41 cm\nSleeve length from neck: 76 cm\n\nModel height: 170 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 95 cm\nDługość tyłu: 41 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 95 см \nДовжина по спині: 41 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 170 см",
    h1en: 'Womens grey leather jacket',
    h1pl: 'Kurtka skórzana szara damska',
    h1ua: 'Сіра вкорочена косуха байкерська',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Famrdq8yg-IMG_4181%202.jpg?alt=media&token=73c0305c-6141-4b18-9c31-bc846f0ef854',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftrwpfyiv-IMG_4178%202.jpg?alt=media&token=d668eb99-1c9f-49ed-b8c8-51b10924130c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F54b1wl0b-IMG_4180%202.jpg?alt=media&token=ca784b48-1ca7-42f2-8ba8-ecb57a168d28',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fig9erj1n-IMG_4179%202.jpg?alt=media&token=be1fd202-5b39-4a69-85ca-fe791eaf6180',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3f2bgotv-IMG_4182%202.jpg?alt=media&token=2902e0e9-474b-4864-81eb-e574d9631da4',
    ],
    interest: '',
    newSale: '',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    seoDescriptionEn:
      'Upgrade your wardrobe with a grey leather jacket. Sleek and versatile, this modern classic offers a sophisticated look that seamlessly complements any outfit, making it a must-have for effortless style.',
    seoDescriptionPl:
      'Dodaj nowoczesnego stylu z naszą szarą skórzaną kurtką damską. Elegancka, modna i idealna na każdą okazję. Sprawdź naszą ofertę!',
    seoDescriptionUa:
      'Сіра вкорочена косуха: стильний вибір для сучасного гардеробу. Додайте елегантності та модного вигляду до вашого образу з цією трендовою вкороченою косухою.\n\n',
    seoTitleEn: 'Short Biker Grey Leather Jacket Women  - Got`s Label',
    seoTitlePl: 'Szara Skórzana Kurtka - Got`s Label',
    seoTitleUa: 'Байкерська косуха сіра коротка  - Got`s Label',
    skin: 'vintage',
    titleEn: 'Short biker jacket with handmade design',
    titlePl: 'Krótka kurtka motocyklowa z wzorem',
    titleUa: 'Коротка байкерська косуха з дизайном ',
  },
  202475200406: {
    article: '0006',
    category: 'jackets',
    characteristicEn: 'A jacket made of natural leather with an elongated back.',
    characteristicPl: 'Kurtka wykonana ze skóry naturalnej z wydłużonym tyłem.',
    characteristicUa: 'Косуха з натуральної шкіри з подовженою спинкою.',
    collection: 'jackets',
    color: 'grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 112 cm\nBack length: 62 cm\nSleeve length from neck: 81 cm\n\nModel height: 170 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 112 cm\nDługość tyłu: 62 cm\nDługość rękawa od szyi: 81 cm\n\nWzrost modelki: 170 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 112 см \nДовжина по спині: 62 см \nДовжина рукава від горловини: 81 см \n\nЗріст моделі: 170 см",
    h1en: 'Grey leather jacket',
    h1pl: 'Szara ramoneska skórzana damska',
    h1ua: 'Натуральна шкіряна куртка жіноча графітова з подовженою спинкою',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F36m5mpoh-IMG_4208%202.jpg?alt=media&token=850b36cf-4ce4-4e24-b56a-31e3e44e06d5',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbtaj7vjg-IMG_4204%202.jpg?alt=media&token=a472dc8b-edc2-4cfd-b26f-d1f3f819c809',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3mmfiewh-IMG_4207%202.jpg?alt=media&token=3b91c06a-42d5-4906-959d-6e9525bf9b89',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjz0qapvi-IMG_4200%202.jpg?alt=media&token=eeeab8b4-5587-4618-9324-beac25a6fbec',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F85q3m9zd-IMG_4202%202.jpg?alt=media&token=1d5afcb7-dd23-46ab-9c78-f3cb4da2304a',
    ],
    interest: '',
    newSale: '',
    priceEn: '700',
    priceEnSale: '',
    priceUa: '31500',
    priceUaSale: '',
    seoDescriptionEn:
      'Enhance your style with a grey leather jacket. This sleek and versatile piece offers a modern, sophisticated look that effortlessly complements any outfit, perfect for any season.',
    seoDescriptionPl:
      'Podkreśl swój unikalny styl z naszą szarą skórzaną kurtką oversize. Modna, wygodna i doskonała na każdą okazję. Odkryj naszą kolekcję!',
    seoDescriptionUa:
      'Купити стильну жіночу куртку графітову: сучасний і елегантний вибір для будь-якого гардеробу. Високоякісні матеріали та сучасний дизайн додадуть вашому образу неповторності та розкішного вигляду.\n\n',
    seoTitleEn: 'Jacket Grey With An Elongated Back - Got`s Label',
    seoTitlePl: 'Skórzana Szara Ramoneska Z Wydłużonym Tyłem - Got`s Label',
    seoTitleUa: 'Жіноча куртка графітова з подовженою спинкою - Got`s Label',
    skin: 'smooth',
    titleEn: 'A jacket with an elongated back',
    titlePl: 'Kurtka z wydłużonym tyłem',
    titleUa: 'Косуха з подовженою спинкою',
  },
  202491114411: {
    article: '0040',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with an elastic band made of natural smooth leather.',
    characteristicPl: 'Kurtka oversize z gumką, wykonana z naturalnej gładkiej skóry.',
    characteristicUa: 'Оверсайз куртка на резинці з натуральної гладкої шкіри.',
    collection: 'new-collection',
    color: 'brown',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 94 cm\nBack length: 48 cm\nSleeve length from neck: 77 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 94 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 177 см\n",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8ls1v0rt-zxy1sfgg-IMG_0656.webp?alt=media&token=5505143a-3b21-4313-bbb0-c8008eb25baf',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0w539euv-IMG_0675.jpg?alt=media&token=fc5c019a-b00c-4b43-a649-3d2f78a8d4b1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhyeof1ia-IMG_0617.jpg?alt=media&token=3f2e7758-38c7-4c35-a403-35296fa896d8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdq6qyvek-IMG_0635.jpg?alt=media&token=a5529a61-cf66-4709-825f-1e0329e3551a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frke3sdu5-wiza1hfs-IMG_0649.webp?alt=media&token=ef3943af-4856-4845-98c4-cf0b25b2342c',
    ],
    interest: '',
    newSale: 'new',
    priceEn: '750',
    priceEnSale: '',
    priceUa: '33750',
    priceUaSale: '',
    skin: 'smooth',
    titleEn: 'Oversize jacket MAJOR',
    titlePl: 'Kurtka oversize MAJOR',
    titleUa: 'Куртка оверсайз MAJOR',
  },
  202491151245: {
    article: '0041',
    category: 'jackets',
    characteristicEn: 'Oversized leather jacket with external pockets made of natural grain leather.',
    characteristicPl: 'Kurtka oversize z kieszeniami zewnętrznymi, wykonana z naturalnej skóry ziarnistej.',
    characteristicUa: 'Оверсайз косуха із зовнішнім кишеннями з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'pistachio',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 116 cm\nBack length: 48 cm\nSleeve length from neck: 74 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 116 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 74 cm\n\nWzrost modelki: 172 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 116 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 74 см \n\nЗріст моделі: 172 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fh8l1312d-oufxqmeo-IMG_0724.webp?alt=media&token=25d4a223-28ee-4ca6-b268-595a5908d4c6',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4twluvi3-IMG_0756.jpg?alt=media&token=544bc32a-2abf-463a-9ec0-4ab5fcd32436',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F7du7wsbj-IMG_0721.jpg?alt=media&token=f2e80e38-c6f1-4abd-9b86-f60b624f6744',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fon4zbrx7-IMG_0694.jpg?alt=media&token=ef1c56e4-cbde-44fd-9471-f89b17cf4b98',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fr7layb4f-kvlzmwb6-IMG_0743.webp?alt=media&token=5beb1f5e-a6ae-4d27-86e1-112f0ce657d3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgnut9s7z-1kpfiydu-IMG_0731.webp?alt=media&token=79e8c1bc-7c94-4f3f-867b-1ea278dd41fe',
    ],
    newSale: 'new',
    priceEn: '750',
    priceUa: '33750',
    skin: 'jumbo',
    titleEn: 'Jacket with pockets ROCKY',
    titlePl: 'Kurtka z kieszeniami ROCKY',
    titleUa: 'Куртка з кишенями ROCKY',
  },
  202491152832: {
    article: '0042',
    category: 'jackets',
    characteristicEn: 'Long oversize leather jacket with a belt made of genuine grain leather.',
    characteristicPl: 'Długa kurtka oversize z paskiem wykonana z naturalnej ziarnistej skóry.',
    characteristicUa: 'Довга оверсайз косуха з поясом з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 122 cm\nBack length: 68 cm\nSleeve length from neck: 77 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 122 cm\nDługość tyłu: 68 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 122 см \nДовжина по спині: 68 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgxedcrw0-nsh20oan-IMG_0821.webp?alt=media&token=ffe79cea-a16a-4bf3-860f-6d0a2f308dee',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdeftsqu8-IMG_0786.jpg?alt=media&token=23ec80ca-ec1b-4764-8be1-5d09774a54d1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdgf3eesf-g2oq7zur-IMG_0830.webp?alt=media&token=84f71964-e1d8-4c87-bdab-e9ffdc99c2a2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ft2m80x2e-20e6q9sy-IMG_0805.webp?alt=media&token=cd75c532-6231-4700-820b-b1e2ee163ca2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frijb4394-l704yl9m-IMG_0815.webp?alt=media&token=80b4ee79-063e-4561-9f7a-07a62ffa0757',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F3vu93jx6-lkppj5z5-IMG_0850.webp?alt=media&token=aa846666-ab5f-4511-a03d-1cb00fea4c4e',
    ],
    newSale: 'new',
    priceEn: '800',
    priceUa: '36000',
    skin: 'jumbo',
    titleEn: 'Long jacket with belt PRETTY WOMAN',
    titlePl: 'Długa kurtka z paskiem PRETTY WOMAN',
    titleUa: 'Довга косуха з поясом PRETTY WOMAN',
  },
  202491154322: {
    article: '0043',
    category: 'jackets',
    characteristicEn: 'Jacket with ties over waist made of natural smooth leather.',
    characteristicPl: 'Kurtka wiązana w pasie, wykonana z naturalnej skóry gładkiej.',
    characteristicUa: 'Куртка на завʼязках на талії з натуральної гладкої шкіри.',
    collection: 'new-collection',
    color: 'brown',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 110 cm\nHips: 110 cm\nBack length: 76 cm\nSleeve length from neck: 77 cm\n\nModel height: 177 cm',
    descriptionPl: '-',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 110 см\nОб'єм бедер: 110 см\nДовжина по спині: 76 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fgxyq2gja-IMG_0957.jpg?alt=media&token=3296d1db-cb6e-4424-9115-cfaf1089991f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fesw6lowd-IMG_0930.jpg?alt=media&token=a1eab085-b91a-48c2-8c66-93b4280ece2f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frtv85tgv-IMG_0946.jpg?alt=media&token=32a10514-6b72-4b67-adfa-3d7bc8c17f9c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2ttqfvlf-IMG_0950.jpg?alt=media&token=1a7a1c2d-1928-44c6-b73b-a95e7941f4e4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fatav00zd-x56xvi98-IMG_0961.webp?alt=media&token=101132fe-1569-4b7a-a455-49f025065152',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ft5k6phz0-13wdekni-IMG_0965.webp?alt=media&token=5d42aeb9-eaaf-4cbe-9ca4-a4dbb1efcb08',
    ],
    newSale: 'new',
    priceEn: '800',
    priceUa: '36000',
    skin: 'smooth',
    titleEn: 'Jacket with ties BRAVE',
    titlePl: 'Kurtka wiązana w pasie BRAVE',
    titleUa: 'Куртка на завʼязках BRAVE',
  },
  202491154618: {
    article: '0041',
    category: 'jackets',
    characteristicEn: 'Oversized leather jacket with external pockets made of natural grain leather.',
    characteristicPl: 'Kurtka oversize z kieszeniami zewnętrznymi, wykonana z prawdziwej skóry ziarnistej.',
    characteristicUa: 'Оверсайз косуха із зовнішнім кишеннями з натуральної зернистої шкіри.\n',
    collection: 'new-collection',
    color: 'brown',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 116 cm\nBack length: 48 cm\nSleeve length from neck: 74 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 116 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 74 cm\n\nWzrost modelki: 172 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 116 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 74 см \n\nЗріст моделі: 172 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnfo0qnrq-gz12ct7r-IMG_0887.webp?alt=media&token=d85b4cd7-719d-4002-87f7-995cfec46f97',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6ivfgw6d-IMG_0861.jpg?alt=media&token=c522696e-bb98-40ed-92c1-3866bdaba0bb',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhauko8kw-IMG_0879.jpg?alt=media&token=6af2a89b-e3bb-4c98-a965-9bc2b8b50491',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwdl90lkk-IMG_0874.jpg?alt=media&token=752fb667-c13d-4593-a9a5-3c7bc91e185d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F4smsx2gt-456ktadr-IMG_0907.webp?alt=media&token=fa13bc7b-09ff-40d4-ba5f-30a46ce430d8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxqfqg62l-zy3ftgih-IMG_0884.webp?alt=media&token=1aa9bdf4-0bd7-4551-b12e-4a6c5ce7cba3',
    ],
    newSale: 'new',
    priceEn: '750',
    priceUa: '33750',
    skin: 'jumbo',
    titleEn: 'Jacket with pockets ROCKY',
    titlePl: 'Kurtka z kieszeniami ROCKY',
    titleUa: 'Куртка з кишенями ROCKY',
  },
  202491155801: {
    article: '0044',
    category: 'jackets',
    characteristicEn: 'Jacket with different pockets made of natural vintage leather.',
    characteristicPl: 'Kurtka z różnymi kieszeniami, wykonana z prawdziwej skóry vintage.',
    characteristicUa: 'Куртка з різними кишенями з натуральної вінтажної шкіри.',
    collection: 'new-collection',
    color: 'brownVintage',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 100 cm\nBack length: 47 cm\nSleeve length from neck: 75 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 100 cm\nDługość tyłu: 47 cm\nDługość rękawa od szyi: 75 cm\n\nWzrost modelki: 172 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 100 см \nДовжина по спині: 47 см \nДовжина рукава від горловини: 75 см \n\nЗріст моделі: 172 см\n",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdxy10948-b438xmy7-IMG_1026.webp?alt=media&token=354028ab-8ebe-4d87-941b-3eab2524b616',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwf4hwjvy-di7xph7n-IMG_1034.webp?alt=media&token=90c07431-b20a-4543-9093-bafa8821188a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxgl95v2s-IMG_1008.jpg?alt=media&token=5c119091-45c5-40d3-9981-b007f622a754',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F15ojcqzz-zboviz8x-IMG_1107.webp?alt=media&token=547b3159-6223-42eb-9058-31f255e5cab2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Femwv9c17-p572wwmc-IMG_1076.webp?alt=media&token=85b6fb4e-fc53-4fee-9669-7349242bbead',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fxy50wa1z-y7wc9oyp-IMG_1016.webp?alt=media&token=e40c648c-54f3-4446-9f3b-9c394483e739',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fbhx0bjgg-7rmisxs2-IMG_1004.webp?alt=media&token=d0132636-bb9d-4693-a395-564085c9693c',
    ],
    newSale: 'new',
    priceEn: '700',
    priceUa: '31500',
    skin: 'vintage',
    titleEn: 'Vintage jacket with pockets on the sleeves',
    titlePl: 'Kurtka vintage z kieszeniami na rękawach',
    titleUa: 'Вінтажна косуха з кишенями на рукавах',
  },
  20249200426: {
    article: '0040',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with an elastic band made of natural grain leather.',
    characteristicPl: 'Kurtka oversize z gumką, wykonana z naturalnej skóry ziarnistej.',
    characteristicUa: 'Оверсайз куртка на резинці з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'beige',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 94 cm\nBack length: 48 cm\nSleeve length from neck: 77 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 172 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 94 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 172 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F370gx8rq-a9q0ht6f-IMG_0362.webp?alt=media&token=3b1bb467-98a9-4b07-92b0-723df0c9b02f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fqyxllxfb-IMG_0353.jpg?alt=media&token=2dc3749f-0413-4f05-8927-84f56b6ba44d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F5hpov4sp-IMG_0358.jpg?alt=media&token=e9bc4829-8974-4059-b4af-988771100db9',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Felhv1jul-udg6co9x-IMG_0413.webp?alt=media&token=0b520763-e50e-463d-b582-876baebcca9c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frywcwn9r-8v3j3o0o-IMG_0367.webp?alt=media&token=735d9bb3-31e2-4693-a485-50a0cc99ea1c',
    ],
    newSale: 'new',
    priceEn: '750',
    priceUa: '33750',
    skin: 'jumbo',
    titleEn: 'Oversize jacket MAJOR',
    titlePl: 'Kurtka oversize MAJOR',
    titleUa: 'Куртка оверсайз MAJOR',
  },
  20249200808: {
    article: '0045',
    category: 'jackets',
    characteristicEn: 'Oversized leather jacket with pockets made of natural grain leather.',
    characteristicPl: 'Kurtka oversize z kieszeniami, wykonana z naturalnej skóry ziarnistej.',
    characteristicUa: 'Оверсайз косуха з кишенями з натуральної зернистої шкіри.',
    collection: 'no',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 114 cm\nBack length: 57 cm\nSleeve length from neck: 77 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 114 cm\nDługość tyłu: 57 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 177 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 114 см \nДовжина по спині: 57 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffcsmaz92-0e8becs3-IMG_0122.webp?alt=media&token=a82cdae4-778a-4ffa-adc8-70232b8e7f0c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ft7rpwgde-IMG_0117.jpg?alt=media&token=fd5f7b90-4a23-4766-8816-32fe1013e7c7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fm5ylejat-4r41mqpn-IMG_0148.webp?alt=media&token=851ad716-eb93-4089-9831-27b63663d71f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fin65rsil-xdag1x3o-IMG_0134.webp?alt=media&token=a6357103-ec11-406b-8256-55d1c98644e8',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8aqy7hij-lzsqodj2-IMG_0132.webp?alt=media&token=6eeccd7f-0d30-42da-9455-41982991ca1d',
    ],
    newSale: 'new',
    priceEn: '700',
    priceUa: '31500',
    skin: 'jumbo',
    titleEn: 'Jacket COURAGE',
    titlePl: 'Kurtka COURAGE',
    titleUa: 'Куртка COURAGE',
  },
  20249200927: {
    article: '0040',
    category: 'jackets',
    characteristicEn: 'Oversized jacket with an elastic band made of natural smooth leather.',
    characteristicPl: 'Kurtka oversize z gumką, wykonana z naturalnej gładkiej skóry.',
    characteristicUa: 'Оверсайз куртка на резинці з натуральної гладкої шкіри.\n',
    collection: 'new-collection',
    color: 'khaki',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 94 cm\nBack length: 48 cm\nSleeve length from neck: 77 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 94 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 172 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 94 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 172 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F7dj835f8-t3pntgnx-IMG_0081.webp?alt=media&token=c6d3589c-5a54-4e70-8964-da2ed640b77c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flgc9lsj5-mkfy6keg-IMG_0098.webp?alt=media&token=5abb2fdf-b80c-4561-a159-a6e722e4bf6e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fufhmhn1h-py8ciek0-IMG_0076.webp?alt=media&token=9cbda061-e905-4321-b2d0-7f2a369ea967',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fh9vmz9nv-rk4lnvih-IMG_0063.webp?alt=media&token=4c5494df-a99b-48bd-9ae9-1e037b2650d7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F159iwvrb-uxyexkjn-IMG_0103.webp?alt=media&token=f25db22a-407a-4c24-87fe-e3194ae80f09',
    ],
    newSale: 'new',
    priceEn: '750',
    priceUa: '33750',
    skin: 'smooth',
    titleEn: 'Oversize jacket MAJOR',
    titlePl: 'Kurtka oversize MAJOR',
    titleUa: 'Куртка оверсайз MAJOR',
  },
  20249201221: {
    article: '0042',
    category: 'jackets',
    characteristicEn: 'Long oversize leather jacket with a belt made of genuine grain leather.',
    characteristicPl: 'Długa kurtka oversize z paskiem wykonana z naturalnej ziarnistej skóry.',
    characteristicUa: 'Довга оверсайз косуха з поясом з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'caramel',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 122 cm\nBack length: 68 cm\nSleeve length from neck: 77 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 122 cm\nDługość tyłu: 68 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 122 см \nДовжина по спині: 68 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fy035qhed-z9hgngv7-IMG_0028.webp?alt=media&token=3be6dadf-9785-48f3-aa3b-9989d10c71b3',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ffso28ccx-IMG_0015.jpg?alt=media&token=e59d409b-ba68-4c4a-a9bf-5ccf9935e633',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fveuetbnp-45w1abri-IMG_0034.webp?alt=media&token=14b6602e-d53e-40fc-9903-b797c9e87d9e',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fpo5pyr5e-IMG_0005_1.jpg?alt=media&token=6e86e344-4f3f-4a57-a612-f1d3ec473116',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fws3cj07m-u5o69uuh-IMG_0008.webp?alt=media&token=6e761b07-f8ea-4ce9-9b00-779353dccc2a',
    ],
    newSale: 'new',
    priceEn: '800',
    priceUa: '36000',
    skin: 'jumbo',
    titleEn: 'Long jacket with belt PRETTY WOMAN',
    titlePl: 'Długa kurtka z paskiem PRETTY WOMAN',
    titleUa: 'Довга косуха з поясом PRETTY WOMAN',
  },
  20249201714: {
    article: '0041',
    category: 'jackets',
    characteristicEn: 'Oversized leather jacket with external pockets made of natural grain leather.',
    characteristicPl: 'Kurtka oversize z kieszeniami zewnętrznymi, wykonana z naturalnej skóry ziarnistej.',
    characteristicUa: 'Оверсайз косуха із зовнішнім кишеннями з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'ginger',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 116 cm\nBack length: 48 cm\nSleeve length from neck: 74 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 116 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 74 cm\n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 116 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 74 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0rmrwoae-09hwmh02-IMG_0301.webp?alt=media&token=6fa4a131-44de-4756-85cc-cde72aee5236',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwj10curk-IMG_0279.jpg?alt=media&token=ff9adffb-e110-4c06-915c-1d91fd3cb104',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhbgtn7r8-IMG_0292.jpg?alt=media&token=525839a1-f111-40a1-8fa8-82a405941d3c',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fu3yvocg7-IMG_0283.jpg?alt=media&token=7eb772cc-08a3-4b5d-8f75-99e767487de1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F671e7hdn-hyypcj94-IMG_0314.webp?alt=media&token=c862ec02-a71f-41b3-8a08-f790dc44c5e1',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fizsj4qce-dopd8br5-IMG_0303.webp?alt=media&token=e92b2a86-99fe-400e-a6e9-0cfcaa9475c5',
    ],
    newSale: 'new',
    priceEn: '750',
    priceUa: '33750',
    skin: 'jumbo',
    titleEn: 'Jacket with pockets ROCKY',
    titlePl: 'Kurtka z kieszeniami ROCKY',
    titleUa: 'Куртка з кишенями ROCKY',
  },
  20249202057: {
    article: '0046',
    category: 'trench',
    characteristicEn: 'Long oversize raincoat made of natural smooth leather.',
    characteristicPl: 'Długi płaszcz oversize wykonany z naturalnej gładkiej skóry.',
    characteristicUa: 'Довгий оверсайз плащ з натуральної гладкої шкіри.',
    collection: 'new-collection',
    color: 'brown',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 101 cm\nWaist: 87 cm \nHips: 102 cm\nBack length: 140 cm\nSleeve length from neck: 73 m\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry Płaszcza: \n\nObjętość klatki piersiowej: 101 cm \nObwód talii: 87 cm\nBiodra: 102 cm\nDługość tyłu: 140 cm \nDługość rękawa od szyi: 73 cm \n\nWzrost modelki: 172 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 101 см \nОб'єм талії: 87 см\nОб'єм бедер: 102 см\nДовжина по спині: 140 см \nДовжина рукава від горловини: 73 см \n\nЗріст моделі: 172 см\n",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fcqazzvq3-IMG_0200.jpg?alt=media&token=7141d990-10d6-4e76-b49e-33327742a4f0',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fhl622qlv-IMG_0212.jpg?alt=media&token=4fda8513-6fd2-45a9-9c84-fc8f0dd9661b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fep28ycuf-IMG_0223.jpg?alt=media&token=0e03fe29-77bd-4873-b284-8edf19ad58d2',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8ikv3yqx-IMG_0229.jpg?alt=media&token=43b489b4-7945-46c1-8e6a-088f23b028ed',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fcc2saqeo-zwajiohg-IMG_0240.webp?alt=media&token=a2f3ae77-76ff-4af8-9afb-38bfc831a750',
    ],
    newSale: 'new',
    priceEn: '900',
    priceUa: '40500',
    skin: 'smooth',
    titleEn: 'Oversized raincoat',
    titlePl: 'Płaszcz oversize',
    titleUa: 'Оверсайз плащ',
  },
  20249203849: {
    article: '0047',
    category: 'jackets',
    characteristicEn: 'Jacket with collar made of natural grain leather.',
    characteristicPl: 'Kurtka ze stójką pod szyją, wykonana z naturalnej skóry ziarnistej.',
    characteristicUa: 'Косуха зі стійкою у горла з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'caramel',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 102 cm\nBack length: 48 cm\nSleeve length from neck: 77 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 102 cm\nDługość tyłu: 48 cm\nDługość rękawa od szyi: 77 cm\n\nWzrost modelki: 177 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 102 см \nДовжина по спині: 48 см \nДовжина рукава від горловини: 77 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fzof03na3-uz3kxdn9-IMG_0020_1.webp?alt=media&token=cbe133cc-cf7a-4358-8ef0-a56494299b27',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F8jv8hpiw-IMG_0060_1.jpg?alt=media&token=bf6f416e-7e5d-48fc-a00f-f4ba0fbdc62d',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjjy906h9-6cxcs660-IMG_0036_1.webp?alt=media&token=5adea0a3-4364-41a8-99b1-8544aeb9fbbd',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fvfzj2rtd-d6cjxfp9-IMG_0140_1.webp?alt=media&token=32dc1dd5-3fc2-42bd-a236-12dcacfd3b0b',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fba0maw3b-r8q1udyn-IMG_0083_1.webp?alt=media&token=1e3cfc5f-9db6-47f9-9657-7298ba4611e6',
    ],
    newSale: 'new',
    priceEn: '700',
    priceUa: '31500',
    skin: 'jumbo',
    titleEn: 'Jacket with collar LIBERTY',
    titlePl: 'Kurtka ze stójką pod szyją LIBERTY',
    titleUa: 'Косуха зі стійкою у горла LIBERTY',
  },
  20249204134: {
    article: '0048',
    category: 'jackets',
    characteristicEn: 'Long oversize leather jacket made of natural grain leather.',
    characteristicPl: 'Długa kurtka oversize wykonana z naturalnej ziarnistej skóry.\n',
    characteristicUa: 'Довга оверсайз косуха з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'sea',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 114 cm\nBack length: 59 cm\nSleeve length from neck: 78 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 114 cm\nDługość tyłu: 59 cm\nDługość rękawa od szyi: 78 cm\n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 114 см \nДовжина по спині: 59 см \nДовжина рукава від горловини: 78 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flaiqx175-IMG_0322_1.jpg?alt=media&token=3730f0b4-417f-4a03-afc8-fb8e0757a499',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fw05ng1gr-IMG_0328_1.jpg?alt=media&token=8db06543-f989-4cbc-89f2-0b37862ed493',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F33ncjvd7-80j1f88t-IMG_0293_1.webp?alt=media&token=acb4475e-ff16-4de6-ae19-61c5f40bca11',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9w37dn90-2kk91ruw-IMG_0337_1.webp?alt=media&token=3bf58a6b-9c3e-486a-8b94-e75195f79959',
    ],
    newSale: 'new',
    priceEn: '650',
    priceUa: '29250',
    skin: 'jumbo',
    titleEn: 'Jacket STRONG',
    titlePl: 'Kurtka STRONG',
    titleUa: 'Косуха STRONG',
  },
  20249204604: {
    article: '0049',
    category: 'jackets',
    characteristicEn: 'Oversize leather jacket with pockets and belt made of natural vintage leather.',
    characteristicPl: 'Kurtka oversize z kieszeniami i paskiem wykonana z naturalnej ziarnistej skóry.\n',
    characteristicUa: 'Оверсайз косуха з кишенями і поясом з натуральної вінтажної шкіри.',
    collection: 'new-collection',
    color: 'brown',
    descriptionEn: '-',
    descriptionPl: '-',
    descriptionUa: '-',
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fj7c9dqgh-r5px53sg-IMG_0549.webp?alt=media&token=995674c0-1fbd-4717-8f28-2c9e4f338f0f',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F0j9pe07q-IMG_0511.jpg?alt=media&token=600bb0e7-add2-43cd-9108-a4fc7c6d7296',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fnciyes65-n9x3o4ja-IMG_0548.webp?alt=media&token=c94882de-8f76-47d9-a8cf-6b240b95bedc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F77cac4m3-m18y9725-IMG_0564.webp?alt=media&token=024d2b92-d703-41e2-9c48-bab77c36bfeb',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fselj4aqa-khml77a2-IMG_0544.webp?alt=media&token=158773ba-2a53-4245-aa03-936bf413d6dc',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Frudw4koo-8o4bgzli-IMG_0539.webp?alt=media&token=01131bab-7131-4de5-a0c4-953d5a7a1428',
    ],
    newSale: 'new',
    priceEn: '750',
    priceUa: '33750',
    skin: 'vintage',
    titleEn: 'Jacket NEW YORK',
    titlePl: 'Kurtka NEW YORK',
    titleUa: 'Косуха NEW YORK',
  },
  202492101229: {
    article: '0050',
    category: 'jackets',
    characteristicEn: 'Classic leather jacket with crocodile print.',
    characteristicPl: 'Klasyczna skórzana kurtka z nadrukiem krokodyla.',
    characteristicUa: 'Класичний піджак зі шкіри з тисненням крокодила.',
    collection: 'new-collection',
    color: 'black',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 75 cm\nSleeve length from neck: 78 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki: \n\nObjętość klatki piersiowej: 104 cm \nDługość tyłu: 75 cm \nDługość rękawa od szyi: 78 cm \n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри піджака: \n\nОб'єм грудей: 104 см \nДовжина по спині: 75 см \nДовжина рукава від горловини: 78 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fwn4z5f31-1pip5cxb-IMG_0473.webp?alt=media&token=959f3bf5-411d-4992-9bf9-c633bbcfa416',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fv9jofysu-IMG_0418.jpg?alt=media&token=e3114bf7-864f-4c1f-ac37-5ffe52cec834',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fcchre7wv-kswoq7ku-IMG_0478.webp?alt=media&token=e4596e35-4d00-467c-96c4-d79b767c85a5',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Feop08wei-30g00h4k-IMG_0482.webp?alt=media&token=a0e6b882-c031-470d-b7bf-34c1eb7e02bb',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fo0lwqskp-dmcib67a-IMG_0463.webp?alt=media&token=ed3accf7-923e-438f-bff3-9598b710eec3',
    ],
    newSale: 'new',
    priceEn: '650',
    priceUa: '29250',
    skin: 'crocodile',
    titleEn: 'Jacket CLASSY',
    titlePl: 'Kurtka CLASSY',
    titleUa: 'Піджак CLASSY',
  },
  202492101619: {
    article: '0051',
    category: 'jackets',
    characteristicEn: 'Elongated jacket made of natural vintage leather.',
    characteristicPl: 'Wydłużona kurtka wykonana z naturalnej skóry vintage.',
    characteristicUa: 'Подовжена косуха з натуральної вінтажної шкіри.',
    collection: 'new-collection',
    color: 'vintage_grey',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 118 cm\nBack length: 57 cm\nSleeve length from neck: 76 cm\n\nModel height: 177 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 118 cm\nDługość tyłu: 57 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 177 cm',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 118 см \nДовжина по спині: 57 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 177 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F2wqzj3xa-cjk4e0k7-IMG_0463_1.webp?alt=media&token=321a006c-1266-4a2d-b45b-15001dea6d86',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F6afcs6vx-00c9vgve-IMG_0437_1.webp?alt=media&token=b4a0708c-4acd-469a-b463-247ab223b368',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Flcumsugz-ueo0pxq5-IMG_0444_1.webp?alt=media&token=fff70781-df14-4baa-a783-af6fb587c32a',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fdr1veck4-k07uybzf-IMG_0462_1.webp?alt=media&token=ff6aeb9d-373a-4308-a3d0-ea55e458b7b7',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fobdixke4-5qpeukm7-IMG_0452_1.webp?alt=media&token=8742ca83-8963-4003-b062-a2ead66ff014',
    ],
    newSale: 'new',
    priceEn: '700',
    priceUa: '31500',
    skin: 'vintage',
    titleEn: 'Jacket ALASKA',
    titlePl: 'Kurtka ALASKA',
    titleUa: 'Косуха ALASKA',
  },
  202492102042: {
    article: '0052',
    category: 'jackets',
    characteristicEn: 'Jacket with buttons from natural grain leather.',
    characteristicPl: 'Kurtka z guzikami z naturalnej skóry ziarnistej.',
    characteristicUa: 'Косуха з гудзиками з натуральної зернистої шкіри.',
    collection: 'new-collection',
    color: 'beige',
    descriptionEn:
      'Measurements of the jacket:\n\nChest volume: 104 cm\nBack length: 50 cm\nSleeve length from neck: 76 cm\n\nModel height: 172 cm',
    descriptionPl:
      'Parametry kurtki:\n\nObwód klatki piersiowej: 104 cm\nDługość tyłu: 50 cm\nDługość rękawa od szyi: 76 cm\n\nWzrost modelki: 172 cm\n',
    descriptionUa:
      "Параметри косухи: \n\nОб'єм грудей: 104 см \nДовжина по спині: 50 см \nДовжина рукава від горловини: 76 см \n\nЗріст моделі: 172 см",
    img: [
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Ftmzwt06v-jq5vvp43-IMG_0628_1.webp?alt=media&token=e0a62190-cc06-44ef-bff2-dbff67e845cb',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F9tfv69eu-IMG_0577_1.jpg?alt=media&token=18205f6d-cbc5-4bdc-a272-0bf093561c30',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2F7n13xsmd-IMG_0593_1.jpg?alt=media&token=ebe84c3c-2fb3-4cc4-8c6c-a9bf6cb8b363',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fozo9atxf-z2bcmwyz-IMG_0606_1.webp?alt=media&token=4e8ede36-baca-4f85-a242-399fb6f0a4e4',
      'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/products%2Fjupmdnj4-kuaezy3p-IMG_0633_1.webp?alt=media&token=60b0d4c0-e26d-43f9-995e-31433d223e78',
    ],
    newSale: 'new',
    priceEn: '600',
    priceUa: '27000',
    skin: 'jumbo',
    titleEn: 'Jacket VICTORY',
    titlePl: 'Kurtka VICTORY',
    titleUa: 'Jacket VICTORY',
  },
};
