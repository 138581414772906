import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';
import { Context } from '../../Context';

export default function NotFound() {
  const { language } = useContext(Context);

  const texts = {
    ukr: {
      title: 'Схоже, сторінки немає...',
      go: 'перейти на головну сторінку',
      link: '/',
    },
    eng: {
      title: 'looks like the page is lost...',
      go: 'go main page',
      link: '/en/',
    },
    pl: {
      title: 'Wygląda na to, że strona została utracona...',
      go: 'przejdź do strony głównej',
      link: '/pl/',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="notFound">
      <div className="notFound__title">
        40
        <span className="notFound__title-reverse">4</span>
      </div>
      <div className="notFound__text">{selectedLanguageData.title}</div>
      <Link to={selectedLanguageData.link} className="thanks-page__button">
        {selectedLanguageData.go}
      </Link>
    </div>
  );
}
