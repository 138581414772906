import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import './Admin.css';

export default function Admin() {
  const navigate = useNavigate();

    const auth = getAuth();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({
    name: false,
    password: false,
  });

  const [errorsEnter, setErrorsEnter] = useState(false);

  const handleSubmit = async (e) => {
    setErrorsEnter(false);
    const newErrors = {
      name: formData.username === '',
      password: formData.password === '',
    };
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    e.preventDefault();

    try {
    await signInWithEmailAndPassword(auth, formData.username, formData.password);
    } catch (error) {
      setFormData({
        username: '',
        password: '',
      });
      setErrorsEnter(true);
      console.error('Ошибка аутентификации:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/setting');
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <div className="admin">
      <input
        className={`admin__input ${errors.name ? 'admin__input-error' : ''}`}
        type="text"
        placeholder={
          !errorsEnter ? (errors.name ? 'Введіть логін' : 'Логін') : 'не вірно введений логін'
        }
        name="username"
        value={formData.username}
        onChange={handleChange}
      />
      <input
        className={`admin__input ${errors.password ? 'admin__input-error' : ''}`}
        type="password"
        placeholder={
          !errorsEnter ? (errors.password ? 'Треба додати пароль' : 'Пароль') : 'або пароль'
        }
        name="password"
        value={formData.password}
        onChange={handleChange}
      />
      <button type="button" onClick={handleSubmit} className="admin__button">
        Увійти
      </button>
    </div>
  );
}
