import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth } from '../../../firebase';
import './DeleteAdmin.css';

export default function DeleteAdmin() {
  const location = useLocation();
  const [off, setOff] = useState(false);

  useEffect(() => {
    if (location.pathname === '/setting') {
      setOff(true);
    } else {
      setOff(false);
    }
  }, [location]);

  const handleLogout = () => {
    auth.signOut()
  }

  return (
    <div className={`delete ${off ? "delete-active" : ""}`}>
      <Link to="/setting" className='delete__item'>
        До адміністатора
      </Link>

      <button onClick={handleLogout} className='delete__item'>
        Забути цей пристрій
      </button>
    </div >
  );
};
