import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Context } from '../../Context';
import './Container.css';

export default function Container({ children }) {
  const {
    activeHeader,
    setActiveHeader,
    setActiveBurger,
    setCookiesActive,
    cookiesActive,
    language,
  } = useContext(Context);

  function handleLinks() {
    setActiveHeader(false);
    setActiveBurger(false);
  }

  useEffect(() => {
    setTimeout(() => {
      const gots = localStorage.getItem('cookies');
      if (!gots) {
        setCookiesActive(true);
      }
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const handleCloseCookies = () => {
    localStorage.setItem('cookies', true);
    setCookiesActive(false);
  };

  const texts = {
    ukr: {
      title:
        'Цей сайт використовує файли cookie для надання та поліпшення вашого досвіду покупок. Якщо ви бажаєте дізнатися більше, будь ласка, перегляньте нашу сторінку політики використання ',
      linkText: 'файлів cookie.',
      link: '/cookie-policy',
      buttonOne: 'Прийняти файли cookie',
      buttonTwo: 'Відхилити всі',
    },
    eng: {
      title:
        'this site uses cookies to provide and improve your shopping experience. If you want to learn more, please check our ',
      buttonOne: 'Accept cookies',
      buttonTwo: 'decline all',
      link: '/en/cookie-policy',
      linkText: 'cookies policy page.',
    },
    pl: {
      title:
        'Ta witryna używa plików cookie, aby zapewnić i ulepszyć Twoje doświadczenie zakupów. Jeśli chcesz dowiedzieć się więcej, proszę sprawdź naszą ',
      buttonOne: 'Zaakceptuj pliki cookie',
      buttonTwo: 'Odrzuć wszystkie',
      link: '/pl/cookie-policy',
      linkText: 'stronę polityki plików cookie.',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="container">
      <div
        onClick={handleLinks}
        className={`container__black ${activeHeader ? 'container__black-active' : ''}`}
      />

      {cookiesActive && (
        <div className="cookies">
          <p className="cookies-title">
            {selectedLanguageData.title}
            <Link
              to={selectedLanguageData.link}
              onClick={() => handleCloseCookies()}
              className="cookies-link"
            >
              {selectedLanguageData.linkText}
            </Link>
          </p>

          <button type="button" onClick={() => handleCloseCookies()} className="cookies__accept">
            {selectedLanguageData.buttonOne}
          </button>

          <button type="button" onClick={() => handleCloseCookies()} className="cookies__decline">
            {selectedLanguageData.buttonTwo}
          </button>
        </div>
      )}

      <Header />
      {children}
      <Footer />
    </div>
  );
}
