import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Context } from '../../Context';
import { handleStorageUpdate } from '../../functions/AddBasket';
import { isImage } from '../../functions/LoadImages';
import { Helmet } from 'react-helmet-async';
import { pathFilters } from '../Category/CategoryInfo';
import Item from '../../components/Item/Item';
import { seoData } from './ProductInfo';
import './Product.css';

export default function Product() {
  const { setActiveWindow, data, setBusketNumber, setWishList, wishList, language } = useContext(Context);

  const location = useLocation();

  // используется только для seo
  const lastSlashIndex = location.pathname.lastIndexOf('/');
  const secondLastSlashIndex = location.pathname.lastIndexOf('/', lastSlashIndex - 1);
  const seolLink = location.pathname.slice(secondLastSlashIndex);

  // товар СЕО
  const [productSeo, setProductSeo] = useState(false);
  // схема
  const [productJsonLd, setProductJsonLd] = useState(false);

  // находим SEO данные
  useEffect(() => {
    // Разделим строку по дефисам и возьмем последний элемент
    const idseo = location.pathname.split('-').pop();

    // Проверяем, существует ли объект SEO данных для текущего idseo
    const item = seoData[idseo] || false;

    // Данные SEO для разных языков
    const textsSeo = {
      ukr: {
        seoTitle: item?.seoTitleUa || '',
        seoDescription: item?.seoDescriptionUa || '',
        h1: item?.h1ua || '',
        title: item?.titleUa || '',
        price: item?.priceUa || '',
        priceCurrency: 'UAH',
        description: item?.descriptionUa || '',
        characteristic: item?.characteristicUa || '',
        img: item ? item.img[0] : '',
        lang: 'uk',
      },
      eng: {
        seoTitle: item?.seoTitleEn || '',
        seoDescription: item?.seoDescriptionEn || '',
        h1: item?.h1en || '',
        title: item?.titleEn || '',
        price: item?.priceEn || '',
        priceCurrency: 'EUR',
        description: item?.descriptionEn || '',
        characteristic: item?.characteristicEn || '',
        img: item ? item.img[0] : '',
        lang: 'en',
      },
      pl: {
        seoTitle: item?.seoTitlePl || '',
        seoDescription: item?.seoDescriptionPl || '',
        h1: item?.h1pl || '',
        title: item?.titlePl || '',
        price: item?.priceEn || '',
        priceCurrency: 'EUR',
        description: item?.descriptionPl || '',
        characteristic: item?.characteristicPl || '',
        img: item ? item.img[0] : '',
        lang: 'pl',
      },
    };

    // Выбираем SEO данные для текущего языка
    const selectedLanguageData = textsSeo[language.toLowerCase()];

    // Создаем объект JSON-LD для схемы товара
    const productJsonLd = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: selectedLanguageData.seoTitle,
      url: `https://gotslabel.com${location.pathname}`,
      image: selectedLanguageData.img,
      description: selectedLanguageData.seoDescription,
      brand: {
        '@type': 'Brand',
        name: 'Got`s Label',
      },
      offers: {
        '@type': 'Offer',
        priceCurrency: selectedLanguageData.priceCurrency,
        price: selectedLanguageData.price,
        itemCondition: 'https://schema.org/NewCondition',
        availability: 'InStock',
      },
    };

    // Устанавливаем данные SEO и JSON-LD
    setProductJsonLd(item ? productJsonLd : false);
    setProductSeo(selectedLanguageData);
  }, [location, language]);

  // товар
  const [product, setProduct] = useState(false);

  // для крошек ссылка категории
  const navLink = location.pathname.replace(/\/[^/]+$/, '');

  let { title } = pathFilters[navLink];

  // цвета
  const [colors, setColors] = useState(false);
  // главный товар
  const [mainProduct, setMainProduct] = useState(false);
  // const [fotoflag, setFotoFlag] = useState(false);
  const [foto, setFoto] = useState(false);
  // const [fotoTwo, setFotoTwo] = useState(false);
  const [safeFoto, setSafeFoto] = useState(false);
  // активный цвет
  const [activeColor, setActiveColor] = useState(0);
  // активное фото
  const [activeFoto, setActiveFoto] = useState(0);
  // иконка избранных
  const [onFavorite, setOnFavorite] = useState(false);

  // список рекомендаций
  const [list, setList] = useState(false);

  // получение id
  const { id } = useParams();
  let newId = id.match(/\d+/g).join('');

  const texts = {
    ukr: {
      seoTitle: product.seoTitleUa,
      seoDescription: product.seoDescriptionUa,
      h1: product.h1ua,
      art: 'Арт.',
      title: product.titleUa,
      price: product.priceUa,
      priceSale: product.priceUaSale,
      priceIcon: '₴',
      priceCurrency: 'UAH',
      color: 'Вибір кольору:',
      size: 'Розмір:',
      guide: 'Розмірна сітка',
      cart: 'До кошика',
      description: product.descriptionUa,
      descriptionTitle: '+ деталі продукту',
      characteristic: product.characteristicUa,
      characteristicTitle: '+ склад',
      // link: 'https://gotslabel.com',
      lang: 'uk',
      main: 'Головна',
      link: '/',
      recommended: 'Вам також може сподобатися',
    },
    eng: {
      seoTitle: product.seoTitleEn,
      seoDescription: product.seoDescriptionEn,
      h1: product.h1en,
      art: 'Art.',
      title: product.titleEn,
      price: product.priceEn,
      priceSale: product.priceEnSale,
      priceIcon: '€',
      priceCurrency: 'EUR',
      color: 'Color selection:',
      size: 'Size:',
      guide: 'Size guide',
      cart: 'To shopping cart',
      description: product.descriptionEn,
      descriptionTitle: '+ product details',
      characteristic: product.characteristicEn,
      characteristicTitle: '+ composition',
      // link: 'https://gotslabel.com/en',
      lang: 'en',
      main: 'Main',
      link: '/en',
      recommended: 'You may also like it',
    },
    pl: {
      seoTitle: product.seoTitlePl,
      seoDescription: product.seoDescriptionPl,
      h1: product.h1pl,
      art: 'Art.',
      title: product.titlePl,
      price: product.priceEn,
      priceSale: product.priceEnSale,
      priceIcon: '€',
      priceCurrency: 'EUR',
      color: 'Wybór koloru:',
      size: 'Rozmiar:',
      guide: 'Tabela rozmiarów',
      cart: 'Do koszyka',
      description: product.descriptionPl,
      descriptionTitle: '+ szczegóły produktu',
      characteristic: product.characteristicPl,
      characteristicTitle: '+ skład',
      // link: 'https://gotslabel.com/pl',
      lang: 'pl',
      main: 'Główny',
      link: '/pl',
      recommended: 'Może ci się również spodobać',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  // находим товар
  useEffect(() => {
    if (data && newId) {
      const filteredProduct = data.filter((item) => item.id === newId);

      setProduct(filteredProduct[0]);
      setMainProduct(filteredProduct[0]);
      setFoto(filteredProduct[0].img[0]);
      setSafeFoto(filteredProduct[0].img);

      const recommended = data
        .filter((item) => item.category === filteredProduct[0].category)
        .sort(() => 0.5 - Math.random()) // Перемешиваем массив случайным образом
        .slice(0, 4); // Берем первые 4 элемента

      setList(recommended);

      const filteredColors = data.filter((item) => item.article === filteredProduct[0].article && item.id !== filteredProduct[0].id);

      setColors(filteredColors);
    }
  }, [data, newId]);

  // нажатие на цвет
  const handleColorClick = (item, index) => {
    setProduct(item);
    setActiveColor(index);
    setFoto(item.img[0]);
    setActiveFoto(0);

    setSafeFoto(item.img);
    const newUrl = `${location.pathname.replace(/\d+$/, '')}${item.id}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  // нажатие на меленкую фотку
  const handleFotoClick = (foto, index) => {
    setActiveFoto(index);
    setFoto(foto);
  };

  useEffect(() => {
    if (wishList.length > 0) {
      const hasMatch = wishList.some((item) => item === product.id);
      if (!hasMatch) {
        setOnFavorite(false);
      } else {
        setOnFavorite(true);
      }
    } else {
      setOnFavorite(false);
    }

    // eslint-disable-next-line
  }, [wishList, product]);

  return (
    <>
      <Helmet>
        {/* Установка языка страницы */}
        <html lang={productSeo.lang} />

        {/* JSON-LD для поисковых систем */}
        {productJsonLd && <script type="application/ld+json">{JSON.stringify(productJsonLd)}</script>}

        {/* SEO метатеги */}
        <title>{productSeo.seoTitle}</title>
        <meta name="description" content={productSeo.seoDescription} />

        {/* Каноническая ссылка */}
        <link rel="canonical" href={`https://gotslabel.com${location.pathname}`} />

        {/* Альтернативные языковые версии */}
        <link rel="alternate" hreflang="uk" href={`https://gotslabel.com${seolLink}`} />
        <link rel="alternate" hreflang="pl" href={`https://gotslabel.com/pl${seolLink}`} />
        <link rel="alternate" hreflang="en" href={`https://gotslabel.com/en${seolLink}`} />
        <link rel="alternate" hreflang="x-default" href={`https://gotslabel.com${location.pathname}`} />

        {/* Open Graph для соцсетей */}
        <meta property="og:title" content={productSeo.seoTitle} />
        <meta property="og:description" content={productSeo.seoDescription} />
        <meta property="og:image" content={productSeo.img} />
        <meta property="og:url" content={`https://gotslabel.com${location.pathname}`} />
        <meta property="og:site_name" content="Got`s Label" />
        <meta property="og:type" content="website" />

        {/* Twitter метатеги */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={productSeo.seoTitle} />
        <meta name="twitter:description" content={productSeo.seoDescription} />
        <meta name="twitter:image" content={productSeo.img} />
      </Helmet>

      <h1 className="seoOff">{productSeo.h1}</h1>
      <p className="seoOff">{productSeo.description}</p>
      <p className="seoOff">{productSeo.characteristic}</p>

      {product ? (
        <div>
          <nav className="category__menu" aria-label="Breadcrumb">
            <ul className="category__menu-title">
              <li>
                <Link to={selectedLanguageData.link}>{selectedLanguageData.main}</Link>
              </li>
              <li>/</li>
              <li>
                <Link to={navLink}>{title}</Link>
              </li>
              <li>/</li>
              <li>{selectedLanguageData.title}</li>
            </ul>
          </nav>

          <div className="product">
            <div className="product__img">
              <button
                type="button"
                onClick={() => {
                  onFavorite
                    ? handleStorageUpdate(product.id, 'gots-favorite', setWishList, false)
                    : handleStorageUpdate(product.id, 'gots-favorite', setWishList, true);
                  setOnFavorite(!onFavorite);
                }}
                className="product__img-favorite"
              >
                <img
                  className="img-contain"
                  src={onFavorite ? '/img/header/favorite-full.svg' : '/img/header/favorite.svg'}
                  alt="favorite"
                />
              </button>

              <ul className="product__img-wrapper">
                {safeFoto &&
                  safeFoto.map((item, index) => {
                    return (
                      <li
                        onClick={() => handleFotoClick(item, index)}
                        key={index}
                        className={`product__img-wrapper-item ${activeFoto === index ? 'product__img-wrapper-item-active' : ''}`}
                      >
                        {isImage(item) ? (
                          <img src={item} alt="small foto" />
                        ) : (
                          <video className="product__img-wrapper-item-video" muted autoPlay loop playsInline controls={false}>
                            <source src={item} type="video/mp4" />
                          </video>
                        )}
                      </li>
                    );
                  })}
              </ul>

              {isImage(foto) ? (
                <img className="product__img" src={foto} alt={selectedLanguageData.title} />
              ) : (
                <video className="product__img" muted autoPlay loop playsInline controls={false}>
                  <source src={foto} type="video/mp4" />
                </video>
              )}
            </div>
            <div className="product__info">
              <h2 className="product__info-title">{selectedLanguageData.title}</h2>
              <div className="product__info-price">
                {selectedLanguageData.priceIcon}{' '}
                {selectedLanguageData.priceSale && selectedLanguageData.priceSale !== '' ? (
                  <>
                    {selectedLanguageData.priceSale}{' '}
                    <span className="product__info-price-sale">
                      {selectedLanguageData.priceIcon} {selectedLanguageData.price}
                    </span>
                  </>
                ) : (
                  selectedLanguageData.price
                )}
              </div>
              <div className="product__info-color-wrap">
                <p className="product__info-color-text">{selectedLanguageData.color}</p>
                <ul className="product__info-color-ul">
                  <li
                    onClick={() => handleColorClick(mainProduct, 0)}
                    className={`product__info-color ${activeColor === 0 ? 'product__info-color-active' : ''}`}
                  >
                    <div className={`product__info-color-body ${mainProduct.color}`} />
                  </li>
                  {colors &&
                    colors.map((item, index) => (
                      <li
                        onClick={() => handleColorClick(item, index + 1)}
                        key={index}
                        className={`product__info-color ${activeColor === index + 1 ? 'product__info-color-active' : ''}`}
                      >
                        <div className={`product__info-color-body ${item.color}`} />
                      </li>
                    ))}
                </ul>
              </div>
              <div className="product__info-size-wrap">
                <p className="product__info-size">{selectedLanguageData.size}</p>
                {/* <button className="product__info-sizeGuide">{selectedLanguageData.guide}</button> */}
              </div>
              <div className="product__info-size-button">OVERSIZE</div>
              <button
                type="button"
                onClick={() => {
                  handleStorageUpdate(product.id, 'gots', setBusketNumber, true);
                  setActiveWindow('gots');
                }}
                className="product__info-cart-button"
              >
                {selectedLanguageData.cart}
              </button>

              {product.descriptionUa && (
                <button
                  type="button"
                  onClick={() => setActiveWindow(['description', selectedLanguageData.description])}
                  className="product__info-more-button"
                >
                  {selectedLanguageData.descriptionTitle}
                </button>
              )}
              {product.characteristicUa && (
                <button
                  type="button"
                  onClick={() => setActiveWindow(['characteristic', selectedLanguageData.characteristic])}
                  className="product__info-more-button"
                >
                  {selectedLanguageData.characteristicTitle}
                </button>
              )}
            </div>
          </div>

          <h3 className="product__recommended">{selectedLanguageData.recommended}</h3>

          {list && (
            <ul className="item-list">
              {list.map((item, index) => (
                <Item key={item.id} item={item} recommended={true} />
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="load">
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
}
